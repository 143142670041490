import { Pojo } from '../types/pojo';

export const emptyStringToNullValues = (object: Pojo): Pojo => {
  const keysArray = Object.keys(object);
  const objectCopy = { ...object };

  keysArray.forEach((key) => {
    if (objectCopy[key] === '') {
      objectCopy[key] = null;
    }
  });
  return objectCopy;
};
