import { FormikHelpers } from 'formik';
import { useContext } from 'react';
import { DocumentsForm } from '../../../queries/documents/types';
import { useCreateDocumentMutation } from '../../../queries/documents/mutations';
import { DocumentFormValues, UseDocumentForm } from './types';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';

export const useDocumentForm = (): UseDocumentForm => {
  const { dispatch } = useContext(Store);

  const createMutation = useCreateDocumentMutation();

  const create = async (values: DocumentFormValues, actions: FormikHelpers<DocumentFormValues>) => {
    try {
      const request: DocumentsForm = values;
      await createMutation.mutateAsync({ request }, {
        onSuccess: async () => {
          dispatch({ type: storeActions.HIDE_DOCUMENT_MODAL });
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    create,
  };
};
