import { ListResponse } from './pagination';
import { Space } from './buildings';
// eslint-disable-next-line import/no-cycle
import { Tenant } from './tenant';

export enum LeaseType {
  'Full-Service Gross (FSG)' = 'Full-Service Gross',
  'Tripple Net (NNN)' = 'Tripple net',
  'Industrial Gross (IG)' = 'Industrial Gross',
}

export enum LeaseRateType {
  FT = 'FT',
  Fixed = 'Fixed',
}

export enum TermType {
  Months = 'Months',
  Years = 'Years',
}

export interface ExpiringLeases {
  leaseId: number
  remainingDays: number
  companyName: string
  buildingName: string
}

export interface LeaseExpirationScheduleData {
  year: number
  leasedArea: number
  occupancyPercentage: number
}

export interface Leases {
  area?: number
  id?: number | undefined
  startDate: string
  endDate: string
  isSublease: boolean
  price: number
  numberOfSpaces: number
  assetTitle: string
  buildingName: string
  termPeriod: number
  termType: string,
  expansionOption: boolean
  purchaseOption: boolean
  isMonthToMonth: boolean
  terminationOption: boolean
  type: string
  space?: Space
  company?: Tenant
  spaces: Space[]
  renewalNoticeStartDate: string
  renewalNoticeEndDate: string
  spaceId: string
  companyId: string
  buildingId: string
  guarantorCompanyName?: string
  guarantorStreetAddress?: string
  guarantorCity?: string
  guarantorState?: string
  guarantorZipCode?: string
  renewalOption?:boolean
  renewalTerm?: number
  renewalTermType?: string
}

export interface LeaseSpace {
  id: number | undefined
  name: string,
  occupiedByLease: string
  rateAmount: string
  isAvailableForSublease?: boolean,
  terminationOption?: boolean,
  tenantImprovement?: string
}

export interface PaginatedLeases extends ListResponse {
  items: Leases[]
  termPeriod: number
  spaces: LeaseSpace[]
  type: string
  renewalNoticeStartDate: string
  renewalNoticeEndDate: string
  expansionOption: boolean
  purchaseOption: boolean
  spaceId: string
  companyId: string
  buildingId: string
  guarantorCompanyName?: string
  guarantorCompanyAddress?: string
}
