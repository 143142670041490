import { makeStyles } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

export const useChartStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    maxWidth: '800px',
    display: 'flex',
  },
  percentWrapper: {
    display: 'flex',
  },
  percentDecrease: {
    display: 'flex',
    alignItems: 'center',
    color: red[500],
  },
  percentIncrease: {
    display: 'flex',
    alignItems: 'center',
    color: green[800],
  },
  companyValue: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2px',
  },
  linkPointer: {
    '&:hover': {
      background: grey[300],
      cursor: 'pointer',
    },
  },
  occupancyChart: {
    margin: '20px 30px',
  },
  autoWidth: {
    width: '200px',
  },
  assetName: {
    paddingTop: '22px',
    paddingRight: '16px',
  },
}));
