import {
  FormControl, FormHelperText, Grid, TextField,
} from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { SubLeaseFormProps, SubLeaseFormValues } from './types';
import { useGetAllTenantsQuery } from '../../../queries/tenants';
import { useSubLeaseForm } from './hooks';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';

const useStyles = makeStyles(() => createStyles({
  formSelect: {
    marginTop: '8px',
  },
  buttonWrapper: {
    display: 'flex',
    '& > button': {
      marginLeft: 'auto',
    },
  },
}));

interface FilmOptionType {
  inputValue?: string;
  name: string;
  id?: number;
}

const SubLeaseForm: React.FC<SubLeaseFormProps> = (props: SubLeaseFormProps): ReactElement => {
  const { model } = props;
  const { createOrUpdate, createSubLeaseCompany } = useSubLeaseForm();
  const { data: tenants } = useGetAllTenantsQuery();
  const classes = useStyles();
  const buttonClasses = useButtonsStyles();
  const filter = createFilterOptions<FilmOptionType>();
  const initialValues: SubLeaseFormValues = {
    companyId: model?.companyId ?? '',
    companyName: undefined,
  };

  const constructAutocompleteOptions:() => FilmOptionType[] | undefined = () => tenants?.map((item) => ({
    name: item.name,
    id: item.id,
    inputValue: item.name,
  }));

  const getDefaultValue = () => tenants?.find((item) => Number(item.id) === Number(model?.companyId));
  const [value, setValue] = useState(getDefaultValue());

  useEffect(() => {
    setValue(getDefaultValue());
  }, [tenants, model?.companyId]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values: SubLeaseFormValues, actions: FormikHelpers<SubLeaseFormValues>) => {
          if (!values.companyId) {
            createSubLeaseCompany(values, actions, Number(model?.leasedSpaceId));
          } else {
            await createOrUpdate(values, actions, Number(model?.leasedSpaceId));
          }
        }}
      >
        {(formProps: FormikProps<SubLeaseFormValues>) => {
          const {
            errors,
            values,
            isSubmitting,
            setFieldValue,
          } = formProps;

          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        defaultValue={value}
                        onChange={(event, newValue) => {
                          if (newValue && !newValue.id && newValue.inputValue) {
                            setFieldValue('companyName', newValue.inputValue);
                            setFieldValue('companyId', undefined);
                          } else {
                            setFieldValue('companyId', newValue?.id);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `Add "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        id="companyId"
                        clearOnBlur={false}
                        clearOnEscape={false}
                        options={constructAutocompleteOptions() || []}
                        getOptionLabel={(option) => option.name}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        renderOption={(optionProps, option) => <li {...optionProps}>{option?.name}</li>}
                        renderInput={(params) => (
                          <TextField
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...params}
                            label="Select or Create Tenant"
                            variant="outlined"
                            error={!!errors.companyId}
                          />
                        )}
                      />
                      <FormHelperText id="status-helper-text" error={!!errors.companyId}>
                        {errors.companyId || ''}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      margin="normal"
                      className={classes.buttonWrapper}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className={buttonClasses.large}
                        disabled={isSubmitting}
                      >
                        {(values.companyName && !values.companyId) ? 'CREATE TENANT & ADD SUBLEASE' : 'ADD SUB-LEASE'}
                      </Button>
                    </FormControl>
                    <LoaderModal isOpen={isSubmitting} />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SubLeaseForm;
