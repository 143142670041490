import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { Building } from '../../types/buildings';
import httpClient from '../../core/httpClient';
import { AggregatedCardData, CompanyStatisticsByLocation } from '../../types/statistics';
import { EmployeesDensityStat, ExpiringLeasesStat } from '../portfolio/types';
import { Asset, AssetsDropdown, AssetSpace } from '../../types/assets';
import { LeaseExpirationScheduleData } from '../../types/leases';
import { QUERY_OPTIONS } from '../../constants';

export const useGetAllAssetsQuery = (): UseQueryResult<Asset[], Error> => useQuery<Asset[], Error>(
  'assets',
  async () => {
    const response = await httpClient.get('/assets');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetAssetsDropdownQuery = (): UseQueryResult<AssetsDropdown[], Error> => useQuery<AssetsDropdown[], Error>(
  'assetsDropdown',
  async () => {
    const response = await httpClient.get('/assets/dropdown-options');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetAssetsBuildingsQuery = (): UseQueryResult<Building[],
Error> => useQuery<Building[], Error>(
  'assetsBuildings',
  async () => {
    const response = await httpClient.get('/buildings/unattached');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetSingleAssetQuery = (assetId: number): UseQueryResult<Asset, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Asset, Error>(
    ['assets', assetId],
    async () => {
      const response = await httpClient.get(`/assets/${assetId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      enabled: !!assetId,
      retry: 1,
      placeholderData: () : Asset | undefined => queryClient
        .getQueryData<Asset[]>('assets')
        ?.find((c:Asset) => c.id === assetId),
    },
  );
};

export const useGetSingleAsset = (assetId: number): UseQueryResult<Asset, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Asset, Error>(
    ['assets', assetId],
    async () => {
      const response = await httpClient.get(`/assets/${assetId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      enabled: !!assetId,
      placeholderData: () : Asset | undefined => queryClient
        .getQueryData<Asset[]>('assets')
        ?.find((c:Asset) => c.id === assetId),
    },
  );
};

export const useGetAssetsBannersQuery = (assetId: number): UseQueryResult<AggregatedCardData, Error> => useQuery<
AggregatedCardData, Error>(
  ['assetBanners', assetId],
  async () => {
    const response = await httpClient.get(`/assets/${assetId}/aggregated`);
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetAssetLeasesStatsQuery = (assetId: number):
UseQueryResult<ExpiringLeasesStat[], Error> => useQuery<ExpiringLeasesStat[], Error>(
  ['assetExpiringLeasesStats', assetId],
  async () => {
    const response = await httpClient.get(`/statistics/expiring-leases-stats?asset_id=${assetId}`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetAssetDensityStatsQuery = (assetId: number):
UseQueryResult<EmployeesDensityStat[], Error> => useQuery<EmployeesDensityStat[], Error>(
  ['assetEmployeesDensityStat', assetId],
  async () => {
    const response = await httpClient.get(`/statistics/density?asset_id=${assetId}`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetAssetDownsizingTenantsQuery = (assetId: number):
UseQueryResult<CompanyStatisticsByLocation[], Error> => useQuery<
CompanyStatisticsByLocation[], Error>(
  ['assetDownsizingTenant', assetId],
  async () => {
    const response = await httpClient.get(`/statistics/downsizing?asset_id=${assetId}`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetAssetHiringTenantsQuery = (assetId: number):
UseQueryResult<CompanyStatisticsByLocation[], Error> => useQuery<
CompanyStatisticsByLocation[], Error>(
  ['assetTopHiringTenant', assetId],
  async () => {
    const response = await httpClient.get(`/statistics/hiring?asset_id=${assetId}`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetAllAssetSpaces = (assetId: number | undefined, buildingId?: number): UseQueryResult<AssetSpace[],
Error> => useQuery<AssetSpace[], Error>(
  ['assetSpaces', assetId, buildingId],
  async () => {
    const query = {
      ...(buildingId && { building_id: buildingId }),
    };
    const response = await httpClient.get(`/assets/${assetId}/spaces`, {
      params: { ...query },
    });
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const getLeasesOccupancyScheduleByBuildingId = (
  buildingId: number | undefined,
): UseQueryResult<LeaseExpirationScheduleData[], Error> => useQuery<LeaseExpirationScheduleData[], Error>(
  ['occupancy-statistics', buildingId],
  async () => {
    const response = await httpClient.get(`/statistics/leases-occupancy-schedule?building_id=${buildingId}`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);
