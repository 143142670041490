import { AxiosError } from 'axios';
import { apiErrors, ApiErrorsModel } from './errors';
import { TenantiApiError, TenantiApiErrorBody } from './TenantiApiError';

export const createApiErrorBody = (error: AxiosError): TenantiApiErrorBody => ({
  name: error.response?.data.name,
  statusCode: error.response?.status,
  message: error.response?.data.message,
});

export class ErrorResolver {
  public apiErrorsMap: ApiErrorsModel = apiErrors;

  public resolve(error: TenantiApiErrorBody): TenantiApiError {
    const errorStatus = error.statusCode;
    if (errorStatus && this.apiErrorsMap[errorStatus]) {
      return new this.apiErrorsMap[errorStatus](error);
    }
    return new TenantiApiError(error);
  }
}

export const errorResolver = new ErrorResolver();
