import * as Yup from 'yup';
import { REQUIRED_FIELD } from '../../../constants';

const DealsValidationSchema = Yup.object().shape({
  dealType: Yup.string().required(REQUIRED_FIELD),
  stage: Yup.string().required(REQUIRED_FIELD),
  logDate: Yup.string().required(REQUIRED_FIELD),
});

export default DealsValidationSchema;
