import { TenantiApiError, TenantiApiErrorBody } from './TenantiApiError';
import { errorResolver } from './ErrorResolver';
import Emitter from '../../core/eventBus';
import { MODAL_ERROR } from '../../constants';

export class TenantiResponseError {
  private apiError: null | TenantiApiError = null;

  public resolveResponseError(error: TenantiApiErrorBody): Promise<TenantiApiError> {
    this.tenantiApiError = errorResolver.resolve(error);
    if (this.tenantiApiError.statusCode === 404) {
      Emitter.emit(MODAL_ERROR, this.tenantiApiError.message);
    }

    if (this.tenantiApiError.statusCode === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.replace('/auth/login');
    }

    return Promise.reject(this.apiError);
  }

  public set tenantiApiError(error: TenantiApiError | null) {
    this.apiError = error;
  }

  public get tenantiApiError(): TenantiApiError | null {
    return this.apiError;
  }
}

export const tenantiResponseError = new TenantiResponseError();
