import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { LoginFormValues, UseLoginForm } from './types';
import { useSecurity } from '../../../../hooks/useSecurity';
import { AlertProps } from '../../../ui/Alert';

const useLoginForm = (): UseLoginForm => {
  const { requestInviteAccess } = useSecurity();

  const [formAlert, setFormAlert] = useState<AlertProps>({
    message: '',
    type: undefined,
  });

  const sendInviteAccess = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
    setFormAlert({ message: '', type: undefined });

    try {
      const response = await requestInviteAccess(values);
      setFormAlert({
        message: response.data.message,
        type: 'success',
      });
      setTimeout(() => {
        setFormAlert({
          message: '',
          type: undefined,
        });
      }, 5000);
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
          setFormAlert({
            message: 'Something went wrong',
            type: 'error',
          });
      }
    }
  };

  return {
    sendInviteAccess,
    formAlert,
  };
};

export default useLoginForm;
