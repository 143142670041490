import React, {
  ReactElement, useEffect, useState,
} from 'react';
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  OutlinedInput,
  Card, Box, Typography, TextField, FormLabel, RadioGroup, FormControlLabel, Radio, Divider,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import {
  FieldArray,
  Form, Formik, FormikHelpers, FormikProps, getIn,
} from 'formik';
import { Delete } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { LeasesFormProps, LeasesFormValues } from './types';
import { useLeasesForm } from './hooks';
import { useGetAllTenantsQuery } from '../../../../queries/tenants';
import {
  Space,
} from '../../../../types/buildings';
import { useFormStyles } from '../../../../styles/useLeaseStyle';
import {
  useGetAllBuildingsByStatusQuery,
  useGetAllSpacesByBuildingIdQuery,
} from '../../../../queries/buildings';
import Alert from '../../../ui/Alert';
import { LeasesValidationSchema } from './validators';
import { LeaseSpace, LeaseType, TermType } from '../../../../types/leases';
import { TermPeriod } from './TermPeriod';
import { printNull } from '../../../../helpers/null';
import { formatAreaSize } from '../../../../formatters/areaSizeFormatter';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';
import { Tenant } from '../../../../types/tenant';
import { convertStringifiedBooleanToBoolean } from '../../../../helpers/convertStringifiedBooleanToBoolean';

const LeasesForm: React.FC<LeasesFormProps> = (props: LeasesFormProps): ReactElement => {
  const { model } = props;
  const classes = useFormStyles();
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    create, update, spaceError, rateError, dateError, renewalDateError,
  } = useLeasesForm();
  const [selectedBuildingId, setSelectedBuildingId] = useState<number | undefined>(
    model?.spaces?.[0]?.floor?.building ? Number(model?.spaces?.[0]?.floor?.building.id) : undefined,
  );
  const { data: spacesById } = useGetAllSpacesByBuildingIdQuery(Number(selectedBuildingId));
  const { data: tenants } = useGetAllTenantsQuery();
  const { data: buildings } = useGetAllBuildingsByStatusQuery();
  const [filteredSpaces, setFilteredSpaces] = useState<Space[]>(selectedBuildingId
  && spacesById?.length ? spacesById : []);
  const [selectedSpaceIds, setSelectedSpaceIds] = useState<(number | undefined)[]>([]);
  const [availableSpaces, setAvailableSpaces] = useState<Space[]>([]);
  const LeaseTypeList = Object.entries(LeaseType).map(([key, value]) => ({ key, value }));
  const buttonClasses = useButtonsStyles();
  const termTypes = Object.keys(TermType);

  const initialValues: LeasesFormValues = {
    buildingId: model?.spaces?.[0]?.floor?.building ? String(model?.spaces?.[0]?.floor?.building.id) : '',
    companyId: model?.company?.id ? String(model?.company?.id) : '',
    type: model?.type ?? '',
    startDate: model?.startDate ?? '',
    endDate: model?.endDate ?? '',
    termPeriod: model?.termPeriod ? String(model?.termPeriod) : '',
    termType: model?.termType ?? '',
    renewalNoticeStartDate: model?.renewalNoticeStartDate ?? '',
    renewalNoticeEndDate: model?.renewalNoticeEndDate ?? '',
    expansionOption: model?.expansionOption ?? false,
    purchaseOption: model?.purchaseOption ?? false,
    terminationOption: model?.terminationOption ?? false,
    isMonthToMonth: model?.isMonthToMonth ?? false,
    guarantorCompanyName: model?.guarantorCompanyName ?? '',
    guarantorStreetAddress: model?.guarantorStreetAddress ?? '',
    guarantorCity: model?.guarantorCity ?? '',
    guarantorState: model?.guarantorState ?? '',
    guarantorZipCode: model?.guarantorZipCode ?? '',
    renewalOption: model?.renewalOption ?? false,
    renewalTerm: model?.renewalTerm ? String(model.renewalTerm) : '',
    renewalTermType: model?.renewalTermType ?? '',
    spaces: model?.spaces
      ? model.spaces.map((space) => (
        {
          id: space.id,
          name: space.name,
          rateAmount: String(space.rate),
          occupiedByLease: String(space.occupiedByLease),
          tenantImprovement: String(space.tenantImprovement),
        }
      )) : [{
        id: undefined,
        name: '',
        rateAmount: '',
        occupiedByLease: '',
        tenantImprovement: '',
      }],
  };
  const getSpacesByBuildingId = (buildingId: number): void => {
    setAvailableSpaces([]);
    setSelectedBuildingId(buildingId);
  };

  const leaseTotalArea = (spaces: LeaseSpace[]) => {
    const occupiedLeaseAreas: number[] = [];
    spaces.forEach((space) => {
      if (space.occupiedByLease) {
        occupiedLeaseAreas.push(Number(space.occupiedByLease));
      }
    });

    let totalCount = 0;

    if (occupiedLeaseAreas.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < occupiedLeaseAreas.length; i++) {
        // eslint-disable-next-line radix
        totalCount += occupiedLeaseAreas[i];
      }
    }
    return totalCount;
  };

  const getSpaceName = (id: number) => {
    const foundSpace = filteredSpaces.find((space) => space.id === id);
    return printNull(foundSpace?.name);
  };

  // eslint-disable-next-line consistent-return
  const getSpaceOccupiedArea = (id: number) => {
    if (spacesById?.length) {
      const foundSpace = spacesById?.find((space) => space.id === id);
      return printNull(foundSpace?.vacantArea);
    }
  };

  const getVacantOnlySpaces = (spaces: Space[] | undefined) => {
    if (spaces) {
      return spaces.filter((space) => Number(space.vacantArea) > 0);
    }
    return [];
  };

  useEffect(() => {
    if (selectedBuildingId && spacesById?.length) {
      setFilteredSpaces(spacesById);
      if (!model?.spaces?.length) {
        setAvailableSpaces(getVacantOnlySpaces(spacesById));
      } else {
        setAvailableSpaces(spacesById);
        setSelectedSpaceIds((prevState) => {
          const preSelectedSpaces = model?.spaces?.map((space) => {
            if (space.vacantArea === 0) {
              return space.id;
            }
            return undefined;
          });
          return [...prevState, ...preSelectedSpaces];
        });
      }
    }
  }, [selectedBuildingId, spacesById]);

  const removeSpaceIdOfSelectedSpaceIds = (spaceId: number | undefined): void => {
    const newSelectedSpaceIds = selectedSpaceIds.filter((id) => id !== spaceId);
    setSelectedSpaceIds(newSelectedSpaceIds);
  };

  const isSpaceSelected = (spaceId: number, selectedSpaceId: number): boolean => {
    const foundSelectedSpaceId = selectedSpaceIds.find((ssId) => ssId === spaceId);

    return foundSelectedSpaceId ? foundSelectedSpaceId === selectedSpaceId : true;
  };

  const setSelectedSpaceId = (prevSpaceId: number, selectedSpaceId: number): void => {
    if (prevSpaceId) {
      const foundIndex = selectedSpaceIds.findIndex((ssId) => ssId === prevSpaceId);
      selectedSpaceIds[foundIndex] = selectedSpaceId;
      setSelectedSpaceIds(selectedSpaceIds);
    } else {
      setSelectedSpaceIds([...selectedSpaceIds, selectedSpaceId]);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={!!model}
        validationSchema={LeasesValidationSchema}
        initialValues={initialValues}
        onSubmit={async (values: LeasesFormValues, actions: FormikHelpers<LeasesFormValues>) => {
          if (model?.id != null) {
            await update(values, actions, Number(model.id));
          } else {
            await create(values, actions);
          }
        }}
      >
        {(formProps: FormikProps<LeasesFormValues>) => {
          const {
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            isSubmitting,
            submitForm,
          } = formProps;

          return (
            <>
              <Grid container spacing={3} className={classes.formWrapper}>
                <Grid item xs={12}>
                  <Card>
                    <Grid container className={classes.root}>
                      <Grid item xs={12} md={11}>
                        <Box>
                          <Form noValidate>
                            {
                              (selectedBuildingId && spacesById?.length === 0)
                                ? <Alert message="No vacant spaces available." type="error" />
                                : ''
                            }
                            <Box>
                              <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                Terms
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    className={classes.fieldsWidth}
                                  >
                                    <Autocomplete
                                      fullWidth
                                      disablePortal
                                      id="buildingId"
                                      disabled={model?.id != null}
                                      className={classes.buildingSelect}
                                      options={buildings || []}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, value) => {
                                        setFieldValue('buildingId', value?.id);
                                        getSpacesByBuildingId(Number(value?.id));
                                      }}
                                      value={
                                        buildings?.find((item) => Number(item.id) === Number(values.buildingId)) || null
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                                          {...params}
                                          label="Choose Building"
                                          variant="outlined"
                                          name="buildingId"
                                          error={!!errors.buildingId}
                                        />
                                      )}
                                    />
                                    <FormHelperText
                                      data-testid="building-helper-text"
                                      error={!!errors.buildingId}
                                    >
                                      {errors.buildingId}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    className={classes.fieldsWidth}
                                  >
                                    <InputLabel
                                      shrink={!!values.companyId}
                                      htmlFor="company-label"
                                    >
                                      Choose Tenant
                                    </InputLabel>
                                    <Select
                                      native
                                      input={
                                        <OutlinedInput notched={!!values.companyId} label="Choose Tenant" />
                                      }
                                      inputProps={{
                                        name: 'companyId',
                                        id: 'company-label',
                                      }}
                                      value={values.companyId}
                                      onChange={handleChange}
                                      error={!!errors.companyId}
                                      data-testid="companyId"
                                      label="Choose Tenant"
                                    >
                                      <option aria-label="None" value="" />
                                      {tenants?.map((tenant:Tenant) => (
                                        <option key={tenant.id} value={tenant.id}>{tenant.name}</option>
                                      ))}
                                    </Select>
                                    <FormHelperText
                                      data-testid="tenant-helper-text"
                                      error={!!errors.companyId}
                                    >
                                      {errors.companyId}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl
                                    margin="normal"
                                    required
                                    variant="outlined"
                                    error={!!errors.type}
                                    className={classes.fieldsWidth}
                                  >
                                    <InputLabel
                                      shrink={!!values.type}
                                      htmlFor="type-label"
                                    >
                                      Lease Rate Type
                                    </InputLabel>
                                    <Select
                                      input={
                                        <OutlinedInput notched={!!values.type} label="Lease Rate Type" />
                                      }
                                      inputProps={{
                                        name: 'type',
                                        id: 'type-label',
                                      }}
                                      value={values.type}
                                      onChange={handleChange}
                                      label="Lease Rate Type"
                                      native
                                    >
                                      <option aria-label="None" value="" />
                                      {LeaseTypeList?.map((item) => (
                                        <option key={item.key} value={item.value}>{item.key}</option>
                                      ))}
                                    </Select>
                                    <FormHelperText
                                      id="type-helper-text"
                                      data-testid="type-helper-text"
                                      error={!!errors.type}
                                    >
                                      {errors.type}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <TermPeriod
                                startDateName="startDate"
                                periodName="termPeriod"
                                termTypeName="termType"
                                endDateName="endDate"
                              />
                            </Box>
                            <Divider className={classes.divider} />
                            <Box>
                              <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                Other Terms
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item sm={12} md={12} lg={12}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      component="legend"
                                      className={classes.radioButtonLabel}
                                    >
                                      Renewal Option
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="renewal-option"
                                      name="renewalOption"
                                      className={classes.radioButtonOptions}
                                      value={String(values.renewalOption)}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              {convertStringifiedBooleanToBoolean(String(values.renewalOption))
                                && (
                                <Grid container spacing={4}>
                                  <Grid item sm={12} md={6} lg={4} style={{ marginTop: '6px' }}>
                                    <FormControl className={classes.datePicker}>
                                      <TextField
                                        id="renewalNoticeStartDate"
                                        name="renewalNoticeStartDate"
                                        data-testid="renewal-start-date"
                                        label="Renewal Notice Start Date"
                                        type="date"
                                        margin="normal"
                                        error={(!!errors.renewalNoticeStartDate) || !!renewalDateError}
                                        helperText={errors.renewalNoticeStartDate || renewalDateError}
                                        onChange={handleChange}
                                        value={values.renewalNoticeStartDate}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={12} md={6} lg={4} style={{ marginTop: '6px' }}>
                                    <FormControl className={classes.datePicker}>
                                      <TextField
                                        id="renewalNoticeEndDate"
                                        name="renewalNoticeEndDate"
                                        data-testid="renewal-end-date"
                                        label="Renewal Notice End Date"
                                        type="date"
                                        margin="normal"
                                        error={!!errors.renewalNoticeEndDate}
                                        helperText={errors.renewalNoticeEndDate}
                                        onChange={handleChange}
                                        value={values.renewalNoticeEndDate}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={12} md={6} lg={4}>
                                    <Box className={classes.fieldsWidth}>
                                      <Typography
                                        variant="caption"
                                        className={classes.leaseLabels}
                                      >
                                        Renewal Term
                                      </Typography>
                                      <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                          <FormControl
                                            className={classes.parallelFieldsWidth}
                                            style={{ margin: '0' }}
                                          >
                                            <TextField
                                              value={values.renewalTerm}
                                              onChange={handleChange}
                                              variant="outlined"
                                              required
                                              id="renewalTerm"
                                              placeholder="Number"
                                              error={!!errors.renewalTerm}
                                            />
                                            <FormHelperText
                                              error={!!errors.renewalTerm}
                                            >
                                              {errors.renewalTerm}
                                            </FormHelperText>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <FormControl
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.parallelFieldsWidth}
                                            style={{ margin: '0' }}
                                          >
                                            <Select
                                              value={values.renewalTermType}
                                              onChange={handleChange}
                                              native
                                              id="renewalTermType"
                                            >
                                              <option aria-label="None" value="" disabled>
                                                Period
                                              </option>
                                              {termTypes?.map((type: string) => (
                                                <option key={type} value={type}>{type}</option>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                                )}
                              <Grid container spacing={4}>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      component="legend"
                                      className={classes.radioButtonLabel}
                                    >
                                      Expansion Option
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="expansion-option"
                                      name="expansionOption"
                                      className={classes.radioButtonOptions}
                                      value={
                                      String(values.expansionOption)
                                    }
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      component="legend"
                                      className={classes.radioButtonLabel}
                                    >
                                      Purchase Option
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="purchase-option"
                                      name="purchaseOption"
                                      className={classes.radioButtonOptions}
                                      value={String(values.purchaseOption)}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid container spacing={4}>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      component="legend"
                                      className={classes.radioButtonLabel}
                                    >
                                      Termination Option
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="termination-option"
                                      name="terminationOption"
                                      className={classes.radioButtonOptions}
                                      value={String(values.terminationOption)}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      component="legend"
                                      className={classes.radioButtonLabel}
                                    >
                                      Month-to-month
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="month-to-month"
                                      name="isMonthToMonth"
                                      className={classes.radioButtonOptions}
                                      value={String(values.isMonthToMonth)}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={
                                          <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Divider className={classes.divider} />
                              <Box>
                                <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                  GUARANTOR COMPANY INFO
                                </Typography>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <FormControl fullWidth>
                                      <TextField
                                        variant="outlined"
                                        className={classes.fieldsWidth}
                                        name="guarantorCompanyName"
                                        placeholder="Guarantor company name"
                                        value={values.guarantorCompanyName}
                                        error={!!errors.guarantorCompanyName}
                                        helperText={errors.guarantorCompanyName}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <FormControl fullWidth>
                                      <TextField
                                        variant="outlined"
                                        placeholder="Street address"
                                        className={classes.fieldsWidth}
                                        name="guarantorStreetAddress"
                                        value={values.guarantorStreetAddress}
                                        error={!!errors.guarantorStreetAddress}
                                        helperText={errors.guarantorStreetAddress}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <FormControl fullWidth>
                                      <TextField
                                        variant="outlined"
                                        placeholder="City"
                                        className={classes.fieldsWidth}
                                        name="guarantorCity"
                                        value={values.guarantorCity}
                                        error={!!errors.guarantorCity}
                                        helperText={errors.guarantorCity}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <FormControl fullWidth>
                                      <TextField
                                        variant="outlined"
                                        placeholder="State"
                                        className={classes.fieldsWidth}
                                        name="guarantorState"
                                        value={values.guarantorState}
                                        error={!!errors.guarantorState}
                                        helperText={errors.guarantorState}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <FormControl fullWidth>
                                      <TextField
                                        variant="outlined"
                                        placeholder="Zip code"
                                        className={classes.fieldsWidth}
                                        name="guarantorZipCode"
                                        value={values.guarantorZipCode}
                                        error={!!errors.guarantorZipCode}
                                        helperText={errors.guarantorZipCode}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Form>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <Box className={classes.root}>
                      <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                        Rent Terms: Available Spaces
                      </Typography>
                      <Box>
                        <FieldArray
                          name="spaces"
                          render={({ remove, push }) => (
                            <Box>
                              {values.spaces?.map((space: LeaseSpace, index: number) => {
                                const spaceErrors = 'spaces[0].spaceId';
                                const spaceId = `spaces[${index}].id`;
                                const rateAmount = `spaces[${index}].rateAmount`;
                                const occupiedByLease = `spaces[${index}].occupiedByLease`;
                                const isAvailableForSublease = `spaces[${index}].isAvailableForSublease`;
                                const tenantImprovement = `spaces[${index}].tenantImprovement`;

                                return (
                                  <Box key={space.id}>
                                    <Box display="flex" justifyContent="space-between">
                                      <Typography variant="h6" display="block" className={classes.totalLeasedArea}>
                                        Space
                                        {' '}
                                        {getSpaceName(Number(space.id))}
                                      </Typography>
                                      {
                                        values.spaces.length > 1 && (
                                          <Button
                                            size="small"
                                            variant="text"
                                            onClick={async () => {
                                              remove(index);
                                              removeSpaceIdOfSelectedSpaceIds(Number(space.id));
                                            }}
                                          >
                                            <Delete style={{ color: grey[500] }} />
                                          </Button>
                                        )
                                      }
                                    </Box>
                                    <Grid container>
                                      <Grid item xs={12} md={11}>
                                        <FormControl fullWidth key={space.id}>
                                          <Grid container spacing={4}>
                                            <Grid item sm={12} md={6} lg={4}>
                                              <FormControl
                                                margin="normal"
                                                required
                                                variant="outlined"
                                                className={classes.fieldsWidth}
                                              >
                                                <InputLabel
                                                  shrink={!!spaceId}
                                                  htmlFor={spaceId}
                                                >
                                                  Choose Space
                                                </InputLabel>
                                                <Select
                                                  input={
                                                    <OutlinedInput notched={!!spaceId} label="Choose Space" />
                                                  }
                                                  inputProps={{
                                                    name: spaceId,
                                                    id: spaceId,
                                                  }}
                                                  value={space.id}
                                                  onChange={(event) => {
                                                    handleChange(event);
                                                    setFieldValue(
                                                      occupiedByLease, getSpaceOccupiedArea(Number(event.target.value)),
                                                    );
                                                    setSelectedSpaceId(Number(space.id), Number(event.target.value));
                                                  }}
                                                  label="Choose Space"
                                                  error={getIn(touched, spaceId) && getIn(errors, spaceErrors)}
                                                  disabled={!selectedBuildingId}
                                                  native
                                                >
                                                  <option aria-label="None" value="" />
                                                  {/* eslint-disable-next-line array-callback-return */}
                                                  {availableSpaces?.map((selectedSpace) => (
                                                    isSpaceSelected(Number(selectedSpace.id), Number(space.id))
                                                      ? (
                                                        <option
                                                          key={selectedSpace.id}
                                                          value={selectedSpace.id}
                                                        >
                                                          {selectedSpace.name}
                                                        </option>
                                                      )
                                                      : null))}
                                                </Select>
                                                <FormHelperText
                                                  id={`${spaceId}-helper-text`}
                                                  data-testid={`${spaceId}-helper-text`}
                                                >
                                                  {getIn(touched, spaceId) && getIn(errors, spaceErrors)}
                                                </FormHelperText>
                                              </FormControl>
                                            </Grid>
                                            <>
                                              <Grid item xs={12} md={6} lg={4}>
                                                <TextField
                                                  value={space.occupiedByLease}
                                                  onChange={handleChange}
                                                  error={(getIn(touched, occupiedByLease)
                                                      && getIn(errors, occupiedByLease)) || spaceError}
                                                  helperText={(getIn(touched, occupiedByLease)
                                                      && getIn(errors, occupiedByLease)) || spaceError}
                                                  variant="outlined"
                                                  margin="normal"
                                                  className={classes.fieldsWidth}
                                                  id={occupiedByLease}
                                                  required
                                                  label="Leased Area"
                                                  type="text"
                                                  name={occupiedByLease}
                                                />
                                              </Grid>
                                              <Grid item xs={12} md={6} lg={4}>
                                                <TextField
                                                  value={space.rateAmount}
                                                  onChange={handleChange}
                                                  error={(getIn(touched, rateAmount) && getIn(errors, rateAmount))
                                                      || rateError}
                                                  helperText={(getIn(touched, rateAmount) && getIn(errors,
                                                    rateAmount)) || rateError}
                                                  variant="outlined"
                                                  margin="normal"
                                                  className={classes.fieldsWidth}
                                                  id={rateAmount}
                                                  required
                                                  label="Rate Amount"
                                                  type="text"
                                                  name={rateAmount}
                                                  InputProps={{
                                                    startAdornment: (
                                                      <Box pr={1}>
                                                        <Typography>$</Typography>
                                                      </Box>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item sm={12} md={6} lg={4}>
                                                <FormControl
                                                  margin="normal"
                                                  required
                                                  variant="outlined"
                                                  className={classes.fieldsWidth}
                                                >
                                                  <InputLabel
                                                    shrink={!!isAvailableForSublease}
                                                    htmlFor={isAvailableForSublease}
                                                  >
                                                    Available For Sublease
                                                  </InputLabel>
                                                  <Select
                                                    value={values.isAvailableForSublease}
                                                    input={(
                                                      <OutlinedInput
                                                        notched={!!isAvailableForSublease}
                                                        label="Available For Sublease"
                                                      />
                                                        )}
                                                    inputProps={{
                                                      name: isAvailableForSublease,
                                                      id: isAvailableForSublease,
                                                    }}
                                                    onChange={(event) => {
                                                      handleChange(event);
                                                    }}
                                                    label="Available For Sublease"
                                                    error={getIn(touched, isAvailableForSublease) && getIn(
                                                      errors, isAvailableForSublease,
                                                    )}
                                                    native
                                                  >
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>
                                                  </Select>
                                                  <FormHelperText
                                                    id={`${isAvailableForSublease}-helper-text`}
                                                    data-testid={`${isAvailableForSublease}-helper-text`}
                                                  >
                                                    {getIn(touched, isAvailableForSublease) && getIn(
                                                      errors, isAvailableForSublease,
                                                    )}
                                                  </FormHelperText>
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={6} lg={4}>
                                                <TextField
                                                  value={space.tenantImprovement}
                                                  onChange={handleChange}
                                                  variant="outlined"
                                                  margin="normal"
                                                  className={classes.fieldsWidth}
                                                  id={tenantImprovement}
                                                  label="Tenant Improvement ($/ft)"
                                                  type="text"
                                                  name={tenantImprovement}
                                                />
                                              </Grid>
                                            </>
                                          </Grid>
                                        </FormControl>
                                        <Divider className={classes.divider} />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                );
                              })}
                              <Grid container className={classes.totalAreaWrapper}>

                                <Typography variant="h6" display="block">
                                  Total Leased Area:
                                  {' '}
                                  {
                                    values.spaces && (
                                      formatAreaSize(leaseTotalArea(values?.spaces))
                                    )
                                  }
                                </Typography>
                                <Grid item>
                                  <Box className={classes.addTermButton}>
                                    <Button
                                      className={clsx(classes.addSpaceButton, buttonClasses.standard)}
                                      onClick={() => {
                                        push({
                                          id: undefined,
                                          name: '',
                                          rateAmount: '',
                                          occupiedByLease: '',
                                        });
                                      }}
                                    >
                                      ADD SPACE
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
              <Box className={classes.buttonWrapper}>
                <FormControl margin="normal">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="medium"
                    data-testid="leases-button"
                    disabled={isSubmitting}
                    className={
                    model?.id != null ? buttonClasses.standard : buttonClasses.large
                  }
                    onClick={submitForm}
                  >
                    {model?.id != null ? 'Save Lease' : 'Create Lease'}
                  </Button>
                </FormControl>
              </Box>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default LeasesForm;
