import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Box, FormControl, IconButton, InputLabel, Select, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { useGetAllTenantsQuery, useGetSingleTenantQuery } from '../../../queries/tenants';
import { Tenant } from '../../../types/tenant';
import { accessToken } from '../../../models/security';

export interface TenantDetailsFilterProps {
  tenantId: number;
  compared: boolean,
  onFilterChange: (companyId: number, compared: boolean) => void
}

const TenantDetailsFilter: React.FC<TenantDetailsFilterProps> = (props: TenantDetailsFilterProps): ReactElement => {
  const { tenantId, compared, onFilterChange } = props;
  const { data: tenants } = useGetAllTenantsQuery();
  const { data: tenant } = useGetSingleTenantQuery(Number(tenantId));

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    onFilterChange(Number(event.target.value), compared);
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <Box display="flex" flexDirection="row" px={2}>
            <Typography component="h1" color="primary" variant="h5">
              <Link to={`/tenants/view/${tenant?.id}`}>
                {tenant?.name}
              </Link>
            </Typography>
            {!accessToken.isCurrentRoleBroker()
            && (
            <Box pl={2}>
              <IconButton size="small" component={Link} to={`/tenants/edit/${tenant?.id}`}>
                <Edit fontSize="small" />
              </IconButton>
            </Box>
            )}
          </Box>
        </Grid>
        {!accessToken.isCurrentRoleBroker()
          && (
          <Grid item xs={3}>
            <FormControl
              margin="normal"
              fullWidth
            >
              <InputLabel shrink htmlFor="tenant-label" required>
                {`Compare ${tenant?.name}`}
              </InputLabel>
              <Select
                native
                inputProps={{
                  name: 'comparedTenantId',
                  id: 'tenant-label',
                }}
                value={tenant?.id}
                label={`Compare ${tenant?.name}`}
                onChange={handleFilterChange}
              >
                {tenants?.map((singleCompany: Tenant) => (
                  <option key={singleCompany.id} value={singleCompany.id}>{singleCompany.name}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          )}
      </Grid>
    </>
  );
};

export default TenantDetailsFilter;
