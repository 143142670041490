import * as Yup from 'yup';
import { MUST_BE_NUMBER, REQUIRED_FIELD } from '../../../../constants';

export const LeasesValidationSchema = Yup.object().shape({
  companyId: Yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  buildingId: Yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  type: Yup.string().required(REQUIRED_FIELD),
  startDate: Yup.string().required(REQUIRED_FIELD),
  endDate: Yup.string().required(REQUIRED_FIELD),
  termPeriod: Yup.number().typeError(MUST_BE_NUMBER).required(REQUIRED_FIELD),
  termType: Yup.string().required(REQUIRED_FIELD),
  renewalTerm: Yup.number().typeError(MUST_BE_NUMBER),
});
