import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Card, FormControl } from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import useLoginForm from './hooks';
import { useCardStyles } from '../../../../styles/useCardStyles';
import Alert from '../../../ui/Alert';
import { LoginFormValues } from './types';
import PageTitle from '../../../ui/PageTitle';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';

const LoginForm: React.FC = (): ReactElement => {
  const classes = useCardStyles();
  const { sendInviteAccess, formAlert } = useLoginForm();
  const buttonClasses = useButtonsStyles();

  const initialValues: LoginFormValues = {
    email: '',
  };

  return (
    < >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
          await sendInviteAccess(values, actions);
        }}
      >
        {(props: FormikProps<LoginFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = props;
          return (
            <Card className={classes.card}>
              <PageTitle>Log in</PageTitle>
              <Form noValidate>
                <FormControl fullWidth>
                  <TextField
                    value={values.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Button
                    type="submit"
                    fullWidth
                    className={buttonClasses.standard}
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </Button>
                </FormControl>
                <Alert message={formAlert.message} type={formAlert.type} />
              </Form>
            </Card>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
