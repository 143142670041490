import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import Portfolio from './Portfolio';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import { AuthRoles } from '../../../types/auth';

const PortfolioIndex: React.FC = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter
        exact
        path={path}
        component={Portfolio}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
    </RouterBaseSwitch>
  );
};

export default PortfolioIndex;
