import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ModalProps } from '../interfaces';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';

const useStyles = makeStyles(() => createStyles({
  paperWidth: {
    width: 418,
  },
  cancelIcon: {
    paddingTop: '16px',
    padding: '0',
  },
  title: {
    fontSize: 'large',
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    '& h2': {
      fontWeight: 'bold',
    },
  },
  body: {
    display: 'flex',
    textAlign: 'center',
    padding: '19px 54px 26px 54px',
    margin: '0',
    color: 'black',
  },
}));

const SuccessModal: React.FC<ModalProps> = (props: ModalProps): ReactElement => {
  const {
    isOpen = false,
    handleClose,
    cancelAction,
    title = 'Confirmation',
    body,
    cancelText = 'Cancel',
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const buttonClasses = useButtonsStyles();

  const handleCancelAction = () => {
    if (cancelAction) {
      cancelAction();
    }
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      classes={{
        paper: classes.paperWidth,
      }}
    >
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancelAction}
          className={clsx(classes.cancelIcon, buttonClasses.standard)}
        >
          {cancelText}
        </Button>
      </DialogActions>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.body}>
          {body}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
