import React, { ReactElement } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useGetAllFloorsQuery, useGetAllSpacesByBuildingIdQuery } from '../../../queries/buildings';
import { LayoutRouter } from '../../../router/LayoutRouter';
import EditSpace from './EditSpace';
import CreateSpace from './CreateSpace';
import { SpaceRouteProps } from './interfaces';
import ListSpaces from './ListSpaces';
import ViewSpace from '../../forms/buildings/spaces/SpaceForm/ViewSpace';
import { AuthRoles } from '../../../types/auth';

const SpacesIndex:React.FC = (): ReactElement => {
  const { path } = useRouteMatch();
  const { buildingId } = useParams<SpaceRouteProps>();

  useGetAllFloorsQuery(Number(buildingId));
  useGetAllSpacesByBuildingIdQuery(Number(buildingId));

  return (
    <Switch>
      <LayoutRouter exact path={path} component={ListSpaces} isPrivate />
      <LayoutRouter
        path={`${path}/edit/:spaceId`}
        component={EditSpace}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        path={`${path}/view/:spaceId`}
        component={ViewSpace}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        path={`${path}/create`}
        component={CreateSpace}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
    </Switch>
  );
};

export default SpacesIndex;
