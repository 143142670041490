import React, { ReactElement } from 'react';
import PageTitle from '../../ui/PageTitle';
import NewsForm from '../../forms/news/NewsForm/NewsForm';

const CreateNews: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create News</PageTitle>
    <NewsForm />
  </>
);

export default CreateNews;
