import React, {
  ReactElement, useEffect, useState,
} from 'react';
import {
  Box, Button, FormControl, IconButton, InputAdornment, InputLabel, Select, TextField, Tooltip, Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import {
  useGetTenantByYearQuery,
} from '../../../queries/tenants';
import { useDeleteTenant } from '../../../queries/tenants/mutations';
import PageTitle from '../../ui/PageTitle';
import { useChartStyles } from '../../../styles/useChartStyles';
import { formatPercentage } from '../../../formatters/percentageFormatter';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { useGetExpiringLeasesYears } from '../../../queries/leases';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { printNull } from '../../../helpers/null';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { sortAreaColumn } from '../../../helpers/sortArea';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { AssetsDropdown } from '../../../types/assets';
import { useGetAssetsDropdownQuery } from '../../../queries/assets';
import { useListingsFiltrationStyles } from '../../../styles/useListingsFiltrationStyles';
import { accessToken } from '../../../models/security';
import { TenantListing } from '../../../types/tenant';

const ListTenants: React.FC = (): ReactElement => {
  const deleteCompanyMutation = useDeleteTenant();
  const classes = useChartStyles();
  const [filterFromDate, setFilterFromDate] = useState<string>('');
  const [filterToDate, setFilterToDate] = useState<string>('');
  const [filteredTenant, setFilteredTenant] = useState<string>('');
  const [filteredAsset, setFilteredAsset] = useState<string>('');
  const [tenantName, setTenantName] = useState<string>('');
  const [hasTenantToEdit, setHasTenantToEdit] = useState<boolean>(false);
  const { data: assets } = useGetAssetsDropdownQuery();
  const query = new URLSearchParams(useLocation().search);
  const { tenantsNavigator } = useNavigationManager();
  const { path } = useRouteMatch();
  const history = useHistory();

  const {
    data: companyAggregatedData,
    isLoading,
    isFetching,
  } = useGetTenantByYearQuery(filterFromDate, filterToDate, filteredTenant, Number(filteredAsset));
  const { data: expiringLeasesYears } = useGetExpiringLeasesYears();

  const { showModal, RenderModal, modalId } = useDeleteModal();

  const buttonClasses = useButtonsStyles();
  const filterClasses = useListingsFiltrationStyles();

  const isTenantActionHidden = () => {
    if (!accessToken.isCurrentRoleBroker()) {
      return false;
    }
    if (companyAggregatedData && accessToken.isCurrentRoleBroker()) {
      const result = companyAggregatedData?.find((tenant) => tenant.canEdit === true);
      return !result;
    }
    return false;
  };

  useEffect(() => {
    const fromYear = query.get('leaseEndYearFrom') ?? '';
    const toYear = query.get('leaseEndYearTo') ?? '';
    const id = query.get('assetId') ?? '';
    const tenant = query.get('tenantName') ?? '';
    setFilterFromDate(fromYear);
    setFilterToDate(toYear);
    setFilteredAsset(id);
    setFilteredTenant(tenant);
    setTenantName(tenant);
    setHasTenantToEdit(isTenantActionHidden());
  }, []);

  useEffect(() => {
    query.set('assetId', filteredAsset);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [filteredAsset]);

  useEffect(() => {
    query.set('tenantName', tenantName);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [tenantName]);

  const deleteCompany = (companyId: number | null) => {
    if (!companyId) {
      return;
    }
    deleteCompanyMutation.mutate({ companyId });
  };

  const navigateToEditCompany = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    tenantsNavigator.edit(Number(id)).navigate();
  };

  const columns: MRT_ColumnDef<TenantListing>[] = [
    {
      header: 'Tenant Name',
      accessorKey: 'companyName',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.companyName || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {row.companyName || ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      header: 'Number of Leases',
      accessorKey: 'numberOfLeases',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <Box fontSize={14}>{printNull(row.numberOfLeases)}</Box>
        </Box>
      ),
    },
    {
      header: 'Local Employees',
      accessorKey: 'employeesCount',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => {
        const percentage = row.employeesCountChange;
        const isPercentageEmpty = !percentage || percentage === 0;
        const isPercentagePositive = percentage && percentage > 0;
        return (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Box fontSize={14}>{printNull(row.employeesCount)}</Box>
            {
              !isPercentageEmpty
                ? (
                  <Box fontSize={12} className={isPercentagePositive ? classes.percentIncrease : classes.percentDecrease}>
                    {!isPercentageEmpty && isPercentagePositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    {formatPercentage(Number(percentage))}
                  </Box>
                )
                : (
                  <></>
                )
            }
          </Box>
        );
      },
    },
    {
      header: 'Total Area ft',
      accessorKey: 'totalArea',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => formatAreaSize(row.totalArea),
      sortingFn: (rowA, rowB) => sortAreaColumn(String(rowA.original.totalArea), String(rowB.original.totalArea)),
    },
    {
      header: 'Lease End Date',
      accessorKey: 'leaseEndDate',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip
          title={row.leaseEndDate ? formatDateUSReadable(
            row.leaseEndDate,
          ) : ''}
          placement="top"
          arrow
        >
          <Typography
            style={{ cursor: 'pointer' }}
          >
            {row.leaseEndDate ? formatDateUSReadable(row.leaseEndDate) : ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: true,
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => {
        const isBroker = accessToken.isCurrentRoleBroker();
        return (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            {
                isBroker && row.canEdit
                && (
                  <IconButton
                    style={{ width: '25px' }}
                    onClick={
                        (e) => navigateToEditCompany(Number(row.id), e)
                    }
                    aria-label="edit"
                  >
                    <Edit />
                  </IconButton>
                )
              }
            {
                  !isBroker && (
                  <>
                    <IconButton
                      style={{ width: '25px' }}
                      onClick={
                            (e) => navigateToEditCompany(Number(row.id), e)
                        }
                      aria-label="edit"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      style={{ width: '25px' }}
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        showModal(Number(row.id));
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </>
                  )
              }
          </Box>
        );
      },
    },
  ];

  const validateLeaseYears = () => {
    if ((!filterFromDate && !!filterToDate) || (Number(filterFromDate) > Number(filterToDate))) {
      setFilterToDate('');
    }
  };

  const searchTenant = () => {
    setFilteredTenant(tenantName);
  };

  useEffect(() => {
    validateLeaseYears();
    query.set('leaseEndYearFrom', filterFromDate);
    query.set('leaseEndYearTo', filterToDate);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [filterFromDate, filterToDate]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={3}
        className={filterClasses.listingFilterWrapper}
      >
        <PageTitle>
          Tenants
        </PageTitle>
        <Box display="flex" alignItems="center" alignSelf="flex-end">
          <Box mr={2} maxWidth={158}>
            <FormControl
              margin="none"
            >
              <TextField
                label="Search Tenant"
                variant="standard"
                type="text"
                value={tenantName}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    searchTenant();
                  }
                }}
                onChange={(event) => setTenantName(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => searchTenant()}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
          <Box px={2} maxWidth={184}>
            <FormControl style={{ minWidth: 154 }}>
              <InputLabel shrink={!!filteredAsset} htmlFor="filter-asset">Filter By Asset</InputLabel>
              <Select
                native
                value={filteredAsset}
                onChange={(event) => setFilteredAsset(String(event.target.value))}
                label="Filter By Asset"
                inputProps={{
                  name: 'filter-asset',
                  id: 'filter-asset',
                }}
              >
                <option aria-label="None" defaultValue="" />
                {assets?.map((asset : AssetsDropdown) => (
                  <option key={asset.id} value={asset.id}>
                    {asset.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mr={2}>
            <FormControl
              style={{ minWidth: 60 }}
              margin="none"
              fullWidth
            >
              <InputLabel shrink={!!filterFromDate} htmlFor="tenant-from-date">From</InputLabel>
              <Select
                native
                value={filterFromDate}
                onChange={(event) => setFilterFromDate(String(event.target.value))}
                label="From"
                inputProps={{
                  name: 'tenant-from-date',
                  id: 'tenant-from-date',
                }}
              >
                <option aria-label="None" defaultValue="" />
                {expiringLeasesYears?.map((year : number) => (
                  <option key={year} value={year}>
                    {`Lease End In ${year}`}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mr={3}>
            <FormControl
              style={{ minWidth: 60 }}
              margin="none"
              fullWidth
            >
              <InputLabel shrink={!!filterToDate} htmlFor="tenant-to-date">To</InputLabel>
              <Select
                native
                value={filterToDate}
                onChange={(event) => setFilterToDate(String(event.target.value))}
                label="To"
                disabled={!filterFromDate}
                inputProps={{
                  name: 'tenant-to-date',
                  id: 'tenant-to-date',
                }}
              >
                <option aria-label="None" defaultValue="" />
                {expiringLeasesYears?.map((year : number) => (
                  <option key={year} value={year}>
                    {`Lease End In ${year}`}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box minWidth={5}>
            {!accessToken.isCurrentRoleBroker()
              && (
              <Button
                component={Link}
                className={buttonClasses.large}
                to={tenantsNavigator.create().path}
                variant="contained"
                color="primary"
                fullWidth
              >
                Create Tenant
              </Button>
              )}
          </Box>
        </Box>
      </Box>
      <MaterialReactTable
        columns={columns}
        data={companyAggregatedData || []}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableSorting={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        layoutMode="grid"
        initialState={{ columnVisibility: { id: !hasTenantToEdit } }}
        state={{ isLoading: isLoading || isFetching }}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.stopPropagation();
            tenantsNavigator.single(Number(row.original.id)).navigate();
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
      <RenderModal
        body="Are you sure you want to delete this Tenant?"
        confirmAction={() => deleteCompany(modalId)}
        title="Delete Tenant"
      />
      <LoaderModal isOpen={deleteCompanyMutation.isLoading} />
    </>
  );
};

export default ListTenants;
