import { makeStyles } from '@material-ui/core/styles';

export const useBuildingCardStyles = makeStyles(() => ({
  cardWrapper: {
    padding: '36px 50px 36px 25px',
  },
  fieldsWidth: {
    maxWidth: '328px',
    width: '100%',
  },
  status: {
    paddingBottom: '14px',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  floorBuilding: {
    padding: '14px 0',
  },
}));
