import React, { useContext } from 'react';
import useBaseModal from '../hooks';
import CustomModal from './SuccessModal';
import { RenderModalProps, UseModalProps } from '../interfaces';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';

const useSuccessModal = (): UseModalProps => {
  const { dispatch } = useContext(Store);

  const {
    isOpen, modalId, showModal, hideModal,
  } = useBaseModal();

  const handleClose = () => {
    hideModal();
    dispatch({ type: storeActions.HIDE_SUCCESS_MODAL });
  };

  const SuccessModal = (props: RenderModalProps) => {
    const {
      body, confirmAction, title, ...rest
    } = props;
    return (
      <>
        {isOpen ? (
          <CustomModal
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            isOpen={isOpen}
            confirmAction={confirmAction}
            handleClose={() => handleClose()}
            body={body}
            title={title}
          />
        ) : null}
      </>
    );
  };

  return {
    isOpen,
    modalId,
    showModal,
    hideModal,
    RenderModal: SuccessModal,
  };
};

export default useSuccessModal;
