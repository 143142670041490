import { ListResponse } from '../pagination';
import { Space } from '../buildings';
import { Asset } from '../assets';
import { User } from '../users';
import { TenantiEnum } from '../../core/TenantiEnum';
import { Tenant } from '../tenant';

export const dealLastActivityTypeOptions = {
  7: {
    value: 7,
    displayValue: 'Last 7 Days',
  },
  30: {
    value: 30,
    displayValue: 'Last 30 Days',
  },
  60: {
    value: 60,
    displayValue: 'Last 60 Days',
  },
  90: {
    value: 90,
    displayValue: 'Last 90 Days',
  },
  180: {
    value: 180,
    displayValue: 'Last 180 Days',
  },
};

export const dealStageOptions = {
  prospect: {
    value: 'prospect',
    displayValue: 'Prospect',
    color: '#B950A4',
  },
  inquiry: {
    value: 'inquiry',
    displayValue: 'Inquiry',
    color: '#953BA5',
  },
  preview: {
    value: 'preview',
    displayValue: 'Preview',
    color: '#643EA3',
  },
  tour: {
    value: 'tour',
    displayValue: 'Tour',
    color: '#4946AF',
  },
  proposal: {
    value: 'proposal',
    displayValue: 'Proposal',
    color: '#3E61CC',
  },
  loi: {
    value: 'loi',
    displayValue: 'LOI',
    color: '#4E8AE4',
  },
  legal: {
    value: 'legal',
    displayValue: 'Legal',
    color: '#14AEF5',
  },
  lease_out: {
    value: 'lease_out',
    displayValue: 'Lease Out',
    color: '#65B868',
  },
  exec_lease: {
    value: 'exec_lease',
    displayValue: 'Exec. Lease',
    color: '#ABB342',
  },
  deal_dead: {
    value: 'deal_dead',
    displayValue: 'Deal Dead',
    color: '#FF9800',
  },
  idle: {
    value: 'idle',
    displayValue: 'Idle',
    color: '#FF6633',
  },
};

export class DealStageType extends TenantiEnum {
  // eslint-disable-next-line consistent-return,@typescript-eslint/explicit-module-boundary-types
  public getColorByKey(key: string | undefined) {
    if (key !== undefined) {
      return this.options[key].color;
    }
  }
}

export interface DealComment {
  id: number,
  user: User,
  comment: string,
  createdAt: string
}
export interface DealOwner {
  id: number,
  email: string,
  fullName: string,
  firstName: string,
  lastName: string
}

export interface Deals {
  id?: number | undefined,
  dealType: string,
  stage: string,
  undisclosedTenant: string,
  requirementType: string,
  requirementMinSize: number,
  requirementMaxSize: number,
  leaseTermPeriod: number,
  leaseTermType: string,
  requirementNotes: string,
  logDate: string,
  comment: DealComment,
  commencementDate: string,
  tenant: Tenant,
  assets: Asset[],
  representative: string,
  owner: DealOwner,
  spaces: Space[],
  competitors: CompetitorAsset[],
  watchers?: User[],
  tenantId?: number,
  representativeId?: number,
  createdAt: string,
  updatedAt: string,
}

export interface DealsListResponse extends ListResponse{
  items: Deals[],
  stagesByCount: {
    [key: string]: number
  },
}

export interface DealRouteParams {
  dealId: string
}

export interface CompetitorAsset {
  assetTitle: string
  rate: number | undefined
  spaceSize: number | undefined
  termType: string
  termPeriod: number | undefined
  tenantImprovement: number | undefined
  notes: string
  city: string
  state: string
  zipcode: string
  streetAddress: string
  id?: number
}
