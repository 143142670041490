import React, { ReactElement, useEffect, useState } from 'react';
import {
  Card, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Select,
} from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import LoaderModal from '../../../modals/LoaderModal/LoaderModal';
import { UserFormProps, UserFormValues } from './types';
import { useUserCardStyle } from '../../../../styles/useUserCardStyle';
import { UserRole, userRoleOptions } from '../../../../types/users';
import { useUserForm } from './hooks';
import { UserValidationSchema } from './validators';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';
import { Asset } from '../../../../types/assets';
import { useGetAllAssetsQuery } from '../../../../queries/assets';

const UserForm: React.FC<UserFormProps> = (props: UserFormProps): ReactElement => {
  const { model } = props;
  const { create, update } = useUserForm();
  const { data: assets } = useGetAllAssetsQuery();
  const [chipAsset, setChipAsset] = useState<Asset[]>([]);
  const [initialAsset, setAsset] = useState<Asset[]>([]);
  const classes = useUserCardStyle();
  const buttonClasses = useButtonsStyles();
  const userRoleList = new UserRole(userRoleOptions).getDropdownOptions();
  const broker = 'broker';

  const onAssetDelete = (data: Asset) => {
    const asset = chipAsset.filter((chip) => chip.id !== data.id);
    setChipAsset(asset);
    setAsset([...initialAsset, data]);
  };

  const initialValues: UserFormValues = {
    firstName: model?.firstName ?? '',
    lastName: model?.lastName ?? '',
    email: model?.email ?? '',
    role: model?.roleType ?? '',
    assets: model?.assets ?? [],
  };

  useEffect(() => {
    if (assets) {
      setAsset(assets);
      setChipAsset(initialValues.assets);
    }
  }, [assets, model?.assets]);

  return (
    <Card className={classes.root}>
      <Formik
        enableReinitialize={!!model}
        initialValues={initialValues}
        validationSchema={UserValidationSchema}
        onSubmit={async (values: UserFormValues, actions: FormikHelpers<UserFormValues>) => {
          if (model?.id != null) {
            await update({ ...values, assets: chipAsset }, actions, Number(model?.id));
          } else {
            await create({ ...values, assets: chipAsset }, actions);
          }
        }}
      >
        {(formProps: FormikProps<UserFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;

          return (
            <>
              <Grid container>
                <Grid lg={12}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <TextField
                        value={values.firstName}
                        onChange={handleChange}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        variant="outlined"
                        margin="normal"
                        required
                        id="firstName"
                        label="First Name"
                        type="text"
                        name="firstName"
                        data-testid="first-name"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.lastName}
                        onChange={handleChange}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        required
                        variant="outlined"
                        margin="normal"
                        id="lastName"
                        label="Last Name"
                        type="text"
                        name="lastName"
                        data-testid="last-name"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        required
                        variant="outlined"
                        margin="normal"
                        id="email"
                        label="Email Address"
                        type="text"
                        name="email"
                        data-testid="email"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      required
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel
                        shrink={!!values.role}
                        htmlFor="status-label"
                        id="role-label"
                      >
                        Role Type
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput notched={!!values.role} label="Role Type" />
                        }
                        inputProps={{
                          name: 'role',
                          id: 'role-label',
                        }}
                        value={values.role}
                        onChange={handleChange}
                        error={!!errors.role}
                        label="Role Type"
                        native
                      >
                        <option aria-label="None" defaultValue="" />
                        {userRoleList?.map((role) => (
                          <option key={role.value} value={role.value}>{role.displayValue}</option>
                        ))}
                      </Select>
                      <FormHelperText
                        id="role-helper-text"
                        data-testid="role-helper-text"
                        error={!!errors.role}
                      >
                        {errors.role}
                      </FormHelperText>
                    </FormControl>
                    {
                      values.role === broker && (
                      <FormControl
                        margin="normal"
                        className={clsx(classes.fieldsWidth)}
                      >
                        <Autocomplete
                          multiple
                          id="assets"
                          value={chipAsset}
                          options={initialAsset.map((asset) => (asset)) || []}
                          onChange={(event, assetValue) => {
                            setChipAsset(assetValue);
                          }}
                          getOptionLabel={(option) => option.title}
                          renderTags={(assetValue: Asset[], getTagProps) => (
                            chipAsset.map((option: Asset, index: number) => (
                              // eslint-disable-next-line react/jsx-key
                              <Chip
                                label={option.title}
                                            // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                                {...getTagProps({ index })}
                                onDelete={() => {
                                  onAssetDelete(option);
                                }}
                              />
                            )))}
                          renderInput={(params) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} label="Asset(s)" variant="outlined" />
                          )}
                        />
                      </FormControl>
                      )
                    }
                    <FormControl margin="normal">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        className={
                        model?.id != null ? buttonClasses.standard : buttonClasses.large
                        }
                        disabled={isSubmitting}
                        data-testid="users-button"
                      >
                        {model?.id != null ? 'Save' : 'Create And Invite'}
                      </Button>
                    </FormControl>
                    <LoaderModal isOpen={isSubmitting} />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </Card>
  );
};

export default UserForm;
