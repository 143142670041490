import { useQuery, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import {
  AiChatListResponse,
  AIModels,
  Chat, CreateGptResponse, SuggestionEntity,
} from './types';
import { QUERY_OPTIONS } from '../../constants';

export const useGetSuggestions = ():
UseQueryResult<SuggestionEntity[], Error> => useQuery<SuggestionEntity[], Error>(
  'suggestions',
  async () => {
    const response = await httpClient.get('/gpt/suggestions');
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetAIModels = ():
UseQueryResult<AIModels[], Error> => useQuery<AIModels[], Error>(
  'AI-models',
  async () => {
    const response = await httpClient.get('/ai_chats/ai_models');
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetUserChats = ():
UseQueryResult<Chat[], Error> => useQuery<Chat[], Error>(
  'user-chats',
  async () => {
    const response = await httpClient.get('/ai_chats/user-chats');
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    retry: false,
  },
);

export const useGetAllChatsQuery = (
  page: number,
  perPage: number,
  setRowCount: (rowCount: number) => void,
): UseQueryResult<AiChatListResponse, Error> => useQuery<AiChatListResponse, Error>(
  ['chats', page, perPage],
  async () => {
    const response = await httpClient.get(`/ai_chats/ai-chats?page=${page}&per_page=${perPage}`);
    setRowCount(response.data.total);
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetChatQuestions = (chat: Chat | undefined):
UseQueryResult<CreateGptResponse[], Error> => useQuery<CreateGptResponse[], Error>(
  ['chat-questions', chat?.id],
  async () => {
    const response = await httpClient.get(`/ai_chats/chat-questions/${chat?.id}`);
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: chat !== undefined,
    retry: false,
  },
);
