import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { LoginFormValues } from '../components/forms/auth/LoginForm/types';
import httpClient from '../core/httpClient';
import { accessToken } from '../models/security';

export interface UseSecurityResult {
  requestInviteAccess: (data: LoginFormValues) => Promise<AxiosResponse>;
  login: (userId: number, token: string) => Promise<AxiosResponse>;
  logout: () => Promise<void>;
}

export const useSecurity = (): UseSecurityResult => {
  const history = useHistory();
  const requestInviteAccess = async (data: LoginFormValues): Promise<AxiosResponse> => {
    const requestData = {
      email: data.email.toLocaleLowerCase(),
    };
    return httpClient.post('/auth/request-invite-access', requestData);
  };
  const login = async (userId: number, token: string): Promise<AxiosResponse> => {
    const response = await httpClient.post(`/auth/invite-access/${userId}/${token}`);
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    if (accessToken.isCurrentRoleBroker()) {
      history.push('/assets');
    } else {
      history.push('/');
    }
    return response;
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  return {
    login,
    requestInviteAccess,
    logout,
  };
};
