import React, { ReactElement } from 'react';
import BuildingForm from '../../forms/buildings/BuildingForm/BuildingForm';
import PageTitle from '../../ui/PageTitle';

const CreateBuilding: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create Building</PageTitle>
    <BuildingForm />
  </>
);

export default CreateBuilding;
