import React, { ReactElement } from 'react';
import TenantForm from '../../forms/tenants/TenantForm/TenantForm';
import PageTitle from '../../ui/PageTitle';

const CreateTenant: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create Tenant</PageTitle>
    <TenantForm />
  </>
);

export default CreateTenant;
