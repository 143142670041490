import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import { SubLeaseFormValues, UseSubLeaseForm } from './types';
import { useCreateSubLeaseCompanyMutation, useCreateSubLeaseMutation } from '../../../queries/subleases/mutations';

export const useSubLeaseForm = (): UseSubLeaseForm => {
  const { dispatch } = useContext(Store);

  const createMutation = useCreateSubLeaseMutation();
  const createCompanyMutation = useCreateSubLeaseCompanyMutation();

  const createOrUpdate = async (values: SubLeaseFormValues,
    actions: FormikHelpers<SubLeaseFormValues>,
    modelId: number) => {
    try {
      const request = {
        companyId: values.companyId,
        leasedSpaceId: modelId,
      };
      await createMutation.mutateAsync({ request }, {
        onSuccess: async () => {
          dispatch({ type: storeActions.HIDE_SUBLEASE_MODAL });
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const createSubLeaseCompany = async (values: SubLeaseFormValues,
    actions: FormikHelpers<SubLeaseFormValues>,
    leasedSpaceId: number) => {
    try {
      const request = {
        name: values.companyName,
      };
      await createCompanyMutation.mutateAsync({ request, leasedSpaceId }, {
        onSuccess: async () => {
          dispatch({ type: storeActions.HIDE_SUBLEASE_MODAL });
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    createOrUpdate,
    createSubLeaseCompany,
  };
};
