import { makeStyles } from '@material-ui/core/styles';

export const useAssetStyles = makeStyles((theme) => ({
  assetInputRightMargin: {
    marginRight: theme.spacing(2),
  },
  chipWrapper: {
    display: 'flex',
    listStyle: 'none',
    padding: theme.spacing(0),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  switcherWrapper: {
    display: 'flex',
  },
  switcherButton: {
    paddingTop: '10px',
  },
}));
