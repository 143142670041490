import React, { ReactElement, useContext } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Delete, Edit } from '@material-ui/icons';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import PageTitle from '../../ui/PageTitle';
import { useGetAllAssetsQuery } from '../../../queries/assets';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import { useDeleteAssetMutation } from '../../../queries/assets/mutations';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { sortAreaColumn } from '../../../helpers/sortArea';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import { accessToken } from '../../../models/security';
import { Asset } from '../../../types/assets';

const ListAssets: React.FC = (): ReactElement => {
  const { data: assets, isLoading, isFetching } = useGetAllAssetsQuery();
  const { dispatch } = useContext(Store);
  const deleteAssetMutation = useDeleteAssetMutation();
  const { assetsNavigator } = useNavigationManager();

  const {
    showModal: showDeleteModal, RenderModal: DeleteModal, modalId,
  } = useDeleteModal();

  const buttonClasses = useButtonsStyles();

  const deleteAsset = (assetId: number | null) => {
    if (!assetId) {
      return;
    }
    deleteAssetMutation.mutate({ assetId });
  };

  const navigateToEditAsset = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    assetsNavigator.edit(Number(id)).navigate();
  };

  const handleDispatchBuilding = (assetId: number) => {
    const asset = assets?.find((item) => item.id === assetId);
    if (asset) {
      const firstBuilding = asset.buildings.length ? asset.buildings[0] : null;
      dispatch({
        type: storeActions.UPDATE_STACKING_PLAN,
        payload: {
          filteredBuilding: firstBuilding,
        },
      });
    }
    assetsNavigator.single(Number(assetId)).navigate();
  };

  const columns: MRT_ColumnDef<Asset>[] = [
    {
      accessorKey: 'title',
      header: 'Asset Name',
      enableHiding: false,
    },
    {
      accessorKey: 'type',
      header: 'Asset Type',
      enableHiding: false,
    },
    {
      accessorKey: 'buildings',
      header: 'Number of Buildings',
      accessorFn: (row) => row.numberOfBuildings,
      enableHiding: false,
    },
    {
      accessorKey: 'totalArea',
      accessorFn: (row) => formatAreaSize(row.totalArea),
      header: 'Total Area',
      enableSorting: true,
      enableHiding: false,
      sortingFn: (rowA, rowB) => sortAreaColumn(String(rowA.original.totalArea), String(rowB.original.totalArea)),
    },
    {
      accessorKey: 'id',
      header: 'Actions',
      enableHiding: true,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <IconButton
            onClick={
              (e) => navigateToEditAsset(row.id, e)
            }
            aria-label="edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showDeleteModal(Number(row.id));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
      enableSorting: false,
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Assets</PageTitle>
        {!accessToken.isCurrentRoleBroker()
          && (
          <Button
            component={Link}
            className={buttonClasses.large}
            to={assetsNavigator.create().path}
            variant="contained"
            color="primary"
          >
            Create Asset
          </Button>
          )}
      </Box>
      <MaterialReactTable
        columns={columns}
        data={assets || []}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableSorting={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        state={{ isLoading: isLoading || isFetching }}
        initialState={{ columnVisibility: { id: !accessToken.isCurrentRoleBroker() } }}
        layoutMode="grid"
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.stopPropagation();
            handleDispatchBuilding(Number(row.original.id));
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
      <DeleteModal
        body="Are you sure you want to delete this Asset?"
        confirmAction={() => deleteAsset(modalId)}
        title="Delete Asset"
      />
      <LoaderModal isOpen={deleteAssetMutation.isLoading} />
    </>
  );
};

export default ListAssets;
