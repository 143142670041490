import { useHistory } from 'react-router-dom';
import { BaseNavigationProps, BaseNavigationRouteParams } from './interfaces';

export const useBaseNavigation = (): BaseNavigationProps => {
  const history = useHistory();

  const setRouteParams = (baseRoute: string): BaseNavigationRouteParams => ({
    path: baseRoute,
    navigate: () => history.push(baseRoute),
  });

  return {
    setRouteParams,
  };
};
