import { Building } from './buildings';

export enum AssetType {
  Multi = 'Multi',
  Single = 'Single',
}

export interface Asset {
  id: number
  title: string
  totalArea: number
  createdAt: string
  type: AssetType
  numberOfBuildings: number
  buildings: Building[]
}

export interface AssetsDropdown {
  id: number | undefined
  title: string
}

export interface AssetSpace {
  buildingName: string
  endDate: string
  floorName: string
  leaseRate: number
  leasedArea: number
  spaceName: string
  tenantName: string
}
