import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormControl, Grid } from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import Alert from '../../../ui/Alert';
import { EditProfileFormProps, EditProfileFormValues } from './types';
import useEditProfileForm from './hooks';
import EditProfileValidationSchema from './validators';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';

const EditProfileForm: React.FC<EditProfileFormProps> = (props: EditProfileFormProps): ReactElement => {
  const { model } = props;
  const { update, formAlert } = useEditProfileForm();
  const buttonClasses = useButtonsStyles();

  let initialValues: EditProfileFormValues;

  if (model) {
    initialValues = {
      firstName: model.firstName,
      lastName: model.lastName,
    };
  } else {
    initialValues = {
      firstName: '',
      lastName: '',
    };
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={EditProfileValidationSchema}
        onSubmit={async (values: EditProfileFormValues, actions: FormikHelpers<EditProfileFormValues>) => {
          await update(values, actions);
        }}
      >
        {(formProps: FormikProps<EditProfileFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;
          return (
            <>
              <Alert message={formAlert.message} type={formAlert.type} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={6} lg={4}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <TextField
                        value={values.firstName}
                        onChange={handleChange}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        variant="outlined"
                        margin="normal"
                        required
                        id="firstName"
                        label="First Name"
                        type="text"
                        name="firstName"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.lastName}
                        onChange={handleChange}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        variant="outlined"
                        margin="normal"
                        required
                        name="lastName"
                        label="Last Name"
                        type="text"
                        id="lastName"
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className={buttonClasses.standard}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </FormControl>
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default EditProfileForm;
