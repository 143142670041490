import React, { ReactElement } from 'react';
import { Alert as BaseAlert } from '@material-ui/lab';
import { Color } from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';

export interface AlertProps {
  message: string,
  type: Color | undefined,
}

const Alert: (props: AlertProps) => React.ReactElement = (props: AlertProps): ReactElement => {
  const { message, type } = props;

  return (
    <Box my={2}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {!!message && <BaseAlert severity={type}>{message}</BaseAlert>}
    </Box>
  );
};

export default Alert;
