import React, { ReactElement } from 'react';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { TabularData, TableRow } from '../../queries/gpt/types';

const GptDataGrid: React.FC<TabularData> = (props: TabularData): ReactElement => {
  const { columns, data } = props;
  const tableColumn: MRT_ColumnDef<TableRow>[] = [];
  const rows: TableRow[] = [];

  columns.forEach((item) => {
    tableColumn.push({
      accessorKey: item,
      header: item,
    });
  });

  const headersCount = columns.length;
  data.forEach((item) => {
    const row: TableRow = {};
    row.id = data.indexOf(item);
    for (let i = 0; i < headersCount; i += 1) {
      row[columns[i]] = item[i];
    }
    rows.push(row);
  });

  return (
    <MaterialReactTable
      columns={tableColumn}
      data={rows || []}
      enableTopToolbar={false}
      enableColumnResizing
      enableColumnActions={false}
      enableFullScreenToggle={false}
      enableRowOrdering={false}
      enableSorting={false}
      enableGlobalFilterModes={false}
      enableDensityToggle={false}
      layoutMode="grid"
      muiPaginationProps={{
        rowsPerPageOptions: [5, 10],
      }}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
          display: 'table',
        },
      }}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      muiTableBodyRowProps={({ row }) => ({
        sx: {
          cursor: 'pointer',
        },
      })}
    />
  );
};

export default GptDataGrid;
