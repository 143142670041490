import { makeStyles } from '@material-ui/core/styles';

export const useNewsCardStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  linkStyle: {
    color: 'blue',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  textField: {
    borderRadius: '3px',
    borderColor: 'lightgray',
  },
}));
