import { AuthRoles } from '../types/auth';
import { decodeToken } from '../helpers/auth';

export class AccessToken {
  private readonly subject: string | undefined;

  private readonly role: AuthRoles | undefined;

  private readonly expiresAt: number | undefined;

  private readonly encoded: string | undefined;

  constructor() {
    this.encoded = localStorage.getItem('accessToken') ?? '';

    try {
      const decoded = decodeToken(this.encoded);
      this.subject = decoded.sub;
      this.expiresAt = decoded.exp;
      this.role = decoded.role;
    } catch (error) {
      this.subject = undefined;
      this.expiresAt = undefined;
      this.role = undefined;
    }
  }

  public getSubject(): string | undefined {
    return this.subject;
  }

  public getUserId(): number {
    return Number(this.subject);
  }

  public getCurrentUserRole(): AuthRoles | undefined {
    return this.role;
  }

  public hasRole(): boolean {
    return this.role !== undefined;
  }

  public isCurrentRoleAdmin(): boolean {
    return this.role === AuthRoles.Admin;
  }

  public isCurrentRoleUser(): boolean {
    return this.role === AuthRoles.User;
  }

  public isCurrentRoleBroker(): boolean {
    return this.role === AuthRoles.Broker;
  }

  public isValid(): boolean {
    return this.subject !== undefined;
  }

  public getToken(): string | undefined {
    return this.encoded;
  }

  public isExpired(): boolean {
    if (this.expiresAt) {
      return new Date(this.expiresAt * 1000) < new Date();
    }
    return false;
  }
}

export const accessToken = new AccessToken();
