import React, { ReactElement } from 'react';
import {
  Box, Button, Card, CardActions, CardHeader, Grid, IconButton, Typography, Link as TitleLink, CardContent,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useDeleteNewsMutation } from '../../../queries/news/mutations';
import { useNewsCardStyles } from '../../../styles/useNewsCardStyles';
import PageTitle from '../../ui/PageTitle';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import ComponentError from '../../ui/ComponentError';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { News } from '../../../types/news';
import ComponentLoader from '../../ui/ComponentLoader';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { accessToken } from '../../../models/security';

export interface NewsCardsProps {
  data: News[] | undefined
  error: Error | null
  isLoading?: boolean
  relatedTenantId?: number
}

const NewsCards: React.FC<NewsCardsProps> = (props: NewsCardsProps): ReactElement => {
  const {
    data: news, error: listError, isLoading, relatedTenantId,
  } = props;
  const deleteNewsMutation = useDeleteNewsMutation();
  const classes = useNewsCardStyles();
  const buttonClasses = useButtonsStyles();
  const { showModal, RenderModal, modalId } = useDeleteModal();

  const deleteNews = (newsId: number | null) => {
    if (!newsId) {
      return;
    }
    deleteNewsMutation.mutate({ newsId });
  };

  const isDataPresent = (): boolean => !!news?.length;

  const getCreateNewsRoute = (): string => {
    const createNewsRoute = '/news/create';
    return relatedTenantId ? `${createNewsRoute}?tenantId=${relatedTenantId}` : createNewsRoute;
  };

  return (
    <Grid>
      <Box display="flex" justifyContent="space-between" alignItems="center" mx={2} my={2} py={1}>
        <PageTitle>News</PageTitle>
        {!accessToken.isCurrentRoleBroker()
          && (
          <Button
            component={Link}
            className={buttonClasses.large}
            to={getCreateNewsRoute()}
            variant="contained"
            color="primary"
            hidden
          >
            Create News
          </Button>
          )}
      </Box>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {isDataPresent() && !isLoading
        && news?.map((singleNews) => (
          <Box m={2} key={singleNews.id} width="100%">
            <Card className={classes.root}>
              <CardHeader
                action={(
                  <CardActions disableSpacing>
                    {!accessToken.isCurrentRoleBroker()
                      && (
                      <>
                        <IconButton component={Link} to={`/news/edit/${singleNews.id}`} aria-label="edit">
                          <Edit />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => showModal(Number(singleNews.id))}>
                          <Delete />
                        </IconButton>
                      </>
                      )}
                  </CardActions>
                )}
                title={(
                  <Typography variant="h4">
                    <TitleLink href={singleNews.url} target="_blank" color="inherit">
                      {singleNews.title}
                    </TitleLink>
                  </Typography>
                )}
                subheader={formatDateUSReadable(singleNews?.createdAt)}
              />
              <CardContent>
                <Typography variant="body2" color="textPrimary" component="p">
                  {singleNews.description}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {
                    singleNews.company
                      ? (
                        <Link
                          to={`/tenants/view/${singleNews.company.id}`}
                          color="textSecondary"
                          className={classes.linkStyle}
                        >
                          {singleNews.company.name}
                        </Link>
                      )
                      : ('_')
                  }
                </Typography>
              </CardActions>
            </Card>
          </Box>
        ))}
        {listError && !isLoading && (
          <ComponentError error={listError} />
        )}
      </Grid>
      { !isDataPresent() && !isLoading && (
        <Box display="flex" alignItems="center" justifyContent="center" my={3}>
          No Results.
        </Box>
      )}
      <ComponentLoader isOpen={!!isLoading} />
      <RenderModal
        body="Are you sure you want to delete this News?"
        confirmAction={() => deleteNews(modalId)}
        title="Delete News"
      />
      <LoaderModal isOpen={deleteNewsMutation.isLoading} />
    </Grid>
  );
};

export default NewsCards;
