import { FormikHelpers } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateSpaceMutation, useUpdateSpaceMutation } from '../../../../../queries/buildings/mutations';
import { CreateSpaceRequest, UpdateSpaceRequest } from '../../../../../queries/buildings/types';
import { SpaceRouteProps } from '../../../../pages/spaces/interfaces';
import { SpaceFormValues, UseSpaceForm } from './types';

export const useSpaceForm = (): UseSpaceForm => {
  const createMutation = useCreateSpaceMutation();
  const updateMutation = useUpdateSpaceMutation();
  const history = useHistory();
  const { buildingId } = useParams<SpaceRouteProps>();

  const create = async (
    values: SpaceFormValues, actions: FormikHelpers<SpaceFormValues>,
  ) => {
    try {
      const request: CreateSpaceRequest = {
        name: values.name,
        area: Number(values.area),
        floorId: Number(values.floorId),
      };
      await createMutation.mutateAsync({ request }, {
        onSuccess: async () => {
          history.push(`/buildings/${buildingId}/spaces`);
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (values: SpaceFormValues, actions: FormikHelpers<SpaceFormValues>, spaceId: number) => {
    try {
      const request: UpdateSpaceRequest = {
        name: values.name,
        area: Number(values.area),
        floorId: Number(values.floorId),
      };
      await updateMutation.mutateAsync(
        { request, spaceId: Number(spaceId) },
        {
          onSuccess: async () => {
            history.push(`/buildings/${buildingId}/spaces`);
          },
        },
      );
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    create,
    update,
  };
};
