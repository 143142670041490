import React, { ReactElement, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box, FormControl, FormHelperText, Grid, Select, TextField, Typography,
} from '@material-ui/core';
import { differenceInMonths } from 'date-fns';
import { useFormStyles } from '../../../../styles/useLeaseStyle';
import { TermType } from '../../../../types/leases';
import { LeasesFormValues } from './types';

export interface TermPeriodProps {
  startDateName: string
  periodName: string
  termTypeName: string
  endDateName: string
}

export const TermPeriod: React.FC<TermPeriodProps> = (props: TermPeriodProps): ReactElement => {
  const {
    startDateName,
    periodName,
    termTypeName,
    endDateName,
  } = props;
  const classes = useFormStyles();
  const termTypes = Object.keys(TermType);
  const {
    values,
    errors,
    handleChange,
  } = useFormikContext<LeasesFormValues>();

  const calculateDifferenceInMonths = (): void => {
    if (values[startDateName] && values[endDateName]) {
      const selectedStartDate = new Date(String(values[startDateName]));
      const selectedEndDate = new Date(String(values[endDateName]));
      const differenceBetweenStartAndEndDate = differenceInMonths(selectedEndDate, selectedStartDate);
      values.termPeriod = String(differenceBetweenStartAndEndDate);
      values.termType = TermType.Months;
    }
  };

  useEffect(() => {
    calculateDifferenceInMonths();
  }, [values.startDate, values.endDate]);

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6} lg={4}>
        <Box className={classes.startDate}>
          <FormControl className={classes.datePicker} fullWidth>
            <TextField
              required
              id="startDate"
              name={startDateName}
              data-testid="start-date"
              label="Start Date"
              type="date"
              error={!!errors[startDateName]}
              helperText={errors[startDateName]}
              onChange={handleChange}
              value={values[startDateName]}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Box className={classes.startDate}>
          <FormControl className={classes.datePicker} fullWidth>
            <TextField
              required
              id="endDate"
              name={endDateName}
              type="date"
              label="End Date"
              data-testid="end-date"
              onChange={handleChange}
              error={!!errors[endDateName]}
              helperText={errors[endDateName]}
              value={values[endDateName]}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Box className={classes.fieldsWidth}>
          <Typography
            variant="caption"
            className={classes.leaseLabels}
          >
            Term Period *
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl className={classes.parallelFieldsWidth}>
                <TextField
                  value={values.termPeriod}
                  onChange={handleChange}
                  error={!!errors[periodName]}
                  helperText={errors[periodName]}
                  variant="outlined"
                  required
                  id="termPeriod"
                  data-testid="term-period"
                  placeholder="Number"
                  name={periodName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                required
                variant="outlined"
                margin="normal"
                className={classes.parallelFieldsWidth}
              >
                <Select
                  name={termTypeName}
                  value={values.termType}
                  onChange={handleChange}
                  native
                >
                  <option aria-label="None" value="" disabled>Period</option>
                  {termTypes?.map((type: string) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </Select>
                <FormHelperText
                  data-testid="termType-helper-text"
                  error={!!errors[termTypeName]}
                >
                  {errors[termTypeName]}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
