import { Actions } from './interfaces';

export const storeActions: Actions = {
  OPEN_DOCUMENT_MODAL: 'OPEN_DOCUMENT_MODAL',
  HIDE_DOCUMENT_MODAL: 'HIDE_DOCUMENT_MODAL',
  OPEN_SUBLEASE_MODAL: 'OPEN_SUBLEASE_MODAL',
  HIDE_SUBLEASE_MODAL: 'HIDE_SUBLEASE_MODAL',
  OPEN_SUCCESS_MODAL: 'OPEN_SUCCESS_MODAL',
  HIDE_SUCCESS_MODAL: 'HIDE_SUCCESS_MODAL',
  UPDATE_STACKING_PLAN: 'UPDATE_STACKING_PLAN',
};
