import React from 'react';
import CustomModal from '../Modal/Modal';
import useBaseModal from '../hooks';
import { RenderModalProps, UseModalProps } from '../interfaces';
import { tenantiResponseError } from '../../../models/errors/TenantiResponseError';
import { NotFoundError } from '../../../models/errors/errors';

const useErrorModal = (): UseModalProps => {
  const {
    isOpen, modalId, showModal, hideModal,
  } = useBaseModal();

  const handleCloseModal = () => {
    if (tenantiResponseError.tenantiApiError instanceof NotFoundError) {
      window.history.back();
    }
    hideModal();
  };

  const ErrorModal = (props: RenderModalProps) => {
    const {
      body,
      title,
      ...rest
    } = props;

    return (
      <>
        {isOpen ? (
          <CustomModal
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            isOpen={isOpen}
            handleClose={hideModal}
            cancelText="Close"
            cancelAction={handleCloseModal}
            body={body}
            title="Error"
          />
        ) : null}
      </>
    );
  };

  return {
    isOpen,
    modalId,
    showModal,
    hideModal,
    RenderModal: ErrorModal,
  };
};

export default useErrorModal;
