export enum DocumentType {
  Amendment = 'Amendment',
  Abstract = 'Abstract',
  Lease = 'Lease',
}

export interface Document {
  id: number,
  title: string,
  url: string,
  type: DocumentType,
  created_at: string,
}

export interface DocumentsForm {
  title: string,
  url: string,
  type: string,
  leaseId?: number | string,
  assetId?: number | string,
}

export interface CreateDocumentsMutationVariables {
  request: DocumentsForm
}

export interface DeleteDocumentMutationVariables {
  documentId: number
}
