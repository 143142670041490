import React, { createContext, useReducer, Dispatch } from 'react';
import { ActionType, IInitialStateType } from './reducers/interfaces';
import { storeInitialState, storeReducer } from './reducers/storeReducer';

export const Store = createContext<{
  state: IInitialStateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: storeInitialState,
  dispatch: () => null,
});

export function StoreProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const [state, dispatch] = useReducer(storeReducer, storeInitialState);

  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>;
}
