import { makeStyles } from '@material-ui/core/styles';

export const useUserCardStyle = makeStyles({
  root: {
    maxWidth: '360px',
    padding: '18px',
  },
  fieldsWidth: {
    maxWidth: '328px',
    width: '100%',
  },
});
