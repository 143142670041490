import { grey, orange } from '@material-ui/core/colors';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StackingPlanFloor, Term } from '../../../../types/buildings';
import { BuildingStatistics } from '../../../../types/statistics';
import { formatAreaSize } from '../../../../formatters/areaSizeFormatter';
import { useFloorAreaStyles } from '../../../../styles/useFloorAreaStyles';
import { LeaseCardColorsInterface, NonVacantAreaData, UseBuildingFloorArea } from './interfaces';
import { divide, getPercentage } from '../../../../math/lib';
import { getDateFullYear, isDateAfterOrEqualNow, isDateBeforeNow } from '../../../../helpers/timePeriod';
import { ViewBuildingRouteProps } from '../../../pages/buildings/ViewBuilding/interfaces';
import { useGetSingleBuildingQuery } from '../../../../queries/buildings';
import { Store } from '../../../../store/AppGlobalContext';
import { storeActions } from '../../../../store/actions';
import { ALL_YEARS } from '../../../../constants';

const palette = [
  orange[500], '#ABB342', '#65B868', '#14AEF5', '#4E8AE4',
  '#3E61CC', '#4946AF', '#643EA3',
  '#953BA5', '#B950A4',
];

export const useBuildingFloorArea = (): UseBuildingFloorArea => {
  const { state, dispatch } = useContext(Store);
  const [isSpaceSelectionTriggered, setIsSpaceSelectionTriggered] = useState<boolean>(false);
  const [selectedSpace, setSelectedSpace] = useState<number | undefined>(undefined);
  const { buildingId } = useParams<ViewBuildingRouteProps>();
  const { data: building } = useGetSingleBuildingQuery(Number(buildingId));
  const classes = useFloorAreaStyles();
  const minimumSpaceWidth = 5;
  const maximumWideSpacesCount = 7;
  const extraWideSpaceCount = 14;
  const nonYearTitles = {
    vacant: 'Vacant',
    mtm: 'MTM',
  };

  const selectSpace = (spaceId: number): void => {
    setSelectedSpace(spaceId);
    setIsSpaceSelectionTriggered(true);
    if (selectedSpace === spaceId) {
      setSelectedSpace(undefined);
      setIsSpaceSelectionTriggered((prevState) => !prevState);
    }
  };

  const isSelectedBarActive = (title: string): boolean => (
    Boolean(state.stackingPlan.selectedBarTitles?.includes(title)));

  const getYearBarClassName = (title: string): string => (
    state.stackingPlan.isYearSelectionTriggered
    && !isSelectedBarActive(title) ? classes.inactiveYear : ''
  );

  const yearColors: LeaseCardColorsInterface = {
    vacant: grey[600],
    mtm: '#FF6633',
  };

  const getSpacePercentage = (singleArea: number, totalArea: number): number => {
    const percentage = getPercentage(singleArea, totalArea);
    return percentage < minimumSpaceWidth ? minimumSpaceWidth : percentage;
  };

  const hasPassedMaxWideSpacesCount = (floor: StackingPlanFloor) => floor.spaces.length > maximumWideSpacesCount;
  const hasPassedExtraWideSpacesCount = (floor: StackingPlanFloor) => floor.spaces.length > extraWideSpaceCount;

  const isSpaceInFloor = (spaceId: number, floor: StackingPlanFloor) => floor.spaces.some(
    (space) => space.id === spaceId,
  );

  const isSpaceInFloorNotTheSelectedOne = (spaceId: number, floor: StackingPlanFloor): boolean => !!(selectedSpace
      && isSpaceSelectionTriggered
      && isSpaceInFloor(selectedSpace, floor)
      && selectedSpace !== spaceId);

  const isSpaceInFloorTheSelectedOne = (spaceId: number, floor: StackingPlanFloor): boolean => !!(selectedSpace
      && isSpaceSelectionTriggered
      && isSpaceInFloor(selectedSpace, floor)
      && selectedSpace === spaceId);

  const getSpaceEqualProportionWidth = (floor: StackingPlanFloor, singleArea: number, totalArea: number): number => {
    if (totalArea) {
      return getSpacePercentage(totalArea / floor.spaces.length, totalArea);
    }
    return divide(100, floor.spaces.length).toNumber();
  };

  const getSpaceModifiedPercentage = (
    floor: StackingPlanFloor,
    spaceId: number,
    singleArea: number,
    totalArea: number,
  ): number => {
    const spaceWidth = getSpacePercentage(singleArea, totalArea);
    const expansionPercent = 70;
    const spaceEqualProportionalWidth = getSpaceEqualProportionWidth(floor, singleArea, totalArea);

    if (!totalArea && !!floor.spaces.length) {
      return spaceEqualProportionalWidth;
    }

    if (state.stackingPlan.isYearSelectionTriggered) {
      if (isSpaceInFloorTheSelectedOne(spaceId, floor)
        && spaceEqualProportionalWidth < expansionPercent) {
        return expansionPercent;
      }
      return spaceEqualProportionalWidth;
    }

    if (hasPassedMaxWideSpacesCount(floor) && isSpaceInFloorNotTheSelectedOne(spaceId, floor)) {
      return minimumSpaceWidth;
    }

    if ((selectedSpace === spaceId) && (spaceWidth < expansionPercent)) {
      return expansionPercent;
    }

    return spaceWidth;
  };

  const getSpaceWidth = (
    floor: StackingPlanFloor,
    spaceId: number,
    singleArea: number,
    totalArea: number,
  ) => {
    if (hasPassedExtraWideSpacesCount(floor) && !selectedSpace) {
      return '20px';
    }
    return `${getSpaceModifiedPercentage(floor, spaceId, singleArea, totalArea)}%`;
  };

  const getSpaceClassName = (
    floor: StackingPlanFloor,
    spaceId: number,
    singleArea: number,
    totalArea: number,
  ): string => {
    const spacePercentage = getSpaceModifiedPercentage(floor, spaceId, singleArea, totalArea);
    const mediumSpaceWidth = 2 * minimumSpaceWidth;

    if (hasPassedMaxWideSpacesCount(floor) && isSpaceInFloorNotTheSelectedOne(spaceId, floor)) {
      return classes.multipleNotSelectedSpace;
    }

    if (!isSpaceInFloorTheSelectedOne(spaceId, floor)) {
      if (spacePercentage <= minimumSpaceWidth) {
        return classes.smallSpace;
      }
      if (spacePercentage < mediumSpaceWidth && spacePercentage > minimumSpaceWidth) {
        return classes.mediumSpace;
      }
    }

    return classes.largeSpace;
  };

  const getNonVacantAreas = (data: BuildingStatistics): NonVacantAreaData[] => {
    const nonVacantAreas = data.byYear;
    return nonVacantAreas.map((areaData, index) => {
      yearColors[`year${areaData.leaseEndYear}`] = palette[index];
      return {
        title: String(areaData.leaseEndYear),
        area: formatAreaSize(Number(areaData.totalArea)),
        percentage: Number(areaData.percentage),
        color: palette[index],
      };
    });
  };

  const getFilteredLeasesList = (terms: Term[]): Term[] => {
    let filteredTerms: Term[] = [];
    if (terms.length) {
      const expiredLeaseTerms = terms.filter((term) => isDateBeforeNow(term.lease.endDate));
      if (expiredLeaseTerms.length < terms.length) {
        filteredTerms = terms.filter((term) => isDateAfterOrEqualNow(term.lease.endDate));
      } else {
        filteredTerms = terms;
      }
    }
    return filteredTerms;
  };

  const isLeaseDateExpired = (endDate: string): boolean => isDateBeforeNow(endDate);

  const getAreaStatistics = (data: BuildingStatistics): NonVacantAreaData[] => {
    const floorLeaseYears = [
      {
        title: nonYearTitles.vacant,
        color: yearColors.vacant,
        area: formatAreaSize(data.vacant),
        percentage: getPercentage(data.vacant, Number(building?.totalArea)),
      },
      {
        title: nonYearTitles.mtm,
        color: yearColors.mtm,
        area: formatAreaSize(data.byMonth.totalArea),
        percentage: data.byMonth.percentage,
      },
    ];
    const nonVacantAreas = getNonVacantAreas(data);
    floorLeaseYears.push(...nonVacantAreas);
    return floorLeaseYears;
  };

  const getFloorsBySelectedBar = (floors: StackingPlanFloor[], ...titles: string[]): void => {
    let filteredFloorsList: StackingPlanFloor[] | undefined;

    const mtmFloors = floors?.filter(
      (floor) => floor.spaces.some(
        (space) => space.terms.some(
          ((term) => term.lease.isMonthToMonth),
        ),
      ),
    ).map((floor) => ({
      ...floor,
      spaces: floor.spaces.filter(
        (space) => space.terms.some((term) => term.lease.isMonthToMonth),
      ).map((space) => ({
        ...space,
        terms: space.terms.filter((term) => term.lease.isMonthToMonth),
      })),
    })) || [];

    const vacantFloors = floors?.filter(
      (floor) => !floor.spaces.length || floor.spaces.some((space) => !space.terms.length
        || (space.terms.length === 1 && space.terms.some((term) => isDateBeforeNow(term.lease.endDate)))),
    ).map((floor) => ({
      ...floor,
      spaces: floor.spaces.filter(
        (space) => !space.terms.length
          || (space.terms.length === 1 && space.terms.some((term) => isDateBeforeNow(term.lease.endDate))),
      ),
    })) || [];

    const filteredByYearFloors = floors?.filter(
      (floor) => floor.spaces.some(
        (space) => space.terms.some(
          (term) => titles.includes(getDateFullYear(term.lease.endDate).toString()),
        ),
      ),
    ).map((floor) => ({
      ...floor,
      spaces: floor.spaces.filter(
        (space) => space.terms.some(
          (term) => titles.includes(getDateFullYear(term.lease.endDate).toString()),
        ),
      ).map((space) => ({
        ...space,
        terms: space.terms.filter(
          (term) => titles.includes(getDateFullYear(term.lease.endDate).toString()),
        ),
      })),
    }));

    if (titles.includes(nonYearTitles.vacant)) {
      filteredFloorsList = vacantFloors;
    } else if (titles.includes(nonYearTitles.mtm)) {
      filteredFloorsList = mtmFloors;
    } else {
      filteredFloorsList = filteredByYearFloors;
    }
    dispatch({
      type: storeActions.UPDATE_STACKING_PLAN,
      payload: {
        selectedFloors: filteredFloorsList,
      },
    });
  };

  const getFloorsBySelectedTenant = (floors: StackingPlanFloor[], ...titles: string[]): void => {
    const filteredByTenantFloors = floors?.filter(
      (floor) => floor.spaces.some(
        (space) => space.terms.some(
          (term) => titles.includes(term.lease.companyName),
        ),
      ),
    ).map((floor) => ({
      ...floor,
      spaces: floor.spaces.filter(
        (space) => space.terms.some(
          (term) => titles.includes(term.lease.companyName),
        ),
      ).map((space) => ({
        ...space,
        terms: space.terms.filter(
          (term) => titles.includes(term.lease.companyName),
        ),
      })),
    }));

    dispatch({
      type: storeActions.UPDATE_STACKING_PLAN,
      payload: {
        selectedFloors: filteredByTenantFloors,
      },
    });
  };

  const selectBar = (floors: StackingPlanFloor[], ...title: string[]): void => {
    dispatch({
      type: storeActions.UPDATE_STACKING_PLAN,
      payload: {
        isYearSelectionTriggered: true,
        selectedBarTitles: title,
        filteredYear: title[0],
        filteredByTenant: null,
      },
    });
    getFloorsBySelectedBar(floors, ...title);
  };

  const dropAllFiltrationValues = (floors: StackingPlanFloor[]) => {
    dispatch({
      type: storeActions.UPDATE_STACKING_PLAN,
      payload: {
        isYearSelectionTriggered: false,
        selectedBarTitles: [],
        selectedFloors: floors,
        filteredYear: ALL_YEARS,
        filteredByTenant: null,
      },
    });
  };

  const toggleFiltrationButton = (floors: StackingPlanFloor[]) => {
    if (state.stackingPlan.isYearSelectionTriggered) {
      dropAllFiltrationValues(floors);
    } else {
      selectBar(floors, nonYearTitles.vacant);
    }
  };

  const handleYearFilterChange = (
    floors: StackingPlanFloor[] | undefined, event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>,
  ) => {
    if (floors) {
      if (event.target.value === ALL_YEARS) {
        dropAllFiltrationValues(floors);
      } else {
        getFloorsBySelectedBar(floors, String(event.target.value));
        dispatch({
          type: storeActions.UPDATE_STACKING_PLAN,
          payload: {
            isYearSelectionTriggered: true,
            selectedBarTitles: [String(event.target.value)],
            filteredYear: String(event.target.value),
            filteredByTenant: null,
          },
        });
      }
    }
  };

  const handleTenantFilterChange = (
    floors: StackingPlanFloor[] | undefined, value?: string | unknown,
  ) => {
    if (floors) {
      if (value === undefined) {
        dropAllFiltrationValues(floors);
      } else {
        getFloorsBySelectedTenant(floors, String(value));
        dispatch({
          type: storeActions.UPDATE_STACKING_PLAN,
          payload: {
            isYearSelectionTriggered: true,
            filteredByTenant: String(value),
            filteredYear: ALL_YEARS,
            selectedBarTitles: [],
          },
        });
      }
    }
  };

  return {
    nonYearTitles,
    selectedSpace,
    setSelectedSpace,
    yearColors,
    isSpaceInFloorNotTheSelectedOne,
    getAreaStatistics,
    getFilteredLeasesList,
    isLeaseDateExpired,
    getSpaceWidth,
    getSpaceClassName,
    getSpacePercentage,
    getYearBarClassName,
    selectSpace,
    getFloorsBySelectedBar,
    selectBar,
    toggleFiltrationButton,
    handleYearFilterChange,
    dropAllFiltrationValues,
    handleTenantFilterChange,
  };
};
