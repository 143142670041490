import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { EmptyLayout } from '../components/layouts/EmptyLayout';

export const EmptyLayoutRouterSwitch: React.FC = (props): ReactElement => {
  const { children } = props;

  return (
    <Switch>
      <EmptyLayout>
        {children}
      </EmptyLayout>
    </Switch>
  );
};

EmptyLayoutRouterSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};
