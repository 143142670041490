import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import { useGetAllLeasesQuery } from '../../../queries/leases';
import ListLeases from './ListLeases';
import CreateLeases from './CreateLeases';
import EditLeases from './EditLeases';
import ViewLease from './ViewLease';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import { AuthRoles } from '../../../types/auth';

const LeasesIndex:React.FC = (): ReactElement => {
  useGetAllLeasesQuery();

  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter
        exact
        path={path}
        component={ListLeases}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        exact
        path={`${path}/create`}
        component={CreateLeases}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        exact
        path={`${path}/edit/:leaseId`}
        component={EditLeases}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        exact
        path={`${path}/view/:leaseId`}
        component={ViewLease}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
    </RouterBaseSwitch>
  );
};

export default LeasesIndex;
