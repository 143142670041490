import React, {
  ReactElement, useContext, useEffect,
} from 'react';
import {
  Box, FormControl, Grid, IconButton, InputLabel, Select, Typography,
} from '@material-ui/core';
import PageTitle from '../../../ui/PageTitle';
import { getBuildingAreaDetails } from '../../../../helpers/address';
import { Building, StackingPlanFloor } from '../../../../types/buildings';
import ActiveWindowIcon from '../../../../styles/icons/ActiveWindowIcon.svg';
import WindowIcon from '../../../../styles/icons/WindowIcon.svg';
import ActiveTableIcon from '../../../../styles/icons/ActiveTableIcon.svg';
import TableIcon from '../../../../styles/icons/TableIcon .svg';
import { Asset, AssetType } from '../../../../types/assets';
import { useAssetStyles } from '../../../../styles/useAssetStyles';
import { Store } from '../../../../store/AppGlobalContext';
import { storeActions } from '../../../../store/actions';
import { BuildingStatistics, NonVacantBuildingArea } from '../../../../types/statistics';
import { useBuildingFloorArea } from './hooks';
import { ALL_YEARS } from '../../../../constants';

interface AssetStackingPlanHeaderProps {
  floors: StackingPlanFloor[] | undefined
  asset: Asset | undefined
  buildingStatistics: BuildingStatistics | undefined
}

export const AssetStackingPlanHeader: React.FC<AssetStackingPlanHeaderProps> = (
  props: AssetStackingPlanHeaderProps,
): ReactElement => {
  const { floors, asset, buildingStatistics } = props;
  const classes = useAssetStyles();
  const { state, dispatch } = useContext(Store);
  const { handleYearFilterChange, dropAllFiltrationValues } = useBuildingFloorArea();
  const dropdownAllYears = ALL_YEARS;

  const isMultiAsset = (): boolean => asset?.type === AssetType.Multi;

  const handleBuildingChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    if (asset && !!asset.buildings.length) {
      const selectedBuilding = asset.buildings
        .find((assetBuilding: Building) => assetBuilding.id === Number(event.target.value));
      dispatch({ type: storeActions.UPDATE_STACKING_PLAN, payload: { filteredBuilding: selectedBuilding } });
    }
  };

  const changeTableState = (value: boolean): void => {
    dispatch({ type: storeActions.UPDATE_STACKING_PLAN, payload: { isTableActive: value } });
  };

  useEffect(() => {
    changeTableState(false);
    if (floors) {
      dropAllFiltrationValues(floors);
    }
  }, []);

  return (
    <Box mt={3} mb={2}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column">
            <PageTitle>{state.stackingPlan.filteredBuilding?.name || ''}</PageTitle>
            <Box px={2}>
              <Typography variant="caption">
                {
                  floors && state.stackingPlan.filteredBuilding
                    ? getBuildingAreaDetails(state.stackingPlan.filteredBuilding) : ''
                }
              </Typography>
            </Box>
          </Box>
        </Grid>
        {
          !!asset?.buildings.length
          && (
            <Grid
              item
              xs={5}
              className={classes.switcherWrapper}
              justifyContent="flex-end"
            >
              <FormControl
                className={classes.assetInputRightMargin}
                fullWidth
              >
                <InputLabel shrink htmlFor="building">Filter By Expiration Year</InputLabel>
                <Select
                  native
                  value={state.stackingPlan.filteredYear}
                  onChange={(event) => handleYearFilterChange(floors, event)}
                  label="Filter By Expiration Year"
                  inputProps={{
                    name: 'year',
                    id: 'year',
                  }}
                >
                  <option aria-label={dropdownAllYears} value={dropdownAllYears}>
                    {dropdownAllYears}
                  </option>
                  {buildingStatistics?.byYear?.map((area: NonVacantBuildingArea) => (
                    <option key={area.leaseEndYear} value={area.leaseEndYear}>
                      {area.leaseEndYear}
                    </option>
                  ))}

                </Select>
              </FormControl>
              {
                isMultiAsset() && (
                  <FormControl
                    margin="none"
                    fullWidth
                  >
                    <InputLabel shrink htmlFor="building">Filter By Building</InputLabel>
                    <Select
                      native
                      onChange={handleBuildingChange}
                      label="Filter By Building"
                      inputProps={{
                        name: 'building',
                        id: 'building',
                      }}
                    >
                      {asset.buildings?.map((selectedBuilding: Building) => (
                        <option
                          key={selectedBuilding.id}
                          value={selectedBuilding.id}
                          defaultValue={state.stackingPlan.filteredBuilding?.name}
                        >
                          {selectedBuilding.name}
                        </option>
                      ))}

                    </Select>
                  </FormControl>
                )
              }
              <Box className={classes.switcherWrapper}>
                <FormControl className={classes.switcherButton}>
                  <IconButton onClick={() => changeTableState(false)}>
                    {
                      !state.stackingPlan.isTableActive ? (<img src={ActiveWindowIcon} alt="window" />)
                        : (<img src={WindowIcon} alt="window" />)
                    }
                  </IconButton>
                </FormControl>
                <FormControl className={classes.switcherButton}>
                  <IconButton onClick={() => changeTableState(true)}>
                    {
                      state.stackingPlan.isTableActive ? (<img src={ActiveTableIcon} alt="activeTable" />)
                        : (<img src={TableIcon} alt="table" />)
                    }
                  </IconButton>
                </FormControl>
              </Box>
            </Grid>
          )
        }
      </Grid>
    </Box>
  );
};
