import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useSecurity } from '../../../hooks/useSecurity';

export interface AccessInvitationRouteProps {
  userId: string
  token: string
}

export const AccessInvitation: React.FC = (): ReactElement => {
  const { userId, token } = useParams<AccessInvitationRouteProps>();
  const { login } = useSecurity();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const authenticate = async () => {
    try {
      await login(Number(userId), token);
    } catch (error) {
      switch (error.statusCode) {
        case 401:
          setErrorMessage(error.message);
          break;
        default:
          setErrorMessage('Something went wrong.');
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (userId && token) {
        await authenticate();
      }
    })();
  }, []);

  return (
    <>
      {
        errorMessage && (
          <Typography>{errorMessage}</Typography>
        )
      }
    </>
  );
};
