import { FormControl, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useAssetStyles } from '../../../../styles/useAssetStyles';
import { useGetAssetsBuildingsQuery } from '../../../../queries/assets';
import { AssetFormProps, AssetFormValues } from './types';
import { useAssetsForm } from './hooks';
import { Building } from '../../../../types/buildings';
import LoaderModal from '../../../modals/LoaderModal/LoaderModal';
import AssetsValidationSchema from './validators';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';

const AssetForm: React.FC<AssetFormProps> = (props: AssetFormProps): ReactElement => {
  const { model } = props;
  const { data: assetsBuildings } = useGetAssetsBuildingsQuery();
  const { create, update, isLoading } = useAssetsForm();
  const [chipData, setChipData] = useState<Building[]>([]);
  const [initialBuilding, setBuilding] = useState<Building[]>([]);

  const classes = useAssetStyles();
  const buttonClasses = useButtonsStyles();

  let initialValues: AssetFormValues;

  useEffect(() => {
    if (assetsBuildings) {
      setBuilding(assetsBuildings);
      setChipData(initialValues.buildings);
    }
  }, [assetsBuildings]);

  if (model) {
    initialValues = {
      title: model.title,
      buildings: model.buildings,
    };
  } else {
    initialValues = {
      title: '',
      buildings: [],
    };
  }
  const onDelete = (data: Building) => {
    const a = chipData.filter((chip) => chip.id !== data.id);
    setChipData(a);
    setBuilding([...initialBuilding, data]);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AssetsValidationSchema}
        onSubmit={async (values: AssetFormValues, actions: FormikHelpers<AssetFormValues>) => {
          if (model?.id != null) {
            await update({ ...values, buildings: chipData }, actions, Number(model.id));
          } else {
            await create({ ...values, buildings: chipData }, actions);
          }
        }}
      >
        {(formProps: FormikProps<AssetFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;

          return (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Form noValidate>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      className={classes.assetInputRightMargin}
                      value={values.title}
                      onChange={handleChange}
                      error={!!errors.title}
                      helperText={errors.title}
                      variant="outlined"
                      margin="normal"
                      required
                      id="title"
                      name="title"
                      label="Asset Name"
                      type="text"
                      data-testid="title-helper-text"
                      autoFocus
                    />
                  </FormControl>
                  {assetsBuildings && (
                  <Autocomplete
                    fullWidth
                    multiple
                    id="buildings"
                    value={chipData}
                    onChange={(event, value) => {
                      setChipData(value);
                    }}
                    options={initialBuilding.map((building) => (building))}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value: Building[], getTagProps) => (
                      chipData.map((option: Building, index: number) => (
                        <Chip
                          key={option.id}
                          label={option.name}
                            // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                          {...getTagProps({ index })}
                          onDelete={() => {
                            onDelete(option);
                          }}
                        />
                      )))}
                    renderInput={(params) => (
                      <FormControl margin="normal" fullWidth>
                        <TextField
                              // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          label="Choose Building"
                          variant="outlined"
                          placeholder="Favorites"
                          helperText={errors.buildings}
                        />
                      </FormControl>
                    )}
                  />
                  )}
                  <FormControl margin="normal">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      className={buttonClasses.standard}
                      data-testid="assets-button"
                      disabled={isSubmitting}
                    >
                      {model?.id != null ? 'Save' : 'Create'}
                    </Button>
                  </FormControl>
                  <LoaderModal isOpen={isLoading} />
                </Form>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </>
  );
};

export default AssetForm;
