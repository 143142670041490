import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from '../components/layouts/DefaultLayout';

export const NavigableLayoutRouterSwitch: React.FC = (props): ReactElement => {
  const { children } = props;

  return (
    <DefaultLayout>
      {children}
    </DefaultLayout>
  );
};

NavigableLayoutRouterSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};
