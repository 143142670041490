import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import {
  useGetAllAssetSpaces, useGetAssetDownsizingTenantsQuery, useGetAssetHiringTenantsQuery,
  useGetAssetLeasesStatsQuery,
  useGetAssetsBannersQuery,
  useGetSingleAsset,
} from '../../../queries/assets';
import { getBuildingStatisticsById, useGetAllFloorsQuery } from '../../../queries/buildings';
import PageTitle from '../../ui/PageTitle';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { Asset } from '../../../types/assets';
import ExpiringLeasesStats from '../../partials/portfolio/ExpiringLeasesStats';
import TopTenantsTable from '../../partials/portfolio/TopTenantsTable';
import NewsCards from '../news/NewsCards';
import { useGetExpiringLeasesByAsset } from '../../../queries/leases';
import { useGetNewsByAssetId } from '../../../queries/news';
import { cardTitleProps } from '../../../styles/useCardStyles';
import ListStackingPlan from '../stackingPlan/ListStackingPlan';
import BuildingFloorArea from '../../partials/buildings/BuildingFloorArea/BuildingFloorArea';
import { Store } from '../../../store/AppGlobalContext';
import { AssetStackingPlanHeader } from '../../partials/buildings/BuildingFloorArea/AssetStackingPlanHeader';
import { ExpiringLeasesTable } from '../../partials/assets/ExpiringLeasesTable';
import { DashboardBanners } from '../../ui/DashboardBanners';
import LeaseExpirationSchedule from '../../partials/assets/LeaseExpirationSchedule';
import { accessToken } from '../../../models/security';

export interface ViewAssetRouteParams {
  assetId: string
}

const ViewAsset: React.FC = (): ReactElement => {
  const { assetId } = useParams<ViewAssetRouteParams>();
  const { data: asset } = useGetSingleAsset(Number(assetId));
  const { state } = useContext(Store);
  const [filteredBuildingId, setFilteredBuildingId] = useState<number | undefined>(undefined);
  const {
    data: assetSpaces,
    isLoading: isAssetSpacesLoading,
  } = useGetAllAssetSpaces(Number(asset?.id), filteredBuildingId);

  const {
    data: assetBanners,
    error: bannersError,
    isLoading: bannersLoading,
  } = useGetAssetsBannersQuery(Number(assetId));
  const { data: expiringLeasesStat, isLoading: isLeasesStatLoading } = useGetAssetLeasesStatsQuery(Number(assetId));
  const {
    data: topDownsizingTenantsList, error: downsizingError,
    isLoading: downsizingLoader,
  } = useGetAssetDownsizingTenantsQuery(Number(assetId));
  const {
    data: topHiringTenantsList,
    error: hiringError,
    isLoading: hiringLoader,
  } = useGetAssetHiringTenantsQuery(Number(assetId));
  const {
    data: expiringLeases,
    error: leasesError,
    isLoading: isLeasesLoading,
  } = useGetExpiringLeasesByAsset(Number(assetId));
  const { data: news, error: newsError, isLoading: isNewsLoading } = useGetNewsByAssetId(Number(assetId));

  useEffect(() => {
    if (state.stackingPlan.filteredBuilding) {
      setFilteredBuildingId(state.stackingPlan.filteredBuilding?.id);
    }
  }, [state.stackingPlan.filteredBuilding, assetId]);

  const { data: buildingStatistics, isLoading: isStatisticsLoading } = getBuildingStatisticsById(
    state.stackingPlan.filteredBuilding?.id,
  );
  const { data: floors, isLoading: isFloorsLoading } = useGetAllFloorsQuery(state.stackingPlan.filteredBuilding?.id);

  const getAssetDetails = (targetAsset: Asset) => `
  ${targetAsset.type ? (`${targetAsset.type} | `) : ''}
  ${targetAsset.numberOfBuildings ? (`${targetAsset.numberOfBuildings} Buildings | `) : ''}
  ${targetAsset.totalArea ? (`${formatAreaSize(targetAsset.totalArea)}`) : ''}
  `;

  return (
    <>
      <Box display="flex" flexDirection="column" mb={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <PageTitle>{asset?.title}</PageTitle>
          {!accessToken.isCurrentRoleBroker()
              && (
              <Box pl={2}>
                <IconButton size="small" component={Link} to={`/assets/edit/${asset?.id}`}>
                  <Edit fontSize="small" />
                </IconButton>
              </Box>
              )}
        </Box>
        <Box px={2}>
          <Typography variant="caption">
            {asset && getAssetDetails(asset) }
          </Typography>
        </Box>
      </Box>
      <DashboardBanners data={assetBanners} isLoading={bannersLoading} error={bannersError} />
      <Box mb={3}>
        <Card>
          <CardHeader titleTypographyProps={cardTitleProps} title="Tenant Snapshot Report" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} sm={12}>
                <ExpiringLeasesStats
                  data={expiringLeasesStat}
                  assetId={assetId}
                  assetName={asset?.title}
                  isLoading={isLeasesStatLoading}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {!accessToken.isCurrentRoleBroker()
        && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid>
                <LeaseExpirationSchedule asset={asset} isLoading={isAssetSpacesLoading} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <ExpiringLeasesTable data={expiringLeases} error={leasesError} isLoading={isLeasesLoading} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TopTenantsTable
                type="hiring"
                data={topHiringTenantsList}
                error={hiringError}
                isLoading={hiringLoader}
              />
            </Grid>
            <Grid item xs={6}>
              <TopTenantsTable
                type="downsizing"
                data={topDownsizingTenantsList}
                error={downsizingError}
                isLoading={downsizingLoader}
              />
            </Grid>
          </Grid>
        </>
        )}
      {
        asset?.numberOfBuildings && (
          <>
            <AssetStackingPlanHeader
              floors={floors}
              buildingStatistics={buildingStatistics}
              asset={asset}
            />
            {
              state.stackingPlan.isTableActive && assetSpaces ? (
                <ListStackingPlan assetSpaces={assetSpaces} isLoading={isAssetSpacesLoading} />
              )
                : (
                  <BuildingFloorArea
                    floors={floors}
                    buildingId={Number(state.stackingPlan.filteredBuilding?.id)}
                    buildingStatistics={buildingStatistics}
                    isLoading={isFloorsLoading || isStatisticsLoading}
                  />
                )
            }
          </>
        )
      }
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Card>
              <CardHeader titleTypographyProps={cardTitleProps} title="Latest News and Updates" />
              <Divider />
              <CardContent>
                <NewsCards data={news} error={newsError} isLoading={isNewsLoading} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ViewAsset;
