import React, { ReactElement } from 'react';
import { FormControl, Grid, TextareaAutosize } from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTenantForm } from './hooks';
import { TenantFormProps, TenantFormValues } from './types';
import { TenantValidationSchema } from './validators';
import { useNewsCardStyles } from '../../../../styles/useNewsCardStyles';
import LoaderModal from '../../../modals/LoaderModal/LoaderModal';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';

const TenantForm: React.FC<TenantFormProps> = (props: TenantFormProps): ReactElement => {
  const { model } = props;
  const { create, update } = useTenantForm();
  const classes = useNewsCardStyles();
  const buttonClasses = useButtonsStyles();

  const initialValues: TenantFormValues = {
    name: model?.name ?? '',
    ceoName: model?.ceoName ?? '',
    description: model?.description ?? '',
    linkedinUrl: model?.linkedinUrl ?? '',
    indeedUrl: model?.indeedUrl ?? '',
    url: model?.url ?? '',
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={TenantValidationSchema}
        onSubmit={async (values: TenantFormValues, actions: FormikHelpers<TenantFormValues>) => {
          if (model?.id != null) {
            await update(values, actions, Number(model?.id));
          } else {
            await create(values, actions);
          }
        }}
      >
        {(formProps: FormikProps<TenantFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;

          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={6} lg={4}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <TextField
                        value={values.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name}
                        variant="outlined"
                        margin="normal"
                        required
                        id="name"
                        label="Tenant Name"
                        type="text"
                        name="name"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.ceoName}
                        onChange={handleChange}
                        error={!!errors.ceoName}
                        helperText={errors.ceoName}
                        variant="outlined"
                        margin="normal"
                        id="ceoName"
                        label="Tenant CEO"
                        type="text"
                        name="ceoName"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.linkedinUrl}
                        onChange={handleChange}
                        error={!!errors.linkedinUrl}
                        helperText={errors.linkedinUrl}
                        variant="outlined"
                        margin="normal"
                        id="linkedinUrl"
                        label="Linkedin Url"
                        type="text"
                        name="linkedinUrl"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.indeedUrl}
                        onChange={handleChange}
                        error={!!errors.indeedUrl}
                        helperText={errors.indeedUrl}
                        variant="outlined"
                        margin="normal"
                        id="indeedUrl"
                        label="Indeed Url"
                        type="text"
                        name="indeedUrl"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.url}
                        onChange={handleChange}
                        error={!!errors.url}
                        helperText={errors.url}
                        variant="outlined"
                        margin="normal"
                        id="url"
                        label="Tenant Url"
                        type="text"
                        name="url"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextareaAutosize
                        aria-label="minimum height"
                        rowsMin={6}
                        placeholder="description"
                        value={values.description}
                        onChange={handleChange}
                        className={classes.textField}
                        id="description"
                        name="description"
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        className={buttonClasses.standard}
                        disabled={isSubmitting}
                      >
                        {model?.id != null ? 'Save' : 'Create'}
                      </Button>
                    </FormControl>
                    <LoaderModal isOpen={isSubmitting} />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default TenantForm;
