import React, { ReactElement, useEffect, useState } from 'react';
import {
  FieldArray, Formik, FormikHelpers, FormikProps, getIn,
} from 'formik';
import {
  Button,
  Checkbox,
  Divider,
  FormControl, FormControlLabel, FormHelperText, InputLabel, OutlinedInput, Select, Typography,
} from '@material-ui/core';
import {
  Box, Card, Grid, TextField,
} from '@mui/material';
import clsx from 'clsx';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { Delete } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { DealFormProps, DealFormValues } from './types';
import { useGetAllAssetsQuery } from '../../../queries/assets';
import { useUsersDropdownOptionsQuery } from '../../../queries/users';
import { useGetAllTenantsQuery } from '../../../queries/tenants';
import { useDealForm } from './hooks';
import { Asset } from '../../../types/assets';
import { Space } from '../../../types/buildings';
import { UserDropdownOptions } from '../../../types/users';
import {
  CompetitorAsset,
  dealStageOptions, DealStageType,
} from '../../../types/deals/deals';
import { TermType } from '../../../types/leases';
import { getAssetSpaces } from '../../../queries/deals';
import { useFormStyles } from '../../../styles/useLeaseStyle';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import DealsValidationSchema from './validators';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { DealType, dealTypeOptions } from '../../../types/deals/dealType';
import { RequirementType, requirementTypeOptions } from '../../../types/deals/dealRequirementType';

interface TenantOptionType {
  newTenantName?: string;
  name: string;
  id?: number;
}

const DealForm: React.FC<DealFormProps> = (props: DealFormProps): ReactElement => {
  const { model } = props;
  const { data: assets } = useGetAllAssetsQuery();
  const { data: watchers } = useUsersDropdownOptionsQuery();
  const { data: tenants } = useGetAllTenantsQuery();
  const { create, update, isLoading } = useDealForm();
  const [chipAsset, setChipAsset] = useState<Asset[]>([]);
  const [initialAsset, setAsset] = useState<Asset[]>([]);
  const [tenantName, setTenantName] = useState('');
  const [showAllSpaces, setShowAllSpaces] = useState(false);
  const [chipSpace, setChipSpace] = useState<Space[]>([]);
  const [initialSpace, setSpace] = useState<Space[]>([]);
  const [chipWatcher, setChipWatcher] = useState<UserDropdownOptions[]>([]);
  const [initialWatcher, setWatcher] = useState<UserDropdownOptions[]>([]);
  const [selectedTenantId, setSelectedTenantId] = useState<number | null>(null);
  const [selectedCompetitorsIds, setSelectedCompetitorsIds] = useState<(number | undefined)[]>([]);
  const [deletedCompetitorsIds, setDeletedCompetitorsIds] = useState<(number | undefined)[]>([]);
  const [isTenantUndisclosed, setIsTenantUndisclosed] = useState<boolean>(false);
  const dealTypes = new DealType(dealTypeOptions).getDropdownOptions();
  const requirementTypes = new RequirementType(requirementTypeOptions).getDropdownOptions();
  const termTypes = Object.keys(TermType);
  const dealsStageStatuses = new DealStageType(dealStageOptions).getDropdownOptions();

  const assetIds = chipAsset.map((asset) => asset.id);
  const assetSpaces = getAssetSpaces(assetIds, showAllSpaces);

  const classes = useFormStyles();
  const buttonClasses = useButtonsStyles();

  const toggleUndisclosedTenant = () => {
    setIsTenantUndisclosed(!isTenantUndisclosed);
  };

  const handleShowSpacesChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setShowAllSpaces(event.target.value as boolean);
  };

  const onAssetDelete = (data: Asset) => {
    const asset = chipAsset.filter((chip) => chip.id !== data.id);
    setChipAsset(asset);
    setAsset([...initialAsset, data]);
  };
  const onWatcherDelete = (data: UserDropdownOptions) => {
    const watcher = chipWatcher.filter((chip) => chip.id !== data.id);
    setChipWatcher(watcher);
    setWatcher([...initialWatcher, data]);
  };

  const onSpaceDelete = (data: Space) => {
    const space = chipSpace.filter((chip) => chip.id !== data.id);
    setChipSpace(space);
    setSpace([...initialSpace, data]);
  };

  const removeCompetitorsIdOfSelectedCompetitorsIds = (competitorsId: number | undefined): void => {
    const newSelectedCompetitorsIds = selectedCompetitorsIds.filter((id) => id !== competitorsId);
    setSelectedCompetitorsIds(newSelectedCompetitorsIds);
    if (competitorsId) {
      setDeletedCompetitorsIds([...deletedCompetitorsIds, competitorsId]);
    }
  };

  let initialValues: DealFormValues;

  useEffect(() => {
    if (assets) {
      setAsset(assets);
      setChipAsset(initialValues.assets);
    }
  }, [assets]);

  useEffect(() => {
    if (assetSpaces.data) {
      setSpace(assetSpaces.data);
      setChipSpace(initialValues.spaces);
    }
  }, [assetSpaces.data]);

  useEffect(() => {
    if (watchers) {
      setWatcher(watchers);
      setChipWatcher(initialValues.watchers);
    }
  }, [watchers]);

  useEffect(() => {
    if (initialValues.tenantId) {
      setSelectedTenantId(initialValues.tenantId);
    }
  }, []);

  useEffect(() => {
    if (isTenantUndisclosed) {
      setSelectedTenantId(null);
    }
  }, [isTenantUndisclosed]);

  initialValues = {
    dealType: model?.dealType ?? 'new_deal',
    stage: model?.stage ?? '',
    undisclosedTenant: model?.undisclosedTenant ?? '',
    requirementType: model?.requirementType ?? '',
    requirementMinSize: model?.requirementMinSize ?? null,
    requirementMaxSize: model?.requirementMaxSize ?? null,
    commencementDate: model?.commencementDate ?? '',
    assets: model?.assets ?? [],
    spaces: model?.spaces ?? [],
    leaseTermType: model?.leaseTermType ?? '',
    leaseTermPeriod: model?.leaseTermPeriod ?? null,
    requirementNotes: model?.requirementNotes ?? '',
    logDate: model?.logDate ?? '',
    watchers: model?.watchers ?? [],
    tenantId: model?.tenantId ?? null,
    representative: model?.representative ?? '',
    competitors: model?.competitors ? model?.competitors?.map((competitor) => ({
      assetTitle: competitor.assetTitle,
      rate: competitor.rate,
      spaceSize: competitor.spaceSize,
      termType: competitor.termType,
      termPeriod: competitor.termPeriod,
      tenantImprovement: competitor.tenantImprovement,
      notes: competitor.notes,
      city: competitor.city,
      state: competitor.state,
      zipcode: competitor.zipcode,
      streetAddress: competitor.streetAddress,
      id: competitor.id,
    })) : [{
      assetTitle: '',
      rate: undefined,
      spaceSize: undefined,
      termType: '',
      termPeriod: undefined,
      tenantImprovement: undefined,
      notes: '',
      city: '',
      state: '',
      zipcode: '',
      streetAddress: '',
    }],
  };

  const constructTenantsAutocompleteOptions:() => TenantOptionType[] | undefined = () => tenants?.map((item) => ({
    name: item.name,
    id: item.id,
    newTenantName: item.name,
  }));

  const getDefaultTenantValue = () => tenants?.find((item) => Number(item.id) === Number(model?.tenantId));
  const [defaultTenantValue, setDefaultTenantValue] = useState(getDefaultTenantValue());
  const filter = createFilterOptions<TenantOptionType>();

  useEffect(() => {
    setDefaultTenantValue(getDefaultTenantValue());
  }, [tenants, model?.tenantId]);

  useEffect(() => {
    if (initialValues.undisclosedTenant) {
      setIsTenantUndisclosed(true);
    }
  }, [initialValues.undisclosedTenant]);

  return (
    <>
      <Formik
        enableReinitialize={!!model}
        initialValues={initialValues}
        validationSchema={DealsValidationSchema}
        onSubmit={async (values: DealFormValues, actions: FormikHelpers<DealFormValues>) => {
          if (model?.id) {
            await update({
              ...values,
              assets: chipAsset,
              spaces: chipSpace,
              watchers: chipWatcher,
              tenantId: selectedTenantId,
              tenantName,
            },
            actions,
            Number(model?.id),
            deletedCompetitorsIds);
          } else {
            await create({
              ...values,
              assets: chipAsset,
              spaces: chipSpace,
              watchers: chipWatcher,
              tenantId: selectedTenantId,
              tenantName,
            }, actions);
          }
        }}
      >
        {
          (formProps: FormikProps<DealFormValues>) => {
            const {
              values,
              errors,
              handleChange,
              isSubmitting,
              setFieldValue,
              touched,
              submitForm,
            } = formProps;

            return (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <Grid container className={classes.root}>
                        <Grid item xs={12} md={11}>
                          <Box>
                            <Box>
                              <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                TENANT REQUIREMENTS
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item sm={12} md={6} lg={4}>
                                  <Box className={classes.leaseTermPeriod}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.fieldsWidth}
                                    >
                                      <InputLabel
                                        shrink={!!values.requirementType}
                                        htmlFor="requirement-label"
                                      >
                                        Requirement Type
                                      </InputLabel>
                                      <Select
                                        input={
                                          <OutlinedInput notched={!!values.requirementType} label="Requirement Type" />
                                          }
                                        native
                                        inputProps={{
                                          name: 'requirementType',
                                          id: 'requirement-label',
                                        }}
                                        value={values.requirementType}
                                        onChange={handleChange}
                                        error={!!errors.requirementType}
                                        label="Requirement Type"
                                      >
                                        <option aria-label="None" value="" />
                                        {requirementTypes?.map((type) => (
                                          <option key={type.value} value={type.value}>
                                            {type.displayValue}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                  <Box className={clsx(classes.leaseTermPeriod, classes.fieldsWidth)}>
                                    <Typography
                                      variant="caption"
                                      className={classes.leaseLabels}
                                    >
                                      Lease Term Period
                                    </Typography>
                                    <Grid container spacing={3}>
                                      <Grid item xs={6}>
                                        <FormControl className={classes.parallelFieldsWidth}>
                                          <TextField
                                            variant="outlined"
                                            name="leaseTermPeriod"
                                            id="leaseTermPeriod"
                                            data-testid="term-period"
                                            type="number"
                                            placeholder="Number"
                                            inputProps={{ min: 0 }}
                                            value={values.leaseTermPeriod}
                                            onChange={handleChange}
                                            error={!!errors.leaseTermPeriod}
                                            helperText={errors.leaseTermPeriod}
                                          />
                                        </FormControl>

                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControl
                                          variant="outlined"
                                          margin="normal"
                                          className={classes.parallelFieldsWidth}
                                        >
                                          <Select
                                            name="leaseTermType"
                                            id="leaseTermType"
                                            value={values.leaseTermType}
                                            onChange={handleChange}
                                            native
                                          >
                                            <option aria-label="None" value="" disabled>Period</option>
                                            {termTypes?.map((type: string) => (
                                              <option key={type} value={type}>{type}</option>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <Box className={classes.fieldsWidth}>
                                    <Typography
                                      variant="caption"
                                      className={classes.leaseLabels}
                                    >
                                      Requirement Size (ft)
                                    </Typography>
                                    <Grid container spacing={3}>
                                      <Grid item xs={6}>
                                        <FormControl className={classes.parallelFieldsWidth}>
                                          <TextField
                                            variant="outlined"
                                            id="requirementMinSize"
                                            name="requirementMinSize"
                                            data-testid="requirementMinSize"
                                            placeholder="Min"
                                            value={values.requirementMinSize}
                                            onChange={handleChange}
                                            error={!!errors.requirementMinSize}
                                            helperText={errors.requirementMinSize}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControl className={classes.parallelFieldsWidth}>
                                          <TextField
                                            variant="outlined"
                                            name="requirementMaxSize"
                                            id="requirementMaxSize"
                                            data-testid="requirementMinSize"
                                            placeholder="Max"
                                            value={values.requirementMaxSize}
                                            onChange={handleChange}
                                            error={!!errors.requirementMaxSize}
                                            helperText={errors.requirementMaxSize}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box className={classes.startDate}>
                                    <FormControl
                                      className={clsx(classes.datePicker, classes.commencementDate)}
                                      fullWidth
                                    >
                                      <TextField
                                        variant="standard"
                                        id="commencementDate"
                                        name="commencementDate"
                                        data-testid="Commencement-date"
                                        label="Commencement Date"
                                        value={values.commencementDate}
                                        error={!!errors.commencementDate}
                                        onChange={handleChange}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                      <FormHelperText error={!!errors.commencementDate}>
                                        {errors.commencementDate}
                                      </FormHelperText>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <FormControl
                                    margin="normal"
                                    variant="outlined"
                                    className={clsx(classes.dealCommentMargin, classes.fieldsWidth)}
                                  >
                                    <TextField
                                      variant="outlined"
                                      id="requirementNotes"
                                      name="requirementNotes"
                                      data-testid="requirementNotes"
                                      label="Other Requirement Notes"
                                      onChange={handleChange}
                                      value={values.requirementNotes}
                                      multiline
                                      rows={6}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider className={classes.divider} />
                          </Box>
                          <Box>
                            <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                              ASSET DETAILS
                            </Typography>
                            <Grid container spacing={4}>
                              <Grid item sm={12} md={6} lg={4}>
                                <FormControl
                                  variant="outlined"
                                  margin="normal"
                                  className={clsx(classes.assetsField, classes.fieldsWidth)}
                                >
                                  <Autocomplete
                                    multiple
                                    id="assets"
                                    value={chipAsset}
                                    options={initialAsset.map((asset) => (asset)) || []}
                                    onChange={(event, assetValue) => {
                                      setChipAsset(assetValue);
                                    }}
                                    getOptionLabel={(option) => option.title}
                                    renderTags={(assetValue: Asset[], getTagProps) => (
                                      chipAsset.map((option: Asset, index: number) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <Chip
                                          label={option.title}
                                        // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                                          {...getTagProps({ index })}
                                          onDelete={() => {
                                            onAssetDelete(option);
                                          }}
                                        />
                                      )))}
                                    renderInput={(params) => (
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      <TextField {...params} label="Asset(s)" />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item sm={12} md={6} lg={7}>
                                <Box className={classes.assetsFieldsWidth}>
                                  <Box className={classes.assetsContainer}>
                                    <FormControl
                                      required
                                      variant="outlined"
                                      className={classes.parallelIsAvailableField}
                                    >
                                      <InputLabel
                                        htmlFor="asset-spaces"
                                      >
                                        Space(s)
                                      </InputLabel>
                                      <Select
                                        native
                                        value={showAllSpaces}
                                        onChange={handleShowSpacesChange}
                                        label="Filter by Spaces"
                                        inputProps={{
                                          name: 'asset-spaces',
                                          id: 'asset-spaces',
                                        }}
                                      >
                                        <option value="false">Show all</option>
                                        <option value="true">Available</option>
                                      </Select>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.parallelSpaceField}>
                                      <Autocomplete
                                        fullWidth
                                        multiple
                                        id="assetSpace"
                                        value={chipSpace}
                                        options={initialSpace.map((space) => space) || []}
                                        getOptionLabel={(option) => `${option.spaceName} (${option.buildingName})`}
                                        onChange={(event, spaceValue) => {
                                          setChipSpace(spaceValue);
                                        }}
                                        renderTags={(spaceValue: Space[],
                                          getTagProps) => (
                                          chipSpace.map((option: Space, index: number) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <Chip
                                              label={`${option.spaceName} (${option.buildingName})`}
                                            // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                                              {...getTagProps({ index })}
                                              onDelete={() => {
                                                onSpaceDelete(option);
                                              }}
                                            />
                                          )))}
                                        renderInput={(params) => (
                                          <TextField
                                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                                            {...params}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                          </Box>
                          <Box>
                            <Box>
                              <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                DEAL OVERVIEW
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item sm={12} md={6} lg={4}>
                                  <Box className={classes.requirementType}>
                                    <FormControl
                                      required
                                      variant="outlined"
                                      margin="normal"
                                      className={classes.fieldsWidth}
                                    >
                                      <InputLabel
                                        shrink={!!values.dealType}
                                        htmlFor="deal-type"
                                      >
                                        Deal Type
                                      </InputLabel>
                                      <Select
                                        name="dealType"
                                        id="deal-type"
                                        value={values.dealType}
                                        error={!!errors.dealType}
                                        disabled
                                        onChange={handleChange}
                                        native
                                        data-testid="deal-type"
                                        label="Deal Type"
                                      >
                                        <option aria-label="None" value="" />
                                        {dealTypes?.map((type) => (
                                          <option key={type.value} value={type.value}>
                                            {type.displayValue}
                                          </option>
                                        ))}
                                      </Select>
                                      <FormHelperText
                                        data-testid="deal-type-helper-text"
                                        error={!!errors.dealType}
                                      >
                                        {errors.dealType}
                                      </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                      required
                                      variant="outlined"
                                      margin="normal"
                                      className={clsx(classes.assetsField, classes.fieldsWidth)}
                                    >
                                      <Autocomplete
                                        multiple
                                        id="watchers"
                                        value={chipWatcher}
                                        options={initialWatcher.map((watcher) => (watcher)) || []}
                                        onChange={(event, watcherValue) => {
                                          setChipWatcher(watcherValue);
                                        }}
                                        getOptionLabel={(option) => option.fullName}
                                        renderTags={(watcher: UserDropdownOptions[], getTagProps) => (
                                          chipWatcher.map((option: UserDropdownOptions, index: number) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <Chip
                                              label={option.fullName}
                                            // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                                              {...getTagProps({ index })}
                                              onDelete={() => {
                                                onWatcherDelete(option);
                                              }}
                                            />
                                          )))}
                                        renderInput={(params) => (
                                          // eslint-disable-next-line react/jsx-props-no-spreading
                                          <TextField {...params} label="Followers" />
                                        )}
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <Box className={classes.requirementType}>
                                    <FormControl
                                      required
                                      variant="outlined"
                                      margin="normal"
                                      className={classes.fieldsWidth}
                                    >
                                      <InputLabel
                                        shrink={!!values.stage}
                                        htmlFor="stage-label"
                                      >
                                        Stage
                                      </InputLabel>
                                      <Select
                                        native
                                        input={
                                          <OutlinedInput notched={!!values.stage} label="Stage" />
                                          }
                                        inputProps={{
                                          name: 'stage',
                                          id: 'stage-label',
                                        }}
                                        value={values.stage}
                                        onChange={handleChange}
                                        error={!!errors.stage}
                                        label="Stage"
                                      >
                                        <option aria-label="None" value="" />
                                        {dealsStageStatuses?.map((dealStage) => (
                                          <option key={dealStage.value} value={dealStage.value}>
                                            {dealStage.displayValue}
                                          </option>
                                        ))}
                                      </Select>
                                      <FormHelperText
                                        data-testid="stage-helper-text"
                                        error={!!errors.stage}
                                      >
                                        {errors.stage}
                                      </FormHelperText>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4} style={{ paddingTop: '26px' }}>
                                  <Box className={classes.startDate}>
                                    <FormControl
                                      className={classes.datePicker}
                                      fullWidth
                                      required
                                    >
                                      <TextField
                                        variant="standard"
                                        id="logDate"
                                        name="logDate"
                                        label="Log Date"
                                        value={values.logDate}
                                        error={!!errors.logDate}
                                        onChange={handleChange}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                      <FormHelperText
                                        data-testid="log-date-helper-text"
                                        error={!!errors.logDate}
                                      >
                                        {errors.logDate}
                                      </FormHelperText>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider className={classes.divider} />
                          </Box>
                          <Box>
                            <Box>
                              <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                TENANT SECTION
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item sm={12} md={6} lg={4}>
                                  <Box className={classes.requirementType}>
                                    <FormControl
                                      required
                                      variant="outlined"
                                      margin="normal"
                                      className={classes.fieldsWidth}
                                    >
                                      <Autocomplete
                                        fullWidth
                                        defaultValue={defaultTenantValue}
                                        onChange={(event, newValue) => {
                                          if (newValue && !newValue.id && newValue.newTenantName) {
                                            setTenantName(newValue.newTenantName);
                                            setFieldValue('tenantId', undefined);
                                          } else {
                                            setFieldValue('tenantId', newValue?.id);
                                            setSelectedTenantId(Number(newValue?.id));
                                          }
                                        }}
                                        filterOptions={(options, params) => {
                                          const filtered = filter(options, params);
                                          if (params.inputValue !== '') {
                                            filtered.push({
                                              newTenantName: params.inputValue,
                                              name: `Add "${params.inputValue}"`,
                                            });
                                          }

                                          return filtered;
                                        }}
                                        id="tenantId"
                                        clearOnBlur={false}
                                        clearOnEscape={false}
                                        options={constructTenantsAutocompleteOptions() || []}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(optionProps,
                                          option) => (
                                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                                            <li {...optionProps}>
                                              {option?.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                                            {...params}
                                            label="Select or Create Tenant"
                                            variant="outlined"
                                            error={!!errors.tenantId}
                                          />
                                        )}
                                      />
                                      <FormHelperText error={!!errors.tenantId}>{errors.tenantId}</FormHelperText>
                                    </FormControl>
                                    <FormControlLabel
                                      control={
                                          (
                                            <Checkbox
                                              color="primary"
                                              name="Undisclosed Tenant"
                                              checked={isTenantUndisclosed}
                                              onChange={() => {
                                                setFieldValue('tenantId', selectedTenantId);
                                                toggleUndisclosedTenant();
                                              }}
                                            />
                                          )
                                        }
                                      label="Undisclosed Tenant"
                                    />
                                    <FormControl
                                      required
                                      variant="outlined"
                                      margin="normal"
                                      className={classes.fieldsWidth}
                                    >
                                      <TextField
                                        variant="outlined"
                                        name="representative"
                                        id="tenantRep"
                                        placeholder="Tenant Rep"
                                        value={values.representative}
                                        onChange={handleChange}
                                        error={!!errors.representative}
                                        helperText={errors.representative}
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item sm={12} md={6} lg={4}>
                                  <Box className={clsx(classes.nickNameBox, classes.requirementType)}>
                                    { isTenantUndisclosed
                                    && (
                                    <FormControl fullWidth>
                                      <TextField
                                        variant="outlined"
                                        id="undisclosedTenant"
                                        name="undisclosedTenant"
                                        label="Tenant Nickname"
                                        value={values.undisclosedTenant}
                                        onChange={handleChange}
                                        className={classes.fieldsWidth}
                                      />
                                    </FormControl>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Box className={classes.root}>
                        <Grid item xs={12} md={11}>
                          <Box>
                            <Box>
                              <Grid container>
                                <Grid item xs={12} md={11}>
                                  <Box>
                                    <Typography variant="overline" display="block" className={classes.leaseSectionTitles}>
                                      COMPETITOR ASSET(S)
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Box>
                                <FieldArray
                                  name="competitors"
                                  render={({ remove, push }) => (
                                    <Box>
                                      {values.competitors?.map((competitor: CompetitorAsset, index: number) => {
                                        const assetTitle = `competitors[${index}].assetTitle`;
                                        const tenantImprovement = `competitors[${index}].tenantImprovement`;
                                        const streetAddress = `competitors[${index}].streetAddress`;
                                        const state = `competitors[${index}].state`;
                                        const rate = `competitors[${index}].rate`;
                                        const spaceSize = `competitors[${index}].spaceSize`;
                                        const city = `competitors[${index}].city`;
                                        const zipcode = `competitors[${index}].zipcode`;
                                        const termPeriod = `competitors[${index}].termPeriod`;
                                        const termType = `competitors[${index}].termType`;
                                        const notes = `competitors[${index}].notes`;

                                        return (
                                        // eslint-disable-next-line react/no-array-index-key
                                          <Box key={index}>
                                            <Grid container spacing={4}>
                                              <Grid item xs={12} md={4} lg={4} className={classes.competitorAssets}>
                                                <FormControl fullWidth className={classes.competitorFieldsWidth}>
                                                  <TextField
                                                    variant="outlined"
                                                    label="Asset Name"
                                                    name={assetTitle}
                                                    type="text"
                                                    placeholder="Asset Name"
                                                    value={competitor.assetTitle}
                                                    onChange={handleChange}
                                                    error={getIn(touched, assetTitle)
                                                        && getIn(errors, assetTitle)}
                                                  />
                                                </FormControl>
                                                <FormControl fullWidth className={classes.competitorFieldsWidth}>
                                                  <TextField
                                                    variant="outlined"
                                                    label="Tenant Improvement($/ft)"
                                                    name={tenantImprovement}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    placeholder="Tenant Improvement($/ft)"
                                                    value={competitor.tenantImprovement}
                                                    onChange={handleChange}
                                                    error={getIn(touched, tenantImprovement)
                                                            && getIn(errors, tenantImprovement)}
                                                  />
                                                </FormControl>
                                                <FormControl fullWidth className={classes.competitorFieldsWidth}>
                                                  <TextField
                                                    variant="outlined"
                                                    label="Street Address"
                                                    placeholder="Street Address"
                                                    name={streetAddress}
                                                    onChange={handleChange}
                                                    value={competitor.streetAddress}
                                                    error={getIn(touched, streetAddress)
                                                            && getIn(errors, streetAddress)}
                                                  />
                                                </FormControl>
                                                <FormControl fullWidth className={classes.competitorFieldsWidth}>
                                                  <TextField
                                                    variant="outlined"
                                                    label="State"
                                                    placeholder="State"
                                                    name={state}
                                                    onChange={handleChange}
                                                    value={competitor.state}
                                                    error={getIn(touched, state)
                                                            && getIn(errors, state)}
                                                  />
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={4} lg={4} className={classes.competitorAssets}>
                                                <FormControl fullWidth>
                                                  <TextField
                                                    variant="outlined"
                                                    label="Rate Amount ($)"
                                                    placeholder="Rate Amount ($)"
                                                    inputProps={{ min: 0 }}
                                                    name={rate}
                                                    type="number"
                                                    value={competitor.rate}
                                                    onChange={handleChange}
                                                    error={getIn(touched, rate)
                                                            && getIn(errors, rate)}
                                                    className={classes.fieldsWidth}
                                                  />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                  <TextField
                                                    variant="outlined"
                                                    label="Space Size (ft)"
                                                    placeholder="Space Size (ft)"
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    name={spaceSize}
                                                    value={competitor.spaceSize}
                                                    onChange={handleChange}
                                                    error={getIn(touched, spaceSize)
                                                            && getIn(errors, spaceSize)}
                                                    className={classes.fieldsWidth}
                                                  />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                  <TextField
                                                    variant="outlined"
                                                    label="City"
                                                    placeholder="City"
                                                    name={city}
                                                    value={competitor.city}
                                                    onChange={handleChange}
                                                    error={getIn(touched, city)
                                                            && getIn(errors, city)}
                                                    className={classes.fieldsWidth}
                                                  />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                  <TextField
                                                    variant="outlined"
                                                    label="Zip code"
                                                    placeholder="Zip code"
                                                    name={zipcode}
                                                    value={competitor.zipcode}
                                                    onChange={handleChange}
                                                    error={getIn(touched, zipcode)
                                                            && getIn(errors, zipcode)}
                                                    className={classes.fieldsWidth}
                                                  />
                                                </FormControl>
                                              </Grid>
                                              <Grid item sm={12} md={4} lg={4} style={{ paddingTop: '0' }}>
                                                <Box className={classes.fieldsWidth}>
                                                  <Typography
                                                    variant="caption"
                                                    className={classes.leaseLabels}
                                                  >
                                                    Term Period
                                                  </Typography>
                                                  <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                      <FormControl
                                                        className={classes.parallelFieldsWidth}
                                                        style={{ marginBottom: '32px' }}
                                                      >
                                                        <TextField
                                                          variant="outlined"
                                                          label="Number"
                                                          id="termPeriod"
                                                          type="number"
                                                          inputProps={{ min: 0 }}
                                                          name={termPeriod}
                                                          value={competitor.termPeriod}
                                                          onChange={handleChange}
                                                          error={getIn(touched, termPeriod)
                                                                  && getIn(errors, termPeriod)}
                                                          data-testid="term-period"
                                                          placeholder="Number"
                                                        />
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ position: 'relative' }}>
                                                      <FormControl
                                                        variant="outlined"
                                                        className={classes.parallelFieldsWidth}
                                                      >
                                                        <InputLabel
                                                          shrink={!!competitor.termType}
                                                          htmlFor="termType"
                                                        >
                                                          Period
                                                        </InputLabel>
                                                        <Select
                                                          label="Period"
                                                          id="termType"
                                                          value={competitor.termType}
                                                          name={termType}
                                                          onChange={handleChange}
                                                          native
                                                        >
                                                          <option aria-label="None" value="" disabled>Period</option>
                                                          {termTypes?.map((type: string) => (
                                                            <option key={type} value={type}>{type}</option>
                                                          ))}
                                                        </Select>
                                                      </FormControl>
                                                      <Box>
                                                        {
                                                          values.competitors.length > 1 && (
                                                            <Button
                                                              className={classes.competitorDeleteButton}
                                                              size="small"
                                                              variant="text"
                                                              onClick={async () => {
                                                                remove(index);
                                                                removeCompetitorsIdOfSelectedCompetitorsIds(
                                                                  competitor.id,
                                                                );
                                                              }}
                                                            >
                                                              <Delete style={{ color: grey[500] }} />
                                                            </Button>
                                                          )
                                                        }
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                                <FormControl
                                                  required
                                                  variant="outlined"
                                                  className={classes.fieldsWidth}
                                                >
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Notes"
                                                    multiline
                                                    value={competitor.notes}
                                                    name={notes}
                                                    onChange={handleChange}
                                                    rows={9}
                                                  />
                                                </FormControl>
                                              </Grid>
                                            </Grid>
                                            <Divider className={classes.divider} />
                                          </Box>
                                        );
                                      })}
                                      <Box className={classes.addTermButton}>
                                        <Button
                                          className={clsx(classes.addSpaceButton, buttonClasses.standard)}
                                          onClick={() => {
                                            push({
                                              assetTitle: '',
                                              rate: null,
                                              spaceSize: null,
                                              termType: '',
                                              termPeriod: null,
                                              tenantImprovement: null,
                                              notes: '',
                                              city: '',
                                              state: '',
                                              zipcode: '',
                                              streetAddress: '',
                                            });
                                          }}
                                        >
                                          ADD COMPETITOR
                                        </Button>
                                      </Box>
                                    </Box>
                                  )}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
                <Box className={classes.buttonWrapper}>
                  <FormControl margin="normal">
                    <Button
                      type="submit"
                      fullWidth
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      size="medium"
                      data-testid="deals-button"
                      className={buttonClasses.standard}
                      onClick={submitForm}
                    >
                      {model?.id != null ? 'Save Deal' : 'Create Deal'}
                    </Button>
                  </FormControl>
                </Box>
                <LoaderModal isOpen={isLoading} />
              </>
            );
          }
        }
      </Formik>
    </>
  );
};

export default DealForm;
