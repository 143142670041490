import React, { ReactElement } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import PageTitle from '../../ui/PageTitle';
import {
  useGetTenantsBannersQuery,
  useGetDownsizingTenantsQuery,
  useGetTopHiringTenantsQuery,
} from '../../../queries/tenants';
import 'chartjs-plugin-labels';
import ExpiringLeasesStats from '../../partials/portfolio/ExpiringLeasesStats';
import EmployeesDensityStats from '../../partials/portfolio/EmployeesDensityStats';
import ExpiringLeasesList from '../../partials/portfolio/ExpiringLeasesList';
import TopTenantsTable from '../../partials/portfolio/TopTenantsTable';
import { useGetExpiringLeases } from '../../../queries/leases';
import {
  useGetEmployeesDensityStatsQuery,
  useGetExpiringLeasesStatsQuery,
  useGetReviewOccupancyStatsQuery,
} from '../../../queries/portfolio';
import { useGetLatestNewsQuery } from '../../../queries/news';
import NewsCards from '../news/NewsCards';
import { cardTitleProps } from '../../../styles/useCardStyles';
import ReviewOccupancyChart from '../../partials/portfolio/ReviewOccupancyChart';
import { DashboardBanners } from '../../ui/DashboardBanners';

const Portfolio: React.FC = (): ReactElement => {
  const {
    data: topDownsizingTenantsList, error: downsizingError,
    isLoading: downsizingLoader,
  } = useGetDownsizingTenantsQuery();
  const { data: topHiringTenantsList, error: hiringError, isLoading: hiringLoader } = useGetTopHiringTenantsQuery();
  const { data: companyBanners, error: bannersError, isLoading: bannersLoader } = useGetTenantsBannersQuery();

  const { data: employeeDensityData, isLoading: isDensityLoading } = useGetEmployeesDensityStatsQuery();
  const { data: expiringLeases, error: leasesError, isLoading: isLeasesLoading } = useGetExpiringLeases();
  const { data: expiringLeasesStat, isLoading: isLeasesStatLoading } = useGetExpiringLeasesStatsQuery();
  const {
    data: latestNews, error: latestNewsError, isLoading: isNewsLoading,
  } = useGetLatestNewsQuery();

  const {
    data: reviewOccupancyData,
    isLoading: isOccupancyLoading,
  } = useGetReviewOccupancyStatsQuery();

  return (
    <>
      <Box mb={3}>
        <PageTitle>Portfolio</PageTitle>
      </Box>
      <DashboardBanners data={companyBanners} isLoading={bannersLoader} error={bannersError} />
      <Box mb={3}>
        <Card>
          <CardHeader titleTypographyProps={cardTitleProps} title="Tenant Snapshot Report" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={8} sm={12} style={{ height: 450 }}>
                <EmployeesDensityStats data={employeeDensityData} isLoading={isDensityLoading} />
              </Grid>
              <Grid item md={4} sm={12}>
                <ExpiringLeasesStats data={expiringLeasesStat} isLoading={isLeasesStatLoading} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box mb={3}>
        <Card>
          <CardHeader titleTypographyProps={cardTitleProps} title="Occupancy by Building" />
          <CardContent>
            <ReviewOccupancyChart data={reviewOccupancyData} isLoading={isOccupancyLoading} />
          </CardContent>
        </Card>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <TopTenantsTable
            type="hiring"
            data={topHiringTenantsList}
            error={hiringError}
            isLoading={hiringLoader}
          />
        </Grid>
        <Grid item md={6}>
          <TopTenantsTable
            type="downsizing"
            data={topDownsizingTenantsList}
            error={downsizingError}
            isLoading={downsizingLoader}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Card>
            <CardHeader titleTypographyProps={cardTitleProps} title="Latest News and Updates" />
            <Divider />
            <CardContent>
              <NewsCards data={latestNews} error={latestNewsError} isLoading={isNewsLoading} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <ExpiringLeasesList data={expiringLeases} error={leasesError} isLoading={isLeasesLoading} />
        </Grid>
      </Grid>
    </>
  );
};

export default Portfolio;
