import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import { useGetAllNewsQuery } from '../../../queries/news';
import EditNews from './EditNews';
import CreateNews from './CreateNews';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import ListNews from './ListNews';
import { AuthRoles } from '../../../types/auth';

const NewsIndex:React.FC = (): ReactElement => {
  useGetAllNewsQuery();

  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter
        exact
        path={path}
        component={ListNews}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        path={`${path}/edit/:newsId`}
        component={EditNews}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        path={`${path}/create`}
        component={CreateNews}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
    </RouterBaseSwitch>
  );
};

export default NewsIndex;
