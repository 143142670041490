import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box, Button,
  Card,
  Divider,
  Grid, IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Tooltip, Typography,
} from '@material-ui/core';
import {
  Link, useParams,
} from 'react-router-dom';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Edit } from '@material-ui/icons';
import { useGetSingleSpaceQuery } from '../../../../../queries/leases';
import PageTitle from '../../../../ui/PageTitle';
import { formatMoney } from '../../../../../formatters/moneyFormatter';
import { formatAreaSize } from '../../../../../formatters/areaSizeFormatter';
import { SpaceRouteProps } from '../../../../pages/spaces/interfaces';
import { formatDateUSReadable } from '../../../../../formatters/dateFormatters/dateFormatters';
import { useButtonsStyles } from '../../../../../styles/useButtonsStyles';
import { Leases } from '../../../../../types/leases';

export interface SpaceRouteParams {
  spaceId: string
}

const ViewSpace:React.FC = ():ReactElement => {
  const [leases, setLeases] = useState<Leases[]>([]);
  const { spaceId } = useParams<SpaceRouteParams>();
  const { buildingId } = useParams<SpaceRouteProps>();
  const { data: space, isLoading, isFetching } = useGetSingleSpaceQuery(Number(spaceId));
  useEffect(() => {
    const leasesData = space ? space.leases.map((lease) => ({
      ...lease,
      area: space.terms.find((term) => term.lease.id === lease.id)?.area,
      price: space.terms.find((term) => term.lease.id === lease.id)?.rate,
    })) : [];
    setLeases(leasesData as Leases[] || []);
  }, [space]);
  const buttonClasses = useButtonsStyles();

  const columns: MRT_ColumnDef<Leases>[] = [
    {
      header: 'Company',
      accessorKey: 'company',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.company?.name || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {row.company?.name || ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={formatDateUSReadable(row.endDate) || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {formatDateUSReadable(row.endDate) || ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={formatDateUSReadable(row.startDate) || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {formatDateUSReadable(row.startDate) || ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      header: 'Price',
      accessorKey: 'price',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={formatMoney(row.price)} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {formatMoney(row.price)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      header: 'Area',
      accessorKey: 'area',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={formatAreaSize(row.area)} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {formatAreaSize(row.area)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <IconButton
            style={{ width: '25px' }}
            to={`/leases/edit/${row.id}`}
            component={Link}
            aria-label="edit"
          >
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" flexDirection="column">
          <Typography component={Link} color="primary" to={`/buildings/view/${buildingId}`}>
            {`${space?.name} - ${space?.floor?.building?.name}`}
          </Typography>
          {leases
          && (
            <Typography>
              {space?.floor?.building?.streetAddress}
            </Typography>
          )}
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <Paper>
              <Box display="flex" justifyContent="space-between" alignItems="center" pr={2} p={1}>
                <PageTitle>Space Info</PageTitle>
                <>
                  <IconButton
                    component={Link}
                    to={`/buildings/${space?.floor?.building?.id}/spaces/edit/${space?.id}`}
                    aria-label="edit"
                  >
                    <Edit />
                  </IconButton>
                </>
              </Box>
            </Paper>
            <Divider />
            {space
          && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow key={space.floor.floor}>
                  <TableCell component="th" scope="row">
                    Floor
                  </TableCell>
                  <TableCell align="right">
                    {space.floor.floor}
                  </TableCell>
                </TableRow>
                <TableRow key={space.area}>
                  <TableCell component="th" scope="row">
                    Total Area
                  </TableCell>
                  <TableCell align="right">
                    {`${space.area} ft`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          )}
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            <Box display="flex" justifyContent="space-between" alignItems="center" pr={2} p={2}>
              <PageTitle>Leases</PageTitle>
              <Button
                component={Link}
                to="/leases/create"
                variant="contained"
                className={buttonClasses.large}
                color="primary"
              >
                Create Lease
              </Button>
            </Box>
          </Paper>
          <Divider />
          <Card>
            { leases
        && (
          <MaterialReactTable
            columns={columns}
            data={leases}
            enableTopToolbar={false}
            enableColumnResizing
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableRowOrdering={false}
            enableSorting={false}
            enableGlobalFilterModes={false}
            enableDensityToggle={false}
            layoutMode="grid"
            state={{ isLoading: isLoading || isFetching }}
            muiTableProps={{
              sx: {
                tableLayout: 'fixed',
                columnWidth: 'auto',
              },
            }}
          />
        )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default ViewSpace;
