import { useQuery, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import { EmployeesDensityStat, ExpiringLeasesStat, LeasesOccupancyState } from './types';
import { QUERY_OPTIONS } from '../../constants';

export const useGetExpiringLeasesStatsQuery = ():
UseQueryResult<ExpiringLeasesStat[], Error> => useQuery<ExpiringLeasesStat[], Error>(
  'expiringLeasesStats',
  async () => {
    const response = await httpClient.get('/statistics/expiring-leases-stats');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetEmployeesDensityStatsQuery = ():
UseQueryResult<EmployeesDensityStat[], Error> => useQuery<EmployeesDensityStat[], Error>(
  'employeesDensityStat',
  async () => {
    const response = await httpClient.get('/statistics/density');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetReviewOccupancyStatsQuery = ():
UseQueryResult<LeasesOccupancyState[], Error> => useQuery<LeasesOccupancyState[], Error>(
  'reviewOccupancyStat',
  async () => {
    const response = await httpClient.get('/statistics/buildings-occupancy');
    return response.data.items;
  },
  QUERY_OPTIONS,
);
