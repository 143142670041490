import React, { useState } from 'react';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import {
  Box, FormControl, Grid, IconButton, InputLabel, Select,
} from '@material-ui/core';
import clsx from 'clsx';
import { Delete } from '@material-ui/icons';
import PageTitle from '../../ui/PageTitle';
import { useGetAllTenantsQuery, useGetDataPointsQuery } from '../../../queries/tenants';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import { useDeleteDataPoint } from '../../../queries/tenants/mutations';
import { TenantSource } from '../../../types/statistics';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { useTableStyles } from '../../../styles/useTableStyles';
import { sortArea } from '../../../helpers/sortArea';
import { useListingsFiltrationStyles } from '../../../styles/useListingsFiltrationStyles';
import XDataGridLoader from '../../ui/XDataGridLoader';

export const ListDataPoints: React.FC = () => {
  const [filteredTenant, setFilterTenant] = useState<number | undefined>(undefined);
  const [filteredResource, setFilteredResource] = useState<TenantSource | ''>('');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const classes = useTableStyles();
  const filterClasses = useListingsFiltrationStyles();

  const { data: companyStatisticsData, isLoading, isFetching } = useGetDataPointsQuery(
    (page + 1), pageSize, filteredTenant, filteredResource,
  );

  const { data: tenants } = useGetAllTenantsQuery();
  const { showModal, RenderModal, modalId } = useDeleteModal();
  const deleteCompanyStatisticsMutation = useDeleteDataPoint();
  const companySources = Object.keys(TenantSource);

  const deleteCompanyStatisticsData = (statisticsId: number | null) => {
    if (!statisticsId) {
      return;
    }
    deleteCompanyStatisticsMutation.mutate({ statisticsId });
  };

  const handleChangeCompany = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setFilterTenant(Number(event.target.value));
    setPage(0);
  };

  const handleChangeSource = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setFilteredResource(event.target.value as TenantSource);
    setPage(0);
  };

  const columns: GridColumns = [
    {
      field: 'dataSource',
      headerName: 'Resource',
      flex: 1,
    },
    {
      field: 'locationCriteria',
      headerName: 'Location',
      flex: 1,
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      sortComparator: (v1, v2, params1,
        params2) => sortArea(params1, params2),
    },
    {
      field: 'dateEntered',
      headerName: 'Entered Date',
      type: 'date',
      valueGetter: (params) => formatDateUSReadable(String(params.row.dateEntered)),
      flex: 1,
    },
    {
      field: 'id',
      sortable: false,
      headerName: 'Actions',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showModal(Number(params.value));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
      width: 130,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Data Points</PageTitle>
        <Box
          display="flex"
          alignItems="center"
          flex="0.46"
          alignSelf="flex-end"
          className={filterClasses.listingFilterWrapper}
        >
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <FormControl
                margin="none"
                fullWidth
              >
                <InputLabel shrink={!!filteredTenant} htmlFor="company">Filter by Tenant</InputLabel>
                <Select
                  native
                  value={filteredTenant}
                  onChange={handleChangeCompany}
                  label="Filter by Tenant"
                  inputProps={{
                    name: 'company',
                    id: 'company',
                  }}
                >
                  <option aria-label="None" value="" />
                  {tenants?.map((tenant) => (
                    <option key={tenant.id} value={tenant?.id}>
                      {tenant?.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="none"
                fullWidth
              >
                <InputLabel htmlFor="company-date">Filter by Source</InputLabel>
                <Select
                  native
                  value={filteredResource}
                  onChange={handleChangeSource}
                  label="Filter by Source"
                  inputProps={{
                    name: 'company-source',
                    id: 'company-source',
                  }}
                >
                  <option aria-label="None" value="" />
                  {companySources?.map((source) => (
                    <option key={source} value={source}>
                      {source}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div style={{ height: '100%' }}>
        <DataGrid
          pagination
          paginationMode="server"
          rows={companyStatisticsData?.items || []}
          columns={columns}
          className={clsx(classes.cellSelectDisabled, classes.tablesBackground)}
          pageSize={pageSize}
          page={page}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          onPageChange={(param: number) => {
            if (param !== 0 || page === 1) {
              setPage(param);
            }
          }}
          rowCount={companyStatisticsData?.total}
          autoHeight
          components={{
            LoadingOverlay: XDataGridLoader,
          }}
          loading={isFetching || isLoading}
        />
      </div>
      <RenderModal
        body="Are you sure you want to delete this Tenant?"
        confirmAction={() => deleteCompanyStatisticsData(modalId)}
        title="Delete Tenant"
      />
      <LoaderModal isOpen={isLoading || deleteCompanyStatisticsMutation.isLoading} />
    </>
  );
};
