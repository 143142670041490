import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../ui/PageTitle';
import { useBrokerUserAssetsQuery, useGetSingleUserQuery } from '../../../queries/users';
import UserForm from '../../forms/users/UsersForm/UsersForm';

export interface EditUserProps {
  userId: string
}

const EditUser: React.FC = (): ReactElement => {
  const { userId } = useParams<EditUserProps>();
  const { data: user } = useGetSingleUserQuery(Number(userId));
  const { data: brokerAssets } = useBrokerUserAssetsQuery(Number(userId));

  return (
    <>
      <PageTitle>Edit User</PageTitle>
      {user && <UserForm model={{ ...user, assets: brokerAssets }} />}
    </>
  );
};

export default EditUser;
