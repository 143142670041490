export const generalSorting = (param1: number | string, param2: number | string): number => String(param1)
  .localeCompare(String(param2), 'en-US', { numeric: true });

export const minMaxElements = (params: number[]): number[] => {
  const leasedArea = [];
  const area = params.slice().sort((a, b) => a - b);
  const areaFirstElement = area[0] || 0;
  const areaLastElement = area[area.length - 1] || 0;
  leasedArea.push(areaFirstElement, areaLastElement);
  return leasedArea;
};
