import React, { ReactElement } from 'react';
import PageTitle from '../../ui/PageTitle';
import SpaceForm from '../../forms/buildings/spaces/SpaceForm/SpaceForm';

const CreateSpace: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create Space</PageTitle>
    <SpaceForm />
  </>
);

export default CreateSpace;
