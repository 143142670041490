import React, { ReactElement, useState } from 'react';
import {
  FormControl, FormHelperText, Grid, InputLabel, Select,
} from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { SpaceFormProps, SpaceFormValues } from './types';
import { useSpaceForm } from './hooks';
import { SpaceValidationSchema } from './validators';
import { SpaceRouteProps } from '../../../../pages/spaces/interfaces';
import { StackingPlanFloor } from '../../../../../types/buildings';
import { useGetAllFloorsQuery } from '../../../../../queries/buildings';
import LoaderModal from '../../../../modals/LoaderModal/LoaderModal';
import { useButtonsStyles } from '../../../../../styles/useButtonsStyles';

const SpaceForm: React.FC<SpaceFormProps> = (props: SpaceFormProps): ReactElement => {
  const { model } = props;
  const { create, update } = useSpaceForm();
  const { buildingId } = useParams<SpaceRouteProps>();
  const { data: floors } = useGetAllFloorsQuery(Number(buildingId));
  const [selectedFloor, setSelectedFloor] = useState<string>(model?.floor.id ? String(model?.floor.id) : '');
  const buttonClasses = useButtonsStyles();

  const initialValues: SpaceFormValues = {
    name: model?.name ?? '',
    floorId: model?.floor.id ? String(model?.floor.id) : '',
    area: model?.area ?? '',
  };

  const getSelectedFloorVacantArea = (): number => {
    const enteredArea = model ? model.area : 0;
    const filteredFloor = floors?.find((floor) => String(floor.id) === selectedFloor);
    const vacantArea = filteredFloor ? Number(enteredArea) + filteredFloor.uncultivatedArea : null;
    return Number(vacantArea);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SpaceValidationSchema}
        onSubmit={async (values: SpaceFormValues, actions: FormikHelpers<SpaceFormValues>) => {
          if (model?.id != null) {
            await update(values, actions, model.id);
          } else {
            await create(values, actions);
          }
        }}
      >
        {(formProps: FormikProps<SpaceFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={6} lg={4}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <TextField
                        value={values.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name}
                        variant="outlined"
                        margin="normal"
                        required
                        id="name"
                        label="Space Name"
                        type="text"
                        name="name"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    >
                      <InputLabel htmlFor="floorId" required>Floor</InputLabel>
                      <Select
                        native
                        inputProps={{
                          name: 'floorId',
                          id: 'floorId',
                        }}
                        value={values.floorId}
                        onChange={(event) => {
                          handleChange(event);
                          setSelectedFloor(String(event.target.value));
                        }}
                        error={!!errors.floorId}
                        label="Floor"
                      >
                        <option aria-label="None" value="" />
                        {floors?.map((floor: StackingPlanFloor) => (
                          <option key={floor.id} value={String(floor.id)}>{floor.floor}</option>
                        ))}
                      </Select>
                      <FormHelperText id="floor-helper-text" error={!!errors.floorId}>{errors.floorId}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.area}
                        onChange={handleChange}
                        error={!!errors.area || Number(values.area) > getSelectedFloorVacantArea()}
                        helperText={errors.area}
                        variant="outlined"
                        margin="normal"
                        required
                        id="area"
                        label="Area"
                        type="text"
                        name="area"
                        autoFocus
                      />
                    </FormControl>
                    <FormHelperText id="available-area" error={Number(values.area) > getSelectedFloorVacantArea()}>
                      {selectedFloor
                      && `Available max. area: ${getSelectedFloorVacantArea()}`}
                    </FormHelperText>
                    <FormControl margin="normal">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={buttonClasses.standard}
                        size="large"
                        disabled={isSubmitting || Number(values.area) > getSelectedFloorVacantArea()}
                      >
                        {model?.id != null ? 'Save' : 'Create'}
                      </Button>
                    </FormControl>
                    <LoaderModal isOpen={isSubmitting} />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SpaceForm;
