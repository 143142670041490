import { withStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@mui/material/colors';
import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: grey[50],
    opacity: 1,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${grey[200]}`,
    color: grey[800],
    boxShadow: theme.shadows[1],
  },
  arrow: {
    opacity: 1,
    '&::before': {
      border: `1px solid ${grey[200]}`,
      boxShadow: theme.shadows[1],
      backgroundColor: grey[50],
      boxSizing: 'border-box',
    },
  },
}))(Tooltip);
