import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { DealFormValues, UseDealsForm } from './types';
import {
  CreateCompetitorRequest,
  CreateDealRequest,
  UpdateCompetitorRequest,
  UpdateDealRequest,
} from '../../../queries/deals/types';
import {
  useCreateCompetitorMutation,
  useCreateDealMutation, useUpdateCompetitorMutation,
  useUpdateDealMutation,
} from '../../../queries/deals/mutations';
import { CompetitorAsset } from '../../../types/deals/deals';
import { useCreateTenantMutation } from '../../../queries/tenants/mutations';
import { CreateTenantRequest } from '../../../queries/tenants/types';

export const useDealForm = (): UseDealsForm => {
  const createDealMutation = useCreateDealMutation();
  const createCompetitorMutation = useCreateCompetitorMutation();
  const updateCompetitorMutation = useUpdateCompetitorMutation();
  const createTenantMutation = useCreateTenantMutation();
  const updateDealMutation = useUpdateDealMutation();
  const history = useHistory();

  const createCompetitor = async (competitors: CompetitorAsset[], dealId: number) => {
    const competitorsRequest:CompetitorAsset[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const competitor of competitors) {
      const checker = Object.values(competitor);
      if (checker.some(Boolean)) {
        competitorsRequest.push(competitor);
      }
    }

    const requests: CreateCompetitorRequest[] = competitorsRequest.map((competitor: CompetitorAsset):
    CreateCompetitorRequest => ({
      id: competitor.id,
      assetTitle: competitor.assetTitle,
      rate: competitor.rate,
      spaceSize: competitor.spaceSize,
      termType: competitor.termType,
      termPeriod: competitor.termPeriod,
      tenantImprovement: competitor.tenantImprovement,
      notes: competitor.notes,
      city: competitor.city,
      state: competitor.state,
      zipcode: competitor.zipcode,
      streetAddress: competitor.streetAddress,
      dealId,
    }));

    await createCompetitorMutation.mutateAsync({ requests });
  };

  const updateCompetitor = async (
    competitors: CompetitorAsset[],
    dealId: number | undefined,
    deletedIds: (number | undefined)[],
  ) => {
    const competitorsRequest:CompetitorAsset[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const competitor of competitors) {
      const checker = Object.values(competitor);
      if (checker.some(Boolean)) {
        competitorsRequest.push(competitor);
      }
    }

    const requests: UpdateCompetitorRequest[] = competitorsRequest.map((competitor: CompetitorAsset):
    UpdateCompetitorRequest => ({
      id: competitor.id,
      assetTitle: competitor.assetTitle,
      rate: competitor.rate,
      spaceSize: competitor.spaceSize,
      termType: competitor.termType,
      termPeriod: competitor.termPeriod,
      tenantImprovement: competitor.tenantImprovement,
      notes: competitor.notes,
      city: competitor.city,
      state: competitor.state,
      zipcode: competitor.zipcode,
      streetAddress: competitor.streetAddress,
      dealId,
    }));

    await updateCompetitorMutation.mutateAsync({ requests, deletedIds });
  };

  const updateCompetitorAfterLengthCheck = async (
    competitors: CompetitorAsset[],
    dataId: number | undefined,
    deletedIds: (number | undefined)[],
  ) => {
    if (competitors.length) {
      await updateCompetitor(competitors, Number(dataId), deletedIds);
    }
    history.push('/deals');
  };

  const createCompetitorAfterLengthCheck = async (competitors: CompetitorAsset[], dataId: number | undefined) => {
    if (competitors.length) {
      await
      createCompetitor(competitors, Number(dataId));
    }
    history.push('/deals');
  };

  const create = async (values: DealFormValues, actions: FormikHelpers<DealFormValues>) => {
    try {
      const dealRequest: CreateDealRequest = {
        dealType: values.dealType,
        stage: values.stage,
        undisclosedTenant: values.undisclosedTenant,
        tenantId: values.tenantId,
        representative: values.representative,
        requirementType: values.requirementType,
        requirementMinSize: values.requirementMinSize,
        requirementMaxSize: values.requirementMaxSize,
        commencementDate: values.commencementDate,
        leaseTermType: values.leaseTermType,
        leaseTermPeriod: values.leaseTermPeriod,
        requirementNotes: values.requirementNotes,
        logDate: values.logDate,
        assets: values.assets.map((asset) => asset.id),
        spaces: values.spaces.map((space) => space.id),
        watchers: values.watchers.map((watcher) => watcher.id),
      };
      if (!values?.tenantId && values.tenantName) {
        const request: CreateTenantRequest = {
          name: values.tenantName,
          ceoName: '',
          description: '',
          linkedinUrl: '',
          indeedUrl: '',
          url: '',
        };
        await createTenantMutation.mutateAsync(
          { request }, {
            onSuccess: async (tenant) => {
              await createDealMutation.mutateAsync({ dealRequest: { ...dealRequest, tenantId: Number(tenant.id) } }, {
                onSuccess: async (data) => {
                  await createCompetitorAfterLengthCheck(values.competitors, data.id);
                },
              });
            },
          },
        );
      } else if (!values?.tenantId && !values.tenantName) {
        await createDealMutation.mutateAsync({ dealRequest }, {
          onSuccess: async (data) => {
            await createCompetitorAfterLengthCheck(values.competitors, data.id);
          },
        });
      } else if (values.tenantId) {
        await createDealMutation.mutateAsync({ dealRequest }, {
          onSuccess: async (data) => {
            await createCompetitorAfterLengthCheck(values.competitors, data.id);
          },
        });
      }
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (
    values: DealFormValues,
    actions: FormikHelpers<DealFormValues>,
    dealId: number,
    deletedIds: (number | undefined)[],
  ) => {
    try {
      const dealRequest: UpdateDealRequest = {
        dealType: values.dealType,
        stage: values.stage,
        undisclosedTenant: values.undisclosedTenant,
        tenantId: values.tenantId,
        requirementType: values.requirementType,
        requirementMinSize: values.requirementMinSize,
        requirementMaxSize: values.requirementMaxSize,
        commencementDate: values.commencementDate,
        leaseTermType: values.leaseTermType,
        leaseTermPeriod: values.leaseTermPeriod,
        requirementNotes: values.requirementNotes,
        logDate: values.logDate,
        representative: values.representative,
        assets: values.assets.map((asset) => asset.id),
        spaces: values.spaces.map((space) => space.id),
        watchers: values.watchers.map((watcher) => watcher.id),
      };
      if (!values?.tenantId && values.tenantName) {
        const request: CreateTenantRequest = {
          name: values.tenantName,
          ceoName: '',
          description: '',
          linkedinUrl: '',
          indeedUrl: '',
          url: '',
        };
        await createTenantMutation.mutateAsync(
          { request }, {
            onSuccess: async (tenant) => {
              // eslint-disable-next-line max-len
              await updateDealMutation.mutateAsync({ dealRequest: { ...dealRequest, tenantId: Number(tenant.id) }, dealId }, {
                onSuccess: async (data) => {
                  await updateCompetitorAfterLengthCheck(values.competitors, data.id, deletedIds);
                },
              });
            },
          },
        );
      }
      if (!values?.tenantId && !values.tenantName) {
        await updateDealMutation.mutateAsync({ dealRequest, dealId }, {
          onSuccess: async (data) => {
            await updateCompetitorAfterLengthCheck(values.competitors, data.id, deletedIds);
          },
        });
      }
      if (values.tenantId) {
        await updateDealMutation.mutateAsync({ dealRequest, dealId }, {
          onSuccess: async (data) => {
            await updateCompetitorAfterLengthCheck(values.competitors, data.id, deletedIds);
          },
        });
      }
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    isLoading: createDealMutation.isLoading || updateDealMutation.isLoading || createCompetitorMutation.isLoading
        || createTenantMutation.isLoading,
    create,
    update,
  };
};
