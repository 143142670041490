import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Container, CssBaseline } from '@material-ui/core';

export const EmptyLayout: React.FC = (props): ReactElement => {
  const { children } = props;
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div>
        {children}
      </div>
    </Container>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
