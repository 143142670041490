import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../ui/PageTitle';
import { useGetSingleDealQuery } from '../../../queries/deals';
import DealForm from '../../forms/deals/DealForm';

export interface EditDealProps {
  dealId: string
}

const EditDeals: React.FC = (): ReactElement => {
  const { dealId } = useParams<EditDealProps>();
  const { data: deal } = useGetSingleDealQuery(Number(dealId));

  return (
    <>
      <PageTitle>Edit Deal</PageTitle>
      {deal && (
      <DealForm model={{
        ...deal,
        tenantId: deal.tenant?.id,
        representative: deal.representative,
      }}
      />
      )}
    </>
  );
};

export default EditDeals;
