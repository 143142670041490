import { Pojo } from '../types/pojo';

export class TenantiEnum {
  public options: Pojo;

  constructor(options: Pojo) {
    this.options = options;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getDropdownOptions() {
    return Object.values(this.options);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setDefaultDisplayValue(value:string) {
    this.options.default.displayValue = value;
    return this;
  }

  // eslint-disable-next-line consistent-return,@typescript-eslint/explicit-module-boundary-types
  public getDisplayNameByKey(key: string | undefined) {
    if (key && key !== undefined) {
      return this.options[key].displayValue;
    }
  }
}
