export interface CreateTenantRequest {
  name: string
  ceoName: string | null
  description: string
  linkedinUrl: string | null
  indeedUrl: string | null
  url: string | null
}

export interface UpdateTenantRequest {
  name: string
  ceoName: string | null
  description: string
  linkedinUrl: string | null
  indeedUrl: string | null
  url: string | null
}

export interface CreateTenantMutationVariables {
  request: CreateTenantRequest
}

export interface UpdateTenantMutationVariables {
  request: UpdateTenantRequest
  tenantId: number
}

export interface DeleteTenantMutationVariables {
  companyId: number
}

export interface DeleteDataPointsMutationVariables {
  statisticsId: number
}

export enum DataPointsRequestVariables {
  linkedIn = 'linkedin',
  indeed = 'indeed',
}
