import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { NewsFormValues, UseNewsForm } from './types';
import { useCreateNewsMutation, useUpdateNewsMutation } from '../../../../queries/news/mutations';
import { CreateNewsRequest, UpdateNewsRequest } from '../../../../queries/news/types';
import { News } from '../../../../types/news';
import { navigateBack } from '../../../../helpers/navigation';

export const useNewsForm = (): UseNewsForm => {
  const createMutation = useCreateNewsMutation();
  const updateMutation = useUpdateNewsMutation();
  const history = useHistory();

  const create = async (values: NewsFormValues, actions: FormikHelpers<NewsFormValues>) => {
    try {
      const request: CreateNewsRequest = values;
      await createMutation.mutateAsync({ request }, {
        onSuccess: async () => {
          navigateBack(history);
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (values: NewsFormValues, actions: FormikHelpers<NewsFormValues>, model: News) => {
    try {
      const request: UpdateNewsRequest = values;
      await updateMutation.mutateAsync({ request, newsId: Number(model.id) }, {
        onSuccess: async () => {
          navigateBack(history);
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    create,
    update,
  };
};
