import { PRINT_NULL } from '../constants';

/**
 * @deprecated use formatters functions from formatters directory
 */
// eslint-disable-next-line
export const printNull = (value: any) => {
  if (value === null) {
    return PRINT_NULL;
  }
  return value;
};
