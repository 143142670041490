import React, { ReactElement } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export interface ComponentLoaderProps {
  isOpen: boolean
}

const ComponentLoader: React.FC<ComponentLoaderProps> = (props: ComponentLoaderProps): ReactElement => {
  const { isOpen } = props;

  return (
    <>
      {
        isOpen
        && (
        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
          <CircularProgress />
        </Box>
        )
      }
    </>
  );
};

export default ComponentLoader;
