import * as Yup from 'yup';
import { REQUIRED_FIELD } from '../../../../constants';

const BuildingValidationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  status: Yup.string().required(REQUIRED_FIELD),
  totalArea: Yup.number().typeError('Total Area must be a number.').required(REQUIRED_FIELD),
  floors: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(REQUIRED_FIELD),
      floor: Yup.number().typeError('Floor must be a number.').required(REQUIRED_FIELD),
      area: Yup.string().required(REQUIRED_FIELD),
    }),
  ),
});

export default BuildingValidationSchema;
