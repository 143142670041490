import { useQuery, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import { QUERY_OPTIONS } from '../../constants';
import { AiPromptListResponse } from '../../types/aiPrompt';

export const useGetAllAiPromptsQuery = (
  page: number,
  perPage: number,
): UseQueryResult<AiPromptListResponse, Error> => useQuery<AiPromptListResponse, Error>(
  ['aiPrompts', page, perPage],
  async () => {
    const response = await httpClient.get(`/ai_questions?page=${page}&per_page=${perPage}`);
    return response.data;
  },
  QUERY_OPTIONS,
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDownloadAiPrompts = async (
  page: number,
  perPage: number,
) => {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('per_page', String(perPage));
  const response = await httpClient.get(`/ai_questions/download?${params.toString()}`);

  const blob = new Blob([response.data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'ai_prompts.csv');
  a.click();
};
