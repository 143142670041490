import React, { ReactElement } from 'react';
import { FormControl } from '@material-ui/core';
import PageTitle from '../../ui/PageTitle';
import UserForm from '../../forms/users/UsersForm/UsersForm';
import { useCreateStyles } from '../../../styles/useCreateStyles';

const CreateUser: React.FC = (): ReactElement => {
  const classes = useCreateStyles();
  return (
    <>
      <FormControl className={classes.formTitle}>
        <PageTitle>Create User</PageTitle>
      </FormControl>
      <UserForm />
    </>
  );
};

export default CreateUser;
