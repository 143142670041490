import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import { LayoutRouter } from '../../../router/LayoutRouter';
import { useGetAllAssetsQuery } from '../../../queries/assets';
import ListAssets from './ListAssets';
import CreateAsset from './CreateAsset';
import EditAsset from './EditAsset';
import ViewAsset from './ViewAsset';
import ListTenants from '../tenants/ListTenants';
import { AuthRoles } from '../../../types/auth';

const AssetsIndex: React.FC = (): ReactElement => {
  useGetAllAssetsQuery();

  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter exact path={path} component={ListAssets} isPrivate />
      <LayoutRouter exact path={`${path}/tenants-list`} component={ListTenants} isPrivate />
      <LayoutRouter
        exact
        path={`${path}/create`}
        component={CreateAsset}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        exact
        path={`${path}/edit/:assetId`}
        component={EditAsset}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter exact path={`${path}/view/:assetId`} component={ViewAsset} isPrivate />
    </RouterBaseSwitch>
  );
};

export default AssetsIndex;
