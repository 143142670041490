import React, { ReactElement, useContext, useState } from 'react';
import {
  Box, Button, IconButton, Tooltip,
} from '@material-ui/core';
import { Divider, Typography } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import clsx from 'clsx';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { useViewLeaseStyles } from '../../../styles/useViewLeaseStyles';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { useDeleteLease } from '../../../queries/leases/mutations';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { useTableStyles } from '../../../styles/useTableStyles';
import { generalSorting } from '../../../helpers/sorting';
import { formatMoney } from '../../../formatters/moneyFormatter';
import { getFormattedAddress } from '../../../helpers/address';
import { sortArea } from '../../../helpers/sortArea';
import { formatBoolean } from '../../../formatters/formatBoolean';
import SubLeaseModal from '../../modals/SubLeaseModal/SubLeaseModal';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import { useDeleteSubLease } from '../../../queries/subleases/mutations';
import { Leases } from '../../../types/leases';
import { formatNullToHyphen } from '../../../formatters/formatNullToHyphen';
import { formatToDollarFT } from '../../../formatters/dollarFtFormatter';
import { PRINT_HYPHEN } from '../../../constants';

interface LeaseAbstractProps {
  lease: Leases | undefined
  leaseId: string
  leasedArea: string | undefined
}

export const LeaseAbstract: React.FC<LeaseAbstractProps> = (props: LeaseAbstractProps): ReactElement => {
  const { lease, leaseId, leasedArea } = props;
  const { state, dispatch } = useContext(Store);
  const { leasesNavigator } = useNavigationManager();
  const leaseClasses = useViewLeaseStyles();
  const deleteLeaseMutation = useDeleteLease();
  const deleteSubLeaseMutation = useDeleteSubLease();
  const tableClasses = useTableStyles();
  const [leasedSpaceId, setLeasedSpaceId] = useState<number | null>(null);
  const [subLeasedCompanyId, setSubLeasedCompanyId] = useState<number | null>(null);

  const {
    showModal: showSubLeaseDeleteModal,
    RenderModal: SubLeaseDeleteModal,
    modalId: subLeaseModalId,
  } = useDeleteModal();

  const {
    showModal: showLeaseDeleteModal,
    RenderModal: LeaseDeleteModal,
    modalId: leaseModalId,
  } = useDeleteModal();

  const deleteLeaseAbstract = (abstractLeaseId: number | null) => {
    if (!abstractLeaseId) {
      return;
    }
    deleteLeaseMutation.mutate({ leaseId: abstractLeaseId });
    leasesNavigator.base().navigate();
  };

  const deleteSubLease = (subleasedSpaceId: number | null) => {
    if (!subleasedSpaceId) {
      return;
    }
    deleteSubLeaseMutation.mutate({ subleasedSpaceId });
  };

  const getOptionValues = (value: boolean | undefined) => (value ? 'Yes' : 'No');

  const handleOpenLeaseModal = () => {
    dispatch({ type: storeActions.OPEN_SUBLEASE_MODAL, payload: { leaseId } });
  };

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Space Number',
      sortComparator: (v1, v2, params1, params2) => {
        const space1 = String(params1.value);
        const space2 = String(params2.value);
        return generalSorting(space1, space2);
      },
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Space Number',
      sortComparator: (v1, v2, params1, params2) => {
        const space1 = String(params1.value);
        const space2 = String(params2.value);
        return generalSorting(space1, space2);
      },
      flex: 1,
    },
    {
      field: 'rate',
      headerName: 'Lease Rate',
      valueGetter: (params) => formatMoney(params.row.rate),
      sortComparator: (v1, v2, params1, params2) => {
        const rate1 = String(params1.value);
        const rate2 = String(params2.value);
        return generalSorting(rate1, rate2);
      },
      flex: 1,
    },
    {
      field: 'occupiedByLease',
      headerName: 'Leased Area',
      valueGetter: (params) => formatAreaSize(params.row.occupiedByLease),
      sortComparator: (v1, v2, params1,
        params2) => sortArea(params1, params2),
      flex: 1,
    },
    {
      field: 'tenantImprovement',
      headerName: 'Tenant Improvement',
      valueGetter: (params) => formatToDollarFT(params.row.tenantImprovement),
      flex: 1,
    },
    {
      field: 'isAvailableForSublease',
      headerName: 'Available For Lease',
      valueGetter: (params) => formatBoolean(params.row.isAvailableForSublease),
      flex: 1,
    },
    {
      field: 'subleasedCompanyName',
      headerName: 'Sub-lease Info',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <>
          {
              params.row.subleasedCompanyName ? (
                <Tooltip
                  className={tableClasses.subleaseName}
                  title={params.row.subleasedCompanyName || ''}
                  placement="top"
                  arrow
                >
                  <Typography
                    variant="inherit"
                  >
                    {params.row.subleasedCompanyName}
                  </Typography>
                </Tooltip>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSubLeasedCompanyId(null);
                    handleOpenLeaseModal();
                    setLeasedSpaceId(Number(params?.row.leasedSpaceTermId));
                  }}
                >
                  ADD Sub-Lease
                </Button>
              )
            }
          {
            params.row.subleasedCompanyId
              ? (
                <Box className={tableClasses.tableButtonWrapper}>
                  <IconButton onClick={() => {
                    setSubLeasedCompanyId(Number(params?.row.subleasedCompanyId));
                    setLeasedSpaceId(Number(params?.row.leasedSpaceTermId));
                    handleOpenLeaseModal();
                  }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setLeasedSpaceId(Number(params?.row.leasedSpaceTermId));
                      return showSubLeaseDeleteModal(Number(leasedSpaceId));
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ) : ''
          }
        </>
      ),
      flex: 3,
    },
  ];

  return (
    <Box className={leaseClasses.abstractWrapper}>
      <Box className={leaseClasses.abstractCard}>
        <Box className={leaseClasses.spaceBetween} marginBottom={3}>
          <Typography component="h1" variant="h5" style={{ fontWeight: 'bold' }}>
            Lease Abstract
          </Typography>
          <Box>
            <IconButton
              onClick={() => leasesNavigator.edit(Number(leaseId)).navigate()}
              aria-label="edit"
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => showLeaseDeleteModal(Number(leaseId))}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
        <Box className={leaseClasses.termCard}>
          <Box className={leaseClasses.infoWrapper}>
            <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>TERMS</Typography>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Start Date
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {formatDateUSReadable(lease?.startDate)}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                {`Lease Term (${lease?.termType})`}
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {lease?.termPeriod}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                End Date
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {formatDateUSReadable(lease?.endDate)}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Leased Area
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {formatAreaSize(String(leasedArea))}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Lease Type
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {lease?.type}
              </Typography>
            </Box>
          </Box>
          <Box className={leaseClasses.divider}>
            <Divider orientation="vertical" />
          </Box>
          <Box className={leaseClasses.infoWrapper}>
            <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>
              OTHER TERMS
            </Typography>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Renewal Notice Start Date
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                { lease?.renewalOption ? formatDateUSReadable(lease?.renewalNoticeStartDate) : PRINT_HYPHEN}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Renewal Notice End Date
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                { lease?.renewalOption ? formatDateUSReadable(lease?.renewalNoticeEndDate) : PRINT_HYPHEN}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Renewal Term
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {`${formatNullToHyphen(lease?.renewalTerm)} ${formatNullToHyphen(lease?.renewalTermType)}`}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Expansion Option
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {getOptionValues(lease?.expansionOption)}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Purchase Option
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {getOptionValues(lease?.purchaseOption)}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Termination Option
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {getOptionValues(lease?.terminationOption)}
              </Typography>
            </Box>
          </Box>
          <Box className={leaseClasses.divider}>
            <Divider orientation="vertical" />
          </Box>
          <Box className={leaseClasses.infoWrapper}>
            <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>
              GUARANTOR COMPANY
            </Typography>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Company Name
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {lease?.guarantorCompanyName}
              </Typography>
            </Box>
            <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
              <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                Company Address
              </Typography>
              <Typography variant="inherit" className={leaseClasses.abstractText}>
                {getFormattedAddress(
                  lease?.guarantorStreetAddress, lease?.guarantorCity, lease?.guarantorState, lease?.guarantorZipCode,
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={leaseClasses.infoWrapper}>
          <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>
            RENT TERMS
          </Typography>
        </Box>
      </Box>
      <DataGrid
        className={clsx(tableClasses.tablesBackground, tableClasses.cellSelectDisabled)}
        rows={lease?.spaces || []}
        columns={columns}
        autoHeight
        disableColumnMenu
        hideFooter
      />
      <LeaseDeleteModal
        body="Are you sure you want to delete this Lease?"
        confirmAction={() => deleteLeaseAbstract(leaseModalId)}
        title="Delete Lease"
      />
      <SubLeaseDeleteModal
        body="Are you sure you want to delete this SubLease?"
        confirmAction={() => deleteSubLease(subLeaseModalId)}
        title="Delete Document"
      />
      <LoaderModal isOpen={deleteLeaseMutation.isLoading} />
      {
        state.subleaseModal.visible
        && (
          <SubLeaseModal
            isOpen={state.subleaseModal.visible}
            title="Add Sublease"
            leasedSpaceId={leasedSpaceId}
            companyId={subLeasedCompanyId}
          />
        )
      }
    </Box>
  );
};
