import React, { ReactElement } from 'react';
import { Bubble } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartEvent,
  Tooltip,
  registerables,
} from 'chart.js';
import { useHistory } from 'react-router-dom';
import { generateColors } from '../../../styles/colors/palette';
import { formatNumericValue } from '../../../formatters/numberFormatter';
import { EmployeesDensityStat } from '../../../queries/portfolio/types';
import ComponentLoader from '../../ui/ComponentLoader';

export interface EmployeesDensityProps {
  data: EmployeesDensityStat[] | undefined
  isLoading: boolean
}

const EmployeesDensityStats: React.FC<EmployeesDensityProps> = (props: EmployeesDensityProps): ReactElement => {
  ChartJS.register(
    Tooltip,
    ...registerables,
  );

  const { data: employeeDensityData, isLoading } = props;
  const colors = generateColors(Number(employeeDensityData?.length), 0.5);
  const history = useHistory();

  const areas = employeeDensityData?.map((value) => value.totalArea) ?? [];
  const max = Math.max(...areas);
  const proportion = max / 100;

  const employees = employeeDensityData?.map((value) => value.density) ?? [];
  const maxEmployees = Math.max(...employees);

  const data = {
    datasets: employeeDensityData?.map((densityData, index) => ({
      label: densityData.companyName,
      responsive: true,
      maintainAspectRatio: false,
      fill: true,
      data: [
        {
          y: densityData.density,
          x: densityData.totalArea,
          r: densityData.totalArea / proportion,
        },
      ],
      id: densityData.companyId,
      companyName: densityData.companyName,
      totalArea: densityData.totalArea,
      density: densityData.density,
      backgroundColor: colors[index],
      borderWidth: 1,
    })),
  };

  const options = {
    maintainAspectRatio: false,
    onClick: (event: ChartEvent, legendItem:({ datasetIndex: number })[]) => {
      if (data && data.datasets && legendItem.length) {
        // eslint-disable-next-line no-underscore-dangle
        const index = legendItem[0].datasetIndex;
        const tenantId = data.datasets[index].id;
        history.push(`/tenants/view/${tenantId}`);
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Tenant\'s Employee Density vs Lease Square Footage',
        padding: 25,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: ({ datasetIndex: number })) => {
            const index = context.datasetIndex;
            if (data && data.datasets) {
              return `${data.datasets[index].companyName} (${
                formatNumericValue(data.datasets[index].density)} SF/Employee, ${
                formatNumericValue(data.datasets[index].totalArea)} SF)`;
            }
            return '';
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Local Employees Density (SF / Employee)',
          fontColor: 'black',
          padding: 25,
        },
        ticks: {
          min: 0,
          max: Math.round(100 + maxEmployees),
          stepSize: 100,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Leased Square Footage (SF)',
          fontColor: 'black',
          padding: 25,
        },
        ticks: {
          stepSize: 10000,
        },
      },
    },
    options: {
      responsive: true,
    },
  };

  return (
    <>
      {
        employeeDensityData && data.datasets && !isLoading && (
          <Bubble
              // eslint-disable-next-line
            data={{ datasets: data.datasets! }}
            options={options}
          />
        )
       }
      <ComponentLoader isOpen={isLoading} />
    </>
  );
};

export default EmployeesDensityStats;
