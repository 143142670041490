import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event: string, fn: (value: string) => void): EventEmitter => eventEmitter.on(event, fn),
  once: (event: string, fn: (value: string) => void): EventEmitter => eventEmitter.once(event, fn),
  off: (event: string): EventEmitter => eventEmitter.off(event),
  emit: (event: string, payload: string): boolean => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
