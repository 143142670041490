import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { LinkProps, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  grey, indigo,
} from '@material-ui/core/colors';

interface ListItemLinkProps {
  icon: ReactElement,
  primary: string,
  to: string,
  isSelected: boolean,
  hidden: boolean | undefined,
}

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  selected: {
    background: grey[300],
    borderLeft: `4px solid ${indigo[500]}`,
  },
  hiddenForBrokers: {
    display: 'none',
  },
}));

const ListItemLink: (props: ListItemLinkProps) => React.ReactElement = (props: ListItemLinkProps): ReactElement => {
  const classes = useStyles();

  const {
    icon, primary, to, isSelected, hidden,
  } = props;

  // eslint-disable-next-line
  const CustomLink = React.forwardRef(
    (linkProps: LinkProps, refs) => (
      <Link
        to={to}
        // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading,@typescript-eslint/no-unused-vars
        {...linkProps}
        // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading,@typescript-eslint/no-unused-vars
        {...refs}
        hidden={hidden}
      />
    ),
  );

  return (
    <div>
      <ListItem
        className={clsx(classes.menuItem, {
          [classes.selected]: isSelected,
          [classes.hiddenForBrokers]: hidden,
        })}
        button
        component={CustomLink}
      >
        <Tooltip title={primary} arrow placement="right-end">
          <ListItemIcon>{icon}</ListItemIcon>
        </Tooltip>
        <ListItemText primary={primary} />
      </ListItem>
    </div>
  );
};

export default ListItemLink;
