import React, { ReactElement } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import EditBuilding from './EditBuilding';
import CreateBuilding from './CreateBuilding';
import ListBuildings from './ListBuildings';
import { useAllStatesQuery } from '../../../queries/geo';
import SpacesIndex from '../spaces/SpacesIndex';
import ViewBuilding from './ViewBuilding/ViewBuilding';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import { AuthRoles } from '../../../types/auth';

const BuildingsIndex:React.FC = (): ReactElement => {
  useAllStatesQuery();

  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter
        exact
        path={path}
        component={ListBuildings}
        isPrivate
      />
      <LayoutRouter
        path={`${path}/edit/:buildingId`}
        component={EditBuilding}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        path={`${path}/view/:buildingId`}
        component={ViewBuilding}
        isPrivate
      />
      <LayoutRouter
        path={`${path}/create`}
        component={CreateBuilding}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <Route path={`${path}/:buildingId/spaces`} component={SpacesIndex} />
    </RouterBaseSwitch>
  );
};

export default BuildingsIndex;
