import React, { ReactElement } from 'react';
import {
  FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextareaAutosize,
} from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NewsFormProps, NewsFormValues } from './types';
import { useNewsForm } from './hooks';
import { useGetAllTenantsQuery } from '../../../../queries/tenants';
import NewsValidationSchema from './validators';
import { useNewsCardStyles } from '../../../../styles/useNewsCardStyles';
import LoaderModal from '../../../modals/LoaderModal/LoaderModal';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';
import { Tenant } from '../../../../types/tenant';

const NewsForm:React.FC<NewsFormProps> = (props: NewsFormProps): ReactElement => {
  const { model } = props;
  const { create, update } = useNewsForm();
  const { data: tenants } = useGetAllTenantsQuery();
  const classes = useNewsCardStyles();
  const query = new URLSearchParams(window.location.search);
  const tenantId = query.get('tenantId');
  let initialValues: NewsFormValues;
  const buttonClasses = useButtonsStyles();

  if (model) {
    initialValues = {
      title: model.title,
      url: model.url,
      description: model.description,
      companyId: model.company?.id,
    };
  } else {
    initialValues = {
      title: '',
      url: '',
      description: '',
      companyId: tenantId ? Number(tenantId) : undefined,
    };
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NewsValidationSchema}
        onSubmit={async (values: NewsFormValues, actions: FormikHelpers<NewsFormValues>) => {
          if (model?.id) {
            await update(values, actions, model);
          } else {
            await create(values, actions);
          }
        }}
      >
        {(formProps: FormikProps<NewsFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={6} lg={4}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <TextField
                        value={values.title}
                        onChange={handleChange}
                        error={!!errors.title}
                        helperText={errors.title}
                        variant="outlined"
                        margin="normal"
                        required
                        id="title"
                        label="News Title"
                        type="text"
                        name="title"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={values.url}
                        onChange={handleChange}
                        error={!!errors.url}
                        helperText={errors.url}
                        variant="outlined"
                        margin="normal"
                        id="url"
                        label="Url"
                        type="text"
                        name="url"
                        autoFocus
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    >
                      <InputLabel id="state-label">Tenant</InputLabel>
                      <Select
                        labelId="company-label"
                        id="companyId"
                        value={values.companyId}
                        onChange={handleChange}
                        error={!!errors.companyId}
                        name="companyId"
                        label="Tenant"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {tenants?.map((tenant:Tenant) => (
                          <MenuItem key={tenant.id} value={tenant?.id}>{tenant.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.companyId}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                      <TextareaAutosize
                        aria-label="minimum height"
                        rowsMin={6}
                        placeholder="description"
                        value={values.description}
                        onChange={handleChange}
                        className={classes.textField}
                        id="description"
                        name="description"
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        data-testid="news-button"
                        className={buttonClasses.standard}
                        disabled={isSubmitting}
                      >
                        {
                          model?.id ? 'Save' : 'Create'
                        }
                      </Button>
                    </FormControl>
                    <LoaderModal isOpen={isSubmitting} />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default NewsForm;
