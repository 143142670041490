import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@mui/material/colors';

export const useViewLeaseStyles = makeStyles((theme) => ({
  abstractWrapper: {
    marginBottom: theme.spacing(3),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  termCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(2.25),
  },
  divider: {
    height: theme.spacing(22),
    color: grey[400],
    padding: theme.spacing(3.125),
  },
  abstractCard: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2.25),
    background: 'white',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    border: `1px solid ${grey[300]}`,
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
  },
  abstractTitles: {
    fontSize: theme.spacing(1.75),
    color: grey[600],
  },
  abstractTitlesWhite: {
    fontSize: theme.spacing(1.75),
    color: '#ffffff',
  },
  infoWrapper: {
    maxWidth: theme.spacing(45.5),
    width: '100%',
  },
  abstractInfoRow: {
    marginBottom: theme.spacing(1),
  },
  abstractInfoRowAlignedStart: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  abstractSubtitle: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
  },
  abstractSubtitleWhite: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    color: '#ffffff',
  },
  abstractText: {
    fontSize: theme.spacing(2),
    fontWeight: 'lighter',
    display: 'block',
    textAlign: 'right',
    paddingLeft: theme.spacing(0.5),
  },
}));
