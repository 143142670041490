import React, { ReactElement } from 'react';
import PageTitle from '../../ui/PageTitle';
import DealForm from '../../forms/deals/DealForm';

const CreateDeals: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create Deal</PageTitle>
    <DealForm />
  </>
);

export default CreateDeals;
