import { add, sub } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function now() {
  return new Date();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function beforeDate(date: Date, years = 0, months = 0, days = 0) {
  return sub(date, {
    years,
    months,
    days,
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function afterDate(date: Date, years = 0, months = 0, days = 0) {
  return add(date, {
    years,
    months,
    days,
  });
}
