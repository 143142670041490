import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { BOLD_FONT } from '../constants';

const coverSize = 44;

export const useInfoCardStyles = makeStyles((theme: Theme) => createStyles({
  rootCard: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
    paddingBottom: theme.spacing(1.75),
    height: theme.spacing(16.625),
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: theme.spacing(1.4),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contentText: {
    fontSize: theme.spacing(3),
    fontWeight: BOLD_FONT,
    marginRight: theme.spacing(1),
  },
  coverWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cover: {
    flex: '1',
    flexGrow: 1,
    width: coverSize,
    height: coverSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: grey[200],
    borderRadius: '50%',
  },
  subDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    alignItems: 'baseline',
  },
  subtitle: {
    fontSize: theme.spacing(1.5),
  },
  subContent: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2.25),
    fontWeight: BOLD_FONT,
  },
  metrics: {
    fontSize: theme.spacing(2.25),
    fontWeight: BOLD_FONT,
  },
}));
