import { JwtPayload } from 'jwt-decode';

export enum AuthRoles {
  Admin = 'admin',
  User = 'user',
  Broker = 'broker',
}

export interface ModifiedJwtPayload extends JwtPayload {
  role: AuthRoles
}
