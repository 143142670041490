import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Link as OuterLink,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DataGrid, GridColDef, GridRenderCellParams,
} from '@mui/x-data-grid';
import clsx from 'clsx';
import PageTitle from '../../../ui/PageTitle';
import {
  getBuildingStatisticsById, useGetAllFloorsQuery,
  useGetSingleBuildingQuery,
} from '../../../../queries/buildings';
import { ViewBuildingRouteProps } from './interfaces';
import LoaderModal from '../../../modals/LoaderModal/LoaderModal';
import DataGridLoader from '../../../ui/DataGridLoader';
import { useGetDocumentsByBuilding } from '../../../../queries/documents';
import { useTableStyles } from '../../../../styles/useTableStyles';
import BuildingFloorArea from '../../../partials/buildings/BuildingFloorArea/BuildingFloorArea';
import { BuildingStackingPlanHeader } from '../../../partials/buildings/BuildingFloorArea/BuildingStackingPlanHeader';
import { accessToken } from '../../../../models/security';

const ViewBuilding: React.FC = (): ReactElement => {
  const { buildingId } = useParams<ViewBuildingRouteProps>();
  const { data: building, isLoading } = useGetSingleBuildingQuery(Number(buildingId));
  const { data: floors } = useGetAllFloorsQuery(Number(buildingId));
  const { data: buildingStatistics } = getBuildingStatisticsById(Number(buildingId));
  const {
    data: documents,
    isLoading: isDocumentLoading,
    isFetching: isDocumentFetching,
  } = useGetDocumentsByBuilding(Number(buildingId));
  const tableClasses = useTableStyles();

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Document Name',
      // eslint-disable-next-line react/display-name
      renderCell: (params:GridRenderCellParams) => (

        <Tooltip title={params.row.title || ''} placement="top" arrow>
          <Typography
            variant="inherit"
          >
            {params.row.title || ''}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
      width: 200,
    },
    {
      field: 'type',
      headerName: 'Document Type',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params:GridRenderCellParams) => (
        <Tooltip title={params.row.type || ''} placement="top" arrow>
          <Typography
            variant="inherit"
          >
            {params.row.type || ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'url',
      headerName: 'Link',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <OuterLink href={`${params.value}`} target="_blank">{params.value}</OuterLink>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column">
        <BuildingStackingPlanHeader
          building={building}
          buildingStatistics={buildingStatistics}
          floors={floors}
        />
      </Box>
      {
        !!floors?.length
        && (
          <BuildingFloorArea
            floors={floors}
            buildingId={Number(buildingId)}
            buildingStatistics={buildingStatistics}
          />
        )
      }
      {!accessToken.isCurrentRoleBroker()
          && (
          <Box mt={3}>
            <Box mb={3}>
              <PageTitle>Documents</PageTitle>
            </Box>
            <DataGrid
              rows={documents || []}
              columns={columns}
              className={clsx(tableClasses.cellSelectDisabled, tableClasses.tablesBackground)}
              pageSize={25}
              autoHeight
              disableColumnMenu
              components={{
                LoadingOverlay: DataGridLoader,
              }}
              loading={isDocumentFetching || isDocumentLoading}
            />
          </Box>
          )}
      <LoaderModal isOpen={isLoading} />
    </>
  );
};
export default ViewBuilding;
