import React, { ReactElement, useState } from 'react';
import {
  Box, Card,
  CardHeader,
  FormControl,
  InputLabel,
  Select, Tooltip, Typography,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import clsx from 'clsx';
import { CompanyStatisticsByLocation } from '../../../types/statistics';
import ComponentError from '../../ui/ComponentError';
import { useChartStyles } from '../../../styles/useChartStyles';
import { cardTitleProps } from '../../../styles/useCardStyles';
import { generalSorting } from '../../../helpers/sorting';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { sortArea } from '../../../helpers/sortArea';
import DataGridLoader from '../../ui/DataGridLoader';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { useTableStyles } from '../../../styles/useTableStyles';

export interface TopTenantsTableProps {
  data: CompanyStatisticsByLocation[] | undefined
  type: 'downsizing' | 'hiring'
  error: Error | null
  isLoading: boolean
}

const TopTenantsTable: React.FC<TopTenantsTableProps> = (props: TopTenantsTableProps): ReactElement => {
  const defaultLocation = 'Bay Area';
  const [topCompanyLocation, setTopCompanyLocation] = useState(defaultLocation);
  const {
    data: companyData, error, type, isLoading,
  } = props;
  const { tenantsNavigator } = useNavigationManager();
  const tableClasses = useTableStyles();

  const companiesType = type.charAt(0).toUpperCase() + type.slice(1);

  const isHiringType: boolean = type === 'hiring';

  const classes = useChartStyles();

  const changeTopCompanyLocation = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setTopCompanyLocation(String(event.target.value));
  };

  const getStatisticsByLocation = (
    statisticsList: CompanyStatisticsByLocation[], statisticsLocation: string,
  ) => statisticsList?.find(
    (location) => location.location === statisticsLocation,
  );

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Name',
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.companyName || ''} arrow>
          <Typography variant="inherit">
            {params.row.companyName || ''}
          </Typography>
        </Tooltip>
      ),
      sortComparator: (v1, v2, params1, params2) => {
        const company1 = String(params1.value);
        const company2 = String(params2.value);
        return generalSorting(company1, company2);
      },
      width: 200,
    },
    {
      field: 'leasedArea',
      headerName: 'Leased Area',
      valueFormatter: (params) => formatAreaSize(params.row.leasedArea),
      sortComparator: (v1, v2, params1,
        params2) => sortArea(params1, params2),
      width: 150,
    },
    {
      field: 'employeesCount',
      headerName: 'Employees #',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <>
          <Box className={classes.companyValue}>
            {params.row.employeesCount}
          </Box>
          <Box className={isHiringType ? classes.percentIncrease : classes.percentDecrease}>
            {
              isHiringType ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon color="secondary" />
              )
            }
            {`${params.row.employeesCountChange} %`}
          </Box>
        </>
      ),
      width: 150,
    },
  ];

  return (
    <Card style={{ overflowX: 'auto' }}>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <CardHeader titleTypographyProps={cardTitleProps} title={`Top 3 ${companiesType} Tenants`} />
        <Box>
          <FormControl
            margin="normal"
            fullWidth
          >
            <InputLabel
              shrink={!!topCompanyLocation}
              htmlFor="top-location-id"
            >
              Filter by Location
            </InputLabel>
            <Select
              native
              value={topCompanyLocation}
              label="Filter by Location"
              onChange={changeTopCompanyLocation}
              inputProps={{
                name: 'location',
                id: 'top-location-id',
              }}
            >
              <option aria-label="None" value="" />
              {companyData?.map(({ location }: CompanyStatisticsByLocation) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </Select>

          </FormControl>
        </Box>
      </Box>
      {
        companyData && (
          <Box className={tableClasses.noBorderTable}>
            <DataGrid
              columns={columns}
              getRowId={(row) => row.companyId}
              rows={getStatisticsByLocation(
                companyData, topCompanyLocation,
              )?.data || []}
              className={clsx(tableClasses.navigableTable, tableClasses.cellSelectDisabled)}
              components={{
                LoadingOverlay: DataGridLoader,
              }}
              onRowClick={(param) => tenantsNavigator.single(param.row.companyId).navigate()}
              loading={isLoading}
              autoHeight
              hideFooter
              disableColumnMenu
              disableColumnFilter
            />
          </Box>
        )
      }
      { error && (
      <ComponentError error={error} />
      )}
    </Card>
  );
};

export default TopTenantsTable;
