// eslint-disable-next-line max-classes-per-file
import { TenantiApiError } from './TenantiApiError';

export class UnauthorizedError extends TenantiApiError {

}

export class InternalServerError extends TenantiApiError {

}

export class ForbiddenError extends TenantiApiError {

}

export class NotFoundError extends TenantiApiError {

}

export interface ApiErrorsModel {
  [key: number]: typeof TenantiApiError
}

export const apiErrors: ApiErrorsModel = {
  401: UnauthorizedError,
  500: InternalServerError,
  403: ForbiddenError,
  404: NotFoundError,
};
