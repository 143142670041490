import { Building } from '../types/buildings';
import { formatAreaSize } from '../formatters/areaSizeFormatter';

export const getFormattedAddress = (streetAddress: string | undefined,
  city: string | undefined,
  state: string | undefined,
  postalCode: string | undefined): string => `
  ${streetAddress ? (`${streetAddress}, `) : ''}
  ${city ? (`${city}, `) : ''}
  ${state || ''}
  ${postalCode || ''}`;

export const getBuildingAreaDetails = (targetBuilding: Building): string => `
  ${targetBuilding ? (`${getFormattedAddress(targetBuilding.streetAddress, targetBuilding.city, targetBuilding.state,
    targetBuilding.postalCode)} | `) : ''}
  ${targetBuilding.totalArea ? (`${formatAreaSize(targetBuilding.totalArea)} | `) : ''}
  ${targetBuilding.floorsCount ? (`${targetBuilding.floorsCount} floors`) : ''}
  `;
