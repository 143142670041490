import React, { useContext } from 'react';
import { Dialog } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import { SubLeaseDialogProps } from '../interfaces';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import SubLeaseForm from '../../forms/subLease/SubLeaseForm';

const useStyles = makeStyles(() => createStyles({
  paperWidth: {
    width: 376,
  },
  dialogWrapper: {
    position: 'relative',
  },
}));

const SubLeaseModal: React.FC<SubLeaseDialogProps> = (props:SubLeaseDialogProps) => {
  const { dispatch } = useContext(Store);
  const {
    isOpen, title,
    leasedSpaceId, companyId,
  } = props;
  const formData = {
    companyId,
    leasedSpaceId,
  };

  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      onClose={() => dispatch({ type: storeActions.HIDE_SUBLEASE_MODAL })}
      classes={{
        paper: classes.paperWidth,
      }}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <SubLeaseForm model={formData} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SubLeaseModal;
