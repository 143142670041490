import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { BuildingFormFloors, BuildingFormValues, UseBuildingForm } from './types';
import {
  useCreateBuildingMutation,
  useSaveFloorsMutation,
  useUpdateBuildingMutation,
} from '../../../../queries/buildings/mutations';
import {
  CreateBuildingRequest,
  SaveFloorRequest,
  UpdateBuildingRequest,
} from '../../../../queries/buildings/types';
import { AlertProps } from '../../../ui/Alert';
import { EXCEED_TOTAL_AREA_ERROR } from '../../../../constants';

export const useBuildingForm = (): UseBuildingForm => {
  const createMutation = useCreateBuildingMutation();
  const updateMutation = useUpdateBuildingMutation();
  const saveFloorsMutation = useSaveFloorsMutation();
  const history = useHistory();
  const [areaAlert, setAreaAlert] = useState<AlertProps>({
    message: '',
    type: undefined,
  });

  const saveFloors = async (floors: BuildingFormFloors[], buildingId: number) => {
    const requests: SaveFloorRequest[] = floors.map((floor: BuildingFormFloors):SaveFloorRequest => ({
      id: floor.id,
      name: floor.name,
      floor: floor.floor,
      area: floor.area,
      buildingId,
    }));

    await saveFloorsMutation.mutateAsync({ requests });
  };

  const create = async (values: BuildingFormValues, actions: FormikHelpers<BuildingFormValues>) => {
    const totalFloorsArea = values.floors.reduce((a, b) => a + b.area, 0);
    if (Number(values.totalArea) < totalFloorsArea) {
      setAreaAlert({
        message: EXCEED_TOTAL_AREA_ERROR,
        type: 'error',
      });
      return;
    }
    try {
      const request: CreateBuildingRequest = {
        name: values.name,
        totalArea: values.totalArea,
        state: values.state,
        status: values.status,
        city: values.city,
        postalCode: values.postalCode,
        streetAddress: values.streetAddress,
        createAsset: values.createAsset,
      };
      await createMutation.mutateAsync({ request }, {
        onSuccess: async (data) => {
          if (values.floors.length) await saveFloors(values.floors, Number(data.id));
          history.push('/buildings');
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (values: BuildingFormValues, actions: FormikHelpers<BuildingFormValues>, buildingId: number) => {
    let building;
    const totalFloorsArea = values.floors.reduce((a, b) => a + b.area, 0);
    if (Number(values.totalArea) < totalFloorsArea) {
      setAreaAlert({
        message: EXCEED_TOTAL_AREA_ERROR,
        type: 'error',
      });
      return;
    }
    try {
      const request: UpdateBuildingRequest = {
        name: values.name,
        totalArea: values.totalArea,
        state: values.state,
        status: values.status,
        city: values.city,
        postalCode: values.postalCode,
        streetAddress: values.streetAddress,
      };
      building = await updateMutation.mutateAsync({ request, buildingId });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }

    if (building) {
      try {
        await saveFloors(values.floors, Number(building?.id));
        history.push('/buildings');
      } catch (error) {
        switch (error.response?.status) {
          case 422:
            if (error.response.data?.errors) {
              actions.setErrors(error.response.data.errors);
            } else {
              actions.setErrors({ floors: error.response.data.error });
              setAreaAlert({
                message: EXCEED_TOTAL_AREA_ERROR,
                type: 'error',
              });
            }
            break;
          default:
        }
      }
    }
  };

  return {
    isLoading: createMutation.isLoading || updateMutation.isLoading || saveFloorsMutation.isLoading,
    create,
    update,
    areaAlert,
  };
};
