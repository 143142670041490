import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import {
  Building, BuildingStatus, DropdownBuilding, SingleBuildingTenants, Space, StackingPlanFloor,
} from '../../types/buildings';
import httpClient from '../../core/httpClient';
import { BuildingBanners, BuildingStatistics } from '../../types/statistics';
import { QUERY_OPTIONS } from '../../constants';

export const useGetAllBuildingsByStatusQuery = (
  status?: BuildingStatus,
): UseQueryResult<Building[], Error> => useQuery<Building[], Error>(
  ['buildings', status],
  async () => {
    const query = status ? `?status=${status}` : '';
    const response = await httpClient.get(`/buildings${query}`);
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetBuildingsByAssetId = (
  assetId: number,
): UseQueryResult<DropdownBuilding[], Error> => useQuery<DropdownBuilding[], Error>(
  ['buildingsByAssetId', assetId],
  async () => {
    const response = await httpClient.get(`/assets/${assetId}/buildings/dropdown-options`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const getBuildingStatisticsById = (
  buildingId: number | undefined,
): UseQueryResult<BuildingStatistics, Error> => useQuery<BuildingStatistics, Error>(
  ['building-statistics', buildingId],
  async () => {
    const response = await httpClient.get(`/statistics/building-area?building_id=${buildingId}`);
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);

export const useGetSingleBuildingQuery = (buildingId: number): UseQueryResult<Building, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Building, Error>(
    ['buildings', buildingId],
    async () => {
      const response = await httpClient.get(`/buildings/${buildingId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      enabled: !!buildingId,
      placeholderData: () : Building | undefined => queryClient
        .getQueryData<Building[]>('buildings')
        ?.find((c:Building) => c.id === buildingId),
    },
  );
};

export const useGetAllFloorsQuery = (buildingId: number | undefined):
UseQueryResult<StackingPlanFloor[], Error> => useQuery<StackingPlanFloor[], Error>(
  ['floors', buildingId],
  async () => {
    const response = await httpClient.get(`/buildings/${buildingId}/floors`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);

export const useGetAllSpacesByBuildingIdQuery = (
  buildingId: number,
): UseQueryResult<Space[], Error> => useQuery<Space[], Error>(
  ['spaces/byBuilding', buildingId],
  async () => {
    const response = await httpClient.get(`/buildings/${buildingId}/spaces`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);

export const useGetSingleSpaceQuery = (spaceId: number): UseQueryResult<Space, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Space, Error>(
    ['spaces', spaceId],
    async () => {
      const response = await httpClient.get(`/spaces/${spaceId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      placeholderData: (): Space | undefined => queryClient
        .getQueryData<Space[]>('spaces')
        ?.find((space: Space) => space.id === spaceId),
    },
  );
};

export const useGetBuildingBannersQuery = (
  buildingId: number,
): UseQueryResult<BuildingBanners, Error> => useQuery<
BuildingBanners, Error>(
  ['buildingBanners', buildingId],
  async () => {
    const response = await httpClient.get(`/buildings/${buildingId}/aggregated`);
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);

export const useGetSingleBuildingTenantsQuery = (buildingId: number | undefined):
UseQueryResult<SingleBuildingTenants[], Error> => useQuery<SingleBuildingTenants[], Error>(
  ['buildingTenants', buildingId],
  async () => {
    const response = await httpClient.get(`/buildings/${buildingId}/tenants`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);
