import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import {
  CreateCompetitorMutationVariables,
  CreateCompetitorRequest,
  CreateDealMutationVariables,
  DealCommentMutationVariables,
  DeleteDealCommentMutationVariables, DeleteDealCompetitorAssetMutationVariables,
  DeleteDealMutationVariables,
  UpdateCommentMutationVariables,
  UpdateCompetitorMutationVariables, UpdateCompetitorRequest,
  UpdateDealMutationVariables,
} from './types';
import httpClient from '../../core/httpClient';
import { DealComment, Deals } from '../../types/deals/deals';

export const useDeleteDealMutation = (): UseMutationResult<null, Error, DeleteDealMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteDealMutationVariables>(
    async ({ dealId }) => httpClient.delete(`/deals/${dealId}`),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('deals');
      },
    },
  );
};

export const useDeleteDealCommentMutation = (): UseMutationResult<null, Error, DeleteDealCommentMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteDealCommentMutationVariables>(
    async ({ commentId }) => httpClient.delete(`/deal_comments/${commentId}`),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('comments');
      },
    },
  );
};

export const useDeleteDealCompetitorAssetMutation = ():
UseMutationResult<void, Error, DeleteDealCompetitorAssetMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, DeleteDealCompetitorAssetMutationVariables>(
    async ({ competitorId }) => httpClient.delete(`/deal-competitors/${competitorId}`),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('comments');
      },
    },
  );
};

export const useCreateDealCommentMutation = (): UseMutationResult<DealComment, Error, DealCommentMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<DealComment, Error, DealCommentMutationVariables>(
    async ({ comment, dealId }) => httpClient.post(`/deals/${dealId}/comments`, { comment }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('comments');
      },
    },
  );
};

export const useUpdateCommentMutation = (): UseMutationResult<DealComment, Error, UpdateCommentMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<DealComment, Error, UpdateCommentMutationVariables>(
    async ({ comment, commentId }) => {
      const response = await httpClient.put(`/deal_comments/${commentId}`, { comment });
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['comments']);
      },
    },
  );
};

export const useCreateDealMutation = (): UseMutationResult<Deals, Error, CreateDealMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Deals, Error, CreateDealMutationVariables>(
    async ({ dealRequest }) => {
      const response = await httpClient.post('/deals', dealRequest);
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('deals');
      },
    },
  );
};

export const useCreateCompetitorMutation = (): UseMutationResult<void, Error, CreateCompetitorMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreateCompetitorMutationVariables>(
    async ({ requests }) => {
      const httpRequests = requests.map((request: CreateCompetitorRequest) => {
        const { id, ...requestData } = { ...request };
        if (request.id) {
          return httpClient.put(`/deals/${request.id}`, requestData);
        }
        return httpClient.post('/deal-competitors', requestData);
      });

      await axios.all(httpRequests);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('deals');
      },
    },
  );
};

export const useUpdateCompetitorMutation = (): UseMutationResult<void, Error, UpdateCompetitorMutationVariables> => {
  const queryClient = useQueryClient();
  const deleteCompetitor = useDeleteDealCompetitorAssetMutation();

  return useMutation<void, Error, UpdateCompetitorMutationVariables>(
    async ({ requests, deletedIds }) => {
      if (deletedIds?.length) {
        deletedIds.map(async (id) => {
          await deleteCompetitor.mutateAsync({ competitorId: id as number });
        });
      }
      const httpRequests = requests.map((request: UpdateCompetitorRequest) => {
        const { id, ...requestData } = { ...request };
        if (request.id) {
          return httpClient.put(`/deal-competitors/${request.id}`, requestData);
        }
        return httpClient.post('/deal-competitors', requestData);
      });
      await axios.all(httpRequests);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('deals');
      },
    },
  );
};

export const useUpdateDealMutation = (): UseMutationResult<Deals, Error, UpdateDealMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Deals, Error, UpdateDealMutationVariables>(
    async ({ dealRequest, dealId }) => {
      const response = await httpClient.put(`/deals/${dealId}`, dealRequest);
      return response.data;
    }, {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries('deals');
        await queryClient.invalidateQueries(['deals', variables.dealId]);
      },
    },
  );
};
