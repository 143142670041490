import React, { ReactElement } from 'react';
import {
  Box, Button, IconButton, Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  Link, useParams,
} from 'react-router-dom';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import PageTitle from '../../ui/PageTitle';
import { useGetAllSpacesByBuildingIdQuery, useGetSingleBuildingQuery } from '../../../queries/buildings';
import { useDeleteSpaceMutation } from '../../../queries/buildings/mutations';
import { SpaceRouteProps } from './interfaces';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { useNestedNavigationManager } from '../../../navigation/nestedNavigationManager';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { Leases } from '../../../types/leases';
import { accessToken } from '../../../models/security';
import { Space } from '../../../types/buildings';

const ListSpaces: React.FC = (): ReactElement => {
  const { buildingId } = useParams<SpaceRouteProps>();
  const { data: spaces, isLoading } = useGetAllSpacesByBuildingIdQuery(Number(buildingId));
  const { data: building } = useGetSingleBuildingQuery(Number(buildingId));
  const deleteSpaceMutation = useDeleteSpaceMutation();
  const { tenantsNavigator } = useNavigationManager();
  const { spacesNavigator } = useNestedNavigationManager(Number(buildingId));

  const { showModal, RenderModal, modalId } = useDeleteModal();
  const buttonClasses = useButtonsStyles();

  const deleteSpace = (spaceId: number | null) => {
    if (!spaceId) {
      return;
    }
    deleteSpaceMutation.mutate({ spaceId, buildingId: Number(buildingId) });
  };

  const navigateToViewCompanies = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    tenantsNavigator.single(Number(id)).navigate();
  };

  const navigateToViewSpaceEdit = (spaceId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    spacesNavigator.edit(Number(spaceId)).navigate();
  };

  const columns: MRT_ColumnDef<Space>[] = [
    {
      header: 'Space Name',
      accessorKey: 'name',
    },
    {
      header: 'Floor',
      accessorKey: 'floor',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Typography>
          {row.floor.floor}
        </Typography>
      ),
    },
    {
      header: 'Area',
      accessorKey: 'area',
      accessorFn: (row) => formatAreaSize(row.area),
    },
    {
      header: 'Leases',
      accessorKey: 'leases',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        row.leases?.map((lease: Leases, index: number) => (
          <Typography
            key={lease?.company?.id}
            style={{ cursor: 'pointer' }}
            color="primary"
            onClick={(e) => navigateToViewCompanies(Number(lease?.company?.id), e)}
          >
            {(row.leases.length > 0
              && index - row.leases.length === -1)
              ? lease?.company?.name
              : `${lease?.company?.name}, ` }
          </Typography>
        ))
      ),
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      enableSorting: false,
      // eslint
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <IconButton
            onClick={(e) => navigateToViewSpaceEdit(Number(row.id), e)}
            aria-label="edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showModal(Number(row.id));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" flexDirection="column">
          <PageTitle>Spaces</PageTitle>
          {building
          && (
            <Box px={2}>
              <Typography component={Link} color="primary" to={`/buildings/view/${buildingId}`}>
                {building.name}
              </Typography>
            </Box>
          )}
        </Box>
        {!accessToken.isCurrentRoleBroker()
          && (
          <Button
            className={buttonClasses.large}
            component={Link}
            to={spacesNavigator.create().path}
            variant="contained"
            color="primary"
          >
            Create Space
          </Button>
          )}
      </Box>
      <MaterialReactTable
        columns={columns}
        data={spaces || []}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableSorting={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        state={{ isLoading }}
        initialState={{ columnVisibility: { id: !accessToken.isCurrentRoleBroker() } }}
        layoutMode="grid"
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.stopPropagation();
            if (accessToken.isCurrentRoleBroker()) {
              return;
            } spacesNavigator.single(Number(row.original.id)).navigate();
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
      <RenderModal
        body="Are you sure you want to delete this Space?"
        confirmAction={() => deleteSpace(modalId)}
        title="Delete Space"
      />
      <LoaderModal isOpen={deleteSpaceMutation.isLoading} />
    </>
  );
};

export default ListSpaces;
