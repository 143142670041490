import React, { ReactElement } from 'react';
import {
  Button, Card, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@material-ui/core';
import { Tenant } from '../../../types/tenant';

export interface CompanyDescriptionProps {
  company: Tenant
}

const CompanyDescriptionCard: React.FC<CompanyDescriptionProps> = (props: CompanyDescriptionProps): ReactElement => {
  const { company } = props;

  return (
    <Card>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">CEO:</TableCell>
              <TableCell align="left" style={{ width: '40%' }}>{company.ceoName}</TableCell>
              <TableCell align="right">
                {
                  company.url
                  && (
                  <Button
                    variant="outlined"
                    color="primary"
                    component="a"
                    href={company.url}
                    target="_blank"
                  >
                    Learn More
                  </Button>
                  )
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Description:</TableCell>
              <TableCell align="left">
                {company.description}
              </TableCell>
              <TableCell align="right">
                {
                  company.linkedinUrl
                  && (
                    <Button
                      variant="outlined"
                      color="primary"
                      component="a"
                      href={company.linkedinUrl}
                      target="_blank"
                    >
                      Learn More
                    </Button>
                  )
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CompanyDescriptionCard;
