import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import {
  useCreateBrokerAssetsMutation,
  useCreateUserMutation, useUpdateBrokerAssetsMutation,
  useUpdateUserMutation,
} from '../../../../queries/users/mutations';
import { UserFormValues, UseUserForm } from './types';
import { CreateUserRequest, UpdateUserRequest } from '../../../../queries/users/types';
import { Store } from '../../../../store/AppGlobalContext';
import { storeActions } from '../../../../store/actions';

export const useUserForm = (): UseUserForm => {
  const { dispatch } = useContext(Store);
  const createMutation = useCreateUserMutation();
  const updateMutation = useUpdateUserMutation();
  const createUserAssetsMutation = useCreateBrokerAssetsMutation();
  const updateUserAssetsMutation = useUpdateBrokerAssetsMutation();
  const history = useHistory();

  const create = async (
    values: UserFormValues,
    actions: FormikHelpers<UserFormValues>,
  ) => {
    try {
      const request: CreateUserRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role.toLowerCase(),
      };

      await createMutation.mutateAsync({ request },
        {
          onSuccess: async (user) => {
            await createUserAssetsMutation.mutateAsync({
              brokerRequest: {
                brokerId: user.id,
                assetIds: values.assets.map((asset) => asset.id),
              },
            });
            history.push('/users');
            dispatch({ type: storeActions.OPEN_SUCCESS_MODAL });
          },
        });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (values: UserFormValues, actions: FormikHelpers<UserFormValues>, modelId: number) => {
    try {
      const request: UpdateUserRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role.toLowerCase(),
      };
      await updateMutation.mutateAsync(
        { request, userId: modelId },
        {
          onSuccess: async () => {
            await updateUserAssetsMutation.mutateAsync({
              brokerRequest: {
                brokerId: modelId,
                assetIds: values.assets.map((asset) => asset.id),
              },
            });
            history.push('/users');
            dispatch({ type: storeActions.OPEN_SUCCESS_MODAL });
          },
        },
      );
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    create,
    update,
  };
};
