import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouterBaseSwitch: React.FC = (props): ReactElement => {
  const { children } = props;

  return (
    <Switch>
      {children}
    </Switch>
  );
};

RouterBaseSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RouterBaseSwitch;
