import { TenantiEnum } from '../../core/TenantiEnum';

export const dealTypeOptions = {
  new_deal: {
    value: 'new_deal',
    displayValue: 'New Deal',
  },
  renewal: {
    value: 'renewal',
    displayValue: 'Renewal',
  },
  extension: {
    value: 'extension',
    displayValue: 'Extension',
  },
  expansion: {
    value: 'expansion',
    displayValue: 'Еxpansion',
  },
  termination: {
    value: 'termination',
    displayValue: 'Termination',
  },
  modification: {
    value: 'modification',
    displayValue: 'Modification',
  },
  sublease: {
    value: 'sublease',
    displayValue: 'Sublease',
  },
  license_agreement: {
    value: 'license_agreement',
    displayValue: 'License Agreement',
  },
  storage: {
    value: 'storage',
    displayValue: 'Storage',
  },
  relocation: {
    value: 'relocation',
    displayValue: 'Relocation',
  },
  break: {
    value: 'break',
    displayValue: 'Break',
  },
  rent_review: {
    value: 'rent_review',
    displayValue: 'Rent Review',
  },
  other: {
    value: 'other',
    displayValue: 'Other',
  },
};

export class DealType extends TenantiEnum {
}
