import { BaseNavigationRouteParams, UseNavigationProps } from './interfaces';
import { useBaseNavigation } from './baseNavigation';

export const useNavigation = (baseRouteName: string): UseNavigationProps => {
  const { setRouteParams } = useBaseNavigation();

  const base = (): BaseNavigationRouteParams => setRouteParams(`${baseRouteName}`);

  const single = (id: number): BaseNavigationRouteParams => setRouteParams(`${baseRouteName}/view/${id}`);

  const edit = (id: number): BaseNavigationRouteParams => setRouteParams(`${baseRouteName}/edit/${id}`);

  const create = (): BaseNavigationRouteParams => setRouteParams(`${baseRouteName}/create`);

  return {
    base,
    single,
    edit,
    create,
  };
};
