import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../ui/PageTitle';
import AssetForm from '../../forms/assets/AssetForm/AssetForm';
import { useGetSingleAssetQuery } from '../../../queries/assets';

export interface EditAssetProps {
  assetId: string
}

const EditAsset: React.FC = (): ReactElement => {
  const { assetId } = useParams<EditAssetProps>();
  const { data: asset } = useGetSingleAssetQuery(Number(assetId));

  return (
    <>
      <PageTitle>Edit Asset</PageTitle>
      {asset && <AssetForm model={asset} />}
    </>
  );
};

export default EditAsset;
