import BigNumber from 'bignumber.js';

export const Decimal = BigNumber;
export type DecimalType = BigNumber;

export type NumericValue = number | string | DecimalType;

export const decimal = (value: NumericValue): DecimalType => new Decimal(value);

export const zero = (): DecimalType => new Decimal(0);

export const decimalOrZero = (value: NumericValue | undefined): DecimalType => {
  if (!value) {
    return zero();
  }
  return decimal(value);
};
