import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import ListDeals from './ListDeals';
import ViewDeal from './ViewDeal';
import CreateDeals from './CreateDeals';
import EditDeals from './EditDeals';

const DealsIndex:React.FC = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter exact path={path} component={ListDeals} isPrivate />
      <LayoutRouter exact path={`${path}/view/:dealId`} component={ViewDeal} isPrivate />
      <LayoutRouter path={`${path}/create`} component={CreateDeals} isPrivate />
      <LayoutRouter exact path={`${path}/edit/:dealId`} component={EditDeals} isPrivate />
    </RouterBaseSwitch>
  );
};

export default DealsIndex;
