import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { News } from '../../types/news';
import httpClient from '../../core/httpClient';
import { QUERY_OPTIONS } from '../../constants';

export const useGetAllNewsQuery = (): UseQueryResult<News[], Error> => useQuery<News[], Error>(
  'news',
  async () => {
    const response = await httpClient.get('/news');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetLatestNewsQuery = (): UseQueryResult<News[], Error> => useQuery<News[], Error>(
  'news',
  async () => {
    const response = await httpClient.get('/news/latest');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetNewsByAssetId = (assetId: number): UseQueryResult<News[], Error> => useQuery<News[], Error>(
  ['news', assetId],
  async () => {
    const response = await httpClient.get(`/assets/${assetId}/news`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetNewsByTenantId = (companyId: number): UseQueryResult<News[], Error> => useQuery<News[], Error>(
  ['company-news', companyId],
  async () => {
    const response = await httpClient.get(`/companies/${companyId}/news`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!companyId,
  },
);

export const useGetSingleNewsQuery = (newsId: number): UseQueryResult<News, Error> => {
  const queryClient = useQueryClient();

  return useQuery<News, Error>(
    ['news', newsId],
    async () => {
      const response = await httpClient.get(`/news/${newsId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      placeholderData: () : News | undefined => queryClient
        .getQueryData<News[]>('news')
        ?.find((news: News) => news.id === newsId),
    },
  );
};
