import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import TenantForm from '../../forms/tenants/TenantForm/TenantForm';
import { useGetSingleTenantQuery } from '../../../queries/tenants';
import PageTitle from '../../ui/PageTitle';

export interface EditTenantProps {
  tenantId: string
}

const EditTenant: React.FC = (): ReactElement => {
  const { tenantId } = useParams<EditTenantProps>();
  const { data: tenant } = useGetSingleTenantQuery(Number(tenantId));

  return (
    <>
      <PageTitle>Edit Tenant</PageTitle>
      {tenant && <TenantForm model={tenant} />}
    </>
  );
};

export default EditTenant;
