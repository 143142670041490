import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import httpClient from '../../core/httpClient';
import {
  CreateAssetMutationVariables,
  DeleteAssetMutationVariables,
  SaveAssetBuildingMutationVariables, UpdateAssetMutationVariables,
} from './types';
import { Asset } from '../../types/assets';

export const useDeleteAssetMutation = (): UseMutationResult<null, Error, DeleteAssetMutationVariables> => {
  const queryClient = useQueryClient();
  return useMutation<null, Error, DeleteAssetMutationVariables>(
    async ({ assetId }) => httpClient.delete(`/assets/${assetId}`),
    {
      onSuccess: async (data, variables) => {
        // await queryClient.invalidateQueries('assets');
        const prevData = queryClient.getQueryData<Asset[]>(['assets']);
        if (prevData) {
          queryClient.setQueryData(['assets'], prevData.filter((asset) => asset.id !== variables.assetId));
          queryClient.invalidateQueries(['assetsBuildings']);
        }
      },
    },
  );
};

export const useCreateAssetMutation = ():
UseMutationResult<Asset, Error, CreateAssetMutationVariables> => useMutation<Asset, Error, CreateAssetMutationVariables>(
  async ({ request }) => {
    const response = await httpClient.post('/assets', request);
    return response.data;
  },
  {
    onSuccess: async () => {
      // await queryClient.invalidateQueries('assets');
    },
  },
);

export const useSaveAssetBuildingMutation = ():
UseMutationResult<{ data: Asset }, Error, SaveAssetBuildingMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<{ data: Asset }, Error, SaveAssetBuildingMutationVariables>(
    async ({ assetId, buildings }) => httpClient.post(`/assets/${assetId}/buildings`, { buildings }),
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<Asset[]>(['assets']);
        if (prevData) {
          // check if asset already exists in the list
          const assetIndex = prevData.findIndex((asset) => asset.id === data.data.id);
          if (assetIndex === -1) {
            queryClient.setQueryData(['assets'], [data.data, ...prevData]);
          } else {
            queryClient.setQueryData(['assets'], prevData.map((asset) => {
              if (asset.id === data.data.id) {
                return data.data;
              }
              return asset;
            }));
          }
        }
        const prevData2 = queryClient.getQueryData<Asset>(['assets', data.data.id]);
        if (prevData2) {
          queryClient.setQueryData(['assets', data.data.id], data.data);
        }
        queryClient.invalidateQueries(['assetsBuildings']);
      },
    },
  );
};

export const useUpdateAssetMutation = ():
UseMutationResult<Asset, Error, UpdateAssetMutationVariables> => useMutation<Asset, Error, UpdateAssetMutationVariables>(
  async ({ request, assetId }) => {
    const response = await httpClient.put(`/assets/${assetId}`, request);
    return response.data;
  },
);
