import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { Building, Floor, Space } from '../../types/buildings';
import httpClient from '../../core/httpClient';
import {
  CreateBuildingMutationVariables, CreateSpaceMutationVariables,
  DeleteBuildingMutationVariables,
  DeleteFloorMutationVariables, DeleteSpaceMutationVariables,
  SaveFloorRequest,
  SaveFloorsMutationVariables,
  UpdateBuildingMutationVariables, UpdateSpaceMutationVariables,
} from './types';

export const useCreateBuildingMutation = (): UseMutationResult<Building, Error, CreateBuildingMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Building, Error, CreateBuildingMutationVariables>(
    async ({ request }) => {
      const response = await httpClient.post('/buildings', request);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<Building[]>(['buildings', data.status]);
        if (prevData) {
          queryClient.setQueryData(['buildings', data.status], [data, ...prevData]);
        }
      },
    },
  );
};

export const useUpdateBuildingMutation = (): UseMutationResult<Building, Error, UpdateBuildingMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Building, Error, UpdateBuildingMutationVariables>(
    async ({ request, buildingId }) => {
      const response = await httpClient.put(`/buildings/${buildingId}`, request);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<Building[]>(['buildings', data.status]);
        if (prevData) {
          const newData = prevData.map((building) => {
            if (building.id === data.id) {
              return data;
            }
            return building;
          });
          queryClient.setQueryData(['buildings', data.status], newData);
          queryClient.setQueryData(['buildings', variables.buildingId], data);
        }
      },
    },
  );
};

export const useDeleteBuildingMutation = (): UseMutationResult<null, Error, DeleteBuildingMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteBuildingMutationVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ buildingId, status }) => httpClient.delete(`/buildings/${buildingId}`),
    {
      onSuccess: async (_data, variables) => {
        const prevData = queryClient.getQueryData<Building[]>(['buildings', variables.status]);
        if (prevData) {
          const newData = prevData.filter((building) => building.id !== variables.buildingId);
          queryClient.setQueryData(['buildings', variables.status], newData);
        }
      },
    },
  );
};

export const useSaveFloorsMutation = (): UseMutationResult<void, Error, SaveFloorsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, SaveFloorsMutationVariables>(
    async ({ requests }) => {
      const httpRequests = requests.map((request: SaveFloorRequest) => {
        const { id, ...requestData } = { ...request };
        if (request.id) {
          return httpClient.put(`/floors/${request.id}`, requestData);
        }
        return httpClient.post('/floors', requestData);
      });

      await axios.all(httpRequests);
    },
    {
      onSuccess: async (_data, variables) => {
        await queryClient.invalidateQueries('buildings');
        if (variables.requests.length > 0) {
          await queryClient.invalidateQueries(['floors', variables.requests[0].buildingId]);
        }
      },
    },
  );
};

export const useDeleteFloorMutation = (): UseMutationResult<null, Error, DeleteFloorMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteFloorMutationVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ floorId, buildingId }) => httpClient.delete(`/floors/${floorId}`),
    {
      onSuccess: async (_data, variables) => {
        const prevData = queryClient.getQueryData<Floor[]>(['floors', variables.buildingId]);
        if (prevData) {
          const newData = prevData.filter((floor) => floor.id !== variables.floorId);
          queryClient.setQueryData(['floors', variables.buildingId], newData);
          await queryClient.invalidateQueries('buildings');
        }
      },
    },
  );
};

export const useCreateSpaceMutation = (): UseMutationResult<Space, Error, CreateSpaceMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Space, Error, CreateSpaceMutationVariables>(
    async ({ request }) => {
      const response = await httpClient.post('/spaces', request);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const buildingId = data.floor.building?.id;
        queryClient.invalidateQueries(['floors', buildingId]);
        const prevData = queryClient.getQueryData<Space[]>(['spaces/byBuilding', buildingId]);
        if (prevData) {
          queryClient.setQueryData(['spaces/byBuilding', buildingId], [...prevData, data]);
        }
      },
    },
  );
};

export const useUpdateSpaceMutation = (): UseMutationResult<Space, Error, UpdateSpaceMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Space, Error, UpdateSpaceMutationVariables>(
    async ({ request, spaceId }) => {
      const response = await httpClient.put(`/spaces/${spaceId}`, request);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(['floors', data.floor.building?.id]);
        queryClient.setQueryData(['spaces', variables.spaceId], data);
        const prevSpaces = queryClient.getQueryData<Space[]>(['spaces/byBuilding', data.floor.building?.id]);
        if (prevSpaces) {
          const newData = prevSpaces.map((space) => {
            if (space.id === data.id) {
              return data;
            }
            return space;
          });
          queryClient.setQueryData(['spaces/byBuilding', data.floor.building?.id], newData);
        }
      },
    },
  );
};

export const useDeleteSpaceMutation = (): UseMutationResult<null, Error, DeleteSpaceMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteSpaceMutationVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ spaceId, buildingId }) => httpClient.delete(`/spaces/${spaceId}`),
    {
      onSuccess: async (_data, variables) => {
        const prevData = queryClient.getQueryData<Space[]>(['spaces/byBuilding', variables.buildingId]);
        if (prevData) {
          const newData = prevData.filter((space) => space.id !== variables.spaceId);
          queryClient.setQueryData(['spaces/byBuilding', variables.buildingId], newData);
        }
      },
    },
  );
};
