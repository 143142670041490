import React from 'react';
import CustomModal from '../Modal/Modal';
import useBaseModal from '../hooks';
import { RenderModalProps, UseModalProps } from '../interfaces';

const useGptModal = (): UseModalProps => {
  const {
    isOpen, modalId, showModal, hideModal,
  } = useBaseModal();

  const GptModal = (props: RenderModalProps) => {
    const {
      body, confirmAction, title, ...rest
    } = props;
    return (
      <>
        {isOpen ? (
          <CustomModal
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            isOpen={isOpen}
            handleClose={hideModal}
            confirmAction={confirmAction}
            cancelText=""
            body={body}
            title={title}
          />
        ) : null}
      </>
    );
  };

  return {
    isOpen,
    modalId,
    showModal,
    hideModal,
    RenderModal: GptModal,
  };
};

export default useGptModal;
