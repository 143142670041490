import { useNavigation } from './navigation';
import { useAuthNavigation } from './auth/authNavigation';
import { UseNavigationManagerProps } from './interfaces';

export const useNavigationManager = (): UseNavigationManagerProps => ({
  portfolioNavigator: useNavigation('/portfolio'),
  buildingsNavigator: useNavigation('/buildings'),
  assetsNavigator: useNavigation('/assets'),
  tenantsNavigator: useNavigation('/tenants'),
  leasesNavigator: useNavigation('/leases'),
  dealsNavigator: useNavigation('/deals'),
  usersNavigator: useNavigation('/users'),
  authNavigator: useAuthNavigation('/auth'),
  newsNavigator: useNavigation('/news'),
  dataPointsNavigator: useNavigation('/data-points'),
  aiPromptsNavigator: useNavigation('/AiPrompts'),
});
