import React, {
  ReactElement, useContext,
} from 'react';
import {
  Box, Button, FormControl, IconButton, InputLabel, Select, TextField, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PageTitle from '../../../ui/PageTitle';
import { getBuildingAreaDetails } from '../../../../helpers/address';
import { useNavigationManager } from '../../../../navigation/navigationManager';
import { useNestedNavigationManager } from '../../../../navigation/nestedNavigationManager';
import { BuildingStatistics, NonVacantBuildingArea } from '../../../../types/statistics';
import { ALL_YEARS } from '../../../../constants';
import { useBuildingFloorArea } from './hooks';
import { Store } from '../../../../store/AppGlobalContext';
import { Building, SingleBuildingTenants, StackingPlanFloor } from '../../../../types/buildings';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';
import { useGetSingleBuildingTenantsQuery } from '../../../../queries/buildings';
import { accessToken } from '../../../../models/security';

interface BuildingStackingPlanHeaderProps {
  building: Building | undefined
  buildingStatistics: BuildingStatistics | undefined
  floors: StackingPlanFloor[] | undefined,
}

export const BuildingStackingPlanHeader: React.FC<BuildingStackingPlanHeaderProps> = (
  props: BuildingStackingPlanHeaderProps,
): ReactElement => {
  const {
    building, buildingStatistics, floors,
  } = props;
  const { state } = useContext(Store);
  const { buildingsNavigator } = useNavigationManager();
  const { spacesNavigator } = useNestedNavigationManager(Number(building?.id));
  const { handleYearFilterChange, handleTenantFilterChange } = useBuildingFloorArea();
  const { data: tenants } = useGetSingleBuildingTenantsQuery(Number(building?.id));
  const buttonClasses = useButtonsStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              {
              building?.name && (
                <PageTitle>{building.name}</PageTitle>
              )
            }
              {!accessToken.isCurrentRoleBroker()
                  && (
                  <Box pl={2}>
                    <IconButton size="small" component={Link} to={buildingsNavigator.edit(Number(building?.id)).path}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </Box>
                  )}
            </Box>
            <Box px={2}>
              <Typography variant="caption">
                {building && getBuildingAreaDetails(building) }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <FormControl
              fullWidth
              style={{ maxWidth: 230, marginRight: '15px' }}
            >
              <Autocomplete
                fullWidth
                disablePortal
                options={tenants || []}
                getOptionLabel={(option:SingleBuildingTenants) => option.name}
                onChange={(event, value) => {
                  handleTenantFilterChange(floors, value?.name);
                }}
                value={tenants?.find((tenant) => tenant.name === state.stackingPlan.filteredByTenant) || null}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                    {...params}
                    label="Filter By Tenant Name"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
            <FormControl
              fullWidth
              style={{ maxWidth: 180 }}
            >
              <InputLabel shrink htmlFor="building">Filter By Expiration Year</InputLabel>
              <Select
                native
                fullWidth
                value={state.stackingPlan.filteredYear}
                onChange={(event) => handleYearFilterChange(floors, event)}
                label="Filter By Expiration Year"
                inputProps={{
                  name: 'year',
                  id: 'year',
                }}
              >
                <option aria-label={ALL_YEARS} key={ALL_YEARS} value={ALL_YEARS}>
                  {ALL_YEARS}
                </option>
                {buildingStatistics?.byYear?.map((area: NonVacantBuildingArea) => (
                  <option key={area.leaseEndYear} value={area.leaseEndYear}>
                    {area.leaseEndYear}
                  </option>
                ))}

              </Select>
            </FormControl>
            <Box ml={2}>
              <Button
                component={Link}
                className={buttonClasses.standard}
                to={spacesNavigator.base().path}
                variant="contained"
                color="primary"
              >
                View Spaces
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
