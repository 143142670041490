import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import ListTenants from './ListTenants';
import EditTenant from './EditTenant';
import CreateTenant from './CreateTenant';
import ViewTenant from './ViewTenant';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import { AuthRoles } from '../../../types/auth';

const TenantsIndex:React.FC = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter exact path={path} component={ListTenants} isPrivate />
      <LayoutRouter
        exact
        path={`${path}/create`}
        component={CreateTenant}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter
        exact
        path={`${path}/edit/:tenantId`}
        component={EditTenant}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User, AuthRoles.Broker]}
      />
      <LayoutRouter exact path={`${path}/view/:tenantId/:comparedTenantId?`} component={ViewTenant} isPrivate />
    </RouterBaseSwitch>
  );
};

export default TenantsIndex;
