import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../ui/PageTitle';
import { useGetSingleLeaseQuery } from '../../../queries/leases';
import LeasesForm from '../../forms/leases/Leases/LeasesForm';

export interface EditLeasesProps {
  leaseId: string
}

const EditLeases: React.FC = (): ReactElement => {
  const { leaseId } = useParams<EditLeasesProps>();
  const { data: leases } = useGetSingleLeaseQuery(Number(leaseId));

  return (
    <>
      <PageTitle>Edit Lease</PageTitle>
      {leases && <LeasesForm model={leases} />}
    </>
  );
};

export default EditLeases;
