export interface TenantiApiErrorBody {
  name: string
  statusCode?: number
  message: string
}

export class TenantiApiError extends Error {
  public readonly message: string;

  public readonly name: string;

  public readonly statusCode?: number;

  constructor(error: TenantiApiErrorBody) {
    super();
    this.message = error.message;
    this.name = error.name;
    this.statusCode = error.statusCode;
  }
}
