import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import httpClient from '../../core/httpClient';
import {
  DealsListResponse, DealComment, Deals,
} from '../../types/deals/deals';
import { Space } from '../../types/buildings';
import { QUERY_OPTIONS } from '../../constants';

export const useGetDealsListQuery = (
  page: number,
  perPage: number,
  assetId?: number | null,
  activityPeriod?: number,
  dealStages?: string[] | null,
  filteredMinSize?: number | null,
  filteredMaxSize?: number | null,
): UseQueryResult<DealsListResponse, Error> => useQuery<DealsListResponse, Error>(
  ['deals', page, perPage, assetId, activityPeriod, dealStages, filteredMinSize, filteredMaxSize],

  async () => {
    const params = new URLSearchParams();
    params.append('page', String(page));
    params.append('per_page', String(perPage));
    if (assetId) {
      params.append('asset_id', String(assetId));
    }
    params.append('activity_period', String(activityPeriod));
    dealStages?.forEach((stage: string) => {
      params.append('stage', stage);
    });
    if (filteredMinSize) {
      params.append('requirement_min_size', String(filteredMinSize));
    }
    if (filteredMaxSize) {
      params.append('requirement_max_size', String(filteredMaxSize));
    }
    const response = await httpClient.get(`/deals?${params.toString()}`);
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetDealCommentsQuery = (dealId: number)
: UseQueryResult<DealComment[], Error> => useQuery<DealComment[], Error>(
  ['comments', dealId],
  async () => {
    const response = await httpClient.get(`deals/${dealId}/comments`);
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetSingleDealQuery = (dealId: number): UseQueryResult<Deals, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Deals, Error>(
    ['deals', dealId],
    async () => {
      const response = await httpClient.get(`deals/${dealId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      placeholderData: () : Deals | undefined => queryClient
        .getQueryData<Deals[]>('deals')
        ?.find((d:Deals) => d.id === dealId),
    },
  );
};

export const getAssetSpaces = (
  assetIds: (number | undefined)[],
  vacantValue: boolean,
): UseQueryResult<Space[], Error> => useQuery<Space[], Error>(
  ['assetSpaces', assetIds, vacantValue],
  async () => {
    const repeatedQueryParams = new URLSearchParams();
    assetIds.forEach((assetIdSingle) => {
      repeatedQueryParams.append('asset_id', String(assetIdSingle));
    });
    repeatedQueryParams.append('vacant_only', String(vacantValue));

    const response = await httpClient.get(`/assets/spaces/dropdown-options?${repeatedQueryParams.toString()}`);
    return response.data.items;
  },
  QUERY_OPTIONS,
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDownloadDeals = async (
  page: number,
  perPage: number,
  assetId?: number | null,
  activityPeriod?: number,
  dealStages?: string[] | null,
  filteredMinSize?: number | null,
  filteredMaxSize?: number | null,
) => {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('per_page', String(perPage));
  if (assetId) {
    params.append('asset_id', String(assetId));
  }
  params.append('activity_period', String(activityPeriod));
  dealStages?.forEach((stage: string) => {
    params.append('stage', stage);
  });
  if (filteredMinSize) {
    params.append('requirement_min_size', String(filteredMinSize));
  }
  if (filteredMaxSize) {
    params.append('requirement_max_size', String(filteredMaxSize));
  }
  const response = await httpClient.get(`/deals/download?${params.toString()}`);

  const blob = new Blob([response.data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'deals.csv');
  a.click();
};
