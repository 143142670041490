import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../ui/PageTitle';
import { useGetSingleNewsQuery } from '../../../queries/news';
import NewsForm from '../../forms/news/NewsForm/NewsForm';

export interface EditNewsProps {
  newsId: string
}

const EditNews: React.FC = (): ReactElement => {
  const { newsId } = useParams<EditNewsProps>();
  const { data: news } = useGetSingleNewsQuery(Number(newsId));

  return (
    <>
      <PageTitle>Edit News</PageTitle>
      {news && <NewsForm model={news} />}
    </>
  );
};

export default EditNews;
