import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { LeasesFormValues, UseLeasesForm } from './types';
import { useCreateLeaseMutation, useUpdateLeasesMutation } from '../../../../queries/leases/mutations';
import { CreateLeasesRequest, UpdateLeasesRequest } from '../../../../queries/leases/types';
import { navigateBack } from '../../../../helpers/navigation';

export const useLeasesForm = (): UseLeasesForm => {
  const createMutation = useCreateLeaseMutation();
  const updateMutation = useUpdateLeasesMutation();
  const [spaceError, setSpaceError] = useState('');
  const [rateError, setRateError] = useState('');
  const [dateError, setDateError] = useState('');
  const [renewalDateError, setRenewalDateError] = useState('');

  const history = useHistory();

  const create = async (values: LeasesFormValues, actions: FormikHelpers<LeasesFormValues>) => {
    try {
      const request: CreateLeasesRequest = {
        companyId: Number(values.companyId),
        type: values.type,
        startDate: values.startDate,
        endDate: values.endDate,
        termPeriod: values.termPeriod,
        termType: values.termType,
        renewalNoticeStartDate: values.renewalNoticeStartDate,
        renewalNoticeEndDate: values.renewalNoticeEndDate,
        expansionOption: values.expansionOption,
        purchaseOption: values.purchaseOption,
        terminationOption: values.terminationOption,
        isMonthToMonth: values.isMonthToMonth,
        guarantorCompanyName: values.guarantorCompanyName,
        guarantorStreetAddress: values.guarantorStreetAddress,
        guarantorCity: values.guarantorCity,
        guarantorState: values.guarantorState,
        guarantorZipcode: values.guarantorZipcode,
        renewalOption: values.renewalOption,
        renewalTerm: values.renewalTerm,
        renewalTermType: values.renewalTermType,
        spaces: [...values.spaces],
      };
      await createMutation.mutateAsync({ request }, {
        onSuccess: async () => {
          navigateBack(history);
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          setSpaceError(error.response.data.errors.spaces?.[0].schema);
          setSpaceError(error.response.data.errors.spaces?.[0].area);
          setRateError(error.response.data.errors.spaces?.[0].rate);
          setDateError(error.response.data.errors.dateError);
          setRenewalDateError(error.response.data.errors.renewalDateError);
          await Promise.reject(error);
          break;
        default:
      }
    }
  };

  const update = async (values: LeasesFormValues, actions: FormikHelpers<LeasesFormValues>, leaseId: number) => {
    try {
      const request: UpdateLeasesRequest = {
        companyId: Number(values.companyId),
        type: values.type,
        startDate: values.startDate,
        endDate: values.endDate,
        termPeriod: values.termPeriod,
        termType: values.termType,
        renewalNoticeStartDate: values.renewalNoticeStartDate,
        renewalNoticeEndDate: values.renewalNoticeEndDate,
        expansionOption: values.expansionOption,
        purchaseOption: values.purchaseOption,
        terminationOption: values.terminationOption,
        isMonthToMonth: values.isMonthToMonth,
        guarantorCompanyName: values.guarantorCompanyName,
        guarantorStreetAddress: values.guarantorStreetAddress,
        guarantorCity: values.guarantorCity,
        guarantorState: values.guarantorState,
        guarantorZipcode: values.guarantorZipcode,
        renewalOption: values.renewalOption,
        renewalTerm: values.renewalTerm,
        renewalTermType: values.renewalTermType,
        spaces: [...values.spaces],
      };
      await updateMutation.mutateAsync(
        { request, leaseId: Number(leaseId) },
        {
          onSuccess: async () => {
            navigateBack(history);
          },
        },
      );
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          setSpaceError(error.response.data.errors.spaces?.[0].schema);
          setSpaceError(error.response.data.errors.spaces?.[0].area);
          setRateError(error.response.data.errors.spaces?.[0].rate);
          setDateError(error.response.data.errors.dateError);
          setRenewalDateError(error.response.data.errors.renewalDateError);
          break;
        default:
      }
    }
  };

  return {
    create,
    update,
    spaceError,
    rateError,
    dateError,
    renewalDateError,
  };
};
