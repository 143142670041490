import { now } from '../formatters/dateFormatters/date';

export const isDateBeforeNow = (date: string): boolean => new Date(date) < now();

export const isDateAfterNow = (date: string): boolean => new Date(date) > now();

export const isDateBeforeOrEqualNow = (date: string): boolean => new Date(date) <= now();

export const isDateAfterOrEqualNow = (date: string): boolean => new Date(date) >= now();

export const getDateFullYear = (date: string): number => new Date(date).getFullYear();
