import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import ListUsers from './ListUsers';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import { AuthRoles } from '../../../types/auth';

const UsersIndex: React.FC = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter
        exact
        path={path}
        component={ListUsers}
        isPrivate
        authorizedUsers={[AuthRoles.Admin, AuthRoles.User]}
      />
      <LayoutRouter exact path={`${path}/create`} authorizedUsers={[AuthRoles.Admin]} component={CreateUser} isPrivate />
      <LayoutRouter
        exact
        path={`${path}/edit/:userId`}
        authorizedUsers={[AuthRoles.Admin]}
        component={EditUser}
        isPrivate
      />
    </RouterBaseSwitch>
  );
};

export default UsersIndex;
