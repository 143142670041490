import React from 'react';
import { Box } from '@material-ui/core';

export interface TabPanelProps {
  children: React.ReactNode
  index: number
  value: number
}

export interface TabClassNameProps {
  id: string,
  [key: string]: string
}

export const a11yProps = (index: number): TabClassNameProps => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const TenantiTabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};
