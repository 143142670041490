import React, { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { grey } from '@material-ui/core/colors';
import TenantDetailsFilter from '../../partials/companies/TenantDetailsFilter';
import TenantDetails from '../../partials/companies/TenantDetails';

export interface TenantRouteParams {
  tenantId: string
  comparedTenantId?: string
}

const ViewTenant: React.FC = (): ReactElement => {
  const history = useHistory();

  const {
    tenantId,
    comparedTenantId,
  } = useParams<TenantRouteParams>();

  useEffect(() => {
    if (tenantId === comparedTenantId) {
      history.push(`/tenants/view/${tenantId}`);
    }
  });

  const handleOnFilterChange = (id: number, compared: boolean) => {
    if (compared) {
      return history.push(`/tenants/view/${tenantId}/${id}`);
    }
    return comparedTenantId ? history.push(`/tenants/view/${id}/${comparedTenantId}`)
      : history.push(`/tenants/view/${tenantId}/${id}`);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={!comparedTenantId ? 12 : 6}>
          <TenantDetailsFilter
            compared={false}
            onFilterChange={handleOnFilterChange}
            tenantId={Number(tenantId)}
          />
        </Grid>
        {comparedTenantId && (
          <Grid item xs={6} style={{ borderLeft: `1px solid ${grey[400]}` }}>
            <TenantDetailsFilter
              compared
              onFilterChange={handleOnFilterChange}
              tenantId={Number(comparedTenantId)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={!comparedTenantId ? 12 : 6}>
          {tenantId && <TenantDetails isCompared={!!comparedTenantId} tenantId={Number(tenantId)} />}
        </Grid>
        {comparedTenantId && (
        <Grid item xs={6} style={{ borderLeft: `1px solid ${grey[400]}` }}>
          <TenantDetails isCompared={!!comparedTenantId} tenantId={Number(comparedTenantId)} />
        </Grid>
        )}
      </Grid>
    </>
  );
};

export default ViewTenant;
