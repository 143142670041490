import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box, Button, FormControl, IconButton, InputLabel, Input,
  Select, TextField, MenuItem, Checkbox, ListItemText, ListItemIcon, Grid, Paper, Typography, Tooltip,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import { camelize } from 'humps';
import * as Yup from 'yup';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import PageTitle from '../../ui/PageTitle';
import {
  dealLastActivityTypeOptions, Deals, dealStageOptions, DealStageType,
} from '../../../types/deals/deals';
import { useDownloadDeals, useGetDealsListQuery } from '../../../queries/deals';
import {
  formatDateUSReadable, formatDateWithoutTimeZoneDifference,
} from '../../../formatters/dateFormatters/dateFormatters';
import { Asset, AssetsDropdown } from '../../../types/assets';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import { useDeleteDealMutation } from '../../../queries/deals/mutations';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { Pojo } from '../../../types/pojo';
import { TenantiEnum } from '../../../core/TenantiEnum';
import { useViewDealStyles } from '../../../styles/useViewDealStyles';
import { MUST_BE_NUMBER } from '../../../constants';
import { printNull } from '../../../helpers/null';
import { useListingsFiltrationStyles } from '../../../styles/useListingsFiltrationStyles';
import { useGetAssetsDropdownQuery } from '../../../queries/assets';

const ListDeals:React.FC = (): ReactElement => {
  // eslint-disable-next-line max-len
  const dealsActivityStatuses = new TenantiEnum(dealLastActivityTypeOptions).getDropdownOptions();
  const [dealsActivityStatus, setDealsActivityStatus] = useState<string>('');
  const [selectedDealStages, setSelectedDealsStages] = React.useState<string[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [filteredAsset, setFilteredAsset] = useState<string>('');
  const [requirementMinSize, setRequirementMinSize] = useState<number | null>(null);
  const [requirementMaxSize, setRequirementMaxSize] = useState<number | null>(null);
  const [requirementMinSizeError, setRequirementMinSizeError] = useState<string>('');
  const [requirementMaxSizeError, setRequirementMaxSizeError] = useState<string>('');
  const [filteredMinSize, setFilteredMinSize] = useState<number | null>(null);
  const [filteredMaxSize, setFilteredMaxSize] = useState<number | null>(null);
  const { dealsNavigator } = useNavigationManager();
  const { data: assets } = useGetAssetsDropdownQuery();
  const buttonClasses = useButtonsStyles();
  const dealClasses = useViewDealStyles();
  const filterClasses = useListingsFiltrationStyles();
  const dealsStageStatuses = new DealStageType(dealStageOptions).getDropdownOptions();
  const deleteDealMutation = useDeleteDealMutation();
  const isAllDealsSelected = dealsStageStatuses.length > 0 && selectedDealStages.length === dealsStageStatuses.length;
  const query = new URLSearchParams(useLocation().search);
  const { path } = useRouteMatch();
  const history = useHistory();
  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setDealsActivityStatus(event.target.value as string);
  };
  const { data: dealsTableData, isLoading, isFetching } = useGetDealsListQuery(
    (pagination.pageIndex + 1), pagination.pageSize, Number(filteredAsset), Number(dealsActivityStatus),
    selectedDealStages, filteredMinSize, filteredMaxSize,
  );
  const download = () => {
    useDownloadDeals(
      (pagination.pageIndex + 1), pagination.pageSize, Number(filteredAsset), Number(dealsActivityStatus),
      selectedDealStages, filteredMinSize, filteredMaxSize,
    );
  };
  // eslint-disable-next-line
  const handleDealStageChange = (event: any) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'Show All') {
      const allStages = dealsStageStatuses.map((stage) => stage.value);
      setSelectedDealsStages(selectedDealStages.length === dealsStageStatuses.length ? [] : allStages);
      return;
    }
    setSelectedDealsStages(value);
  };

  const { showModal, RenderModal, modalId } = useDeleteModal();

  const deleteDeal = (dealId: number | null) => {
    if (!dealId) {
      return;
    }
    deleteDealMutation.mutate({ dealId });
  };

  const DealsFiltrationValidationSchema = Yup.object().shape({
    requirementMaxSize: Yup.number().typeError(MUST_BE_NUMBER)
      .min(Number(requirementMinSize), 'Must be greater than min value').nullable(true),
    requirementMinSize: Yup.number().typeError(MUST_BE_NUMBER).nullable(true),
  });

  useEffect(() => {
    if (dealsTableData) {
      setRowCount(dealsTableData.total);
    }
  }, [dealsTableData]);

  useEffect(() => {
    const id = query.get('assetId') ?? '';
    const stage = query.get('stage') ?? null;
    const minSize = query.get('minSize') ?? null;
    const maxSize = query.get('maxSize') ?? null;
    const dealsActivity = query.get('dealsActivity') ?? '';
    setDealsActivityStatus(dealsActivity);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    minSize ? setRequirementMinSize(Number(minSize)) : setRequirementMinSize(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    maxSize ? setRequirementMaxSize(Number(maxSize)) : setRequirementMaxSize(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    stage ? setSelectedDealsStages(stage.split(',')) : setSelectedDealsStages([]);
    setFilteredAsset(id);
  }, []);

  useEffect(() => {
    query.set('assetId', filteredAsset);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [filteredAsset]);

  useEffect(() => {
    query.set('stage', selectedDealStages.join(','));
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [selectedDealStages]);

  useEffect(() => {
    const minSize = requirementMinSize ? Number(requirementMinSize) : '';
    query.set('minSize', minSize.toString());
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [requirementMinSize]);

  useEffect(() => {
    const maxSize = requirementMaxSize ? Number(requirementMaxSize) : '';
    query.set('maxSize', maxSize.toString());
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [requirementMaxSize]);

  useEffect(() => {
    query.set('dealsActivity', dealsActivityStatus);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [dealsActivityStatus]);

  useEffect(() => {
    DealsFiltrationValidationSchema.validate({ requirementMinSize }).then(() => {
      setRequirementMinSizeError('');
    }).catch((err) => {
      if (err.message && requirementMinSize) {
        setRequirementMinSizeError(err.message);
      } else {
        setRequirementMinSizeError('');
      }
    });
  }, [requirementMinSize]);

  useEffect(() => {
    DealsFiltrationValidationSchema.validate({ requirementMaxSize }).then(() => {
      setRequirementMaxSizeError('');
    }).catch((err) => {
      if (err.message && requirementMaxSize) {
        setRequirementMaxSizeError(err.message);
      } else {
        setRequirementMaxSizeError('');
      }
    });
  }, [requirementMaxSize]);

  const searchDealByMinSize = () => {
    if (!requirementMinSize) {
      setFilteredMinSize(null);
    }
    setFilteredMinSize(Number(requirementMinSize));
  };

  const searchDealByMaxSize = () => {
    if (!requirementMaxSize) {
      setFilteredMaxSize(null);
    }
    setFilteredMaxSize(Number(requirementMaxSize));
    searchDealByMinSize();
  };

  const dealStage = new DealStageType(dealStageOptions);

  const navigateToViewDealEdit = (dealId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    dealsNavigator.edit(Number(dealId)).navigate();
  };

  const columns: MRT_ColumnDef<Deals>[] = [
    {
      header: 'Tenant',
      accessorKey: 'tenant',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip
          title={(
          row.tenant ? row.tenant.name : row.undisclosedTenant)}
          placement="top"
          arrow
        >
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {(row.tenant ? row.tenant.name : row.undisclosedTenant)}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
      size: 200,
    },
    {
      header: 'Asset(s)',
      accessorKey: 'assets',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip
          title={row?.assets.map((item: Asset) => item.title).join(', ')}
          placement="top"
          arrow
        >
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {row?.assets.map((item: Asset) => item.title).join(', ')}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
      size: 200,
    },
    {
      header: 'Size',
      accessorKey: 'size',
      accessorFn: (row) => ((row.requirementMinSize && row?.requirementMaxSize)
        ? (`${row?.requirementMinSize} - ${row?.requirementMaxSize} ft`)
        : printNull(row.requirementMinSize)),
      enableSorting: false,
      size: 100,
    },
    {
      accessorKey: 'stage',
      header: 'Stage',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip
          title={(dealStage.getDisplayNameByKey(row?.stage))}
          placement="top"
          arrow
        >
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {(dealStage.getDisplayNameByKey(row?.stage))}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
      size: 100,
    },
    {
      header: 'Log Date',
      accessorKey: 'logDate',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip
          title={(formatDateWithoutTimeZoneDifference(row?.logDate))}
          placement="top"
          arrow
        >
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {(formatDateWithoutTimeZoneDifference(row?.logDate))}
          </Typography>
        </Tooltip>
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        if (a.original.logDate && b.original.logDate) {
          return new Date(a.original.logDate).getTime() - new Date(b.original.logDate).getTime();
        }
        return 0;
      },
      size: 150,
    },
    {
      header: 'Last Updated',
      accessorKey: 'updatedAt',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip
          title={(formatDateWithoutTimeZoneDifference(row?.updatedAt || row?.createdAt))}
          placement="top"
          arrow
        >
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {(formatDateWithoutTimeZoneDifference(row?.updatedAt || row?.createdAt))}
          </Typography>
        </Tooltip>
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        const date1 = a.original.updatedAt ? new Date(a.original.updatedAt) : new Date(a.original.createdAt);
        const date2 = b.original.updatedAt ? new Date(b.original.updatedAt) : new Date(b.original.createdAt);
        return date1.getTime() - date2.getTime();
      },
      size: 150,
    },
    {
      accessorKey: 'contact',
      header: 'Contact',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <Tooltip
            title={row?.owner?.fullName}
            placement="top"
            arrow
          >
            <Typography
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {row?.owner?.fullName}
            </Typography>
          </Tooltip>
        </>
      ),
      enableSorting: false,
      size: 200,
    },
    {
      accessorKey: 'comment',
      header: 'Last Comment',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => {
        const dealHasComment = row.comment;
        return (
          <Box style={{ display: 'flex', flexDirection: 'column', padding: '18px 0' }}>
            { dealHasComment ? (
              <>
                <Box style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: '210px',
                }}
                >
                  <Typography variant="h6" style={{ fontSize: 14 }}>
                    {`${row.comment.user.firstName} ${row.comment.user.lastName}`}
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontSize: 14 }}>
                    {formatDateUSReadable(row.comment.createdAt)}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
                  {`${row.comment.comment.substring(0, 60)}...`}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>
        );
      },
      enableSorting: false,
      size: 500,
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <IconButton
            style={{ width: '25px' }}
            onClick={
                (e) => navigateToViewDealEdit(Number(row.id), e)
              }
            aria-label="edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            style={{ width: '25px' }}
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showModal(Number(row.id));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
      size: 100,
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Deals</PageTitle>
        <Box display="flex" alignItems="flex-start" alignSelf="flex-end" className={filterClasses.listingFilterWrapper}>
          <Box px={2} maxWidth={184} minWidth={184}>
            <FormControl margin="none" fullWidth>
              <InputLabel>Filter By Stage</InputLabel>
              <Select
                multiple
                label="Filter By Stage"
                value={selectedDealStages}
                onChange={handleDealStageChange}
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(', ')}
              >
                <MenuItem value="Show All">
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={isAllDealsSelected}
                    />
                    <ListItemText primary="Show all" />
                  </ListItemIcon>
                </MenuItem>
                {dealsStageStatuses.map((option: Pojo) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox color="primary" checked={selectedDealStages.indexOf(option.value) > -1} />
                    <ListItemText primary={option.displayValue} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mr={2} maxWidth={184}>
            <Typography variant="subtitle1" className={dealClasses.combinedInputLabel}>
              Filter By Requirement Size (ft)
            </Typography>
            <FormControl
              style={{ maxWidth: '75px', marginRight: '10px' }}
            >
              <TextField
                variant="standard"
                placeholder="Min"
                error={!!requirementMinSizeError}
                className={dealClasses.combinedInput}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    searchDealByMinSize();
                  }
                }}
                helperText={requirementMinSizeError}
                type="text"
                value={requirementMinSize}
                onChange={(event) => setRequirementMinSize(
                  event.target.value as unknown as number,
                )}
              />
            </FormControl>
            <FormControl
              style={{ maxWidth: '75px' }}
            >
              <TextField
                variant="standard"
                placeholder="Max"
                type="text"
                name="requirement-max-size"
                value={requirementMaxSize}
                error={!!requirementMaxSizeError}
                className={dealClasses.combinedInput}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    searchDealByMaxSize();
                  }
                }}
                helperText={requirementMaxSizeError}
                onChange={(event) => setRequirementMaxSize(
                  event.target.value as unknown as number,
                )}
              />
            </FormControl>
          </Box>
          <Box px={2} minWidth={184}>
            <FormControl style={{ minWidth: 184 }}>
              <InputLabel shrink={!!dealsActivityStatus} htmlFor="filter-by-deal-activity">
                Filter By Deal Activity
              </InputLabel>
              <Select
                native
                value={dealsActivityStatus}
                onChange={handleChange}
                label="Filter By Deal Activity"
              >
                <option aria-label="none" defaultValue="" />
                {dealsActivityStatuses?.map((option: Pojo) => (
                  <option key={option.value} value={option.value}>
                    {option.displayValue}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mr={2} maxWidth={184}>
            <FormControl margin="none" fullWidth>
              <InputLabel shrink={!!filteredAsset} htmlFor="filter-asset">Filter By Asset</InputLabel>
              <Select
                native
                value={filteredAsset}
                onChange={(event) => setFilteredAsset(String(event.target.value))}
                label="Filter By Asset"
                inputProps={{
                  name: 'filter-asset',
                  id: 'filter-asset',
                }}
              >
                <option aria-label="None" defaultValue="" />
                {assets?.map((asset : AssetsDropdown) => (
                  <option key={asset.id} value={asset.id}>
                    {asset.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mr={2}>
            <Button
              className={buttonClasses.large}
              variant="contained"
              color="default"
              onClick={download}
            >
              Download CSV
            </Button>
          </Box>

          <Button
            component={Link}
            className={buttonClasses.large}
            to={dealsNavigator.create().path}
            variant="contained"
            color="primary"
          >
            Create Deal
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {Object.keys(dealStage.options).map((item) => (
          <Grid key={item} item xs>
            <Box mt={2} textAlign="center">
              <Paper variant="outlined" className={dealClasses.dealCard}>
                <Typography variant="subtitle1" className={dealClasses.dealType}>
                  {dealStage.getDisplayNameByKey(item)}
                </Typography>
                <Typography variant="h5" className={dealClasses.dealCount}>
                  {dealsTableData?.stagesByCount[camelize(item)]}
                </Typography>
              </Paper>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box style={{ height: '100%' }}>
        <MaterialReactTable
          columns={columns}
          data={dealsTableData?.items || []}
          enableTopToolbar={false}
          enableColumnResizing
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableRowOrdering={false}
          enableSorting
          enableGlobalFilterModes={false}
          enableDensityToggle={false}
          state={{ pagination, isLoading: isLoading || isFetching }}
          manualPagination
          rowCount={rowCount}
          onPaginationChange={setPagination}
          layoutMode="grid"
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
              display: 'table',
            },
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              event.stopPropagation();
              dealsNavigator.single(Number(row.original.id)).navigate();
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Box>
      <RenderModal
        body="Are you sure you want to delete this Deal?"
        confirmAction={() => deleteDeal(modalId)}
        title="Delete Deal"
      />
      <LoaderModal isOpen={deleteDealMutation.isLoading} />
    </>
  );
};

export default ListDeals;
