import { useQuery, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import { Document } from './types';
import { QUERY_OPTIONS } from '../../constants';

export const useGetAllDocumentsQuery = (
  leaseId?: number | undefined,
): UseQueryResult<Document[], Error> => useQuery<Document[], Error>(
  ['documentsList', leaseId],
  async () => {
    const query = {
      ...(leaseId && { lease_id: leaseId }),
    };
    const response = await httpClient.get('/documents', {
      params: { ...query },
    });
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetDocumentsByBuilding = (
  buildingId: number,
): UseQueryResult<Document[], Error> => useQuery<Document[], Error>(
  ['building-documents', buildingId],
  async () => {
    const response = await httpClient.get(`/buildings/${buildingId}/documents`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!buildingId,
  },
);
