import { TenantiEnum } from '../core/TenantiEnum';
import { Asset } from './assets';
import { UserActivity } from './user_activities';

export interface User {
  id: number
  email: string
  firstName: string
  lastName: string
  fullName: string
  roleType? : string
  assets?: Asset[]
  user_activity?: UserActivity
}

export interface UserDropdownOptions {
  id: number
  fullName: string
}

export const userRoleOptions = {
  user: {
    value: 'user',
    displayValue: 'User',
  },
  admin: {
    value: 'admin',
    displayValue: 'Admin',
  },
  broker: {
    value: 'broker',
    displayValue: 'Broker',
  },
};

export class UserRole extends TenantiEnum {

}
