import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { CreateDocumentsMutationVariables, DeleteDocumentMutationVariables, Document } from './types';
import httpClient from '../../core/httpClient';

export const useCreateDocumentMutation = (): UseMutationResult<Document, Error, CreateDocumentsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Document, Error, CreateDocumentsMutationVariables>(
    async ({ request }) => {
      const response = await httpClient.post('/documents', request);
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('documentsList');
      },
    },
  );
};

export const useDeleteDocument = (): UseMutationResult<null, Error,
DeleteDocumentMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteDocumentMutationVariables>(
    async ({ documentId }) => httpClient.delete(`/documents/${documentId}`),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('documentsList');
      },
    },
  );
};
