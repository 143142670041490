import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { News } from '../../types/news';
import { CreateNewsMutationVariables, DeleteNewsMutationVariables, UpdateNewsMutationVariables } from './types';
import httpClient from '../../core/httpClient';

export const useCreateNewsMutation = (): UseMutationResult<News, Error, CreateNewsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<News, Error, CreateNewsMutationVariables>(
    async ({ request }) => {
      const response = await httpClient.post('/news', request);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<News[]>(['news']);
        if (prevData) {
          queryClient.setQueryData(['news'], [data, ...prevData]);
        }
      },
    },
  );
};

export const useUpdateNewsMutation = (): UseMutationResult<News, Error, UpdateNewsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<News, Error, UpdateNewsMutationVariables>(
    async ({ request, newsId }) => {
      const response = await httpClient.put(`/news/${newsId}`, request);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(['news', variables.newsId], data);
        const prevData = queryClient.getQueryData<News[]>(['news']);
        if (prevData) {
          queryClient.setQueryData(['news'], prevData.map((news) => {
            if (news.id === variables.newsId) {
              return data;
            }
            return news;
          }));
        }
      },
    },
  );
};

export const useDeleteNewsMutation = (): UseMutationResult<null, Error, DeleteNewsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteNewsMutationVariables>(
    async ({ newsId }) => httpClient.delete(`/news/${newsId}`),
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<News[]>(['news']);
        if (prevData) {
          queryClient.setQueryData(['news'], prevData.filter((news) => news.id !== variables.newsId));
        }
      },
    },
  );
};
