import React, { ReactElement } from 'react';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import { formatAreaSize } from '../../formatters/areaSizeFormatter';
import { formatNumericValue } from '../../formatters/numberFormatter';
import { formatPercentage } from '../../formatters/percentageFormatter';
import { getPercentage } from '../../math/lib';
import InfoCard from './InfoCard';
import ComponentError from './ComponentError';
import { AggregatedCardData } from '../../types/statistics';
import ComponentLoader from './ComponentLoader';
import { emptyStringValueToStringZero } from '../../formatters/emptyStringValueToStringZero';

interface DashboardBannersProps {
  data: AggregatedCardData | undefined
  isLoading: boolean
  error: Error | null
}

export interface InfoCards {
  icon: React.ReactElement;
  title: string,
  subtitle?: string
  content: number | string,
  subContent?: number | string
  metrics?: string
}

export const DashboardBanners: React.FC<DashboardBannersProps> = (props: DashboardBannersProps): ReactElement => {
  const { data: banner, isLoading, error } = props;

  const totalFtPercentage = (): string => {
    if (banner?.totalLeasedArea && banner.totalBuildingsArea) {
      const percentage = getPercentage(Number(banner?.totalLeasedArea), Number(banner?.totalBuildingsArea));
      return formatPercentage(percentage);
    }
    return formatPercentage(0);
  };

  const infoCards: InfoCards[] = [
    {
      icon: <LocationCityIcon fontSize="medium" />,
      title: 'TOTAL BUILDINGS',
      content: emptyStringValueToStringZero(banner?.numberOfBuildings),
    },
    {
      icon: <PersonIcon fontSize="medium" />,
      title: 'NUMBER OF TENANTS',
      content: emptyStringValueToStringZero(banner?.numberOfTenants),
    },
    {
      icon: <HomeIcon fontSize="medium" />,
      title: 'TOTAL FT',
      subtitle: 'OCCUPIED',
      content: (formatNumericValue(emptyStringValueToStringZero(banner?.totalLeasedArea), 0)),
      subContent: totalFtPercentage(),
    },
    {
      icon: <NewReleasesIcon fontSize="medium" />,
      title: 'NEAR TERM EXP.(1 YEAR)',
      subtitle: 'TOTAL',
      content: emptyStringValueToStringZero(banner?.numberOfNearExpiringLeases),
      subContent: banner?.nearExpiringLeasedArea
        ? formatAreaSize(banner?.nearExpiringLeasedArea) : '',
    },
    {
      icon: <EqualizerIcon fontSize="medium" />,
      title: 'WEIGHTED AVG. TERM REMAINING',
      content: emptyStringValueToStringZero(banner?.weightedAverageExpiryDate),
      metrics: 'months',
    },
  ];

  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        { banner && !isLoading
          && infoCards.map((infoCard) => (
            <Grid item xs={12} md={2.4} lg={2.4} xl={2.4} key={infoCard.title}>
              <InfoCard
                icon={infoCard.icon}
                title={infoCard.title}
                content={infoCard.content}
                subtitle={infoCard.subtitle}
                subContent={infoCard.subContent}
                metrics={infoCard.metrics}
              />
            </Grid>
          ))}
        {
          error && (
            <ComponentError error={error} />
          )
        }
      </Grid>
      <ComponentLoader isOpen={isLoading} />
    </Box>
  );
};
