import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSecurity } from '../../../hooks/useSecurity';

const Logout: React.FC = (): ReactElement => {
  const { logout } = useSecurity();
  const history = useHistory();

  useEffect(() => {
    logout().then(() => history.push('/auth/login'));
  }, []);

  return (
    <div>Logging out...</div>
  );
};

export default Logout;
