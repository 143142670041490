import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { defaultProps, LayoutRouterProps } from './interfaces';
import { accessToken } from '../models/security';
import NotFoundPage from '../components/pages/NotFoundPage';

type PropsType = LayoutRouterProps & typeof defaultProps;

export const LayoutRouter = (props: PropsType): JSX.Element => {
  const {
    component: Component, isPrivate, authorizedUsers, ...rest
  } = props;

  const history = useHistory();
  const [shouldRender, setShouldRender] = useState(false);

  const isPermissionGranted = (): boolean => {
    const userRole = accessToken.getCurrentUserRole();
    return !!(userRole && authorizedUsers.includes(userRole));
  };

  useEffect(() => {
    if (isPrivate && !accessToken?.isValid()) {
      history.push('/auth/login');
    } else {
      setShouldRender(true);
    }
  }, [isPrivate, accessToken]);

  if (!shouldRender) {
    return <></>;
  }

  return (
    <>
      {
        (isPrivate && !isPermissionGranted()) ? (
          <Route render={() => (
            <NotFoundPage />
          )}
          />
        ) : (
          <Route
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            render={() => (
              <Component />
            )}
          />
        )
      }
    </>
  );
};

LayoutRouter.defaultProps = defaultProps;
