import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BOLD_FONT } from '../../constants';

const useStyles = makeStyles(() => createStyles({
  pageTitle: {
    paddingLeft: '16px',
    fontWeight: BOLD_FONT,
  },
}));

const PageTitle: React.FC = (props): ReactElement => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Typography component="h1" variant="h5" className={classes.pageTitle}>
      {children}
    </Typography>
  );
};

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitle;
