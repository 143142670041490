import { useBaseNavigation } from '../baseNavigation';
import { UseAuthNavigationProps } from '../interfaces';

export const useAuthNavigation = (baseRouteName: string): UseAuthNavigationProps => {
  const { setRouteParams } = useBaseNavigation();

  const base = () => setRouteParams(baseRouteName);

  const login = () => setRouteParams(`${baseRouteName}/login`);

  const accessInvitation = (userId: number, token: string) => setRouteParams(`${login().path}/${userId}/${token}`);

  const editProfile = () => setRouteParams(`${baseRouteName}/edit-profile`);

  const logout = () => setRouteParams(`${baseRouteName}/logout`);

  return {
    base,
    login,
    accessInvitation,
    editProfile,
    logout,
  };
};
