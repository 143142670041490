import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { AssetFormValues, UseAssetForm } from './types';
import { CreateAssetRequest, UpdateAssetRequest } from '../../../../queries/assets/types';
import {
  useCreateAssetMutation,
  useSaveAssetBuildingMutation,
  useUpdateAssetMutation,
} from '../../../../queries/assets/mutations';
import { Building } from '../../../../types/buildings';
import { navigateBack } from '../../../../helpers/navigation';

export const useAssetsForm = (): UseAssetForm => {
  const createMutation = useCreateAssetMutation();
  const updateMutation = useUpdateAssetMutation();
  const assetsMutation = useSaveAssetBuildingMutation();
  const history = useHistory();

  const create = async (
    values: AssetFormValues,
    actions: FormikHelpers<AssetFormValues>,
  ) => {
    try {
      const request: CreateAssetRequest = {
        title: values.title,
      };

      await createMutation.mutateAsync({ request }, {
        onSuccess: async ({ id }) => {
          const result = values.buildings.map((building:Building) => building.id);
          if (values.buildings) {
            await assetsMutation.mutateAsync({ assetId: id, buildings: result });
          }
          navigateBack(history);
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (
    values: AssetFormValues,
    actions: FormikHelpers<AssetFormValues>,
    assetId: number,
  ) => {
    let asset;
    try {
      const request: UpdateAssetRequest = {
        title: values.title,
      };
      asset = await updateMutation.mutateAsync({ request, assetId });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
    const result = values.buildings.map((building:Building) => building.id);
    if (asset) {
      try {
        if (values.buildings) {
          await assetsMutation.mutateAsync({ assetId, buildings: result });
        }
        navigateBack(history);
      } catch (error) {
        switch (error.response?.status) {
          case 422:
            actions.setErrors(error.response.data.errors);
            break;
          default:
        }
      }
    }
  };

  return {
    create,
    update,
    isLoading: createMutation.isLoading || updateMutation.isLoading || assetsMutation.isLoading,

  };
};
