import React, { ReactElement, useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { COMPONENT_ERROR_TEXT } from '../../constants';

export interface ComponentErrorProps {
  error: Error | null
}

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    fontSize: theme.spacing(3),
    color: grey[400],
  },
}));

const ComponentError: React.FC<ComponentErrorProps> = (props: ComponentErrorProps): ReactElement => {
  const { error } = props;
  const [errorMessage, setErrorMessage] = useState(COMPONENT_ERROR_TEXT);
  const classes = useStyles();

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  return (
    <Paper elevation={0} className={classes.errorContainer}>
      <Typography className={classes.errorText}>
        {errorMessage}
      </Typography>
    </Paper>
  );
};

export default ComponentError;
