import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { TenantFormValues, UseTenantForm } from './types';
import { useCreateTenantMutation, useUpdateTenantMutation } from '../../../../queries/tenants/mutations';
import { CreateTenantRequest, UpdateTenantRequest } from '../../../../queries/tenants/types';

export const useTenantForm = (): UseTenantForm => {
  const createMutation = useCreateTenantMutation();
  const updateMutation = useUpdateTenantMutation();
  const history = useHistory();

  const create = async (values: TenantFormValues, actions: FormikHelpers<TenantFormValues>) => {
    try {
      const request: CreateTenantRequest = values;
      await createMutation.mutateAsync({ request }, {
        onSuccess: async () => {
          history.push('/tenants');
        },
      });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  const update = async (values: TenantFormValues, actions: FormikHelpers<TenantFormValues>, modelId: number) => {
    try {
      const request: UpdateTenantRequest = values;
      await updateMutation.mutateAsync(
        { request, tenantId: modelId },
        {
          onSuccess: async () => {
            history.push('/tenants');
          },
        },
      );
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    create,
    update,
  };
};
