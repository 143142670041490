import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import { DataPointsRequestVariables } from './types';
import {
  TenantAggregatedData,
  CompanyStatisticsByLocation,
  DataPointsListResponse,
  EmployeesCountChartData, AggregatedCardData,
} from '../../types/statistics';
import { Tenant, TenantListing } from '../../types/tenant';
import { accessToken } from '../../models/security';
import { QUERY_OPTIONS } from '../../constants';

export const useGetAllTenantsQuery = (): UseQueryResult<Tenant[], Error> => useQuery<Tenant[], Error>(
  'tenants',
  async () => {
    const response = await httpClient.get('/companies');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetTenantByYearQuery = (
  fromYear?: string,
  toYear?: string,
  tenantName?: string,
  assetId?: number,
): UseQueryResult<TenantListing[], Error> => useQuery<TenantListing[], Error>(
  ['company-aggregated-data', fromYear, toYear, tenantName, assetId],
  async () => {
    const query = {
      ...(fromYear && { lease_end_year_from: fromYear }),
      ...(toYear && { lease_end_year_to: toYear }),
      ...(tenantName && { tenant_name: tenantName }),
      ...(assetId && { asset_id: assetId }),
    };

    const response = await httpClient.get('/companies/aggregated', {
      params: { ...query },
    });
    return response.data.items.map((dataItem: TenantListing) => ({
      id: dataItem.companyId,
      companyName: dataItem.companyName,
      numberOfLeases: dataItem.numberOfLeases,
      employeesCountChange: dataItem.employeesCountChange,
      employeesCount: dataItem.employeesCount,
      totalArea: dataItem.totalArea,
      leaseEndDate: dataItem.leaseEndDate,
      createdById: dataItem.createdById,
      canEdit: dataItem.createdById === accessToken.getUserId(),
    }));
  },
  {
    ...QUERY_OPTIONS,
    keepPreviousData: true,
    enabled: (!fromYear && !toYear) || (!!fromYear && !!toYear),
    retry: 1,
  },
);

export const useGetDataPointsQuery = (
  page: number,
  perPage: number,
  companyId?: number | undefined,
  source?: string,
): UseQueryResult<DataPointsListResponse, Error> => useQuery<DataPointsListResponse, Error>(
  ['dataPoints', companyId, source, page, perPage],

  async () => {
    const query = {
      ...(page && { page }),
      ...(perPage && { per_page: perPage }),
      ...(companyId && { company_id: companyId }),
      ...(source && { source }),
    };
    const response = await httpClient.get('/companies_statistics', {
      params:
        { ...query },
    });
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetDataPointsDataQuery = (
  companyId: number,
): UseQueryResult<TenantAggregatedData, Error> => useQuery<TenantAggregatedData, Error>(
  ['company-statistics-data', companyId],
  async () => {
    const query = {
      ...(companyId && { company_id: companyId }),
    };
    const response = await httpClient.get('/statistics/company', {
      params: { ...query },
    });
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!companyId,
  },
);

export const useGetDownsizingTenantsQuery = (): UseQueryResult<CompanyStatisticsByLocation[], Error> => useQuery<
CompanyStatisticsByLocation[], Error>(
  'downsizingTenant',
  async () => {
    const response = await httpClient.get('/statistics/downsizing');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetTopHiringTenantsQuery = (): UseQueryResult<CompanyStatisticsByLocation[], Error> => useQuery<
CompanyStatisticsByLocation[], Error>(
  'topHiringTenant',
  async () => {
    const response = await httpClient.get('/statistics/hiring');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetTenantsBannersQuery = (): UseQueryResult<AggregatedCardData, Error> => useQuery<
AggregatedCardData, Error>(
  'companyBanners',
  async () => {
    const response = await httpClient.get('/statistics/dashboard');
    return response.data.data.banners;
  },
  QUERY_OPTIONS,
);

export const useGetCompanyStatisticsQuery = (
  requestKey: DataPointsRequestVariables,
  companyId: number,
  page: number,
  perPage: number,
): UseQueryResult<DataPointsListResponse, Error> => useQuery<DataPointsListResponse, Error>(
  ['tenants-statistics', requestKey, companyId, page, perPage],
  async () => {
    const query = {
      ...(page != null && { page: page + 1 }),
      ...(perPage && { per_page: perPage }),
    };
    const response = await httpClient.get(`/companies_statistics/${companyId}/${requestKey}`, {
      params: { ...query },
    });
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!companyId,
  },
);

export const useGetEmployeesCountChartDataQuery = (
  companyId: number, months = 12,
): UseQueryResult<EmployeesCountChartData[], Error> => useQuery<EmployeesCountChartData[], Error>(
  ['employees-count', companyId],
  async () => {
    const response = await httpClient.get(
      `/statistics/employees-count?company_id=${companyId}&number_of_months=${months}`,
    );
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!companyId,
  },
);

export const useGetSingleTenantQuery = (companyId: number): UseQueryResult<Tenant, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Tenant, Error>(
    ['tenants', companyId],
    async () => {
      const response = await httpClient.get(`/companies/${companyId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      placeholderData: () : Tenant | undefined => queryClient
        .getQueryData<Tenant[]>('tenants')
        ?.find((c:Tenant) => c.id === companyId),
    },
  );
};
