import React, { ReactElement } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useButtonsStyles } from '../../styles/useButtonsStyles';
import { accessToken } from '../../models/security';

const useStyles = makeStyles((theme) => ({
  title: {
    color: grey[500],
    fontSize: theme.spacing(5),
  },
  context: {
    color: grey[700],
    fontSize: theme.spacing(2),
  },
}));

const NotFoundPage: React.FC = (): ReactElement => {
  const classes = useStyles();
  const buttonClasses = useButtonsStyles();
  const history = useHistory();
  const goBackToPortfolio = () => {
    if (accessToken.isCurrentRoleBroker()) {
      history.push('/assets');
    } else {
      history.push('/');
    }
  };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"

    >
      <Typography
        variant="h1"
        component="h1"
        color="primary"
      >
        404
      </Typography>
      <Typography className={classes.title}>Page Not Found</Typography>
      <Typography className={classes.context}>
        Sorry, we could not find that page.
      </Typography>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          className={buttonClasses.large}
          onClick={goBackToPortfolio}
        >
          Back to the
          {' '}
          {accessToken.isCurrentRoleBroker() ? 'Asset' : 'Portfolio'}
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
