import React, { ReactElement } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  EmployeesCountChartData,
  EmployeesCountData,
} from '../../../types/statistics';
import { generateColors } from '../../../styles/colors/palette';
import { cardTitleProps } from '../../../styles/useCardStyles';
import { formatDateReadable } from '../../../formatters/dateFormatters/dateFormatters';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
export interface EmployeesCountChartProps {
  chartData: EmployeesCountChartData[]
}

const getEmployeesChartDates = (chartData: EmployeesCountChartData[]) => chartData
  .map((dataItem: EmployeesCountChartData) => (dataItem.data.map((nestedData: EmployeesCountData) => nestedData.date)))
  .flat();

const getEmployeesChartUniqueDates = (chartData: EmployeesCountChartData[]): string[] => {
  const labels = getEmployeesChartDates(chartData)
    .map((dataItem) => dataItem.slice(0, 7));

  const uniqueLabels = new Set([...labels]);

  return Array.from(uniqueLabels)
    .sort()
    .map((item) => formatDateReadable(item));
};

const getEmployeesChartDataset = (chartData: EmployeesCountChartData[]) => {
  const colors = generateColors(Number(chartData.length));

  return chartData.map((dataItem: EmployeesCountChartData, index: number) => {
    const label = dataItem.location;
    const filteredChartData: EmployeesCountChartData | undefined = chartData
      .find((nestedData: EmployeesCountChartData) => nestedData.location === label);

    return {
      type: 'line' as const,
      label,
      fill: false,
      data: filteredChartData?.data
        .map((nestedData) => ({
          x: new Date(nestedData.date),
          y: nestedData.employeesCount,
        })) ?? [],
      backgroundColor: colors[index],
      borderColor: colors[index],
    };
  });
};

const employeesCountOptions = {
  options: {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
      },
    },
  },
  layout: {
    padding: {
      left: 10,
      right: 20,
      bottom: 10,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

const EmployeesCountChart: React.FC<EmployeesCountChartProps> = (props: EmployeesCountChartProps): ReactElement => {
  const { chartData } = props;
  const employeesCountData = () => ({
    labels: chartData ? getEmployeesChartUniqueDates(chartData) : [],
    datasets: chartData ? getEmployeesChartDataset(chartData) : [],
  });

  return (
    <Card>
      <CardHeader titleTypographyProps={cardTitleProps} title="Employees Count" />
      {
        chartData && (
        <Line options={employeesCountOptions} data={employeesCountData()} />
        )
       }
    </Card>
  );
};

export default EmployeesCountChart;
