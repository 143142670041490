import React, { ReactElement } from 'react';
import { CircularProgress } from '@material-ui/core';

const AppLoader: React.FC = (): ReactElement => (
  <div style={{
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '70vh',
    width: '100vw',
  }}
  >
    <CircularProgress />
  </div>
);

export default AppLoader;
