import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import BuildingForm from '../../forms/buildings/BuildingForm/BuildingForm';
import { useGetAllFloorsQuery, useGetSingleBuildingQuery } from '../../../queries/buildings';
import PageTitle from '../../ui/PageTitle';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';

export interface EditBuildingProps {
  buildingId: string
}

const EditBuilding: React.FC = (): ReactElement => {
  const { buildingId } = useParams<EditBuildingProps>();
  const { data: building, isLoading: isBuildingLoading } = useGetSingleBuildingQuery(Number(buildingId));
  const { data: floors, isLoading: isFloorsLoading } = useGetAllFloorsQuery(Number(buildingId));

  return (
    <>
      <PageTitle>Edit Building</PageTitle>
      {building && (
      <BuildingForm model={{
        ...building,
        floors: floors ?? [],
      }}
      />
      )}
      <LoaderModal isOpen={isFloorsLoading || isBuildingLoading} />
    </>
  );
};

export default EditBuilding;
