import { makeStyles } from '@material-ui/core/styles';

export const useListingsFiltrationStyles = makeStyles({
  listingFilterWrapper: {
    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },
    '& .MuiInputBase-root': {
      fontSize: '14px',
    },
  },
});
