import React, {
  ReactElement, Suspense, useEffect, useState,
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@material-ui/core/styles';
import { RouteWrapper } from './router/RouteWrapper';
import './index.css';
import useErrorModal from './components/modals/ErrorModal/hooks';
import { MODAL_ERROR, GENERAL_ERROR_TEXT } from './constants';
import Emitter from './core/eventBus';
import AppLoader from './components/ui/AppLoader';
import { StoreProvider } from './store/AppGlobalContext';
import { customMaterialUIStyle } from './styles/vendors/CustomMaterialUIStyle';

const queryClient = new QueryClient();

const App: React.FC = (): ReactElement => {
  const { showModal, RenderModal: ErrorModal } = useErrorModal();
  const [errorBody, setErrorBody] = useState(GENERAL_ERROR_TEXT);

  useEffect(() => {
    Emitter.on(MODAL_ERROR, (newValue: string) => {
      setErrorBody(newValue);
      showModal();
    });

    return () => {
      Emitter.off(MODAL_ERROR);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<AppLoader />}>
        <StoreProvider>
          <ThemeProvider theme={customMaterialUIStyle}>
            <>
              <RouteWrapper />
              <ErrorModal body={errorBody} />
            </>
          </ThemeProvider>
        </StoreProvider>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
