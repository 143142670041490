import { makeStyles } from '@material-ui/core/styles';

export const useTableStyles = makeStyles({
  cellSelectDisabled: {
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-window': {
      width: 'max-content',
    },
  },
  navigableTable: {
    '& > .MuiDataGrid-main .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
  noBorderTable: {
    '& .MuiDataGrid-root': {
      border: 'none',
    },
  },
  tablesBackground: {
    background: 'white',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& > .MuiDataGrid-main .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& > .MuiDataGrid-main .MuiDataGrid-columnHeader': {
      padding: '0 16px',
    },
    '& > .MuiDataGrid-main .MuiDataGrid-cell': {
      padding: '0 16px',
    },
  },
  headerStyle: {
    flexDirection: 'row',
    webkitAlignItems: 'center',
  },
  showVacant: {
    display: 'flex',
    flexDirection: 'row-reverse',
    color: '#4051B5',
  },
  tableButtonWrapper: {
    paddingLeft: '80px',
  },
  subleaseName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    inlineSize: '140px',
  },
});
