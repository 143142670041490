import { useState } from 'react';
import { UseBaseModalProps } from './interfaces';

const useBaseModal = (): UseBaseModalProps => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalId, setModalId] = useState<null | number>(null);

  const showModal = (id?: null | number) => {
    setIsOpen(true);
    if (id) setModalId(id);
  };

  const hideModal = () => {
    setIsOpen(false);
    setModalId(null);
  };

  return {
    isOpen,
    modalId,
    showModal,
    hideModal,
  };
};

export default useBaseModal;
