import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import {
  Box, Card, CardContent, Grid, Typography,
} from '@material-ui/core';
import {
  grey,
} from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Button, IconButton } from '@mui/material';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { formatAreaSize } from '../../../../formatters/areaSizeFormatter';
import { formatPercentage } from '../../../../formatters/percentageFormatter';
import { formatMoney } from '../../../../formatters/moneyFormatter';
import { formatDateUSReadable } from '../../../../formatters/dateFormatters/dateFormatters';
import ComponentLoader from '../../../ui/ComponentLoader';
import { useNestedNavigationManager } from '../../../../navigation/nestedNavigationManager';
import { useNavigationManager } from '../../../../navigation/navigationManager';
import { cardMinimumHeight, useFloorAreaStyles } from '../../../../styles/useFloorAreaStyles';
import { useBuildingFloorArea } from './hooks';
import { BuildingFloorAreaProps, NonVacantAreaData } from './interfaces';
import { getDateFullYear } from '../../../../helpers/timePeriod';
import { HtmlTooltip } from '../../../ui/HtmlTooltip';
import { Store } from '../../../../store/AppGlobalContext';
import { storeActions } from '../../../../store/actions';
import { StackingPlanFloor } from '../../../../types/buildings';
import InfoCard from '../../../ui/InfoCard';
import ComponentError from '../../../ui/ComponentError';
import { getPercentage } from '../../../../math/lib';
import { InfoCards } from '../../../ui/DashboardBanners';
import { emptyStringValueToStringZero } from '../../../../formatters/emptyStringValueToStringZero';
import { useGetBuildingBannersQuery } from '../../../../queries/buildings';
import { useCardStyles } from '../../../../styles/useCardStyles';
import { formatNumericValue } from '../../../../formatters/numberFormatter';
import { accessToken } from '../../../../models/security';

const BuildingFloorArea: React.FC<BuildingFloorAreaProps> = (props: BuildingFloorAreaProps): ReactElement => {
  const {
    floors,
    buildingId,
    buildingStatistics,
    isLoading,
  } = props;
  const classes = useFloorAreaStyles();
  const { spacesNavigator } = useNestedNavigationManager(buildingId);
  const { data: buildingBanners, error: bannersError } = useGetBuildingBannersQuery(buildingId);
  const { tenantsNavigator } = useNavigationManager();
  const { state, dispatch } = useContext(Store);

  const {
    yearColors,
    nonYearTitles,
    isLeaseDateExpired,
    getAreaStatistics,
    getYearBarClassName,
    selectSpace,
    getSpaceWidth,
    // getSpaceClassName,
    getFilteredLeasesList,
    getSpacePercentage,
    selectBar,
    toggleFiltrationButton,
  } = useBuildingFloorArea();
  const cardClasses = useCardStyles();

  useEffect(() => {
    if (floors?.length) {
      dispatch({
        type: storeActions.UPDATE_STACKING_PLAN,
        payload: {
          selectedFloors: floors,
        },
      });
    }
  }, [floors]);

  useEffect(() => {
    if (buildingStatistics) {
      getAreaStatistics(buildingStatistics);
    }
  }, [buildingStatistics]);

  const buildingAreaStatistics = buildingStatistics ? getAreaStatistics(buildingStatistics) : [];

  const isUncultivatedAreaVisible = (floor: StackingPlanFloor): boolean => (
    Boolean(floor.uncultivatedArea !== 0 && (
      !state.stackingPlan.isYearSelectionTriggered
      || (state.stackingPlan.isYearSelectionTriggered
        && state.stackingPlan.selectedBarTitles?.includes(nonYearTitles.vacant)
      ))));

  const totalFtPercentage = (): string => {
    if (buildingBanners?.leasesTotalArea && buildingBanners.buildingTotalArea) {
      const percentage = getPercentage(Number(buildingBanners?.leasesTotalArea),
        Number(buildingBanners?.buildingTotalArea));
      return formatPercentage(percentage);
    }
    return formatPercentage(0);
  };

  const infoCards: InfoCards[] = [
    {
      icon: <PersonIcon fontSize="medium" />,
      title: 'NUMBER OF TENANTS',
      content: emptyStringValueToStringZero(buildingBanners?.numberOfTenants),
    },
    {
      icon: <HomeIcon fontSize="medium" />,
      title: 'TOTAL FT',
      subtitle: 'OCCUPIED',
      content: (formatNumericValue(emptyStringValueToStringZero(buildingBanners?.buildingTotalArea), 0)),
      subContent: totalFtPercentage(),
    },
    {
      icon: <NewReleasesIcon fontSize="medium" />,
      title: 'NEAR TERM EXP.(1 YEAR)',
      subtitle: 'TOTAL',
      content: emptyStringValueToStringZero(buildingBanners?.numberOfNearExpiringLeases),
      subContent: buildingBanners?.nearExpiringLeasedArea
        ? formatAreaSize(buildingBanners?.nearExpiringLeasedArea) : '',
    },
    {
      icon: <EqualizerIcon fontSize="medium" />,
      title: 'WEIGHTED AVG. TERM REMAINING',
      content: emptyStringValueToStringZero(buildingBanners?.weightedAverageExpiryDate),
      metrics: 'months',
    },
  ];

  return (
    <>
      <Card className={cardClasses.buildingCardBackground}>
        <CardContent>
          <Box>
            <Grid container spacing={3} className={cardClasses.cardWrapper}>
              { infoCards.map((infoCard) => (
                <Grid item xs={12} md={3} key={infoCard.title} className={cardClasses.buildingAggregatedCard}>
                  <InfoCard
                    icon={infoCard.icon}
                    title={infoCard.title}
                    content={infoCard.content}
                    subtitle={infoCard.subtitle}
                    subContent={infoCard.subContent}
                    metrics={infoCard.metrics}
                  />
                </Grid>
              ))}
              {
                bannersError && (
                <ComponentError error={bannersError} />
                )
              }
            </Grid>
          </Box>
          {floors && (
            <Box mb={3}>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Button type="button" variant="text" onClick={() => toggleFiltrationButton(floors)}>
                  <Typography className={classes.showVacantButton}>
                    { state.stackingPlan.isYearSelectionTriggered ? 'SHOW ALL' : 'SHOW VACANT ONLY' }
                  </Typography>
                </Button>
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                    {buildingStatistics && !isLoading
                    && buildingAreaStatistics.map((leaseCard: NonVacantAreaData) => (
                      <Box
                        key={leaseCard.title}
                        className={getYearBarClassName(leaseCard.title)}
                        onClick={() => selectBar(floors, leaseCard.title)}
                      >
                        <Box className={classes.yearCardInfo}>
                          <Typography className={classes.cardPercentage}>
                            {formatPercentage(leaseCard.percentage)}
                          </Typography>
                          <Typography className={classes.cardArea}>{leaseCard.area}</Typography>
                        </Box>
                        <Box
                          className={classes.yearCard}
                          style={{
                            height: cardMinimumHeight * leaseCard.percentage,
                            backgroundColor: leaseCard.color,
                          }}
                        />
                        <Typography className={classes.cardTitle}>{leaseCard.title}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {floors && state.stackingPlan.selectedFloors && !isLoading
          && state.stackingPlan.selectedFloors.map((floor) => (
            <Grid container spacing={2} key={floor.id} alignItems="center">
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    flexWrap="no-wrap"
                    className={classes.floorFlex}
                  >
                    {buildingAreaStatistics
                    && floor.spaces.map((space) => (
                      <Box
                        key={space.id}
                        className={classes.cardMinWidth}
                        width={
                        getSpaceWidth(floor, Number(space.id), Number(space.area), floor.area)
                      }
                        onClick={(event) => {
                          event.stopPropagation();
                          selectSpace(Number(space.id));
                        }}
                      >
                        <Box
                          component={Card}
                          height={178}
                          style={{
                            border: !space.terms.length ? `1px solid ${grey[900]}` : 'none',
                          }}
                        >
                          <Box
                            display="flex"
                            height="100%"
                            // className={getSpaceClassName(floor, Number(space.id), Number(space.area), floor.area)}
                          >
                            <Box className={classes.slideshowContainer}>
                              {
                                getFilteredLeasesList(space.terms).length
                                  ? (
                                    <Box width="100%" height="100%">
                                      {() => {
                                        const terms = getFilteredLeasesList(space.terms);
                                        const [currentStep, setCurrentStep] = useState<number>(0);

                                        const nextStep = () => {
                                          if (currentStep >= space.terms.length) return;
                                          setCurrentStep(currentStep + 1);
                                        };

                                        const prevStep = () => {
                                          if (currentStep === 0) return;
                                          setCurrentStep(currentStep - 1);
                                        };

                                        return (
                                          <>
                                            <HtmlTooltip
                                              arrow
                                              title={(
                                                <>
                                                  <Typography variant="subtitle2">
                                                    {terms[currentStep].lease.companyName || ''}
                                                  </Typography>
                                                  <Typography variant="caption">{space.name}</Typography>
                                                  <br />
                                                  <Typography
                                                    variant="caption"
                                                  >
                                                    {`${formatAreaSize(space.terms[currentStep].area)} | ${formatMoney(
                                                      terms[currentStep].rate,
                                                    )}` || ''}
                                                  </Typography>
                                                  <br />
                                                  <Typography variant="caption">
                                                    {formatDateUSReadable(terms[currentStep].lease.endDate) || ''}
                                                  </Typography>
                                                  {
                                                    terms[currentStep].isAvailableForSublease
                                                      ? (
                                                        <Typography variant="subtitle2">
                                                          Available for sublease
                                                        </Typography>
                                                      )
                                                      : (terms[currentStep].lease.isMonthToMonth) ? (
                                                        <Box>
                                                          <Typography variant="subtitle2">
                                                            MTM
                                                          </Typography>
                                                        </Box>
                                                      ) : (terms[currentStep].sublease?.name) ? (
                                                        !accessToken.isCurrentRoleBroker()
                                                          ? (
                                                            <Typography
                                                              variant="subtitle2"
                                                              onClick={() => spacesNavigator.single(Number(space.id))
                                                                .navigate()}
                                                            >
                                                              Subleased to:
                                                              {terms[currentStep].sublease?.name}
                                                            </Typography>
                                                          )
                                                          : (
                                                            <Typography
                                                              variant="subtitle2"
                                                            >
                                                              Subleased to:
                                                              {terms[currentStep].sublease?.name}
                                                            </Typography>
                                                          )
                                                      ) : ''
                                                  }
                                                </>
                                                         )}
                                            >
                                              <Box
                                                p={1}
                                                className={
                                                  !accessToken.isCurrentRoleBroker()
                                                    ? clsx(classes.leaseInfo, classes.fade)
                                                    : classes.leaseInfoDisabled
                                              }
                                                height="100%"
                                                style={{
                                                  backgroundColor: terms[currentStep].lease.isMonthToMonth
                                                    ? yearColors.mtm : terms[currentStep].lease.endDate
                                                      ? yearColors[`year${getDateFullYear(
                                                        terms[currentStep].lease.endDate,
                                                      )}`] : grey[300],
                                                }}
                                              >
                                                {!accessToken.isCurrentRoleBroker()
                                                  ? (
                                                    <Box
                                                      className={classes.space}
                                                      onClick={() => spacesNavigator.single(Number(space.id))
                                                        .navigate()}
                                                    >
                                                      <Typography
                                                        variant="inherit"
                                                        style={{
                                                          color: grey[50],
                                                          textDecoration: 'none',
                                                          whiteSpace: 'pre-line',
                                                        }}
                                                      >
                                                        {`${space.name}
                                                        ${space.area} ft`}
                                                      </Typography>
                                                    </Box>
                                                  )
                                                  : (
                                                    <Box
                                                      className={classes.space}
                                                    >
                                                      <Typography
                                                        variant="inherit"
                                                        style={{
                                                          color: grey[50],
                                                          textDecoration: 'none',
                                                          cursor: 'default',
                                                        }}
                                                      >
                                                        {space.name}
                                                      </Typography>
                                                    </Box>
                                                  )}
                                                <Box>
                                                  {!accessToken.isCurrentRoleBroker()
                                                    ? (
                                                      <Typography
                                                        variant="subtitle2"
                                                        style={{
                                                          color: isLeaseDateExpired(terms[currentStep].lease.endDate)
                                                            ? grey[900] : grey[50],
                                                        }}
                                                        component={Link}
                                                        className={classes.spaceTitle}
                                                        to={
                                                        tenantsNavigator.single(
                                                          Number(terms[currentStep].lease.companyId),
                                                        ).path
                                                      }
                                                      >
                                                        {terms[currentStep].lease.companyName}
                                                      </Typography>
                                                    )
                                                    : (
                                                      <Typography
                                                        variant="subtitle2"
                                                        style={{
                                                          color: isLeaseDateExpired(terms[currentStep].lease.endDate)
                                                            ? grey[900] : grey[50],
                                                        }}
                                                        className={classes.spaceDisabledTitle}
                                                      >
                                                        {terms[currentStep].lease.companyName}
                                                      </Typography>
                                                    )}
                                                </Box>
                                                <Box
                                                  fontSize={12}
                                                  display="flex"
                                                  flexDirection="row"
                                                  className={classes.visibleLarge}
                                                >
                                                  <Typography
                                                    variant="inherit"
                                                    style={{
                                                      color: isLeaseDateExpired(terms[currentStep].lease.endDate)
                                                        ? grey[600] : grey[200],
                                                    }}
                                                  >
                                                    {`${formatAreaSize(space.terms[currentStep].area)} | ${formatMoney(
                                                      terms[currentStep].rate,
                                                    )}`}
                                                  </Typography>
                                                </Box>
                                                <Box fontSize={12} className={classes.visibleLarge}>
                                                  <Typography
                                                    style={{
                                                      color: isLeaseDateExpired(terms[currentStep].lease.endDate)
                                                        ? grey[900] : grey[50],
                                                    }}
                                                    variant="inherit"
                                                  >
                                                    {formatDateUSReadable(terms[currentStep].lease.endDate)}
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  {
                                                    terms[currentStep].isAvailableForSublease
                                                      ? (
                                                        <Typography variant="subtitle2">
                                                          Available for sublease
                                                        </Typography>
                                                      )
                                                      : (terms[currentStep].lease.isMonthToMonth) ? (
                                                        <Box>
                                                          <Typography variant="subtitle2">
                                                            MTM
                                                          </Typography>
                                                        </Box>
                                                      ) : (terms[currentStep].sublease?.name) ? (
                                                        !accessToken.isCurrentRoleBroker()
                                                          ? (
                                                            <Typography
                                                              component={Link}
                                                              className={clsx(classes.sublease,
                                                                classes.visibleExtraLarge)}
                                                              to={tenantsNavigator.single(
                                                                Number(terms[currentStep].lease.companyId),
                                                              ).path}
                                                            >
                                                              Subleased to:
                                                              <span className={classes.subleaseTitle}>
                                                                {terms[currentStep].sublease?.name}
                                                              </span>
                                                            </Typography>
                                                          )
                                                          : (
                                                            <Typography
                                                              className={clsx(classes.sublease,
                                                                classes.visibleExtraLarge)}
                                                            >
                                                              Subleased to:
                                                              <span className={classes.subleaseTitle}>
                                                                {terms[currentStep].sublease?.name}
                                                              </span>
                                                            </Typography>
                                                          )
                                                      ) : ''
                                                  }
                                                </Box>
                                              </Box>
                                            </HtmlTooltip>
                                            {
                                              terms.length > 1 && (
                                                <Box
                                                  className={classes.arrows}
                                                  onClick={(e) => e.stopPropagation()}
                                                >
                                                  <IconButton
                                                    className={classes.spaceButton}
                                                    onClick={prevStep}
                                                  >
                                                    <ArrowBackIosIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    disabled={currentStep === space.terms.length - 1}
                                                    className={classes.spaceButton}
                                                    onClick={nextStep}
                                                  >
                                                    <ArrowForwardIosIcon />
                                                  </IconButton>
                                                </Box>
                                              )
                                            }
                                            <Box className={clsx(classes.slideDots, classes.visibleLarge)}>
                                              {
                                                terms.length > 1
                                                && terms.map((term, index) => (
                                                  <Box
                                                    role="button"
                                                    className={currentStep === index
                                                      ? clsx(classes.slideDot, classes.active) : classes.slideDot}
                                                    key={term.lease.id}
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      setCurrentStep(index);
                                                    }}
                                                  />
                                                ))
                                              }
                                            </Box>
                                          </>
                                        );
                                      }}
                                    </Box>
                                  ) : (
                                    <HtmlTooltip
                                      arrow
                                      title={(
                                        <>
                                          <Typography variant="subtitle2">Vacant</Typography>
                                          <Typography variant="caption">{space.name}</Typography>
                                          <br />
                                          <Typography variant="caption">{formatAreaSize(space.area)}</Typography>
                                        </>
                                      )}
                                    >
                                      <Box
                                        className={
                                        !accessToken.isCurrentRoleBroker()
                                          ? classes.leaseInfo
                                          : classes.leaseInfoDisabled
                                      }
                                        p={1}
                                        height="100%"
                                      >
                                        <Box>
                                          {!accessToken.isCurrentRoleBroker()
                                            ? (
                                              <Box
                                                className={classes.space}
                                                onClick={() => spacesNavigator.single(Number(space.id))
                                                  .navigate()}
                                              >
                                                <Typography
                                                  variant="inherit"
                                                  style={{
                                                    color: grey[50],
                                                    textDecoration: 'none',
                                                  }}
                                                >
                                                  {space.name}
                                                </Typography>
                                              </Box>
                                            )
                                            : (
                                              <Box
                                                className={classes.space}
                                              >
                                                <Typography
                                                  variant="inherit"
                                                  style={{
                                                    color: grey[50],
                                                    textDecoration: 'none',
                                                    cursor: 'default',
                                                  }}
                                                >
                                                  {space.name}
                                                </Typography>
                                              </Box>
                                            )}
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.spaceTitle}
                                            style={{
                                              color: grey[900],
                                              cursor: 'default',
                                            }}
                                          >
                                            Vacant
                                          </Typography>
                                        </Box>
                                        <Box fontSize={12} className={classes.visibleLarge}>
                                          <Typography
                                            variant="inherit"
                                            style={{ color: grey[900] }}
                                          >
                                            {formatAreaSize(space.area)}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </HtmlTooltip>

                                  )
                              }
                            </Box>
                          </Box>

                        </Box>
                      </Box>
                    ))}
                    {
                      isUncultivatedAreaVisible(floor)
                      && (
                        <HtmlTooltip
                          arrow
                          title={(
                            <Typography variant="caption">
                              {formatAreaSize(floor.uncultivatedArea)}
                            </Typography>
                          )}
                        >
                          <Box width={`${getSpacePercentage(Number(floor.uncultivatedArea), floor.area)}%`}>
                            <Box
                              component={Card}
                              style={{ border: `1px solid ${grey[900]}` }}
                              height="100%"
                            >
                              <Box className={classes.leaseInfo} fontSize={15} p={2}>
                                <Typography variant="inherit" style={{ color: grey[900] }}>
                                  {formatAreaSize(floor.uncultivatedArea)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </HtmlTooltip>
                      )
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="h6">{`Floor ${floor.name}`}</Typography>
                  <Typography variant="caption">{`${formatAreaSize(floor.area)}`}</Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
          <ComponentLoader isOpen={!!isLoading} />
        </CardContent>
      </Card>
    </>
  );
};

export default BuildingFloorArea;
