import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ModalProps } from '../interfaces';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';

const Modal: React.FC<ModalProps> = (props: ModalProps): ReactElement => {
  const {
    isOpen = false,
    handleClose,
    confirmAction,
    cancelAction,
    title = 'Confirmation',
    body,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    confirmColor = 'primary',
  } = props;

  const theme = useTheme();
  const buttonClasses = useButtonsStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleConfirmAction = () => {
    if (confirmAction) {
      confirmAction();
    }
    handleClose();
  };

  const handleCancelAction = () => {
    if (cancelAction) {
      cancelAction();
    }
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          className={buttonClasses.standard}
          onClick={handleCancelAction}
          color="primary"
        >
          {cancelText}
        </Button>
        {
          confirmAction
          && (
          <Button
            onClick={handleConfirmAction}
            className={buttonClasses.standard}
            color={confirmColor}
            autoFocus
          >
            {confirmText}
          </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
