import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export const useFormStyles = makeStyles((theme) => ({
  formWrapper: {
    paddingTop: '20px',
  },
  root: {
    padding: 24,
  },
  requirementType: {
    paddingTop: '8px',
    paddingBottom: '16px',
  },
  fieldsWrapper: {
    display: 'flex',
    gridGap: '4%',
  },
  fieldsWidth: {
    maxWidth: '328px',
    width: '100%',
  },
  assetsFieldsWidth: {
    width: '100%',
  },
  competitorFieldsWidth: {
    maxWidth: '328px',
    width: '100%',
    marginBottom: '32px',
  },
  leaseTermPeriod: {
    paddingTop: '25px',
  },
  buildingSelect: {
    '& div:nth-child(2)': {
      paddingBottom: '13px',
    },
  },
  smallerField: {
    maxWidth: '154px',
    width: '100%',
  },
  leaseSectionTitles: {
    fontSize: '11px',
    color: grey[600],
  },
  parallelFieldsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  parallelFieldsWidth: {
    maxWidth: '154px',
    width: '100%',
    margin: '10px 0',
  },
  spaceDetails: {
    fontSize: '12px',
    lineHeight: '18px',
    paddingTop: '25px',
    color: grey[600],
  },
  totalAreaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalLeasedArea: {
    paddingTop: '15px',
  },
  divider: {
    margin: '30px 0',
  },
  leaseLabels: {
    fontSize: '12px',
    color: grey[600],
  },
  addSpaceButton: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
  addTermButton: {
    display: 'flex',
    justifyContent: 'end',
  },
  rateAmount: {
    paddingTop: '3px',
  },
  checkboxWrapper: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
  },
  checkbox: {
    paddingLeft: theme.spacing(3),
  },
  datePicker: {
    marginTop: theme.spacing(1),
    maxWidth: '328px',
    width: '100%',
  },
  startDate: {
    paddingTop: '30px',
  },
  commencementDate: {
    marginTop: '18px',
  },
  inputLabel: {
    marginTop: theme.spacing(9),
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: theme.spacing(3),
  },
  radioButtonLabel: {
    color: 'black',
  },
  radioButtonOptions: {
    gridGap: '83px',
  },
  radio: {
    '&$checked': {
      color: 'black',
    },
  },
  label: {
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
  assetsContainer: {
    display: 'flex',
    paddingTop: '30px',
  },
  parallelIsAvailableField: {
    minWidth: '124px',
    paddingBottom: '15px',
  },
  parallelSpaceField: {
    minWidth: '204px',
  },
  assetsField: {
    paddingTop: '15px',
  },
  logDate: {
    paddingTop: '-4px',
  },
  nickNameBox: {
    marginTop: '16px',
  },
  tenantRap: {
    paddingTop: '50px',
  },
  tenantRapUndisclosed: {
    paddingTop: '104px',
  },
  dealCommentMargin: {
    marginTop: '30px',
  },
  competitorAssets: {
    display: 'grid',
  },
  competitorTitle: {
    paddingBottom: '20px',
  },
  isTenantUndisclosed: {
    display: 'flex',
    flexDirection: 'column',
  },
  tenantWrapper: {
    display: 'flex',
  },
  tenantIndustry: {
    padding: '2px 0px',
  },
  competitorDeleteButton: {
    position: 'absolute',
    right: '-35%',
    top: '38%',
  },
}));
