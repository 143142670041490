import { useQuery, UseQueryResult } from 'react-query';
import { State } from '../../types/geo';
import httpClient from '../../core/httpClient';
import { QUERY_OPTIONS } from '../../constants';

export const useAllStatesQuery = (): UseQueryResult<State[], Error> => useQuery<State[], Error>(
  'states',
  async () => {
    const response = await httpClient.get('/geo/countries/us/states');
    return response.data && Object.values(response.data);
  },
  QUERY_OPTIONS,
);
