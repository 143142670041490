import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { CreateLeasesMutationVariables, DeleteLeasesMutationVariables, UpdateLeasesMutationVariables } from './types';
import httpClient from '../../core/httpClient';
import { convertStringifiedBooleanToBoolean } from '../../helpers/convertStringifiedBooleanToBoolean';
import { Leases } from '../../types/leases';

export const useCreateLeaseMutation = (): UseMutationResult<Leases, Error, CreateLeasesMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Leases, Error, CreateLeasesMutationVariables>(
    async ({ request }) => {
      const modifiedSpaces = request.spaces.map((space) => ({
        spaceId: Number(space.id),
        area: space.occupiedByLease,
        rate: space.rateAmount,
        isAvailableForSublease: convertStringifiedBooleanToBoolean(space.isAvailableForSublease),
        tenantImprovement: Number(space.tenantImprovement),
      }));
      const updatedRequest = {
        companyId: Number(request.companyId),
        type: request.type,
        startDate: request.startDate,
        endDate: request.endDate,
        termPeriod: request.termPeriod,
        termType: request.termType,
        renewalNoticeStartDate: convertStringifiedBooleanToBoolean(request.renewalOption)
          ? request.renewalNoticeStartDate : '',
        renewalNoticeEndDate: convertStringifiedBooleanToBoolean(request.renewalOption)
          ? request.renewalNoticeEndDate : '',
        expansionOption: convertStringifiedBooleanToBoolean(request.expansionOption),
        purchaseOption: convertStringifiedBooleanToBoolean(request.purchaseOption),
        terminationOption: convertStringifiedBooleanToBoolean(request.terminationOption),
        isMonthToMonth: convertStringifiedBooleanToBoolean(request.isMonthToMonth),
        renewalOption: convertStringifiedBooleanToBoolean(request.renewalOption),
        guarantorCompanyName: request.guarantorCompanyName,
        guarantorStreetAddress: request.guarantorStreetAddress,
        guarantorCity: request.guarantorCity,
        guarantorState: request.guarantorState,
        guarantorZipcode: request.guarantorZipcode,
        renewalTerm: convertStringifiedBooleanToBoolean(request.renewalOption) ? request.renewalTerm : '',
        renewalTermType: convertStringifiedBooleanToBoolean(request.renewalOption) ? request.renewalTermType : '',
        spaces: [
          ...modifiedSpaces,
        ],
      };
      const response = await httpClient.post('/leases', updatedRequest);
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('leases');
      },
    },
  );
};

export const useUpdateLeasesMutation = (): UseMutationResult<Leases, Error, UpdateLeasesMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Leases, Error, UpdateLeasesMutationVariables>(
    async ({ request, leaseId }) => {
      const modifiedSpaces = request.spaces.map((space) => ({
        spaceId: Number(space.id),
        area: space.occupiedByLease,
        rate: space.rateAmount,
        isAvailableForSublease: convertStringifiedBooleanToBoolean(space.isAvailableForSublease),
        tenantImprovement: Number(space.tenantImprovement),
      }));
      const updatedRequest = {
        companyId: Number(request.companyId),
        type: request.type,
        startDate: request.startDate,
        endDate: request.endDate,
        termPeriod: request.termPeriod,
        termType: request.termType,
        renewalNoticeStartDate: convertStringifiedBooleanToBoolean(String(request.renewalOption))
          ? request.renewalNoticeStartDate : '',
        renewalNoticeEndDate: convertStringifiedBooleanToBoolean(String(request.renewalOption))
          ? request.renewalNoticeEndDate : '',
        expansionOption: request.expansionOption,
        purchaseOption: request.purchaseOption,
        terminationOption: request.terminationOption,
        isMonthToMonth: request.isMonthToMonth,
        renewalOption: request.renewalOption,
        guarantorCompanyName: request.guarantorCompanyName,
        guarantorStreetAddress: request.guarantorStreetAddress,
        guarantorCity: request.guarantorCity,
        guarantorState: request.guarantorState,
        guarantorZipcode: request.guarantorZipcode,
        renewalTerm: convertStringifiedBooleanToBoolean(String(request.renewalOption)) ? request.renewalTerm : '',
        renewalTermType: convertStringifiedBooleanToBoolean(String(request.renewalOption)) ? request.renewalTermType : '',
        spaces: [
          ...modifiedSpaces,
        ],
      };
      const response = await httpClient.put(`/leases/${leaseId}`, updatedRequest);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['news', variables.leaseId]);
      },
    },
  );
};

export const useDeleteLease = (): UseMutationResult<null, Error, DeleteLeasesMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteLeasesMutationVariables>(
    async ({ leaseId }) => httpClient.delete(`/leases/${leaseId}`),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('lease');
      },
    },
  );
};
