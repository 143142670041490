import React, { ReactElement, useEffect, useState } from 'react';
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton, InputAdornment,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useGetAllLeasesQuery } from '../../../queries/leases';
import { useDeleteLease } from '../../../queries/leases/mutations';
import PageTitle from '../../ui/PageTitle';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { Building, DropdownBuilding } from '../../../types/buildings';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { useGetAssetsDropdownQuery } from '../../../queries/assets';
import { AssetsDropdown } from '../../../types/assets';
import { generalSorting } from '../../../helpers/sorting';
import { useGetAllBuildingsByStatusQuery, useGetBuildingsByAssetId } from '../../../queries/buildings';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { Leases } from '../../../types/leases';
import { useListingsFiltrationStyles } from '../../../styles/useListingsFiltrationStyles';

const useListStyles = makeStyles({
  flexEnd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});

const ListLeases: React.FC = (): ReactElement => {
  const deleteLeaseMutation = useDeleteLease();
  const listClasses = useListStyles();
  const { leasesNavigator } = useNavigationManager();
  const [filteredAsset, setFilteredAsset] = useState<string>('');
  const [filteredBuilding, setFilteredBuilding] = useState<string>('');
  const [filteredTenant, setFilteredTenant] = useState<string>('');
  const [tenantName, setTenantName] = useState<string>('');
  const { data: assets } = useGetAssetsDropdownQuery();
  const { data: buildings } = useGetAllBuildingsByStatusQuery();
  const { data: buildingsByAsset } = useGetBuildingsByAssetId(Number(filteredAsset));
  const { data: leases, isLoading, isFetching } = useGetAllLeasesQuery(
    Number(filteredAsset),
    filteredTenant,
    filteredBuilding,
  );
  const [leasesList, setLeasesList] = useState<Leases[] | undefined>([]);
  const [isAllLeasesShown, setIsAllLeasesShown] = useState<boolean>(false);
  const [isLeasesListLoading, setIsLeasesListLoading] = useState<boolean>(true);
  const buttonClasses = useButtonsStyles();
  const filterClasses = useListingsFiltrationStyles();
  const query = new URLSearchParams(useLocation().search);
  const { path } = useRouteMatch();
  const history = useHistory();

  const updateLeasesList = () => {
    if (leases) {
      setIsLeasesListLoading(true);
      if (isAllLeasesShown) {
        setLeasesList(leases);
      } else {
        setLeasesList(leases?.filter((lease) => new Date(lease.endDate) >= new Date()));
      }
      setTimeout(() => {
        setIsLeasesListLoading(false);
      }, 1500);
    }
  };

  const navigateToEditLease = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    leasesNavigator.edit(Number(id)).navigate();
  };

  useEffect(() => {
    updateLeasesList();
  }, [leases, isAllLeasesShown]);

  useEffect(() => {
    const id = query.get('assetId') ?? '';
    const tenant = query.get('tenantName') ?? '';
    const building = query.get('buildingId') ?? '';
    const includeExpired = query.get('includeExpired') ?? '';
    setFilteredAsset(id);
    setFilteredTenant(tenant);
    setTenantName(tenant);
    setFilteredBuilding(building);
    setIsAllLeasesShown(includeExpired === 'true');
  }, []);

  useEffect(() => {
    query.set('assetId', filteredAsset);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [filteredAsset]);

  useEffect(() => {
    query.set('tenantName', tenantName);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [tenantName]);

  useEffect(() => {
    query.set('buildingId', filteredBuilding);
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [filteredBuilding]);

  useEffect(() => {
    query.set('includeExpired', String(isAllLeasesShown));
    history.push({
      pathname: path,
      search: `?${query}`,
    });
  }, [isAllLeasesShown]);

  const {
    showModal, RenderModal, modalId,
  } = useDeleteModal();

  const deleteLease = (leaseId: number | null) => {
    if (!leaseId) {
      return;
    }

    deleteLeaseMutation.mutate({ leaseId });
  };

  const toggleExpiredLeasesList = () => {
    setIsAllLeasesShown(!isAllLeasesShown);
  };

  const columns: MRT_ColumnDef<Leases>[] = [
    {
      header: 'Asset',
      accessorKey: 'asset',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.assetTitle || ''} placement="top" arrow>
          <Typography
            variant="inherit"
          >
            {row.assetTitle || ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: true,
      sortingFn: (rowA, rowB) => generalSorting(rowA.original.assetTitle, rowB.original.assetTitle),
    },
    {
      header: 'Building',
      accessorKey: 'building',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.buildingName || ''} placement="top" arrow>
          <Typography
            variant="inherit"
          >
            {row.buildingName || ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: true,
      sortingFn: (rowA, rowB) => generalSorting(rowA.original.buildingName, rowB.original.buildingName),
    },
    {
      header: 'Spaces #',
      accessorKey: 'numberOfSpaces',
      enableSorting: true,
      sortingFn: (rowA, rowB) => generalSorting(rowA.original.numberOfSpaces, rowB.original.numberOfSpaces),
      size: 150,
    },
    {
      header: 'Tenant',
      accessorKey: 'company',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip title={row.company?.name || ''} placement="top" arrow>
            <Typography
              noWrap
              variant="inherit"
            >
              {row.company?.name || ''}
            </Typography>
          </Tooltip>
          <Typography variant="caption" style={{ color: grey[700], marginLeft: '0.5em' }}>
            {row.isSublease ? ' | Sublease' : ''}
          </Typography>
        </Box>
      ),
      enableSorting: true,
      sortingFn: (rowA, rowB) => generalSorting(rowA.original.company?.name || '', rowB.original.company?.name || ''),
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={formatDateUSReadable(row.startDate)} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {formatDateUSReadable(row.startDate)}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
    },
    {
      header: 'Term Period',
      accessorKey: 'termPeriod',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={(`${row.termPeriod} ${row.termType}`)} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {(`${row.termPeriod} ${row.termType}`)}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      enableSorting: false,
      // eslint
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <IconButton
            style={{ width: '25px' }}
            onClick={
              (e) => navigateToEditLease(Number(row.id), e)
            }
            aria-label="edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            style={{ width: '25px' }}
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showModal(Number(row.id));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const searchTenant = () => {
    setFilteredTenant(tenantName);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <PageTitle>{`Leases: ${isAllLeasesShown ? 'All' : 'Active'}`}</PageTitle>
        </Box>
        <Box className={clsx(listClasses.flexEnd, filterClasses.listingFilterWrapper)}>
          <FormControlLabel
            control={
              (
                <Checkbox
                  color="primary"
                  name="createAsset"
                  checked={isAllLeasesShown}
                  onChange={toggleExpiredLeasesList}
                />
              )
            }
            label="Include expired leases"
          />
          <Box paddingBottom={1} className={listClasses.flexEnd}>
            <Box mr={2} maxWidth={158}>
              <FormControl
                margin="none"
              >
                <TextField
                  label="Search Tenant"
                  variant="standard"
                  type="text"
                  value={tenantName}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      searchTenant();
                    }
                  }}
                  onChange={(event) => setTenantName(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => searchTenant()}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
            <Box px={2} maxWidth={184}>
              <FormControl>
                <InputLabel shrink={!!filteredAsset} htmlFor="filter-asset">Filter By Asset</InputLabel>
                <Select
                  native
                  value={filteredAsset}
                  onChange={(event) => setFilteredAsset(String(event.target.value))}
                  label="Filter By Asset"
                  inputProps={{
                    name: 'filter-asset',
                    id: 'filter-asset',
                  }}
                >
                  <option aria-label="None" defaultValue="" />
                  {assets?.map((asset : AssetsDropdown) => (
                    <option key={asset.id} value={asset.id}>
                      {asset.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box px={2} maxWidth={184}>
              <FormControl>
                <InputLabel shrink={!!filteredBuilding} htmlFor="filter-building">Filter By Building</InputLabel>
                <Select
                  native
                  value={filteredBuilding}
                  onChange={(event) => setFilteredBuilding(String(event.target.value))}
                  label="Filter By Building"
                  inputProps={{
                    name: 'filter-building',
                    id: 'filter-building',
                  }}
                >
                  <option aria-label="None" defaultValue="" />
                  {!filteredAsset && buildings?.map((building : Building) => (
                    <option key={building.id} value={building.id}>
                      {building.name}
                    </option>
                  ))}
                  {
                    filteredAsset && buildingsByAsset?.map((assetBuilding: DropdownBuilding) => (
                      <option key={assetBuilding.id} value={assetBuilding.id}>
                        {assetBuilding.title}
                      </option>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
            <Button
              component={Link}
              className={buttonClasses.large}
              to={leasesNavigator.create().path}
              variant="contained"
              color="primary"
            >
              Create Lease
            </Button>
          </Box>
        </Box>
      </Box>

      <MaterialReactTable
        columns={columns}
        data={leasesList || []}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        state={{ isLoading: isLoading || isFetching || isLeasesListLoading }}
        layoutMode="grid"
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.stopPropagation();
            leasesNavigator.single(Number(row.original.id)).navigate();
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
      <RenderModal
        body="Are you sure you want to delete this Lease?"
        confirmAction={() => deleteLease(modalId)}
        title="Delete Lease"
      />
      <LoaderModal isOpen={deleteLeaseMutation.isLoading} />
    </>
  );
};

export default ListLeases;
