import { format } from 'date-fns';
import { format as dateFnsTzFormat } from 'date-fns-tz';

export const formatDate = (date: string | Date | undefined): string => (date === undefined ? ''
  : format(new Date(date), 'Y-MM-dd'));

export const formatDateReadable = (date: string): string => (format(new Date(date), 'MMM yyyy'));

export const formatDateUSReadable = (date: string | undefined): string => (
  date ? format(new Date(date), 'MMM dd, yyyy') : ''
);

export const formatDateTimeToLocal = (date: string | undefined): string => (
  date ? dateFnsTzFormat(new Date(`${date}Z`), "MMM dd, yyyy 'at' p") : ''
);

export const formatDateWithoutTimeZoneDifference = (date: string | undefined): string => (
  date ? new Date(date).toLocaleDateString('en-US', {
    timeZone: 'UTC', month: 'short', day: '2-digit', year: 'numeric',
  }) : ''
);
