export const REQUIRED_FIELD = 'Required field.';
export const MUST_BE_NUMBER = 'Must be a number.';
export const EXCEED_TOTAL_AREA_ERROR = 'Total floor area exceed building total area.';
export const GENERAL_ERROR_TEXT = 'An error occurred.';
export const COMPONENT_ERROR_TEXT = 'Oops! Something went wrong.';
export const MODAL_ERROR = 'MODAL_ERROR';
export const PRINT_NULL = 'N/A';
export const PRINT_HYPHEN = '—';
export const TABLE_ROWS_PER_PAGE = [25, 50, 100];
export const BOLD_FONT = 500;
export const ALL_YEARS = 'All Years';
export const QUERY_OPTIONS = { staleTime: 1000 * 60 * 10 };
