import React, { ReactElement } from 'react';
import EditProfileForm from '../../forms/auth/EditProfileForm/EditProfileForm';
import { useUserProfileQuery } from '../../../queries/users';
import PageTitle from '../../ui/PageTitle';

const EditProfile: React.FC = (): ReactElement => {
  const { data: profile } = useUserProfileQuery();

  return (
    <>
      <PageTitle>Edit profile</PageTitle>
      <EditProfileForm model={profile} />
    </>
  );
};

export default EditProfile;
