import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import httpClient from '../../core/httpClient';
import {
  CreateSubLeaseCompanyMutationVariables,
  CreateSubLeaseMutationVariables,
  DeleteSubLeaseMutationVariables,
  SubLease,
} from './types';

export const useCreateSubLeaseMutation = (): UseMutationResult<SubLease, Error, CreateSubLeaseMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<SubLease, Error, CreateSubLeaseMutationVariables>(
    async ({ request }) => {
      const subLeasedSpaceId = request.leasedSpaceId;
      const subLeaseCompanyId = request.companyId;
      const response = await httpClient.put(`/subleases/${subLeasedSpaceId}/company/${subLeaseCompanyId}`);
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('leases');
      },
    },
  );
};

export const useCreateSubLeaseCompanyMutation = (): UseMutationResult<void, Error,
CreateSubLeaseCompanyMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreateSubLeaseCompanyMutationVariables>(
    async ({ leasedSpaceId, request }) => httpClient
      .post(`/subleases/${leasedSpaceId}`,
        request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('leases');
      },
    },
  );
};

export const useDeleteSubLease = (): UseMutationResult<null, Error, DeleteSubLeaseMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteSubLeaseMutationVariables>(
    async ({ subleasedSpaceId }) => httpClient.delete(`/subleases/${subleasedSpaceId}`),
    {
      onSuccess: async () => queryClient.invalidateQueries('leases'),
    },
  );
};
