import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { User, UserDropdownOptions } from '../../types/users';
import httpClient from '../../core/httpClient';
import { accessToken } from '../../models/security';
import { Asset } from '../../types/assets';
import { QUERY_OPTIONS } from '../../constants';

export const useAllUsersQuery = (): UseQueryResult<User[], Error> => useQuery<User[], Error>(
  'users', async () => {
    const response = await httpClient.get('/users');
    return response.data.items;
  },
  QUERY_OPTIONS,
);
export const useUserProfileQuery = (): UseQueryResult<User, Error> => {
  let userId: number | undefined;
  if (accessToken.hasRole()) {
    userId = accessToken.getUserId();
  } else {
    window.location.replace('/auth/login');
  }

  return useQuery<User, Error>(
    ['users', userId],
    async () => {
      const response = await httpClient.get(`/users/${userId}`);
      return response.data;
    },
    {
      suspense: true,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetSingleUserQuery = (userId: number): UseQueryResult<User, Error> => {
  const queryClient = useQueryClient();

  return useQuery<User, Error>(
    ['users', userId],
    async () => {
      const response = await httpClient.get(`users/${userId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      placeholderData: () : User | undefined => queryClient
        .getQueryData<User[]>('users')
        ?.find((u:User) => u.id === userId),
    },
  );
};

export const useBrokerUserAssetsQuery = (brokerId: number): UseQueryResult<Asset[], Error> => useQuery<Asset[], Error>(
  ['brokerAssets', brokerId], async () => {
    const response = await httpClient.get(`/assets_brokers/${brokerId}/assets`);
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useUsersDropdownOptionsQuery = ():
UseQueryResult<UserDropdownOptions[], Error> => useQuery<UserDropdownOptions[], Error>(
  'userDropdownOptions', async () => {
    const response = await httpClient.get('/users/dropdown-options');
    return response.data.items;
  },
  QUERY_OPTIONS,
);
