import React, { ReactElement } from 'react';
import { LinearProgress } from '@material-ui/core';
import { GridOverlay } from '@material-ui/data-grid';

const DataGridLoader: React.FC = ():ReactElement => (
  <GridOverlay>
    <div style={{ position: 'absolute', top: 0, width: '100%' }}>
      <LinearProgress />
    </div>
  </GridOverlay>
);

export default DataGridLoader;
