import React, { ReactElement, useContext } from 'react';
import {
  Box, Button, Grid, IconButton, Link,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import clsx from 'clsx';
import PageTitle from '../../ui/PageTitle';
import { useGetSingleLeaseQuery } from '../../../queries/leases';
import { InfoCards } from '../buildings/ViewBuilding/interfaces';
import InfoCard from '../../ui/InfoCard';
import { useCardStyles } from '../../../styles/useCardStyles';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { useGetAllDocumentsQuery } from '../../../queries/documents';
import DataGridLoader from '../../ui/DataGridLoader';
import { useDeleteDocument } from '../../../queries/documents/mutations';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import DocumentModal from '../../modals/DocumentModal/DocumentModal';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import { useTableStyles } from '../../../styles/useTableStyles';
import { useViewLeaseStyles } from '../../../styles/useViewLeaseStyles';
import { LeaseAbstract } from '../../partials/leases/LeaseAbstract';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';

export interface LeaseRouteParams {
  leaseId: string
}

const ViewLease:React.FC = ():ReactElement => {
  const { state, dispatch } = useContext(Store);
  const { leaseId } = useParams<LeaseRouteParams>();
  const { data: lease } = useGetSingleLeaseQuery(Number(leaseId));
  const { data: documents, isLoading, isFetching } = useGetAllDocumentsQuery(Number(leaseId));
  const deleteDocumentMutation = useDeleteDocument();
  const cardClasses = useCardStyles();
  const tableClasses = useTableStyles();
  const buttonClasses = useButtonsStyles();
  const leaseClasses = useViewLeaseStyles();
  const {
    showModal: showDocumentDeleteModal,
    RenderModal: DocumentDeleteModal,
    modalId: documentModalId,
  } = useDeleteModal();

  const deleteDocument = (documentId: number | null) => {
    if (!documentId) {
      return;
    }
    deleteDocumentMutation.mutate({ documentId });
  };

  const handleOpenDocModal = () => {
    dispatch({ type: storeActions.OPEN_DOCUMENT_MODAL, payload: { leaseId } });
  };

  const getAllLeasedArea = () => {
    const areas = lease?.spaces.map((space) => (
      String(space.occupiedByLease)
    ));
    return areas?.reduce((a, b) => a + b);
  };

  const infoCards: InfoCards[] = [
    {
      icon: <HomeIcon fontSize="medium" />,
      iconBackground: 'secondary.main',
      title: 'TOTAL LEASE FT',
      content: formatAreaSize(String(getAllLeasedArea())),
    },
    {
      icon: <AccessAlarmsIcon fontSize="medium" />,
      iconBackground: 'warning.main',
      title: 'LEASE EXP. DATE',
      content: formatDateUSReadable(lease?.endDate),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Document Name',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Document Type',
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Link',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Link href={`${params.value}`} target="_blank">{params.value}</Link>
      ),
    },
    {
      field: 'id',
      headerName: 'Actions',
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showDocumentDeleteModal(Number(params.value));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <>
      <Box className={leaseClasses.spaceBetween}>
        <Box>
          <PageTitle>
            {lease?.company?.name}
          </PageTitle>
        </Box>
        <Box mb={3}>
          <Grid container spacing={3} className={cardClasses.cardWrapper}>
            { infoCards.map((infoCard) => (
              <Grid item key={infoCard.title}>
                <InfoCard
                  icon={infoCard.icon}
                  title={infoCard.title}
                  content={infoCard.content}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <LeaseAbstract lease={lease} leaseId={leaseId} leasedArea={getAllLeasedArea()} />
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Documents</PageTitle>
        <Button
          className={buttonClasses.standard}
          variant="contained"
          color="primary"
          onClick={() => handleOpenDocModal()}
        >
          ADD DOC
        </Button>
      </Box>
      <DataGrid
        rows={documents || []}
        columns={columns}
        className={clsx(tableClasses.tablesBackground, tableClasses.cellSelectDisabled)}
        pageSize={25}
        autoHeight
        disableColumnMenu
        components={{
          LoadingOverlay: DataGridLoader,
        }}
        loading={isFetching || isLoading}
      />
      <DocumentDeleteModal
        body="Are you sure you want to delete this Document?"
        confirmAction={() => deleteDocument(documentModalId)}
        title="Delete Document"
      />
      {
        state.documentModal.visible
        && (
        <DocumentModal
          isOpen={state.documentModal.visible}
          title="Upload Document"
          leaseId={leaseId}
        />
        )
      }
    </>
  );
};

export default ViewLease;
