import { RouteProps } from 'react-router-dom';
import React from 'react';
import { AuthRoles } from '../types/auth';

export interface LayoutRouterProps extends RouteProps {
  component: React.ComponentType
  isPrivate?: boolean
  authorizedUsers?: AuthRoles[]
}

export const defaultProps = {
  isPrivate: false,
  authorizedUsers: [AuthRoles.Admin, AuthRoles.User, AuthRoles.Broker],
};
