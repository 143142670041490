import React from 'react';
import { useGetAllNewsQuery } from '../../../queries/news';
import NewsCards from './NewsCards';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';

const ListNews: React.FC = () => {
  const {
    data: news, error: newsError, isLoading, isFetching,
  } = useGetAllNewsQuery();

  return (
    <>
      <LoaderModal isOpen={isLoading || isFetching} />
      <NewsCards data={news} error={newsError} />
    </>
  );
};

export default ListNews;
