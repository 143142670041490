import React, { ReactElement } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import clsx from 'clsx';
import { cardTitleProps } from '../../../styles/useCardStyles';
import ComponentError from '../../ui/ComponentError';
import { useNavigationManager } from '../../../navigation/navigationManager';
import DataGridLoader from '../../ui/DataGridLoader';
import { useTableStyles } from '../../../styles/useTableStyles';
import { generalSorting } from '../../../helpers/sorting';
import { ExpiringLeases } from '../../../types/leases';

export interface ExpiringLeasesTableProps {
  data: ExpiringLeases[] | undefined
  error: Error | null
  isLoading: boolean
}

export const ExpiringLeasesTable: React.FC<ExpiringLeasesTableProps> = (
  props: ExpiringLeasesTableProps,
): ReactElement => {
  const { data: expiringLeases, error: leasesError, isLoading } = props;
  const tableClasses = useTableStyles();
  const { leasesNavigator } = useNavigationManager();

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Tenant Name',
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.companyName || ''} arrow>
          <Typography variant="inherit">
            {params.row.companyName || ''}
          </Typography>
        </Tooltip>
      ),
      sortComparator: (v1, v2, params1, params2) => {
        const company1 = String(params1.value);
        const company2 = String(params2.value);
        return generalSorting(company1, company2);
      },
      flex: 1,
    },
    {
      field: 'buildingName',
      headerName: 'Building Name',
      sortComparator: (v1, v2, params1, params2) => {
        const building1 = String(params1.value);
        const building2 = String(params2.value);
        return generalSorting(building1, building2);
      },
      flex: 1,
    },
    {
      field: 'remainingDays',
      headerName: 'Expires in',
      valueFormatter: (params) => `${params.row.remainingDays} days`,
      sortComparator: (v1, v2, params1, params2) => {
        const day1 = String(params1.value);
        const day2 = String(params2.value);
        return generalSorting(day1, day2);
      },
      flex: 1,
    },
  ];

  return (
    <Card>
      <CardHeader titleTypographyProps={cardTitleProps} title="Expiring Leases" />
      <Box className={tableClasses.noBorderTable}>
        <DataGrid
          columns={columns}
          getRowId={(row) => row.leaseId}
          className={clsx(
            tableClasses.navigableTable, tableClasses.cellSelectDisabled,
          )}
          rows={expiringLeases || []}
          components={{
            LoadingOverlay: DataGridLoader,
          }}
          onRowClick={(param) => leasesNavigator.single(param.row.leaseId).navigate()}
          loading={isLoading}
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnFilter
        />
      </Box>
      { leasesError && !isLoading && (
      <ComponentError error={leasesError} />
      )}
    </Card>
  );
};
