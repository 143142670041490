import React, { ReactElement } from 'react';
import {
  Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Select,
} from '@material-ui/core';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps, getIn,
} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Delete } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { Card } from '@mui/material';
import clsx from 'clsx';
import { useBuildingForm } from './hooks';
import { State } from '../../../../types/geo';
import { BuildingStatus } from '../../../../types/buildings';
import { BuildingFormProps, BuildingFormValues } from './types';
import { useDeleteFloorMutation } from '../../../../queries/buildings/mutations';
import BuildingValidationSchema from './validators';
import { useAllStatesQuery } from '../../../../queries/geo';
import Alert from '../../../ui/Alert';
import LoaderModal from '../../../modals/LoaderModal/LoaderModal';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';
import { useFormStyles } from '../../../../styles/useLeaseStyle';
import { useBuildingCardStyles } from '../../../../styles/useBuildingStyles';

const BuildingForm: React.FC<BuildingFormProps> = (props: BuildingFormProps): ReactElement => {
  const { model } = props;
  const {
    create, update, areaAlert, isLoading,
  } = useBuildingForm();
  const deleteFloorMutation = useDeleteFloorMutation();
  const { data: states } = useAllStatesQuery();
  const buildingStatusesList = Object.keys(BuildingStatus);
  const theme = useTheme();
  const classes = useFormStyles();
  const buttonClasses = useButtonsStyles();
  const buildingClasses = useBuildingCardStyles();

  const initialValues: BuildingFormValues = {
    name: model?.name ?? '',
    totalArea: model?.totalArea ?? '',
    state: model?.state ?? '',
    city: model?.city ?? '',
    postalCode: model?.postalCode ?? '',
    streetAddress: model?.streetAddress ?? '',
    status: model?.status ?? '',
    createAsset: false,
    floors: model?.floors?.map((floor) => ({
      id: floor.id,
      name: floor.name,
      area: floor.area,
      floor: floor.floor,
    })) ?? [],
  };

  const isMobileView = (): boolean => window.innerWidth <= theme.breakpoints.values.md;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={BuildingValidationSchema}
        onSubmit={async (values: BuildingFormValues, actions: FormikHelpers<BuildingFormValues>) => {
          if (model?.id != null) {
            await update(values, actions, Number(model.id));
          } else {
            await create(values, actions);
          }
        }}
      >
        {(formProps: FormikProps<BuildingFormValues>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
          } = formProps;

          return (
            <>
              <Form noValidate>
                <Card className={buildingClasses.cardWrapper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10} md={3}>
                      <FormControl className={classes.fieldsWidth}>
                        <TextField
                          value={values.name}
                          onChange={handleChange}
                          error={!!errors.name}
                          helperText={errors.name}
                          variant="outlined"
                          margin="normal"
                          required
                          id="name"
                          label="Name"
                          type="text"
                          name="name"
                          autoFocus
                        />
                      </FormControl>
                      <FormControl className={classes.fieldsWidth}>
                        <TextField
                          value={values.streetAddress}
                          onChange={handleChange}
                          error={!!errors.streetAddress}
                          helperText={errors.streetAddress}
                          variant="outlined"
                          margin="normal"
                          id="streetAddress"
                          label="Street Address"
                          type="text"
                          name="streetAddress"
                          autoFocus
                        />
                      </FormControl>
                      <FormControl className={classes.fieldsWidth}>
                        <TextField
                          value={values.city}
                          onChange={handleChange}
                          error={!!errors.city}
                          helperText={errors.city}
                          variant="outlined"
                          margin="normal"
                          id="city"
                          label="City"
                          type="text"
                          name="city"
                          autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={10} md={3}>
                      <FormControl
                        margin="normal"
                        required
                        variant="outlined"
                        className={clsx(buildingClasses.status, classes.fieldsWidth)}
                      >
                        <InputLabel shrink={!!values.status} htmlFor="status-label">Building Status</InputLabel>
                        <Select
                          native
                          inputProps={{
                            name: 'status',
                            id: 'status-label',
                          }}
                          value={values.status}
                          onChange={handleChange}
                          error={!!errors.status}
                          label="Building Status"
                        >
                          <option aria-label="None" value="" />
                          {buildingStatusesList?.map((status: string) => (
                            <option key={status} value={status}>{status}</option>
                          ))}
                        </Select>
                        <FormHelperText id="status-helper-text" error={!!errors.status}>{errors.status}</FormHelperText>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.fieldsWidth}
                      >
                        <InputLabel shrink={!!values.state} htmlFor="state-label">State</InputLabel>
                        <Select
                          native
                          inputProps={{
                            name: 'state',
                            id: 'state-label',
                          }}
                          value={values.state}
                          onChange={handleChange}
                          error={!!errors.state}
                          label="State"
                        >
                          <option aria-label="None" value="" />
                          {states?.map((state: State) => (
                            <option key={state.code} value={state.code}>{state.name}</option>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.state}>{errors.state}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={10} md={3}>
                      <FormControl
                        className={classes.fieldsWidth}
                      >
                        <TextField
                          value={values.totalArea}
                          onChange={handleChange}
                          error={!!errors.totalArea}
                          helperText={errors.totalArea}
                          variant="outlined"
                          margin="normal"
                          required
                          id="totalArea"
                          label="Total Area"
                          type="text"
                          name="totalArea"
                          autoFocus
                        />
                      </FormControl>
                      <FormControl
                        className={classes.fieldsWidth}
                      >
                        <TextField
                          value={values.postalCode}
                          onChange={handleChange}
                          error={!!errors.postalCode}
                          helperText={errors.postalCode}
                          variant="outlined"
                          margin="normal"
                          id="postalCode"
                          label="Postal Code"
                          type="text"
                          name="postalCode"
                          autoFocus
                        />
                      </FormControl>
                    </Grid>
                    {
                        model?.id == null && (
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                                    (
                                      <Checkbox
                                        color="primary"
                                        name="createAsset"
                                        checked={values.createAsset}
                                        onChange={handleChange}
                                      />
                                    )
                                  }
                            label="Create identical asset."
                          />
                        </Grid>
                        )
                      }
                    <Grid item xs={12} sm={10}>
                      <FormControl fullWidth>
                        <FieldArray
                          name="floors"
                        >
                          {({
                            push,
                            remove,
                          }) => (
                            <>
                              <Box mb={2}>
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  className={buttonClasses.standard}
                                  onClick={() => push({
                                    id: undefined,
                                    name: '',
                                    floor: undefined,
                                    area: undefined,
                                    spaces: [],
                                  })}
                                >
                                  Add Floor
                                </Button>
                                {values.floors.map((floor, index: number) => {
                                  const name = `floors[${index}].name`;
                                  const floorOrder = `floors[${index}].floor`;
                                  const area = `floors[${index}].area`;

                                  return (
                                  // eslint-disable-next-line react/no-array-index-key
                                    <Box key={index} className={buildingClasses.box}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={10} md={4}>
                                          <FormControl
                                            fullWidth
                                            className={classes.fieldsWidth}
                                          >
                                            <TextField
                                              fullWidth
                                              value={floor.name}
                                              className={classes.fieldsWidth}
                                              onChange={handleChange}
                                              error={getIn(touched, name) && getIn(errors, name)}
                                              helperText={getIn(touched, name) && getIn(errors, name)}
                                              variant="outlined"
                                              margin="normal"
                                              id={name}
                                              required
                                              label="Name"
                                              type="text"
                                              name={name}
                                              autoFocus
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={4}>
                                          <FormControl
                                            fullWidth
                                            className={classes.fieldsWidth}
                                          >
                                            <TextField
                                              fullWidth
                                              value={floor.floor}
                                              error={getIn(touched, floorOrder) && getIn(errors, floorOrder)}
                                              helperText={getIn(touched, floorOrder) && getIn(errors, floorOrder)}
                                              onChange={handleChange}
                                              variant="outlined"
                                              margin="normal"
                                              required
                                              id={floorOrder}
                                              label="Floor Order"
                                              type="number"
                                              name={floorOrder}
                                              autoFocus
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={4}>
                                          <FormControl
                                            fullWidth
                                            className={classes.fieldsWidth}
                                          >
                                            <TextField
                                              fullWidth
                                              value={floor.area}
                                              error={getIn(touched, area) && getIn(errors, area)}
                                              helperText={getIn(touched, area) && getIn(errors, area)}
                                              onChange={handleChange}
                                              variant="outlined"
                                              margin="normal"
                                              required
                                              id={area}
                                              label="Floor Area"
                                              type="number"
                                              name={area}
                                              autoFocus
                                            />
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12} md={1}>
                                        <Box my={2}>
                                          <Button
                                            fullWidth
                                            size="large"
                                            variant="outlined"
                                            color="secondary"
                                            onClick={async () => {
                                              remove(index);
                                              if (floor?.id) {
                                                await deleteFloorMutation.mutateAsync({
                                                  floorId: Number(floor.id),
                                                  buildingId: Number(model?.id),
                                                });
                                              }
                                            }}
                                          >
                                            <Delete />
                                          </Button>
                                        </Box>
                                        {
                                            isMobileView() && (
                                            <Box p={2}>
                                              <Divider light />
                                            </Box>
                                            )
                                          }
                                      </Grid>
                                    </Box>
                                  );
                                })}
                                <Alert message={areaAlert.message} type={areaAlert.type} />
                              </Box>
                            </>
                          )}
                        </FieldArray>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Card>
                <Box>
                  <Grid item xs={12}>
                    <Box className={classes.buttonWrapper}>
                      <FormControl margin="normal">
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          className={buttonClasses.standard}
                          disabled={isSubmitting}
                        >
                          {model?.id != null ? 'Save' : 'Create'}
                        </Button>
                      </FormControl>
                    </Box>
                  </Grid>
                  <LoaderModal isOpen={isLoading} />
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default BuildingForm;
