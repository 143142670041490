import React, { ReactElement } from 'react';
import {
  Box, Card, CardContent, CardMedia, Typography,
} from '@material-ui/core';
import { useInfoCardStyles } from '../../styles/useInfoCardStyles';

export interface InfoCardProps {
  icon: ReactElement
  title: string
  content: number | string | JSX.Element
  subtitle?: string
  subContent?: number | string | JSX.Element
  metrics?: string
}

const InfoCard: React.FC<InfoCardProps> = (props: InfoCardProps): ReactElement => {
  const {
    icon, title, content, subContent, subtitle, metrics,
  } = props;
  const classes = useInfoCardStyles();

  return (
    <Card className={classes.rootCard}>
      <Box>
        <Typography className={classes.title} gutterBottom component="p" variant="caption">{title}</Typography>
      </Box>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography className={classes.contentText} component="h5" variant="h6">
            {content}
          </Typography>
          {
            metrics && (
              <Typography className={classes.metrics} component="p">
                {metrics}
              </Typography>
            )
          }
        </CardContent>
        <CardMedia className={classes.coverWrapper}>
          <Box className={classes.cover}>
            {icon}
          </Box>
        </CardMedia>
      </div>
      {
        subContent && subtitle && (
          <Box className={classes.subDetails}>
            <Typography className={classes.subtitle} component="p">
              {subtitle}
            </Typography>
            <Typography className={classes.subContent} component="p" variant="caption">
              {subContent}
            </Typography>
          </Box>
        )
      }
    </Card>
  );
};

export default InfoCard;
