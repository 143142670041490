import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@mui/material/colors';
import { BOLD_FONT } from '../constants';

export const useViewDealStyles = makeStyles((theme) => ({
  dealInfoDivider: {
    height: theme.spacing(35),
    color: grey[400],
    padding: theme.spacing(3.125),
  },
  dealInfoDividerHorizontal: {
    width: '100%',
    color: grey[400],
    paddingTop: theme.spacing(3.125),
    paddingBottom: theme.spacing(3.125),
  },
  abstractRoundBorderedCard: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2.25),
    background: 'white',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    border: `1px solid ${grey[300]}`,
    borderRadius: theme.spacing(2),
  },
  abstractInfoRow: {
    marginBottom: theme.spacing(1),
    alignItems: 'flex-start',
  },
  notesText: {
    fontSize: theme.spacing(2),
    fontWeight: 'lighter',
    display: 'block',
    textAlign: 'left',
    paddingTop: theme.spacing(1),
  },
  notesTextWhite: {
    fontSize: theme.spacing(2),
    fontWeight: 'lighter',
    display: 'block',
    textAlign: 'left',
    paddingTop: theme.spacing(1),
    color: '#ffffff',
  },
  notesTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    paddingTop: theme.spacing(1),
  },
  dealStage: {
    width: 'auto',
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  dealStageText: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  commentCard: {
    borderRadius: '4px',
    backgroundColor: '#FAFAFA',
    border: '1px solid #E0E0E0',
    padding: '18px',
    marginTop: theme.spacing(3),
  },
  ownCommentCard: {
    borderRadius: '4px',
    backgroundColor: '#4051B5',
    border: '1px solid #E0E0E0',
    padding: '18px',
    marginTop: theme.spacing(3),
  },
  editCommentCard: {
    borderRadius: '4px',
    backgroundColor: '#ffff',
    border: '1px solid #4051B5',
    padding: '18px',
    marginTop: theme.spacing(3),
    // eslint-disable-next-line max-len
    boxShadow: '0px 2px 1px -1px rgb(64 81 181 / 20%), 0px 1px 1px 0px rgb(64 81 181 / 14%), 0px 1px 3px 0px rgb(64 81 181 / 12%)',
  },
  commentButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  commentVariant: {
    '& >.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'transparent',
      borderBottom: 'none',
    },
    '& >.MuiInput-underline:after': {
      borderBottomColor: 'transparent',
      borderBottom: 'none',
    },
    '& >.MuiInput-underline:before': {
      borderBottomColor: 'transparent',
      borderBottom: 'none',
    },
  },
  commentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  noCommentsContainer: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTitle: {
    color: grey[600],
  },
  buttonTitlePurple: {
    color: '#4051B5',
  },
  dealCard: {
    height: 102,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 36,
  },
  dealCount: {
    paddingTop: 12,
    fontWeight: BOLD_FONT,
  },
  dealType: {
    fontSize: 14,
  },
  combinedInputLabel: {
    fontSize: 12,
    color: '#757575',
    lineHeight: 1.3,
  },
  combinedInput: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  commentMoreHoriz: {
    padding: '0 0 0 18px',
    color: '#FAFAFA',
  },
  editAndDeleteMenuWrapper: {
    width: '140px',
  },
  popupMenuListItem: {
    borderBottom: '1px solid #E0E0E0',
  },
}));
