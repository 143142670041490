import React, { ReactElement } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import {
  ChartEvent,
  Chart as ChartJS,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { grey } from '@mui/material/colors';
import { generateColors } from '../../../styles/colors/palette';
import { ExpiringLeasesStat } from '../../../queries/portfolio/types';
import ComponentLoader from '../../ui/ComponentLoader';
import { accessToken } from '../../../models/security';

export interface ExpiringLeasesStatProps {
  data: ExpiringLeasesStat[] | undefined
  isLoading: boolean
  assetName?: string
  assetId?: string
}

ChartJS.register(
  ArcElement,
  ChartDataLabels,
);

const ExpiringLeasesStats: React.FC<ExpiringLeasesStatProps> = (props: ExpiringLeasesStatProps): ReactElement => {
  const {
    data: expiringLeases, assetName, assetId, isLoading,
  } = props;
  const colors = generateColors(Number(expiringLeases?.length));
  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
  const history = useHistory();

  const getShortenedLeaseListLabels = () => {
    const shortenedLeasesList = expiringLeases?.slice(0, 6);
    return shortenedLeasesList?.map((lease) => (lease.year)) || [];
  };

  const leasesYearLabels = () => {
    const yearLabels = getShortenedLeaseListLabels();
    if (yearLabels.length) {
      const yearLabelsLastElement = yearLabels[yearLabels.length - 1];
      yearLabels?.splice(-1, 1, `${yearLabelsLastElement}+`);
    }
    return yearLabels;
  };

  const totalLeasesCount = () => {
    const leasesCount = expiringLeases?.map((lease) => (lease.count));
    const shortenedLeasesCount = leasesCount?.slice(0, 5);
    if (leasesCount?.length && leasesCount.length > 5) {
      const leftoverLeasesCount = leasesCount?.slice(5);
      const sumOfLeasesCount = leftoverLeasesCount?.reduce(reducer);
      shortenedLeasesCount?.push(sumOfLeasesCount || 0);
    }
    return shortenedLeasesCount;
  };

  const options = {
    // eslint-disable-next-line
    onClick: (event: ChartEvent, item: { index: number }[]) => {
      if (item?.length && expiringLeases && !accessToken.isCurrentRoleBroker()) {
        // eslint-disable-next-line no-underscore-dangle
        const labelIndex = item[0].index;
        const yearLabels = getShortenedLeaseListLabels();
        const chartLabel = yearLabels[labelIndex];
        const leaseEndYear = String(chartLabel).replace(/\D/g, '');
        const lastExpiringLeaseYear = expiringLeases[expiringLeases?.length - 1].year;
        const lastYearLabel = Number(yearLabels[yearLabels.length - 1]);

        const leaseToYear = (Number(leaseEndYear) === lastYearLabel) ? lastExpiringLeaseYear : leaseEndYear;

        const query = {
          ...(leaseEndYear && { leaseEndYearFrom: String(leaseEndYear) }),
          ...(leaseToYear && { leaseEndYearTo: String(leaseToYear) }),
          ...(assetName && { assetName: String(assetName) }),
          ...(assetId && { assetId: String(assetId) }),
        };

        const path = assetId ? '/assets/tenants-list' : '/tenants';
        history.push({
          pathname: path,
          search: `?${new URLSearchParams(query).toString()}`,
        });
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Lease Expiration Breakdown',
        padding: 10,
      },
      legend: {
        position: 'left' as const,
      },
      datalabels: {
        display: true,
        color: grey[50],
      },
    },
    scaleShowLabels: true,
    responsive: true,
  };

  const data = {
    labels: leasesYearLabels(),
    datasets: [
      {
        data: totalLeasesCount(),
        backgroundColor: colors,
      },
    ],
  };

  return (
    <>
      {
        expiringLeases && !isLoading && (
          <Doughnut data={data} options={options} height={160} />
        )
       }
      <ComponentLoader isOpen={isLoading} />
    </>
  );
};

export default ExpiringLeasesStats;
