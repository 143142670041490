import { ListResponse } from './pagination';

export interface Tenant {
  id: number | undefined
  name: string
  ceoName: string
  description: string
  linkedinUrl: string
  indeedUrl: string
  url: string
  industry?: string
  leases?: Leases[]
}

export interface TenantListing {
  id?: number
  companyId?: number
  companyName: string
  numberOfLeases?: number
  employeesCountChange: number | null,
  employeesCount: number | null,
  totalArea: number,
  leaseEndDate: string
}

export interface LeaseSpace {
  id: number | undefined
  name: string,
  occupiedByLease: string
  rateAmount: string
  isAvailableForSublease?: boolean
}

export interface AssetSpace {
  buildingName: string
  endDate: string
  floorName: string
  leaseRate: number
  leasedArea: number
  spaceName: string
  tenantName: string
}

export interface Leases {
  id?: number | undefined
  startDate: string
  endDate: string
  isSublease: boolean
  price: number
  numberOfSpaces: number
  assetTitle: string
  buildingName: string
  termPeriod: number
  termType: string,
  expansionOption: boolean
  purchaseOption: boolean
  isMonthToMonth: boolean
  terminationOption: boolean
  type: string
  space?: Space
  company?: Tenant
  spaces: Space[]
  renewalNoticeStartDate: string
  renewalNoticeEndDate: string
  spaceId: string
  companyId: string
  buildingId: string
  guarantorCompanyName?: string
  guarantorStreetAddress?: string
  guarantorCity?: string
  guarantorState?: string
  guarantorZipCode?: string
}

export interface PaginatedLeases extends ListResponse {
  items: Leases[]
  termPeriod: number
  spaces: LeaseSpace[]
  type: string
  renewalNoticeStartDate: string
  renewalNoticeEndDate: string
  expansionOption: boolean
  purchaseOption: boolean
  spaceId: string
  companyId: string
  buildingId: string
  guarantorCompanyName?: string
  guarantorCompanyAddress?: string
}

export interface Space {
  id: number
  name: string
  isVacant: boolean
  area: string
  vacantArea?: number
  rate: number
  occupiedByLease: number
  floor: Floor
  leases: Leases[]
  terms: Term[]
  tenantImprovement?: number
  spaceName: string,
  buildingName: string,
}

export interface SingleSpace extends Space {
  leases: Leases[];
}

export interface Floor {
  id: number | undefined;
  name: string;
  floor: number;
  area: number;
  spaces: Space[];
  building?: Building;
  vacantArea: number;
  uncultivatedArea: number;
}

export interface StackingPlanLease {
  id: number
  companyName: string
  companyId: number
  startDate: string
  endDate: string
  isMonthToMonth: boolean
  termPeriod: number
}

export interface Term {
  area: number
  isAvailableForSublease: boolean
  rate: number
  lease: StackingPlanLease
  sublease: Tenant
}

export interface StackingPlanSpace {
  id: number
  area: number
  name: string
  vacantArea: number
  terms: Term[]
}

export interface StackingPlanFloor {
  id: number
  name: string
  area: number
  floor: number
  uncultivatedArea: number
  vacantArea: number
  spaces: StackingPlanSpace[]
}

export enum BuildingStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export interface Building {
  id: number;
  name: string;
  totalArea: string;
  state: string;
  city: string;
  postalCode: string;
  streetAddress: string;
  floorsCount: number;
  status: BuildingStatus;
  floors?: StackingPlanFloor[];
}

export interface DropdownBuilding {
  id: number
  title: string
}

export interface SingleBuildingTenants {
  id: number,
  name: string,
}
