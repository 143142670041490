import { makeStyles } from '@material-ui/core/styles';
import { BOLD_FONT } from '../constants';

export const cardTitleProps = {
  style: {
    fontWeight: BOLD_FONT,
  },
};

export const useCardStyles = makeStyles((theme) => ({
  cardWrapper: {
    paddingTop: '18px',
  },
  card: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buildingAggregatedCard: {
    maxWidth: '239px',
  },
  buildingCardBackground: {
    marginTop: '18px',
  },
}));
