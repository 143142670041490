import React, { ReactElement } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  Legend,
  Tooltip,
  registerables,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useChartStyles } from '../../../styles/useChartStyles';
import { LeasesOccupancyState } from '../../../queries/portfolio/types';
import ComponentLoader from '../../ui/ComponentLoader';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { formatPercentage } from '../../../formatters/percentageFormatter';

export interface ReviewOccupancyChartProps {
  data: LeasesOccupancyState[] | undefined
  isLoading: boolean
}

const barColor = '#2AB5F6';
const bubbleColor = '#E84126';

const getReviewOccupancyBarDatasets = (chartData: LeasesOccupancyState[]) => {
  const occupancyData = chartData.map((item: LeasesOccupancyState) => item.occupancyPercentage);
  return {
    type: 'bar' as const,
    fill: false,
    data: occupancyData,
    backgroundColor: barColor,
    borderColor: barColor,
    borderRadius: 5,
    borderWidth: 2,
    order: 2,
    yAxisID: 'yPercentage',
  };
};

const getReviewOccupancyBubbleDatasets = (chartData: LeasesOccupancyState[]) => {
  const leasedAreaData = chartData.map((item: LeasesOccupancyState) => item.leasedArea);
  return {
    type: 'bubble' as const,
    fill: false,
    data: leasedAreaData,
    backgroundColor: bubbleColor,
    borderColor: 'white',
    borderWidth: 2,
    order: 1,
    yAxisID: 'yArea',
    radius: 6,
  };
};

const getOccupancyChartNames = (chartData: LeasesOccupancyState[]): string[] => chartData
  .map((dataItem: LeasesOccupancyState) => (dataItem.buildingName))
  .flat();

const ReviewOccupancyChart: React.FC<ReviewOccupancyChartProps> = (props: ReviewOccupancyChartProps): ReactElement => {
  ChartJS.register(
    BarElement,
    LineElement,
    Legend,
    Tooltip,
    ...registerables,
  );
  const { data: reviewOccupancyData, isLoading } = props;

  const occupancyData = () => ({
    labels: reviewOccupancyData ? getOccupancyChartNames(reviewOccupancyData) : [],
    datasets: reviewOccupancyData ? ([getReviewOccupancyBarDatasets(reviewOccupancyData),
      getReviewOccupancyBubbleDatasets(reviewOccupancyData)]) : [],
  });

  const classes = useChartStyles();

  return (
    <Box>
      {
        reviewOccupancyData && !isLoading && (
        <Chart
          className={classes.occupancyChart}
          type="scatter"
          data={occupancyData()}
          options={{
            responsive: true,
            scales: {
              yArea: {
                position: 'left',
                grid: {
                  drawBorder: false,
                  display: true,
                  drawTicks: false,
                  borderDash: [5, 15],
                  color: '#e0e0e0',
                },
                title: {
                  display: true,
                  text: 'Leased FT/Building',
                  padding: 25,
                },
              },
              yPercentage: {
                position: 'right',
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 20,
                  callback(value) {
                    return `${value}%`;
                  },
                },
                grid: {
                  drawBorder: false,
                  display: false,
                  drawTicks: false,
                },
                title: {
                  display: true,
                  text: '% in Total FT/Building',
                  padding: 25,
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const index = context.dataIndex;
                    const displayedData = Number(context.dataset.data[index]) || 0;
                    if (context.datasetIndex === 1) {
                      return `Area: ${formatAreaSize(displayedData)}`;
                    }
                    return `Percentage: ${formatPercentage(displayedData)}`;
                  },
                },
              },
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
          }}
        />
        )
}
      <ComponentLoader isOpen={isLoading} />
    </Box>
  );
};

export default ReviewOccupancyChart;
