import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import {
  SingleSpace, Space,
} from '../../types/buildings';
import { ExpiringLeases, Leases, PaginatedLeases } from '../../types/leases';
import { QUERY_OPTIONS } from '../../constants';

export const useGetAllLeasesQuery = (
  assetId?: number, tenantName?: string | null, buildingId?: string | null,
): UseQueryResult<Leases[], Error> => useQuery<Leases[], Error>(
  ['leases', assetId, tenantName, buildingId],
  async () => {
    const query = {
      ...(assetId && { asset_id: assetId }),
      ...(tenantName && { tenant_name: tenantName }),
      ...(buildingId && { building_id: buildingId }),
    };
    const response = await httpClient.get('/leases', {
      params: { ...query },
    });
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetAllSpacesQuery = (): UseQueryResult<Space[], Error> => useQuery<Space[], Error>(
  'spaces',
  async () => {
    const response = await httpClient.get('/spaces');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetExpiringLeases = (): UseQueryResult<ExpiringLeases[], Error> => useQuery<ExpiringLeases[], Error >(
  'expiring-leases',
  async () => {
    const response = await httpClient.get('/statistics/expiring-leases');
    return response.data.items;
  },
  QUERY_OPTIONS,
);

export const useGetLeasesByTenant = (tenantId: number, page: number, perPage: number)
: UseQueryResult<PaginatedLeases, Error> => useQuery<PaginatedLeases, Error>(
  ['tenant-leases', tenantId, page, perPage],
  async () => {
    const query = {
      ...(page != null && { page: page + 1 }),
      ...(perPage && { per_page: perPage }),
    };
    const response = await httpClient.get(`/companies/${tenantId}/leases`, {
      params: { ...query },
    });
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!tenantId,
  },
);

export const useGetExpiringLeasesByAsset = (assetId: number)
: UseQueryResult<ExpiringLeases[], Error> => useQuery<ExpiringLeases[], Error>(
  ['expiring-leases', assetId],
  async () => {
    const response = await httpClient.get(`/assets/${assetId}/expiring-leases`);
    return response.data.items;
  },
  {
    ...QUERY_OPTIONS,
    enabled: !!assetId,
  },
);

export const useGetExpiringLeasesYears = (): UseQueryResult<
number[], Error
> => useQuery<number[], Error>(
  'expiring-leases-years',
  async () => {
    const response = await httpClient.get('/leases/years');
    return response.data.years;
  },
  QUERY_OPTIONS,
);

export const useGetSingleSpaceQuery = (spaceId: number): UseQueryResult<SingleSpace, Error> => {
  const queryClient = useQueryClient();

  return useQuery<SingleSpace, Error>(
    ['spaces', spaceId],
    async () => {
      const response = await httpClient.get(`/spaces/${spaceId}`);
      return response.data;
    },
    {
      ...QUERY_OPTIONS,
      placeholderData: () : SingleSpace | undefined => queryClient
        .getQueryData<SingleSpace[]>('spaces')
        ?.find((s:SingleSpace) => s.id === spaceId),
    },
  );
};

export const useGetSingleLeaseQuery = (leaseId: number): UseQueryResult<Leases, Error> => {
  const queryClient = useQueryClient();

  return useQuery<Leases, Error>(
    ['leases', leaseId],
    async () => {
      const response = await httpClient.get(`leases/${leaseId}`);
      return response.data;
    },
    {
      placeholderData: () : Leases | undefined => queryClient
        .getQueryData<Leases[]>('leases')
        ?.find((l:Leases) => l.id === leaseId),
    },
  );
};
