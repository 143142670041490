import { ActionType, IInitialStateType } from './interfaces';
import { storeActions } from '../actions';
import { ALL_YEARS } from '../../constants';

export const storeInitialState: IInitialStateType = {
  documentModal: {
    visible: false,
  },
  subleaseModal: {
    visible: false,
  },
  successModal: false,
  stackingPlan: {
    isYearSelectionTriggered: false,
    selectedBarTitles: [],
    selectedFloors: undefined,
    filteredBuilding: null,
    filteredYear: ALL_YEARS,
    filteredByTenant: undefined,
    isTableActive: false,
  },
};

export const storeReducer = (state = storeInitialState, action: ActionType): IInitialStateType => {
  switch (action.type) {
    case storeActions.OPEN_DOCUMENT_MODAL:
      return { ...state, documentModal: { visible: true, ...action.payload } };
    case storeActions.HIDE_DOCUMENT_MODAL:
      return { ...state, documentModal: { visible: false, ...action.payload } };
    case storeActions.OPEN_SUBLEASE_MODAL:
      return { ...state, subleaseModal: { visible: true, ...action.payload } };
    case storeActions.HIDE_SUBLEASE_MODAL:
      return { ...state, subleaseModal: { visible: false, ...action.payload } };
    case storeActions.OPEN_SUCCESS_MODAL:
      return { ...state, successModal: true };
    case storeActions.HIDE_SUCCESS_MODAL:
      return { ...state, successModal: false };
    case storeActions.UPDATE_STACKING_PLAN:
      return { ...state, stackingPlan: { ...state.stackingPlan, ...action.payload } };
    default:
      return state;
  }
};
