import { useNavigation } from './navigation';
import { useNavigationManager } from './navigationManager';
import { UseNestedNavigationManagerProps } from './interfaces';

export const useNestedNavigationManager = (nestId: number): UseNestedNavigationManagerProps => {
  const { buildingsNavigator } = useNavigationManager();

  return {
    spacesNavigator: useNavigation(`${buildingsNavigator.base().path}/${nestId}/spaces`),
  };
};
