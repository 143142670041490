import {
  decimal, Decimal, DecimalType, NumericValue, zero,
} from './Decimal';

export function isValidNumber(number: DecimalType): boolean {
  return Decimal.isBigNumber(number) && number.isFinite();
}

export function canBeDenominator(number: DecimalType): boolean {
  return isValidNumber(number) && !number.isZero();
}

export const plus = (a: NumericValue, b: NumericValue): DecimalType => decimal(a).plus(decimal(b));

export const minus = (a: NumericValue, b: NumericValue): DecimalType => decimal(a).minus(decimal(b));

export const multiply = (a: NumericValue, b: NumericValue): DecimalType => decimal(a).multipliedBy(decimal(b));

export const divide = (a: NumericValue, b: NumericValue): DecimalType => {
  const numerator = decimal(a);
  const denominator = decimal(b);
  if (canBeDenominator(denominator)) {
    return numerator.dividedBy(denominator);
  }
  return zero();
};

export const sum = (...values: NumericValue[]): DecimalType => Decimal.sum(...values);

export function accumulate<T>(values: T[], mapper: (value: T) => NumericValue): DecimalType {
  return sum(...values.map(mapper), zero());
}

export const percent = (portion: NumericValue, total: NumericValue): DecimalType => {
  const ratio = divide(portion, total);
  return multiply(ratio, 100);
};

export const getPercentage = (val1: number, val2: number): number => (val1 / val2) * 100;
