import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from '../../forms/auth/LoginForm/LoginForm';

const Login: React.FC = (): ReactElement => {
  const accessToken = localStorage.getItem('accessToken');
  const history = useHistory();

  useEffect(() => {
    if (accessToken) {
      history.push('/');
    }
  }, [accessToken]);

  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
