import React, { ReactElement } from 'react';
import PageTitle from '../../ui/PageTitle';
import LeasesForm from '../../forms/leases/Leases/LeasesForm';

const CreateLeases: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create Lease</PageTitle>
    <LeasesForm />
  </>
);

export default CreateLeases;
