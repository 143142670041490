import React, { ReactElement, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  registerables,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {
  Box, CardHeader, FormControl, TextField,
} from '@material-ui/core';
import { Card, CardContent, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useChartStyles } from '../../../styles/useChartStyles';
import { Building } from '../../../types/buildings';
import { cardTitleProps } from '../../../styles/useCardStyles';
import { getLeasesOccupancyScheduleByBuildingId } from '../../../queries/assets';
import { Asset } from '../../../types/assets';
import ComponentLoader from '../../ui/ComponentLoader';
import { LeaseExpirationScheduleData } from '../../../types/leases';

export interface LeaseExpirationScheduleProps {
  asset: Asset | undefined
  isLoading: boolean
}

const barColor = '#2AB5F6';
const bubbleColor = '#E84126';

const filteredDate = (chartData: LeaseExpirationScheduleData[]) => chartData
  ?.map((dataItem: LeaseExpirationScheduleData) => (dataItem.occupancyPercentage
      && dataItem.leasedArea) && dataItem.year)
  .filter((item: number | null[]) => item !== null);

const getReviewOccupancyBarDatasets = (chartData: LeaseExpirationScheduleData[]) => {
  const leasedAreaData = chartData.map((item: LeaseExpirationScheduleData) => item.leasedArea);
  return {
    type: 'bar' as const,
    fill: false,
    data: leasedAreaData,
    backgroundColor: barColor,
    borderColor: barColor,
    borderRadius: 5,
    borderWidth: 2,
    order: 2,
    yAxisID: 'yArea',
  };
};

const getReviewOccupancyBubbleDatasets = (chartData: LeaseExpirationScheduleData[]) => {
  const occupancyData = chartData.map((item: LeaseExpirationScheduleData) => item.occupancyPercentage);

  return {
    type: 'line' as const,
    fill: false,
    data: occupancyData,
    backgroundColor: bubbleColor,
    borderColor: bubbleColor,
    borderWidth: 2,
    order: 1,
    yAxisID: 'yPercentage',
  };
};

const LeaseExpirationSchedule: React.FC<LeaseExpirationScheduleProps> = (props: LeaseExpirationScheduleProps):
ReactElement => {
  ChartJS.register(
    ...registerables,
  );
  const { asset: assetBuildings, isLoading } = props;
  const [building, setBuilding] = useState<Building | null>(null);

  const { data: OccupancyData } = getLeasesOccupancyScheduleByBuildingId(Number(building?.id));

  useEffect(() => {
    setBuilding(assetBuildings ? assetBuildings?.buildings[0] : null);
  }, [assetBuildings?.buildings]);

  const occupancyData = () => ({
    labels: OccupancyData ? filteredDate(OccupancyData) : [],
    datasets: OccupancyData ? ([getReviewOccupancyBarDatasets(OccupancyData),
      getReviewOccupancyBubbleDatasets(OccupancyData)]) : [],
  });

  useEffect(() => {
    occupancyData();
  }, [assetBuildings]);

  const classes = useChartStyles();

  return (
    <>
      {
        assetBuildings && !isLoading
        && (
        <Card>
          <Box display="flex" justifyContent="space-between" pr={1}>
            <CardHeader titleTypographyProps={cardTitleProps} title="Lease Expiration Schedule" />
            { assetBuildings?.buildings.length === 1
              ? (
                <Box className={classes.assetName}>
                  <Typography>{assetBuildings?.buildings[0].name}</Typography>
                </Box>
              )
              : (
                <Box>
                  <FormControl
                    required
                    margin="normal"
                  >
                    <Autocomplete
                      className={classes.autoWidth}
                      disablePortal
                      defaultValue={building}
                      id="buildingId"
                      options={assetBuildings?.buildings || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => setBuilding(
                        value,
                      )}
                      renderInput={(params) => (
                        <TextField
                                  // eslint-disable-next-line react/display-name,react/jsx-props-no-spreading
                          {...params}
                          label="Filter By Building"
                          name="buildingId"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              )}
          </Box>
          <CardContent>
            <Chart
              className={classes.occupancyChart}
              type="scatter"
              data={occupancyData()}
              options={{
                responsive: true,
                scales: {
                  yArea: {
                    position: 'left',
                    grid: {
                      drawBorder: false,
                      display: true,
                      drawTicks: false,
                      borderDash: [5, 15],
                      color: '#e0e0e0',
                    },
                    title: {
                      display: true,
                      text: 'Leased FT/Year',
                      padding: 20,
                    },
                  },
                  yPercentage: {
                    position: 'right',
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                      callback(value) {
                        return `${value}%`;
                      },
                    },
                    grid: {
                      drawBorder: false,
                      display: false,
                      drawTicks: false,
                    },
                    title: {
                      display: true,
                      text: '% in Total FT',
                      padding: 20,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    display: false,
                  },
                },
              }}
            />
          </CardContent>

        </Card>
        )
      }
      <ComponentLoader isOpen={isLoading} />
    </>
  );
};

export default LeaseExpirationSchedule;
