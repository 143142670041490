import { Tabs, TabsProps } from '@material-ui/core';
import React, { ChangeEvent } from 'react';

// TODO: Refactor types after converting to Material-UI v5

export interface TabsPropsFixed extends Omit<TabsProps, 'onChange'> {
/* eslint-disable-next-line */
  onChange: ((event: ChangeEvent<{}>, value: any) => void)
}

/* eslint-disable-next-line react/jsx-props-no-spreading */
export const TenantiTabs: React.FC<TabsPropsFixed> = (tabProps) => <Tabs {...tabProps} />;
