import { createTheme } from '@material-ui/core/styles';

export const customMaterialUIStyle = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#E20101',
        '&$error': {
          color: '#E20101',
        },
      },
    },
  },
});
