import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { User } from '../../types/users';
import { accessToken } from '../../models/security';
import httpClient from '../../core/httpClient';
import {
  CreateBrokerAssetsMutationVariables,
  CreateUserMutationVariables,
  UpdateProfileMutationVariables,
  UpdateUserMutationVariables,
} from './types';

export const useUpdateProfileMutation = (): UseMutationResult<User, Error, UpdateProfileMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, UpdateProfileMutationVariables>(
    async ({ request }) => httpClient.put(`/users/profile/${accessToken.getUserId()}`, request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users', accessToken.getUserId()]);
      },
    },
  );
};

export const useUpdateUserMutation = (): UseMutationResult<User, Error, UpdateUserMutationVariables> => {
  const queryClient = useQueryClient();
  return useMutation<User, Error, UpdateUserMutationVariables>(
    async ({ request, userId }) => {
      const updatedRequest = {
        firstName: request.firstName,
        lastName: request.lastName,
        role: request.role.toLowerCase(),
      };
      const response = await httpClient.put(`/users/${userId}`, updatedRequest);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(['users', variables.userId], data);
        const prevData = queryClient.getQueryData<User[]>(['users']);
        if (prevData) {
          queryClient.setQueryData(['users'], prevData.map((user) => {
            if (user.id === data.id) {
              return data;
            }
            return user;
          }));
        }
      },
    },
  );
};

export const useCreateUserMutation = (): UseMutationResult<User, Error, CreateUserMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, CreateUserMutationVariables>(
    async ({ request }) => {
      const response = await httpClient.post('/users', request);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<User[]>(['users']);
        if (prevData) {
          queryClient.setQueryData(['users'], [data, ...prevData]);
        }
      },
    },
  );
};

export const useCreateBrokerAssetsMutation = ():
UseMutationResult<void, Error, CreateBrokerAssetsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreateBrokerAssetsMutationVariables>(
    async ({ brokerRequest }) => {
      const response = await httpClient.post(
        `/assets_brokers/${brokerRequest.brokerId}`,
        { assets: brokerRequest.assetIds },
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('user');
      },
    },
  );
};

export const useUpdateBrokerAssetsMutation = ():
UseMutationResult<void, Error, CreateBrokerAssetsMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreateBrokerAssetsMutationVariables>(
    async ({ brokerRequest }) => {
      const response = await httpClient.put(
        `/assets_brokers/${brokerRequest.brokerId}`,
        { assets: brokerRequest.assetIds },
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('user');
      },
    },
  );
};
