import React, {
  ReactElement, useEffect, useState,
} from 'react';
import Grid from '@material-ui/core/Grid';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import HomeIcon from '@material-ui/icons/Home';
import {
  Box, Card, CardContent, CardHeader, CircularProgress, Divider, Paper, Tab, Tooltip, Typography,
} from '@material-ui/core';
import { DataGrid, GridColDef, GridPageChangeParams } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {
  useGetDataPointsDataQuery,
  useGetCompanyStatisticsQuery,
  useGetEmployeesCountChartDataQuery,
  useGetSingleTenantQuery,
} from '../../../queries/tenants';
import InfoCard from '../../ui/InfoCard';
import CompanyStatisticsTable from '../../ui/tables/CompanyStatisticsTable';
import DataGridLoader from '../../ui/DataGridLoader';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { formatMoney } from '../../../formatters/moneyFormatter';
import { DataPointsRequestVariables } from '../../../queries/tenants/types';
import EmployeesCountChart from './EmployeesCountChart';

import CompanyDescriptionCard from './CompanyDescriptionCard';
import { useChartStyles } from '../../../styles/useChartStyles';
import { formatPercentage } from '../../../formatters/percentageFormatter';
import ComponentError from '../../ui/ComponentError';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { TenantiTabs } from '../../ui/tabs/TenantiTabs';
import { a11yProps, TenantiTabPanel } from '../../ui/tabs/TenantiTabPanel';
import ComponentLoader from '../../ui/ComponentLoader';
import NewsCards from '../../pages/news/NewsCards';
import { useGetNewsByTenantId } from '../../../queries/news';
import { cardTitleProps } from '../../../styles/useCardStyles';
import { useGetLeasesByTenant } from '../../../queries/leases';
import PageTitle from '../../ui/PageTitle';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { generalSorting } from '../../../helpers/sorting';
import { TABLE_ROWS_PER_PAGE } from '../../../constants';
import { accessToken } from '../../../models/security';

export interface TenantDetailsProps {
  tenantId: number
  isCompared: boolean
}

export interface PaginationData {
  page: number
  perPage: number
}

const TenantDetails: React.FC<TenantDetailsProps> = (props: TenantDetailsProps): ReactElement => {
  const {
    tenantId,
    isCompared,
  } = props;
  const {
    data: company,
    error: companyDataError,
    isLoading: isTenantLoading,
  } = useGetSingleTenantQuery(Number(tenantId));
  const {
    data: companyAggregatedData,
    isLoading: isAggregatedDataLoading,
    error: companyAggregatedError,
  } = useGetDataPointsDataQuery(Number(tenantId));
  const classes = useChartStyles();
  const { leasesNavigator, buildingsNavigator } = useNavigationManager();

  const {
    data: employeesChartData,
    error: employeesChartError,
  } = useGetEmployeesCountChartDataQuery(Number(tenantId));

  const { data: news, error: newsError, isLoading: isNewsLoading } = useGetNewsByTenantId(Number(tenantId));

  const leasesTableColumns: GridColDef[] = [
    {
      field: 'building',
      headerName: 'Building Name',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Tooltip title={params.row.spaces[0]?.floor?.building?.name || ''} placement="top" arrow>
          <Typography
            component={Link}
            to={buildingsNavigator.single(Number(params.row.spaces[0]?.floor?.building?.id)).path}
          >
            {params.row.spaces[0]?.floor?.building?.name}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'numberOfSpaces',
      headerName: 'Spaces #',
      valueGetter: (params) => params.row.numberOfSpaces,
      sortComparator: (v1, v2, params1, params2) => (
        generalSorting(params1.row.numberOfSpaces, params2.row.numberOSpaces)
      ),
      flex: 0.5,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (<>{formatDateUSReadable(params.row.startDate)}</>),
      sortComparator: (v1, v2, params1, params2) => (
        generalSorting(params1.row.startDate, params2.row.startDate)
      ),
      flex: 0.6,
    },
    {
      field: 'termPeriod',
      headerName: 'Term Period',
      valueGetter: (params) => (params.row.termPeriod),
      sortComparator: (v1, v2, params1, params2) => (
        generalSorting(params1.row.termPeriod, params2.row.termPeriod)
      ),
      flex: 0.6,
    },
    {
      field: 'type',
      headerName: 'Rate Type',
      valueGetter: (params) => (params.row.type),
      flex: 0.6,
    },
    {
      field: 'rate',
      headerName: 'Price',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (<>{formatMoney(params.row.spaces[0]?.rate)}</>),
      sortComparator: (v1, v2, params1, params2) => {
        const rate1 = params1.row.spaces[0]?.rate || 0;
        const rate2 = params2.row.spaces[0]?.rate || 0;
        return generalSorting(rate1, rate2);
      },
      flex: 0.4,
    },
    {
      field: 'space',
      headerName: 'Area',
      valueGetter: (params) => formatAreaSize(params.row.spaces[0]?.area),
      sortComparator: (v1, v2, params1, params2) => {
        const area1 = params1.row.spaces[0]?.area || 0;
        const area2 = params2.row.spaces[0]?.area || 0;
        return generalSorting(area1, area2);
      },
      flex: 0.4,
    },
  ];

  const aggregatedPercentage = companyAggregatedData?.employeesCountData?.employeesCountChange;
  const isPercentageEmpty = !aggregatedPercentage || aggregatedPercentage === 0;
  const isPercentagePositive = aggregatedPercentage && aggregatedPercentage > 0;
  const aggregatedEmployees = companyAggregatedData?.employeesCountData?.employeesCount;

  const infoCards = [
    {
      icon: <PeopleAltIcon fontSize="medium" />,
      iconBackground: 'info.main',
      title: 'EMPLOYEES (BAY AREA)',
      content: (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography component="h5" variant="h6">
            {aggregatedEmployees || ''}
          </Typography>
          {
            companyAggregatedData && !isPercentageEmpty && (
              <Box className={isPercentagePositive ? classes.percentIncrease : classes.percentDecrease}>
                {isPercentagePositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                {formatPercentage(Number(aggregatedPercentage))}
              </Box>
            )
          }
        </Box>
      ),
    },
    {
      icon: <HomeIcon fontSize="medium" />,
      iconBackground: 'secondary.main',
      title: 'TOTAL LEASE FT',
      content: companyAggregatedData?.leasesTotalArea
        ? formatAreaSize(companyAggregatedData?.leasesTotalArea) : '',
    },
    {
      icon: <AccessAlarmsIcon fontSize="medium" />,
      iconBackground: 'warning.main',
      title: 'NEAREST EXP. DATE',
      content: formatDateUSReadable(companyAggregatedData?.leaseEndDate),
    },
  ];

  const [tabsValue, setTabsValue] = useState<number>(0);

  /* eslint-disable-next-line */
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };

  const tabs = {
    linkedIn: 'LinkedIn',
    indeed: 'Indeed',
  };

  const [tabsList, setTabsList] = useState<string[]>([]);
  const [leasesPagination, setLeasesPagination] = useState<PaginationData>({ page: 0, perPage: 25 });
  const [linkedinPagination, setLinkedinPagination] = useState<PaginationData>({ page: 0, perPage: 25 });
  const [indeedPagination, setIndeedPagination] = useState<PaginationData>({ page: 0, perPage: 25 });

  const getLeasesPaginationData = (paginationData: GridPageChangeParams) => {
    setLeasesPagination({ page: paginationData.page, perPage: paginationData.pageSize });
  };

  const getLinkedinPaginationData = (paginationData: GridPageChangeParams) => {
    setLinkedinPagination({ page: paginationData.page, perPage: paginationData.pageSize });
  };

  const getIndeedPaginationData = (paginationData: GridPageChangeParams) => {
    setIndeedPagination({ page: paginationData.page, perPage: paginationData.pageSize });
  };

  const {
    data: leases,
    error: leasesError,
    isLoading: isLeasesLoading,
    isFetching: isLeasesFetching,
  } = useGetLeasesByTenant(Number(tenantId), leasesPagination.page, leasesPagination.perPage);

  const {
    data: linkedinData, error: linkedinError,
    isLoading: isLinkedinLoading, isFetching: isLinkedinFetching, isFetched: isLinkedinFetched,
  } = useGetCompanyStatisticsQuery(
    DataPointsRequestVariables.linkedIn, Number(tenantId), linkedinPagination.page, linkedinPagination.perPage,
  );

  const {
    data: indeedData, error: indeedError,
    isLoading: isIndeedLoading, isFetching: isIndeedFetching, isFetched: isIndeedFetched,
  } = useGetCompanyStatisticsQuery(
    DataPointsRequestVariables.indeed, Number(tenantId), indeedPagination.page, indeedPagination.perPage,
  );

  const filterTabs = () => {
    const list = [tabs.linkedIn, tabs.indeed];
    if (isLinkedinFetched && !linkedinData?.items.length) {
      list.splice(list.indexOf(tabs.linkedIn), 1);
    }
    if (isIndeedFetched && !indeedData?.items.length) {
      list.splice(list.indexOf(tabs.indeed), 1);
    }
    setTabsList(list);
  };

  useEffect(() => {
    filterTabs();
  }, [linkedinData, indeedData]);

  return (
    <>
      <Box mb={3}>
        {company && (
          <CompanyDescriptionCard company={company} />
        )}
        {companyDataError && (
          <ComponentError error={companyDataError} />
        )}
      </Box>
      {
        isAggregatedDataLoading
          ? (
            <Box display="flex" justifyContent="center" alignItems="center" my={2}>
              <CircularProgress />
            </Box>
          )
          : (
            <Grid container spacing={3}>
              {infoCards.map((infoCard) => (
                <Grid item xs={12} md={isCompared ? 6 : 3} key={infoCard.title}>
                  <InfoCard
                    icon={infoCard.icon}
                    title={infoCard.title}
                    content={infoCard.content}
                  />
                </Grid>
              ))}
            </Grid>
          )
      }
      {
        companyAggregatedError && (
          <ComponentError error={companyAggregatedError} />
        )
      }
      <Grid container spacing={3}>
        {employeesChartData && (
          <Grid item xs={12}>
            <EmployeesCountChart chartData={employeesChartData} />
          </Grid>
        )}
        {employeesChartError && (
          <Grid item xs={12}>
            <ComponentError error={employeesChartError} />
          </Grid>
        )}

        {
          !!leases?.items?.length && (
            <Grid item xs={12}>
              <Box pb={2}>
                <PageTitle>Leases</PageTitle>
              </Box>
              <div style={{ height: leases.items.length >= 10 ? '526px' : 'max-content' }}>
                <DataGrid
                  columns={leasesTableColumns}
                  rows={leases.items || []}
                  components={{
                    LoadingOverlay: DataGridLoader,
                  }}
                  loading={isLeasesLoading || isLeasesFetching}
                  onRowClick={(params) => leasesNavigator.single(Number(params.id)).navigate()}
                  paginationMode="server"
                  pageSize={leasesPagination.perPage}
                  page={leasesPagination.page}
                  rowCount={leases.total}
                  rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                  onPageChange={(params) => getLeasesPaginationData(params)}
                  onPageSizeChange={(params) => getLeasesPaginationData(params)}
                  pagination
                  disableColumnMenu
                  autoHeight
                />
              </div>

            </Grid>
          )
        }
        {leasesError && (
          <Grid item xs={12}>
            {!accessToken.isCurrentRoleBroker()
            && <ComponentError error={leasesError} />}
          </Grid>
        )}

        {!!tabsList.length && (
          <Grid item xs={12}>
            <Paper style={{ minHeight: '100px', height: '100%' }}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                paddingTop={1}
              >
                <TenantiTabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={tabsValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                >
                  {tabsList.map((tab, index) => (
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    <Tab key={tab} label={tab} {...a11yProps(index)} />
                  ))}
                </TenantiTabs>
              </Box>
              <TenantiTabPanel value={tabsValue} index={tabsList.indexOf(tabs.linkedIn)}>
                <CompanyStatisticsTable
                  data={linkedinData}
                  error={linkedinError}
                  isLoading={isLinkedinLoading || isLinkedinFetching}
                  page={linkedinPagination.page}
                  perPage={linkedinPagination.perPage}
                  changePagination={getLinkedinPaginationData}
                />
              </TenantiTabPanel>
              <TenantiTabPanel value={tabsValue} index={tabsList.indexOf(tabs.indeed)}>
                <CompanyStatisticsTable
                  data={indeedData}
                  error={indeedError}
                  isLoading={isIndeedLoading || isIndeedFetching}
                  page={indeedPagination.page}
                  perPage={indeedPagination.perPage}
                  changePagination={getIndeedPaginationData}
                />
              </TenantiTabPanel>
              <ComponentLoader
                isOpen={
                  isTenantLoading || isLinkedinLoading || isIndeedLoading
                }
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader titleTypographyProps={cardTitleProps} title="Latest News and Updates" />
            <Divider />
            <CardContent>
              <NewsCards data={news} error={newsError} relatedTenantId={Number(tenantId)} isLoading={isNewsLoading} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default TenantDetails;
