import React, { ReactElement } from 'react';
import { Paper } from '@material-ui/core';
import {
  DataGrid, GridColDef, GridPageChangeParams, GridValueGetterParams,
} from '@material-ui/data-grid';
import { DataPointsListResponse, StatisticsKeys } from '../../../types/statistics';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { TABLE_ROWS_PER_PAGE } from '../../../constants';
import ComponentError from '../ComponentError';
import DataGridLoader from '../DataGridLoader';

export interface StatisticsTableProps {
  data: DataPointsListResponse | undefined
  error: Error | null
  page: number
  perPage: number
  isLoading: boolean
  changePagination: (paginationData: GridPageChangeParams) => void
}

const getStatisticsDataType = (params: GridValueGetterParams) => {
  switch (params.row.key) {
    case StatisticsKeys.employees:
      return 'Number of employees:';
    case StatisticsKeys.postings:
      return 'Number of postings:';
    case StatisticsKeys.averageRating:
      return 'Average rating:';
    case StatisticsKeys.reviews:
      return 'Number of reviews:';
    default: return '';
  }
};

const CompanyStatisticsTable: React.FC<StatisticsTableProps> = (props: StatisticsTableProps): ReactElement => {
  const {
    data, error, page, perPage, changePagination, isLoading,
  } = props;

  const columns: GridColDef[] = [
    {
      field: 'dateEntered',
      headerName: 'Date',
      valueGetter: (params) => (formatDateUSReadable(params.row.dateEntered)),
      sortComparator: (v1, v2, params1, params2) => (
        String(params1.row.dateEntered).localeCompare(String(params2.row.dateEntered), 'en-US', { numeric: true })
      ),
      flex: 1,
    },
    { field: 'locationCriteria', headerName: 'Location', flex: 1 },
    {
      field: 'key',
      headerName: 'Data Type',
      sortable: false,
      valueGetter: (params) => getStatisticsDataType(params),
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Count',
      sortComparator: (v1, v2, params1, params2) => (
        String(params1.row.value).localeCompare(String(params2.row.value), 'en-US', { numeric: true })
      ),
      width: 100,
    },
  ];

  return (
    <Paper>
      {
        data && (
          <div style={{ height: data.items.length >= 10 ? '526px' : 'max-content' }}>
            <DataGrid
              columns={columns}
              rows={data.items || []}
              paginationMode="server"
              pageSize={perPage}
              page={page}
              rowCount={data.total}
              rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              onPageChange={(params) => changePagination(params)}
              onPageSizeChange={(params) => changePagination(params)}
              components={{
                LoadingOverlay: DataGridLoader,
              }}
              loading={isLoading}
              pagination
              disableColumnMenu
              autoHeight={data.items.length < 10}
            />
          </div>
        )
      }
      {
        error && (
          <ComponentError error={error} />
        )
      }
    </Paper>
  );
};

export default CompanyStatisticsTable;
