import React from 'react';
import CustomModal from '../Modal/Modal';
import useBaseModal from '../hooks';
import { RenderModalProps, UseModalProps } from '../interfaces';

const useDeleteModal = (): UseModalProps => {
  const {
    isOpen, modalId, showModal, hideModal,
  } = useBaseModal();

  const DeleteModal = (props: RenderModalProps) => {
    const {
      body, confirmAction, title, ...rest
    } = props;
    return (
      <>
        {isOpen ? (
          <CustomModal
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            isOpen={isOpen}
            confirmAction={confirmAction}
            confirmColor="secondary"
            confirmText="Delete"
            handleClose={hideModal}
            body={body}
            title={title}
          />
        ) : null}
      </>
    );
  };

  return {
    isOpen,
    modalId,
    showModal,
    hideModal,
    RenderModal: DeleteModal,
  };
};

export default useDeleteModal;
