import {
  FormControl, FormHelperText, Grid, Select, TextField,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { DocumentFormProps, DocumentFormValues } from './types';
import { DocumentType } from '../../../queries/documents/types';
import { useDocumentForm } from './hooks';
import { DocumentValidationSchema } from './validators';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';

const useStyles = makeStyles(() => createStyles({
  formSelect: {
    marginTop: '8px',
  },
  buttonWrapper: {
    display: 'flex',
    '& > button': {
      marginLeft: 'auto',
    },
  },
}));

const DocumentForm: React.FC<DocumentFormProps> = (props: DocumentFormProps): ReactElement => {
  const { model } = props;
  const { create } = useDocumentForm();
  const documentTypeList = Object.keys(DocumentType);
  const buttonClasses = useButtonsStyles();

  const initialValues: DocumentFormValues = {
    title: model?.title ?? '',
    url: model?.url ?? '',
    type: model?.type ?? '',
    leaseId: model?.leaseId ?? '',
  };

  const classes = useStyles();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={DocumentValidationSchema}
        onSubmit={async (values: DocumentFormValues, actions: FormikHelpers<DocumentFormValues>) => {
          await create(values, actions);
        }}
      >
        {(formProps: FormikProps<DocumentFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = formProps;

          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Form noValidate>
                    <FormControl fullWidth>
                      <TextField
                        autoFocus
                        onChange={handleChange}
                        value={values.title}
                        error={!!errors.title}
                        helperText={errors.title}
                        margin="normal"
                        id="title"
                        label="Document Name"
                        type="title"
                        fullWidth
                        variant="outlined"
                        required
                      />
                      <Select
                        native
                        className={classes.formSelect}
                        onChange={handleChange}
                        value={values.type}
                        error={!!errors.type}
                        inputProps={{
                          name: 'type',
                          id: 'type',
                        }}
                        label="Document Type"
                        variant="outlined"
                        required
                      >
                        <option aria-label="None" value="" />
                        {documentTypeList.map((type: string) => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </Select>
                      <FormHelperText id="status-helper-text" error={!!errors.type}>{errors.type || ''}</FormHelperText>
                      <TextField
                        autoFocus
                        onChange={handleChange}
                        value={values.url}
                        error={!!errors.url}
                        helperText={errors.url}
                        margin="normal"
                        id="url"
                        label="Document Link"
                        type="url"
                        fullWidth
                        variant="outlined"
                        required
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      className={classes.buttonWrapper}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className={buttonClasses.standard}
                        disabled={isSubmitting}
                      >
                        ADD DOC
                      </Button>
                    </FormControl>
                    <LoaderModal isOpen={isSubmitting} />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default DocumentForm;
