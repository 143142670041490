import React, { ReactElement } from 'react';
import {
  DataGrid, GridColumns, GridRenderCellParams, GridRowParams,
} from '@mui/x-data-grid';
import clsx from 'clsx';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@mui/material/colors';
import XDataGridLoader from '../../ui/XDataGridLoader';
import { useTableStyles } from '../../../styles/useTableStyles';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { emptyValueToHyphen } from '../../../helpers/emptyValueToHyphen';
import { sortArea } from '../../../helpers/sortArea';
import { AssetSpace } from '../../../types/assets';

export interface StackingPlanBuildingId {
  assetSpaces: AssetSpace[] | undefined
  isLoading: boolean
}

const useRowStyles = makeStyles({
  emptySpace: {
    border: `1px solid ${grey[700]}`,
  },
  vacantSpace: {
    backgroundColor: grey[300],
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
});

const ListStackingPlan: React.FC<StackingPlanBuildingId> = (props: StackingPlanBuildingId): ReactElement => {
  const { assetSpaces, isLoading } = props;

  const classes = useTableStyles();

  const rowClasses = useRowStyles();

  const assetSpacesWithId = () => assetSpaces?.map((assetSpace) => ({ ...assetSpace, id: generateUniqueID() }));

  const isLeaseExpired = (space: AssetSpace) => new Date(space.endDate) < new Date();

  const isLeaseAbsent = (space: AssetSpace) => !space.leaseRate && !space.leasedArea && !space.endDate;

  const getTenantName = (space: AssetSpace) => (
    isLeaseAbsent(space) ? 'Empty Space' : emptyValueToHyphen(space.tenantName)
  );

  const getRowClass = (params: GridRowParams): string => {
    if (isLeaseAbsent(params.row as AssetSpace)) {
      return rowClasses.emptySpace;
    }
    if (isLeaseExpired(params.row as AssetSpace)) {
      return rowClasses.vacantSpace;
    }
    return '';
  };

  const columns: GridColumns = [
    {
      field: 'tenantName',
      headerName: 'Tenant Name',
      // eslint-disable-next-line react/display-name
      renderCell: (params:GridRenderCellParams) => (
        <Tooltip title={getTenantName(params.row)} placement="top" arrow>
          <Typography
            variant="inherit"
          >
            {getTenantName(params.row)}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'buildingName',
      headerName: 'Building Name',
      valueGetter: (params) => (params.row.buildingName),
      // eslint-disable-next-line react/display-name
      renderCell: (params:GridRenderCellParams) => (
        <Tooltip title={params.row.buildingName || ''} placement="top" arrow>
          <Typography
            variant="inherit"
          >
            {params.row.buildingName || ''}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'spaceNumber',
      headerName: 'Space Number',
      valueGetter: (params) => (params.row.spaceName),
      flex: 1,
    },
    {
      field: 'floorName',
      headerName: 'Floor',
      flex: 0.5,
    },
    {
      field: 'leaseRate',
      headerName: 'Lease Rate',
      valueGetter: (params) => emptyValueToHyphen(params.row.leaseRate),
      flex: 0.7,
    },
    {
      field: 'leasedArea',
      headerName: 'Leased Area',
      renderCell: (params) => (params.row.leasedArea
        ? formatAreaSize(params.row.leasedArea) : emptyValueToHyphen(params.row.leasedArea)),
      sortComparator: (v1, v2, params1,
        params2) => sortArea(params1, params2),
      flex: 1,
    },
    {
      field: 'endDate',
      headerName: 'Leased End Date',
      type: 'date',
      renderCell: (params: GridRenderCellParams) => (formatDateUSReadable(params.row.endDate)),
      flex: 1,
    },
  ];

  return (
  // eslint-disable-next-line react/react-in-jsx-scope
    <DataGrid
      rows={assetSpacesWithId() || []}
      columns={columns}
      className={clsx(classes.navigableTable, classes.cellSelectDisabled, classes.tablesBackground)}
      getRowClassName={(params) => getRowClass(params)}
      pageSize={25}
      autoHeight
      disableColumnMenu
      components={{
        LoadingOverlay: XDataGridLoader,
      }}
      loading={isLoading}
    />
  );
};

export default ListStackingPlan;
