import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { useChartStyles } from '../../../styles/useChartStyles';
import ComponentError from '../../ui/ComponentError';
import ComponentLoader from '../../ui/ComponentLoader';
import { cardTitleProps } from '../../../styles/useCardStyles';
import { ExpiringLeases } from '../../../types/leases';

export interface ExpiringLeasesListProps {
  data: ExpiringLeases[] | undefined
  error: Error | null
  isLoading: boolean
}

const ExpiringLeasesList: React.FC<ExpiringLeasesListProps> = (props: ExpiringLeasesListProps): ReactElement => {
  const { data: expiringLeases, error: leasesError, isLoading } = props;
  const history = useHistory();
  const classes = useChartStyles();

  const isDataPresent = (): boolean => !!expiringLeases?.length;

  return (
    <Card>
      <CardHeader titleTypographyProps={cardTitleProps} title="Expiring Leases" />
      <Divider />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          {isDataPresent() && !isLoading && (
            <TableBody>
              {expiringLeases?.map((row) => (
                <TableRow
                  key={row.leaseId}
                  onClick={() => history.push(`/leases/view/${row.leaseId}`)}
                  className={classes.linkPointer}
                >
                  <TableCell component="th" scope="row">
                    {row.companyName + row.buildingName}
                  </TableCell>
                  <TableCell align="right">{`${row.remainingDays} days`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {
            leasesError && !isLoading && (
              <ComponentError error={leasesError} />
            )
          }
          {
            !isDataPresent() && !isLoading && (
              <Box display="flex" alignItems="center" justifyContent="center" my={3}>
                No Results.
              </Box>
            )
          }
          <ComponentLoader isOpen={isLoading} />
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ExpiringLeasesList;
