import React, {
  MouseEvent, ReactElement, useState,
} from 'react';
import {
  Box, Divider, Typography, Grid,
} from '@mui/material';
import {
  FormControl, IconButton, InputAdornment, MenuItem, TextField,
} from '@material-ui/core';
import { Delete, Edit, MoreHoriz } from '@material-ui/icons';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { GridCellValue } from '@mui/x-data-grid';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { useViewLeaseStyles } from '../../../styles/useViewLeaseStyles';
import { useViewDealStyles } from '../../../styles/useViewDealStyles';
import {
  DealRouteParams, dealStageOptions, DealStageType,
} from '../../../types/deals/deals';
import { useGetDealCommentsQuery, useGetSingleDealQuery } from '../../../queries/deals';
import { formatDateTimeToLocal, formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { useUserProfileQuery } from '../../../queries/users';
import {
  useCreateDealCommentMutation,
  useDeleteDealCommentMutation,
  useDeleteDealMutation, useUpdateCommentMutation,
} from '../../../queries/deals/mutations';
import ComponentLoader from '../../ui/ComponentLoader';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { getFormattedAddress } from '../../../helpers/address';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import LoaderModal from '../../modals/LoaderModal/LoaderModal';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { DealType, dealTypeOptions } from '../../../types/deals/dealType';
import { RequirementType, requirementTypeOptions } from '../../../types/deals/dealRequirementType';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';

let commentIdGlobal: number | null = null;

const ViewDeal:React.FC = ():ReactElement => {
  const leaseClasses = useViewLeaseStyles();
  const dealClasses = useViewDealStyles();
  const buttonClasses = useButtonsStyles();
  const { dealId } = useParams<DealRouteParams>();
  const { data: comments, isFetching, isLoading } = useGetDealCommentsQuery(Number(dealId));
  const { data: deal } = useGetSingleDealQuery(Number(dealId));
  const { data: profile } = useUserProfileQuery();
  const [dealComment, setDealComment] = useState<string>('');
  const [dealUpdatedComment, setDealUpdatedComment] = useState<string>('');
  const [commentId, setCommentId] = useState<number | null>(null);
  const [editActive, setEditActive] = useState<boolean>(false);
  const createMutation = useCreateDealCommentMutation();
  const deleteDealMutation = useDeleteDealMutation();
  const deleteDealCommentMutation = useDeleteDealCommentMutation();
  const { dealsNavigator } = useNavigationManager();
  const updateCommentMutation = useUpdateCommentMutation();

  const dealStage = new DealStageType(dealStageOptions);
  const dealType = new DealType(dealTypeOptions);
  const requirementType = new RequirementType(requirementTypeOptions);

  const addCommentToDeal = () => {
    createMutation.mutate({ comment: dealComment, dealId: Number(dealId) });
    setDealComment('');
  };

  const updateCommentToDeal = (singleCommentId: number | null) => {
    updateCommentMutation.mutate({ comment: dealUpdatedComment, commentId: Number(singleCommentId) });
    setDealUpdatedComment('');
  };

  const isUserCommentOwner = (userId:number | undefined, commenterId:number | undefined) => {
    if (!userId || !commenterId) return false;
    return userId === commenterId;
  };

  const {
    showModal: showDealDeleteModal,
    RenderModal: DealDeleteModal,
    modalId: dealModalId,
  } = useDeleteModal();
  const {
    showModal: showCommentDeleteModal,
    RenderModal: CommentDeleteModal,
    modalId: commentModalId,
  } = useDeleteModal();

  const deleteDeal = (singleDealId: number | null) => {
    if (!singleDealId) {
      return;
    }
    deleteDealMutation.mutate({ dealId: singleDealId });
    dealsNavigator.base().navigate();
  };

  const deleteComment = (singleCommentId: number | null) => {
    if (!singleCommentId) {
      return;
    }
    deleteDealCommentMutation.mutate({ commentId: singleCommentId });
  };

  const displayUndisclosedTenant = (undisclosed: string | undefined) => {
    if (undisclosed) {
      return `Undisclosed (${undisclosed})`;
    }

    return 'Undisclosed (Placeholder)';
  };

  const displayRequirementSize = (requirementMinSize: number | undefined, requirementMaxSize: number | undefined) => {
    if (requirementMinSize && requirementMaxSize) {
      return `${requirementMinSize}-${requirementMaxSize} ft`;
    }
    if (requirementMinSize && !requirementMaxSize) {
      return `${requirementMinSize}- ft`;
    }
    if (!requirementMinSize && requirementMaxSize) {
      return `-${requirementMaxSize} ft`;
    }
    return '';
  };

  const navigateToViewDealEdit = (singleDealId: GridCellValue, event: React.MouseEvent) => {
    event.stopPropagation();
    dealsNavigator.edit(Number(singleDealId)).navigate();
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography variant="h5" style={{ paddingLeft: '24px', fontWeight: 'bold' }}>
        {!deal?.tenant ? displayUndisclosedTenant(deal?.undisclosedTenant) : deal?.tenant?.name}
      </Typography>
      <Typography style={{ paddingLeft: '24px', fontWeight: 'lighter', marginBottom: '25px' }}>
        {deal?.representative ? deal.representative : ''}
      </Typography>
      <Box className={leaseClasses.abstractWrapper}>
        <Box className={dealClasses.abstractRoundBorderedCard}>
          <Box className={leaseClasses.spaceBetween} marginBottom={3}>
            <Box className={dealClasses.dealStage} style={{ backgroundColor: dealStage.getColorByKey(deal?.stage) }}>
              <Typography variant="inherit" className={dealClasses.dealStageText}>
                {dealStage.getDisplayNameByKey(deal?.stage)}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={
                    (e) => navigateToViewDealEdit(dealId, e)
                  }
                aria-label="edit"
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => showDealDeleteModal(Number(dealId))}
              >
                <Delete />
              </IconButton>
            </Box>
          </Box>
          <Box className={leaseClasses.termCard}>
            <Box className={leaseClasses.infoWrapper}>
              <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>
                DEAL INFO
              </Typography>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRowAlignedStart)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Asset(s)
                </Typography>
                <Box>
                  {deal?.assets.map((item) => (
                    <Typography key={item.id} variant="inherit" className={leaseClasses.abstractText}>
                      {item.title}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRowAlignedStart)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Space(s)
                </Typography>
                <Box>
                  {deal?.spaces.map((item) => (
                    <Typography key={item.id} variant="inherit" className={leaseClasses.abstractText}>
                      {`${item.spaceName} (${item.buildingName})`}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Deal Type
                </Typography>
                <Box>
                  <Typography variant="inherit" className={leaseClasses.abstractText}>
                    {dealType.getDisplayNameByKey(deal?.dealType)}
                  </Typography>
                </Box>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Log Date
                </Typography>
                <Box>
                  <Typography variant="inherit" className={leaseClasses.abstractText}>
                    {formatDateUSReadable(deal?.logDate)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={dealClasses.dealInfoDivider}>
              <Divider orientation="vertical" />
            </Box>
            <Box className={leaseClasses.infoWrapper}>
              <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>
                TENANT INFO
              </Typography>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRowAlignedStart)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Name/Nickname
                </Typography>
                <Typography variant="inherit" className={leaseClasses.abstractText}>
                  {!deal?.tenant ? displayUndisclosedTenant(deal?.undisclosedTenant) : deal?.tenant?.name}
                </Typography>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Rep. Broker
                </Typography>
                <Typography variant="inherit" className={leaseClasses.abstractText}>
                  {deal?.representative ? deal.representative : ''}
                </Typography>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Requirement Type
                </Typography>
                <Typography variant="inherit" className={leaseClasses.abstractText}>
                  {requirementType.getDisplayNameByKey(deal?.requirementType)}
                </Typography>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Requirement Size (ft)
                </Typography>
                <Typography variant="inherit" className={leaseClasses.abstractText}>
                  {displayRequirementSize(deal?.requirementMinSize, deal?.requirementMaxSize)}
                </Typography>
              </Box>
              <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                  Commencement Date
                </Typography>
                <Typography variant="inherit" className={leaseClasses.abstractText}>
                  {formatDateUSReadable(deal?.commencementDate)}
                </Typography>
              </Box>
            </Box>
            <Box className={dealClasses.dealInfoDivider}>
              <Divider orientation="vertical" />
            </Box>
            <Box className={leaseClasses.infoWrapper}>
              <Typography variant="inherit" className={dealClasses.notesTitle}>
                Other Requirement Notes
              </Typography>
              <Typography variant="inherit" className={dealClasses.notesText}>
                {deal?.requirementNotes}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Box className={dealClasses.abstractRoundBorderedCard}>
            <FormControl
              margin="none"
              fullWidth
            >
              <TextField
                label="Add a comment"
                variant="outlined"
                margin="normal"
                type="text"
                value={dealComment}
                multiline
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    addCommentToDeal();
                    event.preventDefault();
                  }
                }}
                onChange={(event) => setDealComment(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="primary" disabled={!dealComment} onClick={() => addCommentToDeal()}>
                        <Typography
                          variant="overline"
                          className={dealComment ? dealClasses.buttonTitlePurple : dealClasses.buttonTitle}
                          style={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          SEND
                        </Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box style={{ maxHeight: 400, paddingBottom: '24px', overflow: 'scroll' }}>
              {comments?.length === 0 ? (
                <Box className={dealClasses.noCommentsContainer}>
                  <Typography variant="inherit">No comments yet</Typography>
                </Box>
              )
                : (
                  <>
                    {comments?.map((comment) => (
                      <>
                        {
                          Number(commentId) !== Number(comment.id) ? (
                            <Box
                              key={comment.id}
                              className={isUserCommentOwner(profile?.id, comment.user.id)
                                ? dealClasses.ownCommentCard : dealClasses.commentCard}
                            >
                              <Box className={dealClasses.commentInfo}>
                                <Typography
                                  variant="inherit"
                                  className={
                                          isUserCommentOwner(profile?.id, comment.user.id)
                                            ? leaseClasses.abstractSubtitleWhite : leaseClasses.abstractSubtitle
                                        }
                                >
                                  {`${comment.user.firstName} ${comment.user.lastName}`}
                                </Typography>
                                <Box style={{ display: 'flex' }}>
                                  <Typography
                                    className={
                                            isUserCommentOwner(profile?.id, comment.user.id)
                                              ? leaseClasses.abstractTitlesWhite : leaseClasses.abstractTitles
                                          }
                                  >
                                    {formatDateTimeToLocal(comment.createdAt)}
                                  </Typography>
                                  {
                                        isUserCommentOwner(profile?.id, comment.user.id)
                                        && (
                                        <Box>
                                          <IconButton
                                            className={dealClasses.commentMoreHoriz}
                                            onClick={
                                              (event) => {
                                                commentIdGlobal = comment.id;
                                                handleOpenMenu(
                                                  event,
                                                );
                                                setEditActive(false);
                                              }
}
                                          >
                                            <MoreHoriz />
                                          </IconButton>
                                          <Menu
                                            id="menu-appbar"
                                            PopoverClasses={{
                                              paper: dealClasses.editAndDeleteMenuWrapper,
                                            }}
                                            elevation={2}
                                            getContentAnchorEl={null}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right',
                                            }}
                                            open={openMenu}
                                            onClose={handleClose}
                                          >
                                            <MenuItem
                                              onClick={() => {
                                                setEditActive(true);
                                                setCommentId(commentIdGlobal);
                                                setAnchorEl(null);
                                              }}
                                              className={dealClasses.popupMenuListItem}
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => showCommentDeleteModal(commentIdGlobal)}
                                              style={{ color: '#E20101' }}
                                            >
                                              Delete
                                            </MenuItem>
                                          </Menu>
                                        </Box>
                                        )
                                      }
                                </Box>
                              </Box>
                              <Typography
                                variant="inherit"
                                className={
                                        isUserCommentOwner(profile?.id, comment.user.id)
                                          ? dealClasses.notesTextWhite : dealClasses.notesText
                                      }
                              >
                                {comment.comment}
                              </Typography>
                            </Box>
                          )
                            : Number(commentId) === Number(comment.id) && editActive && (
                            <Box className={dealClasses.editCommentCard}>
                              <TextField
                                fullWidth
                                multiline
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                defaultValue={comment.comment}
                                onChange={(event) => setDealUpdatedComment(event.target.value)}
                                variant="standard"
                                className={dealClasses.commentVariant}
                              />
                              <Box className={dealClasses.commentButtonWrapper}>
                                <Button
                                  autoFocus
                                  className={buttonClasses.standard}
                                  onClick={() => {
                                    setCommentId(null);
                                  }}
                                  color="secondary"
                                >
                                  cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    updateCommentToDeal(commentId);
                                    setCommentId(null);
                                  }}
                                  className={buttonClasses.standard}
                                  color="primary"
                                  autoFocus
                                >
                                  update
                                </Button>
                              </Box>
                            </Box>
                            )
                          }

                      </>
                    ))}
                    <ComponentLoader isOpen={isLoading || isFetching} />
                  </>
                )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={dealClasses.abstractRoundBorderedCard}>
            <Typography variant="overline" display="block" className={leaseClasses.abstractTitles}>
              COMPETITOR ASSET(S)
            </Typography>
            {deal?.competitors.length === 0 ? (
              <Box className={dealClasses.noCommentsContainer}>
                <Typography variant="inherit">No competitor assets available.</Typography>
              </Box>
            )
              : (
                <Box>
                  {deal?.competitors.map((item) => (
                    <Box key={item.rate}>
                      <Box display="inline">
                        <Typography variant="h5" display="inline" style={{ paddingRight: '10px', fontWeight: 'bold' }}>
                          {item.assetTitle}
                        </Typography>
                        <Typography display="inline" style={{ fontWeight: 'lighter' }}>
                          {`$${item.rate}`}
                        </Typography>
                      </Box>
                      <Typography style={{ fontWeight: 'lighter', paddingBottom: '12px' }}>
                        {getFormattedAddress(item.streetAddress, item.city, item.state, item.zipcode)}
                      </Typography>
                      <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                        <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                          Term Period
                        </Typography>
                        <Typography variant="inherit" className={leaseClasses.abstractText}>
                          {`${item.termPeriod} ${item.termType}`}
                        </Typography>
                      </Box>
                      <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                        <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                          Tenant Improvement ($/ft)
                        </Typography>
                        <Typography variant="inherit" className={leaseClasses.abstractText}>
                          {item.tenantImprovement}
                        </Typography>
                      </Box>
                      <Box className={clsx(leaseClasses.spaceBetween, leaseClasses.abstractInfoRow)}>
                        <Typography variant="inherit" className={leaseClasses.abstractSubtitle}>
                          Space Size (ft)
                        </Typography>
                        <Typography variant="inherit" className={leaseClasses.abstractText}>
                          {formatAreaSize(item?.spaceSize)}
                        </Typography>
                      </Box>
                      <Typography variant="inherit" className={dealClasses.notesTitle}>
                        Notes
                      </Typography>
                      <Typography variant="inherit" className={dealClasses.notesText}>
                        {item.notes}
                      </Typography>
                      <Box className={dealClasses.dealInfoDividerHorizontal}>
                        <Divider orientation="horizontal" />
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
          </Box>
        </Grid>
      </Grid>
      <DealDeleteModal
        body="Are you sure you want to delete this Deal?"
        confirmAction={() => deleteDeal(dealModalId)}
        title="Delete Deal"
      />
      <LoaderModal isOpen={deleteDealMutation.isLoading} />
      <CommentDeleteModal
        body="Are you sure you want to delete this Comment?"
        confirmAction={() => deleteComment(commentModalId)}
        title="Delete Comment"
      />
      <LoaderModal isOpen={deleteDealCommentMutation.isLoading} />
    </>
  );
};

export default ViewDeal;
