import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { EditProfileFormValues, UseEditProfileForm } from './types';
import { useUpdateProfileMutation } from '../../../../queries/users/mutations';
import { UpdateProfileRequest } from '../../../../queries/users/types';
import { AlertProps } from '../../../ui/Alert';

const useEditProfileForm = (): UseEditProfileForm => {
  const mutation = useUpdateProfileMutation();

  const [formAlert, setFormAlert] = useState<AlertProps>({
    message: '',
    type: undefined,
  });

  const update = async (values: EditProfileFormValues, actions: FormikHelpers<EditProfileFormValues>) => {
    setFormAlert({ message: '', type: undefined });

    try {
      const request: UpdateProfileRequest = values;
      await mutation.mutateAsync({ request });
      setFormAlert({ message: 'Profile updated successfully', type: 'success' });
    } catch (error) {
      switch (error.response?.status) {
        case 422:
          actions.setErrors(error.response.data.errors);
          break;
        default:
      }
    }
  };

  return {
    update,
    formAlert,
  };
};

export default useEditProfileForm;
