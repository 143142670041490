import React, { ReactElement } from 'react';
import PageTitle from '../../ui/PageTitle';
import AssetForm from '../../forms/assets/AssetForm/AssetForm';

const CreateAsset: React.FC = (): ReactElement => (
  <>
    <PageTitle>Create Asset</PageTitle>
    <AssetForm />
  </>
);

export default CreateAsset;
