import { GridSortCellParams } from '@mui/x-data-grid';
import { generalSorting } from './sorting';

export const sortArea = (params1: GridSortCellParams, params2: GridSortCellParams): number => {
  const stringifiedValue1 = String(params1.value).replace(/\D/g, '');
  const stringifiedValue2 = String(params2.value).replace(/\D/g, '');
  const cleanValue1 = stringifiedValue1 || '0';
  const cleanValue2 = stringifiedValue2 || '0';

  const totalArea1 = parseInt(cleanValue1, 10);
  const totalArea2 = parseInt(cleanValue2, 10);
  return generalSorting(totalArea1, totalArea2);
};

export const sortAreaColumn = (value1: string, value2: string): number => {
  const stringifiedValue1 = String(value1).replace(/\D/g, '');
  const stringifiedValue2 = String(value2).replace(/\D/g, '');
  const cleanValue1 = stringifiedValue1 || '0';
  const cleanValue2 = stringifiedValue2 || '0';

  const totalArea1 = parseInt(cleanValue1, 10);
  const totalArea2 = parseInt(cleanValue2, 10);
  return generalSorting(totalArea1, totalArea2);
};
