import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../ui/PageTitle';
import { useGetSingleSpaceQuery } from '../../../queries/buildings';
import SpaceForm from '../../forms/buildings/spaces/SpaceForm/SpaceForm';

export interface EditSpaceProps {
  spaceId: string
}

const EditSpace: React.FC = (): ReactElement => {
  const { spaceId } = useParams<EditSpaceProps>();
  const { data: space } = useGetSingleSpaceQuery(Number(spaceId));

  return (
    <>
      <PageTitle>Edit Space</PageTitle>
      {space && <SpaceForm model={space} />}
    </>
  );
};

export default EditSpace;
