import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const cardMinimumHeight = 2;

export const useFloorAreaStyles = makeStyles((theme: Theme) => ({
  yearCardInfo: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  yearCard: {
    marginBottom: theme.spacing(1.5),
    width: theme.spacing(10.5),
    minHeight: cardMinimumHeight,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  inactiveYear: {
    opacity: 0.5,
  },
  cardPercentage: {
    fontSize: theme.spacing(1.875),
    fontWeight: 'bold',
  },
  cardArea: {
    fontSize: theme.spacing(1.25),
    color: grey[600],
  },
  cardTitle: {
    fontSize: theme.spacing(2.125),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  floorFlex: {
    minHeight: theme.spacing(14),
    rowGap: theme.spacing(1),
    gap: theme.spacing(1),
    height: '100%',
    overflowY: 'auto',
  },
  spaceTitle: {
    fontSize: '13px',
    cursor: 'pointer',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  spaceDisabledTitle: {
    fontSize: '13px',
    overflow: 'hidden',
    cursor: 'default',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  largeSpace: {
    flexDirection: 'row',
  },
  extraLargeSpace: {
    flexDirection: 'row',
  },
  smallSpace: {
    '& $visibleLarge': {
      display: 'none',
    },
    '& $visibleExtraLarge': {
      display: 'none',
    },
    '& $spaceTitle': {
      display: 'none',
    },
  },
  mediumSpace: {
    '& $visibleLarge': {
      display: 'none',
    },
    '& $visibleExtraLarge': {
      display: 'none',
    },
    '& $spaceTitle': {
      fontSize: theme.spacing(1.625),
      wordBreak: 'break-word',
    },
    '& $leaseInfo': {
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(4),
    },
  },
  spaceWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '15px',
  },
  space: {
    display: 'flex',
    backgroundColor: grey[900],
    padding: '4px 4px 2px 4px',
    marginTop: '10px',
    marginBottom: '12px',
    borderRadius: '4px',
    textOrientation: 'mixed',
    cursor: 'pointer',
    width: 'fit-content',
    fontSize: 12,
    '&:hover': {
      backgroundColor: grey[700],
    },
  },
  leaseInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
  },
  leaseInfoDisabled: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'default',
  },
  leaseYearGrid: {
    marginBottom: theme.spacing(8),
    minHeight: theme.spacing(1),
  },
  leaseYearCard: {
    minHeight: theme.spacing(13),
  },
  slideshowContainer: {
    position: 'relative',
    width: '100%',
  },
  fade: {
    animationName: 'fade',
    animationDuration: '1.5s',
  },
  '@-webkit-keyframes fade': {
    from: { opacity: 0.4 },
    to: { opacity: 1 },
  },
  '@keyframes fade': {
    from: { opacity: 0.4 },
    to: { opacity: 1 },
  },
  arrows: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    top: '55%',
    right: '4%',
    '& .css-i4bv87-MuiSvgIcon-root': {
      width: '12px',
      color: '#fafafa',
    },
  },
  slideDots: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    top: '85%',
    right: '6%',
  },
  slideDot: {
    cursor: 'pointer',
    height: 9,
    width: 9,
    marginLeft: 2,
    marginRight: 2,
    backgroundColor: grey[200],
    borderRadius: '50%',
    display: 'inline-block',
    transition: 'background-color 0.6s ease',
  },
  sublease: {
    paddingTop: '20px',
    fontSize: '12px',
    color: 'white',
  },
  subleaseTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  spaceButton: {
    padding: '0px',
  },
  active: {
    backgroundColor: grey[50],
  },
  '.slideDot:hover': {
    backgroundColor: grey[50],
  },
  showVacantButton: {
    color: '#4051B5',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  invisibleSpace: {
    display: 'none',
  },
  visibleLarge: {},
  visibleExtraLarge: {},
  multipleNotSelectedSpace: {
    '& $space': {
      display: 'none',
    },
    '& $visibleLarge': {
      display: 'none',
    },
    '& $visibleExtraLarge': {
      display: 'none',
    },
    '& $spaceTitle': {
      display: 'none',
    },
  },
  cardMinWidth: {
    minWidth: 'fit-content',
  },
}));
