import { TenantiEnum } from '../../core/TenantiEnum';

export const requirementTypeOptions = {
  office: {
    value: 'office',
    displayValue: 'Office',
  },
  medical_office: {
    value: 'medical_office',
    displayValue: 'Medical Office',
  },
  industrial: {
    value: 'industrial',
    displayValue: 'Industrial',
  },
  life_science: {
    value: 'life_science',
    displayValue: 'Life Science',
  },
  retail: {
    value: 'retail',
    displayValue: 'Retail',
  },
};

export class RequirementType extends TenantiEnum {

}
